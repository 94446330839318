import React from "react"
import "./ArticleFilterForm.scss"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { ArticleFilterFormValues } from "../../../models/articleFilter"
import FormikArticleFilterForm from "./FormikArticleFilterForm"
import getTime from "date-fns/getTime"

export interface ArticleFilterFormProps {
    onSubmit: (values: ArticleFilterFormValues) => void
    onCancel: () => void
    articlesFilter: ArticleFilterFormValues
}

const processValues = (values: ArticleFilterFormValues) => {
    if (!values.DateFrom || !values.DateTo) {
        delete values.DateFrom
        delete values.DateTo
    } else {
        values.DateFrom = getTime(values.DateFrom)
        values.DateTo = getTime(values.DateTo)
    }

    if (!values.Tags?.length) delete values.Tags
    if (!values.Type) delete values.Type

    if (values.ParameterValues) {
        const filteredParameterValues = values.ParameterValues.filter(p => p)

        filteredParameterValues.length
            ? (values.ParameterValues = filteredParameterValues)
            : delete values.ParameterValues
    }
}

const ArticleFilterForm: React.FC<ArticleFilterFormProps> = props => {
    const { articlesFilter, onSubmit, onCancel } = props

    const { t } = useTranslation()

    const resetFilters = () => {
        if (Object.keys(articlesFilter).length) onSubmit({})
    }

    return (
        <Formik
            initialValues={articlesFilter}
            onSubmit={(values: ArticleFilterFormValues) => {
                if (Object.keys(values).length) {
                    processValues(values)
                    onSubmit(values)
                } else {
                    resetFilters()
                    onCancel()
                }
            }}
        >
            {formikProps => <FormikArticleFilterForm {...formikProps} {...props} t={t} />}
        </Formik>
    )
}

export default ArticleFilterForm
