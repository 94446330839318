import React from "react"
import "./AddButtonNode.scss"
import { ClassProps } from "../../../../utility/common/props"
import AddButton from "../../../AddButton/AddButton"
import cn from "classnames"

interface Props extends ClassProps {
    onClick: (source?: string, sourceHandle?: string) => void
}

const AddButtonNode: React.FC<Props> = props => {
    const { className, onClick } = props

    return (
        <div className={cn("add-button-node", className)}>
            <AddButton
                key="addButtonNode"
                className="add-button-node__btn"
                iconClassName="add-button-node__icon"
                variant="outline-light"
                onClick={e => {
                    e.stopPropagation()
                    onClick()
                }}
            />
        </div>
    )
}

export default AddButtonNode
