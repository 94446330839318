import type { Metric } from "web-vitals"
import type { SendSystemMetricsRequest, SystemMetric } from "@aibotdev/ct-analytics"
import { MetricType } from "@aibotdev/ct-analytics"

const reportWebVitals = (onPerfEntry?: (request: SendSystemMetricsRequest) => void) => {
    if (onPerfEntry) {
        import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
            const gettingWebVitalsFunctions = [getCLS, getFID, getFCP, getLCP, getTTFB]
            for (const onGetWebVital of gettingWebVitalsFunctions) {
                onGetWebVital(metric => {
                    onPerfEntry({ metrics: [formatVitalsToSystemMetric(metric)] })
                })
            }
        })
    }
}

function formatVitalsToSystemMetric(webVitalsMetric: Metric): SystemMetric {
    return {
        Name: webVitalsMetric.name,
        Value: webVitalsMetric.value,
        Type: MetricType.Histogram,
        Labels: {
            rating: webVitalsMetric.rating
        }
    }
}

export default reportWebVitals
