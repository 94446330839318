import React from "react"
import cn from "classnames"
import TextareaAutosize from "react-textarea-autosize"
import { FieldInputProps, FieldMetaProps } from "formik"
import { ClassProps } from "../../utility/common/props"

export interface FormikTextareaAutosizeProps extends ClassProps {
    id: string
    field: FieldInputProps<string>
    meta: FieldMetaProps<string>
    placeholder: string
}

const FormikTextareaAutosize: React.FC<FormikTextareaAutosizeProps> = props => {
    const { className, id, field, meta, placeholder } = props

    return (
        <TextareaAutosize
            onKeyPress={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                if (e.key === "Enter") e.preventDefault()
            }}
            id={id}
            name={field.name}
            onChange={field.onChange}
            placeholder={placeholder}
            className={cn(className, meta.touched && !!meta.error && "is-invalid")}
            value={field.value}
            onBlur={field.onBlur}
        />
    )
}

export default FormikTextareaAutosize
