import React from "react"
import { Col, Form } from "react-bootstrap"
import { Select } from "../../Select/Select"
import { getIn, useFormikContext } from "formik"
import {
    ConditionsFormValues,
    ConditionStatementType,
    ConditionStatementValue,
    ConditionType
} from "../../../models/scenarioCondition"
import Slider from "../../Slider/Slider"

interface Props {
    name: string
    conditionStatement: ConditionStatementValue
}

export const comparisonSigns: ConditionType[] = [
    ConditionType.Equal,
    ConditionType.Less,
    ConditionType.Greater,
    ConditionType.LessEqual,
    ConditionType.GreaterEqual
]

const AttemptsControl: React.FC<Props> = props => {
    const { name, conditionStatement } = props
    const { values, setFieldValue } = useFormikContext<ConditionsFormValues>()

    const condition = getIn(values, `${name}.Condition`)

    return (
        <div className="choose-conditions-form__subfield">
            <Form.Row className="choose-conditions-form__row">
                <Col md={2}>
                    <Select
                        value={{
                            value: condition,
                            label: condition
                        }}
                        isDisabled={conditionStatement.Type !== ConditionStatementType.Attempts}
                        options={comparisonSigns.map(type => ({
                            label: type,
                            value: type
                        }))}
                        onChange={option => option && setFieldValue(`${name}.Condition`, option.value, false)}
                    />
                </Col>
                <Col md={9} className="choose-conditions-form__slider">
                    <Slider
                        min={1}
                        max={10}
                        step={1}
                        marks={{ 1: "1", 10: "10" }}
                        onChange={(v: number) => {
                            setFieldValue(`${name}.Attempts`, v, false)
                        }}
                        value={getIn(values, `${name}.Attempts`)}
                        disabled={conditionStatement.Type !== ConditionStatementType.Attempts}
                    />
                </Col>
            </Form.Row>
        </div>
    )
}

export default AttemptsControl
