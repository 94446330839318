import React, { useCallback } from "react"
import { ClassProps } from "../../../utility/common/props"
import { useDispatch } from "react-redux"
import { SearchArticlesRequest } from "../../../models/article"
import { SearchSharedArticlesRequest } from "../../../models/sharing"
import { searchSharedArticles } from "../../../store/knowledgeBase/sharedThunks"
import SearchArticlesInputLogic from "../SearchArticlesInputLogic"

interface SharedSearchArticleInputContainerProps extends ClassProps {
    shareId?: string
}

const SharedSearchArticleInputContainer: React.FC<SharedSearchArticleInputContainerProps> = props => {
    const { className, shareId } = props
    const dispatch = useDispatch()

    const handleSearch = useCallback(
        (request: SearchArticlesRequest) => {
            if (shareId) {
                const sharedRequest: SearchSharedArticlesRequest = {
                    Type: request.Type,
                    Text: request.Text,
                    EnablePayload: request.EnablePayload,
                    ParameterValues: request.ParameterValues,
                    Tags: request.Tags,
                    PageFrom: request.PageFrom,
                    PageSize: request.PageSize
                }
                dispatch(searchSharedArticles(shareId, sharedRequest))
            }
        },
        [dispatch, shareId]
    )

    return <SearchArticlesInputLogic onSearch={handleSearch} className={className} />
}

export default SharedSearchArticleInputContainer
