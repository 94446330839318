import { ParameterDeclaration, ParameterDeclarationDto, ParamValues } from "./parameterDeclaration"
import { ClassifierConfigValue } from "./classifier"

export interface Agent {
    Id: string
    ProjectId: string
    Type: AgentType
    ClientWebHook?: string
    NextAgent?: string
    FailOverAgent?: string
    Params: ParamValues
}

export enum AgentType {
    EchoAgent = "echoagent",
    AIAssist2 = "aiassist2",
    RoutingAgent = "routingagent",
    CallCenter = "callcenter",
    InitialMsgAgent = "initialmsgagent",
    AIAssist = "aiassist",
    SwitchAgent = "switchagent",
    EmailAgent = "emailagent",
    ABAgent = "abagent",
    NightAgent = "nightagent",
    RestAgent = "restagent",
    RemoteWorkplaceAgent = "remoteworkplaceagent",
    FixedReplyAgent = "fixedreplyagent",
    FinishDialogAgent = "finishdialogagent",
    SurveysAgent = "surveys_agent",
    IdentificationAgent = "identificationagent",
    JqAgent = "jqagent",
    JavaScriptAgent = "jsagent",
    MEDODSIntegration = "medods_integration",
    ADFSAgent = "adfsagent",
    PsbBssAgent = "psbbssagent",
    YandexMapAgent = "yandexmaplinkagent",
    YandexMapGeocodeAgent = "yandexmapgeocodeagent",
    Unknown = "unknown"
}

export interface AgentDeclarationsDto {
    [type: string]: ParameterDeclarationDto[]
}

export interface AgentDeclarations {
    [type: string]: ParameterDeclaration[]
}

export interface AgentValuesBase {
    Id: string
    NextAgent?: string
    FailOverAgent?: string
    Params: ParamValues
}

export type AgentValues = AgentValuesBase | NightAgentValues | RestAgentValues

export interface RoutingAgentValues {
    Id: string
    FailOverAgent: string
    allow_only_known_agents: boolean
    DefaultSettings: DefaultSettingsValues
}

export type DefaultSettingsValues = {
    call_center: string
    lang: string
    skill: string
    group: string
    max_dialogs: number
    operator_id: string
    queue_id: string
    reply_after_routing: string
}

export enum ButtonTitleSource {
    Title = "title",
    Parameter = "parameter",
    Question = "question"
}

export interface AIAssist2AgentParamValues {
    max_intent_repetition: number
    max_intent_repetition_enabled: boolean
    max_intent_repetition_intent: string
    cancel_button_title: string
    button_classifier_threshold: number
    enable_two_step_intent_clarification: boolean
    two_step_intent_clarification_intent: string
    intent_clarification_button_title_source: ButtonTitleSource
    intent_clarification_intent_parameter_id: string
    clarify_intent: boolean
    clarify_intent_in_reply: boolean
    message_text_for_intent_clarification: string
    max_clarification_intents: number
    knowledge_base_mode: boolean
    knowledge_base_mode_max_subsections: number
    knowledge_base_mode_use_back_button: boolean
    dictionary_slot_max_buttons: number
    dictionary_slot_shortlist_buttons: number
    use_user_input_slot_during_slot_filling: boolean
    intent_clarification_intent_types_to_exclude: string[]
}

export interface AIAssist2AgentValues {
    Id: string
    IntentSettings: IntentSettingsValues
    ClassifiersConf: ClassifierConfigValue[]
    Params: AIAssist2AgentParamValues
}

export interface IntentSettingsValues {
    stop_sf_intent: string
    fail_intent: string
    file_input_intent: string
}

export interface NightAgentValues extends AgentValuesBase {
    Schedule: ScheduleValue[]
}

export interface ScheduleDto {
    NightStartTime: string
    NightEndTime: string
    WeekDays: number[]
    Dates: string[]
}

export interface ScheduleValue {
    NightStartHr: number
    NightStartMin: number
    NightEndHr: number
    NightEndMin: number
    WeekDays: number[]
    Dates: string[]
    AllDay: boolean
}

export interface RestAgentValues extends AgentValuesBase {
    Requests: RequestValues
    AuthorizationRequest: RequestValues
    CustomApiKeyEnabled: boolean
    RequestTokenEnabled: boolean
}

export interface AuthorizationRequestValue {
    BaseUri: string
    Body: string
    Headers: string[]
    Method: string[]
}

export interface RequestDto {
    Id: string
    BaseUri: string
    Method: string
    Query: Pair[]
    Headers: Pair[]
    Body: string
    TimeoutSec: number
    ResponseParameters: ResponseParameters
}

interface ResponseParameters {
    HttpCodeToSlot: boolean
    Ignore: boolean
    ExtractParameters: ExtractParameterDto[]
}

export interface ExtractParameterDto {
    JsonPaths?: string
    Header?: string
    SlotId: string
}

export interface RequestValues {
    BaseUri: string
    BodyEnabled: boolean
    Body: string
    Headers: Pair[]
    Id: string
    Method: string
    Query: Pair[]
    TimeoutSec: number
    TimeoutSecEnabled: boolean
    HttpCodeToSlot: boolean
    Ignore: boolean
    ExtractParameters: ExtractParameterValue[]
}

export enum ExtractParametersType {
    Header = "Header",
    JsonPaths = "JsonPaths"
}

export interface ExtractParameterValue {
    Type: ExtractParametersType
    JsonPaths?: string
    WholeResponse: boolean
    Header?: string
    SlotId: string
    SlotTitle?: string
}

export interface Pair {
    n: string
    v: string
}

export interface IdentificationAgentConfigItem {
    UserType: string
    Action: string
}

export interface ClaimsMapping {
    claim: string
    slot: string
    field: string
}

export interface ClaimsMappingValue extends ClaimsMapping {
    fieldEnabled?: boolean
}

export enum EmailType {
    Text = "text",
    Template = "template"
}
