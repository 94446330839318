import React from "react"
import "./CommonNodeHeader.scss"
import cn from "classnames"
import BlockIcon from "../../../BlockIcon/BlockIcon"
import CloseButton from "../../../CloseButton/CloseButton"
import { ClassProps } from "../../../../utility/common/props"
import { ScenarioBlockType } from "../../../../models/scenario"

interface Props extends ClassProps {
    id: string
    onClick: () => void
    type: ScenarioBlockType
    title: string
}

const CommonNodeHeader: React.FC<Props> = props => {
    const { type, title, onClick, className } = props

    return (
        <div className={cn("common-node-header", className)}>
            <BlockIcon type={type} className="common-node-header__icon" />
            {title}
            <CloseButton onClick={onClick} />
        </div>
    )
}

export default CommonNodeHeader
