import React, { useMemo } from "react"
import { AgentFormProps } from "../../AgentForm"
import { FormikProps } from "formik"
import { RequestValues, RestAgentValues } from "../../../../models/agent"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateAgentState, selectUpdateAgentState } from "../../../../store/agents/selectors"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import RadioButtonValidatableInput from "../../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import InfoIcon from "../../../InfoIcon/InfoIcon"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import RequestSettingsSection from "./common/RequestSettingsSection/RequestSettingsSection"
import { ParamValues } from "../../../../models/parameterDeclaration"
import DebouncedCheckBox from "../../../CheckBoxValidatableInput/DebouncedCheckBox"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import { ExtractParameterSettingsInput, RestAgentRequestType } from "./common/ExtractParameterSettingsSection"

const tNamespace = "agent:form."
const tTooltipNamespace = "agent:tooltip."

const FormikRestAgentFormAuthorization: React.FC<AgentFormProps & FormikProps<RestAgentValues> & WithT> = props => {
    const { agent, t, values, handleSubmit, disabled } = props

    const asyncState = useSelector(agent ? selectUpdateAgentState : selectCreateAgentState, shallowEqual)
    const isDisabled = useMemo(() => !values.RequestTokenEnabled, [values.RequestTokenEnabled])

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="agent-form__section agent-form__section_border">
                <DebouncedCheckBox
                    id="formRequestTokenEnabled"
                    name={nameof<RestAgentValues>("RequestTokenEnabled")}
                    label={t(`${tNamespace}request-token-before-call`)}
                    className="agent-form__check-box-margin"
                />
                <DebouncedCheckBox
                    id="formCustomApiKeyEnabled"
                    name={nameof<RestAgentValues>("CustomApiKeyEnabled")}
                    label={t(`${tNamespace}set-api-id`)}
                    className="agent-form__check-box-margin"
                    icon={<InfoIcon id="popoverCustomApiKeyEnabled" content={t(`${tTooltipNamespace}set-api-id`)} />}
                    disabled={isDisabled}
                />
                <div className="extra-settings">
                    <DebouncedValidatableInput
                        id="formCustomApiKey"
                        type="text"
                        name={nameof2<RestAgentValues, ParamValues>("Params", "custom-api-key")}
                        className="agent-form__message-textarea"
                        placeholder={t(`${tNamespace}enter-id`)}
                        disabled={isDisabled || !values.CustomApiKeyEnabled}
                    />
                </div>
                <DebouncedValidatableInput
                    id="formBaseUriAuth"
                    type="text"
                    name={nameof2<RestAgentValues, RequestValues>("AuthorizationRequest", "BaseUri")}
                    label={t(`${tNamespace}webhook-authorization`)}
                    disabled={isDisabled}
                />
                {t(`${tNamespace}method`)}
                <div className="rest-agent-form__radio-buttons-short">
                    <RadioButtonValidatableInput
                        inline
                        value="GET"
                        id="formMethodType1Auth"
                        name={nameof2<RestAgentValues, RequestValues>("AuthorizationRequest", "Method")}
                        label="GET"
                        disabled={isDisabled}
                    />
                    <RadioButtonValidatableInput
                        inline
                        value="POST"
                        id="formMethodType2Auth"
                        name={nameof2<RestAgentValues, RequestValues>("AuthorizationRequest", "Method")}
                        label="POST"
                        disabled={isDisabled}
                    />
                </div>
            </div>
            <div className="agent-form__section">
                <div className="agent-form__request-subtitle">{t(`${tNamespace}request`)}</div>
                <RequestSettingsSection sectionType="AuthorizationRequest" type="Headers" disabled={isDisabled} />
                <RequestSettingsSection sectionType="AuthorizationRequest" type="Query" disabled={isDisabled} />
            </div>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formBodyAuth"
                    as="textarea"
                    name={nameof2<RestAgentValues, RequestValues>("AuthorizationRequest", "Body")}
                    label={t(`${tNamespace}json-body-template`)}
                    className="agent-form__json-body-template"
                    disabled={isDisabled}
                />
            </div>
            <div className="agent-form__section">
                <div className="agent-form__section-subtitle">
                    {t(`${tNamespace}extract-token-from-result`)}
                    <InfoIcon id="extractToken" content={t(`${tTooltipNamespace}extract-token`)} />
                </div>
                <ExtractParameterSettingsInput
                    disabled={isDisabled}
                    index={0}
                    sectionType={RestAgentRequestType.AuthorizationRequest}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikRestAgentFormAuthorization
