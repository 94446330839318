import React, { createRef, RefObject, useCallback, useEffect, useMemo, useRef } from "react"
import { Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Dispatch } from "../../../../utility/common/storeHelper"
import { TabPaneElement } from "../../../ValidatableFormTabs/ValidatableFormTabs"
import { getValidationSchema } from "../../../../utility/agents/agentValidation"
import { getDefaultRestAgentValues, getValuesFromRestAgent } from "../../../../utility/agents/agentValues"
import { selectCurrentProject } from "../../../../store/projects/selectors"
import OnSubmitValidationError from "../../../OnSubmitValidationError/OnSubmitValidationError"
import Tab from "react-bootstrap/Tab"
import { buildRestAgentRequest } from "../../../../utility/agents/agentRequest"
import { createAgent, getAgentDeclarations, updateAgent } from "../../../../store/agents/thunks"
import { AgentFormProps } from "../../AgentForm"
import { AgentType, RestAgentValues } from "../../../../models/agent"
import { selectAgentDeclarations } from "../../../../store/agents/selectors"
import { selectSlots } from "../../../../store/slots/selectors"
import AgentFormLoader from "../../../AgentForm/AgentFormLoader"
import FormikRestAgentFormGeneral from "./RestAgentGeneral"
import FormikRestAgentFormAuthorization from "./RestAgentAuthorization"
import FormikRestAgentFormAdvanced from "./RestAgentAdvanced"

const RestAgentForm: React.FC<AgentFormProps> = props => {
    const { t } = useTranslation()
    const { agent, tabEntries, validateTabs, submitCallback, ownedByThisScenario } = props

    const dispatch = useDispatch<Dispatch>()
    const project = useSelector(selectCurrentProject)
    const declarations = useSelector(selectAgentDeclarations)
    const slots = useSelector(selectSlots)

    const refs = useRef<RefObject<TabPaneElement>[]>(tabEntries.map(() => createRef()))
    const validateChannelTabs = useCallback(() => {
        validateTabs && validateTabs(refs.current)
    }, [refs, validateTabs])

    const validationSchema = useMemo(
        () => getValidationSchema(AgentType.RestAgent, ownedByThisScenario),
        [ownedByThisScenario]
    )

    const initialValues = useMemo(
        () =>
            declarations && declarations[AgentType.RestAgent] && slots
                ? agent
                    ? getValuesFromRestAgent(agent, declarations[AgentType.RestAgent], slots)
                    : getDefaultRestAgentValues(declarations[AgentType.RestAgent], slots)
                : null,
        [agent, declarations, slots]
    )

    useEffect(() => {
        if (project && (!declarations || !declarations[AgentType.RestAgent])) {
            dispatch(getAgentDeclarations(project.id, AgentType.RestAgent))
        }
    }, [project, dispatch, declarations])

    if (!project) {
        return null
    }

    if (!initialValues) {
        return <AgentFormLoader count={3} />
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: RestAgentValues) => {
                const request = buildRestAgentRequest(project.id, values)
                if (agent) {
                    dispatch(updateAgent(request, submitCallback))
                } else {
                    dispatch(createAgent(request, submitCallback))
                }
            }}
        >
            {formikProps => (
                <>
                    <OnSubmitValidationError formikProps={formikProps} onCallback={validateChannelTabs} />
                    <Tab.Pane eventKey={tabEntries[0].key} ref={refs.current[0]}>
                        <FormikRestAgentFormGeneral {...props} {...formikProps} t={t} />
                    </Tab.Pane>
                    <Tab.Pane eventKey={tabEntries[1].key} ref={refs.current[1]}>
                        <FormikRestAgentFormAuthorization {...props} {...formikProps} t={t} />
                    </Tab.Pane>
                    <Tab.Pane eventKey={tabEntries[2].key} ref={refs.current[2]}>
                        <FormikRestAgentFormAdvanced {...props} {...formikProps} t={t} />
                    </Tab.Pane>
                </>
            )}
        </Formik>
    )
}

export default RestAgentForm
