import React, { ReactNode } from "react"
import styles from "./DraggableItem.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripLinesVertical, faPen } from "@fortawesome/pro-light-svg-icons"
import IconControl from "../IconControl/IconControl"
import CloseButton from "../CloseButton/CloseButton"
import { DraggableProvided } from "react-beautiful-dnd"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"

export interface DraggableItemProps extends ClassProps {
    title?: string
    content?: ReactNode
    onClick?: () => void
    onEdit?: () => void
    onDelete: () => void
    provided?: DraggableProvided
    isEdit?: boolean
    selected?: boolean
}

const DraggableItem: React.FC<DraggableItemProps> = props => {
    const { title, content, onClick, onEdit, onDelete, provided, isEdit = true, selected, className } = props
    const isDraggable = !!provided

    return (
        <div
            ref={provided?.innerRef}
            {...provided?.draggableProps}
            className={cn(
                styles.draggableItem,
                className,
                selected && styles.draggableItem_selected,
                isDraggable && styles.draggableItem_draggable
            )}
            onClick={onClick}
        >
            {isDraggable && (
                <div className={styles.draggableItem__drag} {...provided?.dragHandleProps}>
                    <FontAwesomeIcon icon={faGripLinesVertical} />
                </div>
            )}
            {title || typeof content === "string" ? (
                <div className={styles.draggableItem__content}>
                    {title && <div className={styles.draggableItem__title}>{title}</div>}
                    {content && <div className={styles.draggableItem__comment}>{content}</div>}
                </div>
            ) : (
                content
            )}
            {isEdit && (
                <div className={styles.draggableItem__controls}>
                    {onEdit && <IconControl icon={faPen} className={styles.draggableItem__editIcon} onClick={onEdit} />}
                    <CloseButton onClick={onDelete} className={styles.draggableItem__deleteIcon} />
                </div>
            )}
        </div>
    )
}

export default DraggableItem
