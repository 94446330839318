import { format, formatDistance, formatDistanceStrict, formatDistanceToNow } from "date-fns"
import { ru, enGB } from "date-fns/locale"
import i18next from "i18next"
import { getDateLocale } from "../../locales"

const dateLocales: { [key: string]: Locale } = {
    enGB,
    ru,
    "ru-RU": ru,
    en: enGB
}

export type TimeInHms = {
    hours: number
    minutes: number
    seconds: number
}

export const formatDistanceLocal = (date: Date | number, baseDate: Date | number) =>
    formatDistance(date, baseDate, {
        locale: dateLocales[i18next.language]
    })

export const formatDistanceToNowLocal = (date: Date | number) =>
    formatDistanceToNow(date, {
        locale: dateLocales[i18next.language]
    })

export const formatDistanceStrictLocal = (date: Date | number) =>
    formatDistanceStrict(date, Date.now(), {
        locale: dateLocales[i18next.language]
    })

export const formatMillisecondsToFullDate = (ms: number) => {
    const locale = { locale: getDateLocale(i18next.language) }
    return format(ms, "dd-MM-yyyy HH:mm:ss", locale)
}

export const formatMillisecondsToMMSS = (ms: number) => {
    const locale = { locale: getDateLocale(i18next.language) }

    return format(ms, "mm:ss", locale)
}

export const convertMsToHMS = (ms: number): TimeInHms => {
    const s = Math.floor(ms / 1000)
    const hours = Math.floor(s / 3600)
    const minutes = Math.floor((s % 3600) / 60)
    const seconds = (s % 3600) % 60
    return { hours, minutes, seconds }
}

export const convertTimeDigitToString = (digit: number): string => {
    return digit < 10 ? `0${digit}` : String(digit)
}

export const convertHMSToString = ({ hours, minutes, seconds }: TimeInHms): string => {
    const bHours = convertTimeDigitToString(hours)
    const bMinutes = convertTimeDigitToString(minutes)
    const bSeconds = convertTimeDigitToString(seconds)

    return `${bHours}:${bMinutes}:${bSeconds}`
}

export const beautifyMsToHMSString = (ms: number): string => {
    return convertHMSToString(convertMsToHMS(ms))
}

export default formatDistanceLocal
