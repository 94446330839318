import React from "react"
import InputLabel, { InputLabelSharedProps } from "../InputLabel/InputLabel"
import { CloseButton, FormGroup } from "react-bootstrap"
import { ClassProps } from "../../../utility/common/props"
import { FormGroupProps } from "react-bootstrap/FormGroup"

export interface VerticalInputGroupProps extends InputLabelSharedProps {
    onDelete?: () => void
    disabled?: boolean
}

const VerticalInputGroup: React.FC<VerticalInputGroupProps & FormGroupProps & ClassProps> = props => {
    const { children, onDelete, className, controlId, disabled, ...inputLabelProps } = props

    return (
        <FormGroup className={className} controlId={controlId}>
            <InputLabel {...inputLabelProps}>
                {onDelete && (
                    <CloseButton className="validatable-input__delete-icon" onClick={onDelete} disabled={disabled} />
                )}
            </InputLabel>
            {children}
        </FormGroup>
    )
}

export default VerticalInputGroup
