import { useTranslation } from "react-i18next"
import { AsyncErrorProps } from "../../utility/common/asyncHelper"
import AsyncState from "../../core/asyncState"
import { getSystemErrorMessage } from "../../core/error"
import useOmniLoader from "../../hooks/useOmniLoader"

interface Props<T> {
    state: AsyncState<T>
    /** If empty, <OmniLoader /> will be used automatically */
    processView?: JSX.Element
    errorView: (props: AsyncErrorProps) => JSX.Element
    children: JSX.Element | null
}

function AsyncWithoutData<T>(props: Props<T>): JSX.Element | null {
    const { state, errorView, processView, children } = props
    const { t } = useTranslation()

    useOmniLoader(state.inProcess, !processView)

    if (state.inProcess) {
        if (processView) return processView
    }

    if (state.error) {
        return errorView({ message: getSystemErrorMessage(state.error, t) })
    }

    return children
}

export default AsyncWithoutData
