import React, { useState } from "react"
import styles from "./WizardView.module.scss"
import WizardStage from "../WizardStage/WizardStage"
import { goBack, push } from "connected-react-router"
import { actions } from "../../store/projects/slice"
import { createProject, selectProject } from "../../store/projects/thunks"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
    selectCreatedProject,
    selectProjectWizardFormValues,
    selectProjectWizardStage
} from "../../store/projects/selectors"
import { ProjectWizardFormValues } from "../../models/projectWizard"
import { locales } from "../../locales"
import { nameof } from "../../utility/common/nameof"
import { Col, Row } from "react-bootstrap"
import * as Yup from "yup"
import WizardCompleteImg from "../../assets/images/ctWizardComplete.png"

import ValidatableInput from "../ValidatableInput/ValidatableInput"
import WizardForm from "../WizardForm/WizardForm"
import { CreateProjectRequest } from "../../models/project"
import { projectPath } from "../../routerPaths"
import projectsController from "../../api/controllers/projects"
import { debounceAsync } from "../../utility/common/debounceAsync"
import { Dispatch } from "../../utility/common/storeHelper"
import { projectIdRegex } from "../../utility/common/projectIdRegex"
import { changeProject } from "../../store/users/thunks"
import { getLanguage } from "../../utility/common/language"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"

const tNamespace = "wizard:"

const createRequest = (data: ProjectWizardFormValues): CreateProjectRequest => {
    return {
        ProjectName: data.ProjectName,
        ProjectId: data.ProjectId,
        Language: data.Language
    }
}

const getDefaultValues = (language: string): ProjectWizardFormValues => {
    return {
        Language: language,
        ProjectName: "",
        ProjectId: ""
    }
}

const WizardView: React.FC = () => {
    const { t, i18n } = useTranslation()
    const stage = useSelector(selectProjectWizardStage)
    const wizardFormValues = useSelector(selectProjectWizardFormValues)
    const createdProject = useSelector(selectCreatedProject)
    const dispatch = useDispatch<Dispatch>()

    const [isLoading, setIsLoading] = useState(false)

    const validateProjectNotExists = debounceAsync(async (id: string) => {
        const timeout = setTimeout(() => setIsLoading(true), 300)
        const exists = await projectsController.exists(id)
        clearTimeout(timeout)
        return !exists
    }, 500)

    return (
        <div className={styles.wizardView}>
            <Row className={styles.wizardView__body}>
                <Col sm={12} md={8} lg={6} xl={3} className={styles.wizardView__formContainer}>
                    <WizardForm
                        initialValues={wizardFormValues ? wizardFormValues : getDefaultValues(getLanguage(i18n))}
                        stage={stage}
                        onSubmit={values => dispatch(actions.nextWizardStage({ ...values }))}
                        onComplete={values => dispatch(createProject(createRequest(values)))}
                    >
                        <WizardStage
                            title={t(`${tNamespace}project-name.title`)}
                            backButtonText={t(`${tNamespace}cancel`)}
                            onBack={() => dispatch(goBack())}
                            validationSchema={Yup.object().shape({
                                ProjectName: Yup.string().requiredExcludeEmpty(
                                    `${tNamespace}project-name.validation-failed`
                                )
                            })}
                        >
                            <ValidatableInput
                                id="projectName"
                                type="text"
                                name={nameof<ProjectWizardFormValues>("ProjectName")}
                                placeholder={t(`${tNamespace}project-name.placeholder`)}
                            />
                        </WizardStage>
                        <WizardStage
                            title={t(`${tNamespace}project-id.title`)}
                            onBack={() => dispatch(actions.prevWizardStage())}
                            isLoading={isLoading}
                            validationSchema={Yup.object().shape({
                                ProjectId: Yup.string().test(
                                    "projectId",
                                    `${tNamespace}project-id.validation-exists`,
                                    async value => {
                                        await Yup.object()
                                            .shape({
                                                ProjectId: Yup.string()
                                                    .requiredExcludeEmpty(`${tNamespace}project-id.validation-required`)
                                                    .min(2, `${tNamespace}project-id.validation-min-2`)
                                                    .matches(projectIdRegex, {
                                                        message: `${tNamespace}project-id.validation-invalid`,
                                                        excludeEmptyString: true
                                                    })
                                            })
                                            .validate({ ProjectId: value })

                                        const isValid = value ? await validateProjectNotExists(value) : false
                                        setIsLoading(false)
                                        return isValid
                                    }
                                )
                            })}
                        >
                            <ValidatableInput
                                id="projectId"
                                type="text"
                                name={nameof<ProjectWizardFormValues>("ProjectId")}
                                placeholder={t(`${tNamespace}project-id.placeholder`)}
                            />
                        </WizardStage>
                        <WizardStage
                            isComplete
                            title={t(`${tNamespace}set-language.title`)}
                            onBack={() => dispatch(actions.prevWizardStage())}
                            nextButtonText={t(`${tNamespace}set-language.next`)}
                        >
                            <ValidatableInput
                                id="setLanguage"
                                as="select"
                                name={nameof<ProjectWizardFormValues>("Language")}
                            >
                                {locales.map(locale => (
                                    <option key={locale.id} value={locale.id}>
                                        {locale.name}
                                    </option>
                                ))}
                            </ValidatableInput>
                        </WizardStage>
                        <WizardStage
                            title={t(`${tNamespace}complete.title`)}
                            description={t(`${tNamespace}complete.description`)}
                            nextButtonText={t(`${tNamespace}complete.next`)}
                            onNext={async () => {
                                if (createdProject) {
                                    await dispatch(changeProject(createdProject))
                                    await dispatch(selectProject(createdProject))
                                }
                                dispatch(push(`${projectPath}/${createdProject}/${definedMenuItems.Settings.id}`))
                                dispatch(actions.resetWizard())
                            }}
                        >
                            <img src={WizardCompleteImg} alt="" className={styles.wizardView__img} />
                        </WizardStage>
                    </WizardForm>
                </Col>
            </Row>
        </div>
    )
}

export default WizardView
