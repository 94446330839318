import React, { FunctionComponentElement } from "react"
import ReactDOM from "react-dom"
import { Provider, ReactReduxContext } from "react-redux"
import ResizeObserver from "resize-observer-polyfill"
import NetworkService from "./api/networkService"
import "./utility/common/setupYupCustomValidations"
import "./index.scss"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import store, { history } from "./store/store"
import "./i18n"
import { isMobileOnly } from "react-device-detect"
import MobileLockScreen from "./components/MobileLockScreen/MobileLockScreen"
import { setupListeners } from "@reduxjs/toolkit/query"
import initGlobalPerformanceMeasuring from "./performance"
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary"
import { ConnectedRouter } from "connected-react-router"
import { MOUNT_POINT } from "./constants"

// Use ResizeObserver polyfill
if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
}

// initialize axios interceptors for auth
NetworkService.setupInterceptors(store)
setupListeners(store.dispatch)
initGlobalPerformanceMeasuring()

const renderDOM = (mountElement: FunctionComponentElement<unknown>) =>
    ReactDOM.render(mountElement, document.getElementById(MOUNT_POINT) ?? document.createElement("div"))

function initializeApp() {
    if (isMobileOnly) {
        renderDOM(<MobileLockScreen />)
    } else {
        renderDOM(
            <ErrorBoundary>
                <Provider store={store}>
                    <ConnectedRouter history={history} context={ReactReduxContext}>
                        <App />
                    </ConnectedRouter>
                </Provider>
            </ErrorBoundary>
        )
    }

    serviceWorker.register()
}

initializeApp()
