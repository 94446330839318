import React from "react"
import { WithT } from "i18next"
import { ClassProps } from "../../utility/common/props"
import * as routerPath from "../../routerPaths"
import { Link } from "react-router-dom"

const tNamespace = "signUp:"

export interface HaveAccountLinkProps extends WithT, ClassProps {}

const HaveAccountLink: React.FC<HaveAccountLinkProps> = props => {
    const { t, className } = props

    return (
        <div className={className}>
            {t(`${tNamespace}have-account`)} <Link to={routerPath.loginPath}>{t(`${tNamespace}sign-in`)}</Link>
        </div>
    )
}

export default HaveAccountLink
