import { KnowledgeBasePermittedAction } from "../../models/knowledgeBasePermission"
import usePermissionsCheck from "../common/usePermissionsCheck"
import { ModifyKnowledgeBase } from "../../permissions"
import { useContext } from "react"
import KnowledgeBaseContext from "../../components/KnowledgeBaseProvider/KnowledgeBaseContext"

export const useModifyKnowledgeBase = () => usePermissionsCheck([ModifyKnowledgeBase])

export const useModifyPermitted = (permittedAction?: KnowledgeBasePermittedAction) => {
    const { canModifyKnowledgeBase } = useContext(KnowledgeBaseContext)
    return canModifyKnowledgeBase && isModifyPermitted(permittedAction)
}

export const isModifyPermitted = (action?: KnowledgeBasePermittedAction) => action === KnowledgeBasePermittedAction.Edit
