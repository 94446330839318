import React, { useEffect, useState } from "react"

const useScrollToTopButtonVisibility = <T extends HTMLElement>(scrollableArea: React.RefObject<T>): boolean => {
    const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false)

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableArea.current) {
                const notScenarioArticle: boolean = scrollableArea.current.querySelector("#scenario-article") === null

                if (scrollableArea.current.scrollTop > window.innerHeight / 10 && notScenarioArticle) {
                    setIsButtonVisible(true)
                } else {
                    setIsButtonVisible(false)
                }
            }
        }

        if (scrollableArea.current) {
            scrollableArea.current.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (scrollableArea.current) {
                scrollableArea.current.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    return isButtonVisible
}

export default useScrollToTopButtonVisibility
