import React, { useContext } from "react"
import "./BlockTypeForm.scss"
import { useTranslation } from "react-i18next"
import TypeFormCard from "../TypeFormCard/TypeFormCard"
import BlockIcon from "../BlockIcon/BlockIcon"
import { BlockTypeWithConditions, CommandNode, Connection, ScenarioBlockType } from "../../models/scenario"
import { ConditionStatement, SlotSpecialValue } from "../../models/scenarioCondition"
import { ArticleScenarioContext, ScenarioContext, UpdateConditionsCallback } from "../ScenarioEditor/ScenarioContext"
import { addConnection, addNode } from "../../utility/scenario/scenarioGraph"
import { handleAddConditions, handleUpdateText } from "../../utility/scenario/scenarioNode"
import { FormNodeData } from "../Graph/nodes/Form/FormNode"
import { getConditionId, NodeData } from "../../utility/scenario/scenario"
import { ConditionsNodeData } from "../Graph/nodes/Conditions/ConditionsNode/ConditionsNode"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ViewAgents } from "../../permissions"

const tNamespace = "scenarioEditor:"

const getBlockTypes = (viewAgentsAllowed: boolean) => [
    ScenarioBlockType.Message,
    ...(viewAgentsAllowed ? [ScenarioBlockType.Agent] : []),
    ScenarioBlockType.Condition,
    ScenarioBlockType.Form,
    ScenarioBlockType.Article,
    ScenarioBlockType.Command
]

interface Props {
    onSelect: () => void
    onAddBlock: (source?: string, sourceHandle?: string) => void
    onAddArticle: (connection: Connection) => void
    onAddAgent: (connection: Connection) => void
    onAddCommand: (command?: CommandNode, connection?: Connection) => void
    connection: Connection
}

const BlockTypeForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSelect, onAddBlock, onAddArticle, connection, onAddAgent, onAddCommand } = props
    const viewAgentsAllowed = usePermissionsCheck([ViewAgents])

    const { instance, setElements, scenarioTouched, setScenarioTouched, setSelectedNode } = useContext(ScenarioContext)
    const { handleAddSlot, handleOpenConditionForm } = useContext(ArticleScenarioContext)

    const addBlock = (type: ScenarioBlockType, data: NodeData) => {
        if (!instance) return

        const nodeId = addNode(type, setElements, data, instance, connection.source)
        addConnection(setElements, nodeId, connection.source, connection.sourceHandle)

        return nodeId
    }

    const addCondition = (id: string, blockType: BlockTypeWithConditions, callback: UpdateConditionsCallback) =>
        handleOpenConditionForm(handleAddConditions(setElements, id, callback), blockType)

    const addFormNode = (Slot: string) => {
        const formNodeData: FormNodeData = {
            Slots: [{ SlotId: Slot, IsMandatory: true }],
            Conditions: [
                {
                    Id: getConditionId(),
                    And: [{ Slot: SlotSpecialValue.AllMandatoryFilledIn }]
                }
            ],
            AddBlock: onAddBlock,
            AddFormConditions: addCondition
        }
        addBlock(ScenarioBlockType.Form, formNodeData)
    }

    const addConditionNode = (conditions: ConditionStatement[]) => {
        const conditionsNodeData: ConditionsNodeData = {
            AddBlock: onAddBlock,
            AddCondition: addCondition,
            Conditions: [
                {
                    Id: getConditionId(),
                    And: conditions
                }
            ]
        }
        addBlock(ScenarioBlockType.Condition, conditionsNodeData)
    }

    const addMessageNode = () => {
        const messageNodeData = {
            Text: "",
            Buttons: [],
            UpdateText: handleUpdateText(setElements),
            AddBlock: onAddBlock,
            ManualCreated: true
        }

        const messageNodeId = addBlock(ScenarioBlockType.Message, messageNodeData)

        if (messageNodeId) {
            setSelectedNode(messageNodeId)

            setTimeout(() => {
                const textAreaBlock = document.querySelector<HTMLTextAreaElement>(
                    `[data-id="${messageNodeId}"] .message-node__text`
                )

                if (textAreaBlock) {
                    textAreaBlock.focus()
                }
            }, 300)
        }
    }

    const handleChooseBlock = (type: ScenarioBlockType) => {
        if (!scenarioTouched && setScenarioTouched) {
            setScenarioTouched(true)
        }

        switch (type) {
            case ScenarioBlockType.Article:
                return onAddArticle(connection)
            case ScenarioBlockType.Agent:
                return onAddAgent(connection)
            case ScenarioBlockType.Condition:
                return handleOpenConditionForm(
                    addConditionNode,
                    ScenarioBlockType.Condition,
                    true,
                    undefined,
                    connection
                )
            case ScenarioBlockType.Form:
                return handleAddSlot(addFormNode, undefined, connection, true)
            case ScenarioBlockType.Command:
                return onAddCommand(undefined, connection)
            case ScenarioBlockType.Message:
                onSelect()
                addMessageNode()
        }
    }

    return (
        <div className="block-type-form">
            <div className="block-type-form__content">
                {getBlockTypes(viewAgentsAllowed).map(type => (
                    <TypeFormCard
                        key={type}
                        icon={<BlockIcon type={type} />}
                        onSelect={() => handleChooseBlock(type)}
                        title={t(`${tNamespace}block-type.${type}`)}
                        description={t(`${tNamespace}block-type-desc.${type}`)}
                    />
                ))}
            </div>
        </div>
    )
}

export default BlockTypeForm
