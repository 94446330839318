interface NestedObject {
    [key: string]: object
}

export const updateNestedSettings = (currentValue: object, newValue: object): object => {
    if (
        typeof currentValue !== "undefined" &&
        currentValue !== null &&
        typeof newValue !== "undefined" &&
        newValue !== null
    ) {
        if (!Array.isArray(newValue) && typeof newValue === "object" && typeof currentValue === "object") {
            return Object.keys(newValue).reduce<NestedObject>(
                (updatedValue, key) => {
                    updatedValue[key] = updateNestedSettings(
                        (currentValue as NestedObject)[key],
                        (newValue as NestedObject)[key]
                    )
                    return updatedValue
                },
                { ...currentValue }
            )
        }
    }
    return newValue
}
