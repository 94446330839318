import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { projectPath } from "../../routerPaths"
import { push } from "connected-react-router"
import styles from "./MenusFrame.module.scss"
import Spinner from "../Spinner/Spinner"

interface IntegrationFrameProps {
    src: string
    title: string
    id: string
}

enum MessageEventType {
    iframeHeight = "iframeHeight",
    jumpToClient = "jumpToClient"
}

export const MenusFrame: React.FC<IntegrationFrameProps> = props => {
    const { src, title, id } = props
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    const iframeRef = useRef<HTMLIFrameElement>(null)

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data) {
                switch (event.data.type) {
                    case MessageEventType.iframeHeight:
                        if (event.data.id === id && iframeRef.current) {
                            const { height } = event.data
                            Object.assign(iframeRef.current.style, {
                                height,
                                width: height === "100vh" ? "450px" : "420px",
                                zIndex: height === "100vh" ? "9999" : "auto",
                                position: height === "100vh" ? "fixed" : "relative"
                            })
                        }
                        return
                    case MessageEventType.jumpToClient:
                        if (projectId && event.data.omniUserId) {
                            dispatch(push(`${projectPath}/${projectId}/dialogs`, { omniUserId: event.data.omniUserId }))
                        }
                        return
                }
            }
        }

        window.addEventListener("message", handleMessage)

        return () => {
            window.removeEventListener("message", handleMessage)
        }
    }, [dispatch, id, iframeRef, projectId])

    return (
        <>
            {isLoading && (
                <div className={styles.loader}>
                    <div className={styles.loader__content}>
                        <Spinner className={styles.loader__spinner} />
                    </div>
                </div>
            )}
            <iframe
                style={{
                    visibility: isLoading ? "hidden" : "visible"
                }}
                className={styles.menusFrame}
                title={title}
                ref={iframeRef}
                id={`iframe-${title}`}
                src={src}
                width="100%"
                loading="lazy"
                onLoad={() => setIsLoading(false)}
            />
        </>
    )
}
