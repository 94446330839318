import React, { useCallback, useContext } from "react"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import ConfigContext from "../ConfigContext/ConfigContext"
import { Image } from "react-bootstrap"
import {
    selectCurrentProjectId,
    selectDefaultProjectSettingsState,
    selectGetProjectsState,
    selectThemeInitializingStatus
} from "../../store/projects/selectors"
import { useSelector } from "react-redux"
import { useAppSelector } from "../../store/store"
import styles from "./HugeLogo.module.scss"
import defaultFullLogoSrc from "../../assets/images/logo-full-white.png"

export interface HugeLogoProps extends ClassProps {
    onClick?: () => void
}

const HugeLogo: React.FC<HugeLogoProps> = props => {
    const { className, onClick } = props
    const {
        WebConfig: { isDefault }
    } = useContext(ConfigContext)

    const projectsState = useSelector(selectGetProjectsState)
    const defaultProject = useSelector(selectDefaultProjectSettingsState)
    const projectId = useAppSelector(selectCurrentProjectId)
    const themeInitStatus = useSelector(selectThemeInitializingStatus)

    const renderHugeLogo = useCallback(() => {
        if (!projectsState || (!isDefault && !defaultProject.data)) return null

        let src

        if (isDefault || defaultProject.data?.Settings.System?.LogoHugeSizeChangeAllow) {
            src = projectsState.data?.find(x => x.id === projectId)?.big_logo
        }
        if (defaultProject.data?.Settings.System?.LogoHugeSizeChangeAllow !== undefined && !src) {
            src = defaultProject.data?.Project.big_logo
        }

        return <Image src={src || defaultFullLogoSrc} height="30px" />
    }, [projectsState, isDefault, defaultProject.data, projectId])

    return (
        <div
            className={cn(styles.hugeLogo, className, onClick && styles.hugeLogo_clickable)}
            onClick={() => onClick?.()}
        >
            {!themeInitStatus.inProcess && renderHugeLogo()}
        </div>
    )
}

export default HugeLogo
