const TRANSLATION_NAMESPACE = "error:classifiers."

export const SUBSCRIBE_ON_CLASSIFIER_EVENTS_FAILED = `${TRANSLATION_NAMESPACE}subscribe-on-classifier-events-failed`
export const UNSUBSCRIBE_ON_CLASSIFIER_EVENTS_FAILED = `${TRANSLATION_NAMESPACE}unsubscribe-on-classifier-events-failed`
export const FETCH_CLASSIFIERS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-classifiers-failed`
export const FETCH_CLASSIFIER_SERVERS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-classifier-servers-failed`
export const FETCH_CLASSIFIER_MODELS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-classifier-models-failed`
export const CREATE_CLASSIFIER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}create-classifier-failed`
export const UPDATE_CLASSIFIER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-classifier-failed`
export const DELETE_CLASSIFIER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}delete-classifier-failed`
export const ACTIVATE_CLASSIFIER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}activate-classifier-failed`
export const DEACTIVATE_CLASSIFIER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}deactivate-classifier-failed`
export const START_TRAINING_CLASSIFIER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}start-training-classifier-failed`
export const STOP_TRAINING_CLASSIFIER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}stop-training-classifier-failed`
export const ROLLBACK_CLASSIFIER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}rollback-classifier-failed`
