import React, { ReactElement } from "react"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"

interface Props {
    permission: string
    children: React.ReactNode
    no?: () => ReactElement | null
}

const Can: React.FC<Props> = props => {
    const { permission, children, no } = props
    const allowed = usePermissionsCheck([permission])

    if (typeof children === "function") {
        return children(allowed)
    } else if (allowed) {
        return children
    }

    if (no) {
        return no()
    }

    return null
}

export default Can
