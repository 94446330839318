import React, { useCallback } from "react"
import "./NotificationList.scss"
import SidebarRight from "../SidebarRight/SidebarRight"
import { useDispatch, useSelector } from "react-redux"
import {
    selectDeleteNewsState,
    selectNewsStackNotifications,
    selectNewsState,
    selectNotificationsListState,
    selectStackNotifications
} from "../../store/notifications/selectors"
import { useTranslation } from "react-i18next"
import translation from "../../locales/notification"
import { actions } from "../../store/notifications/slice"
import NotificationSection from "../NotificationSection/NotificationSection"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import {
    clearAndUpdateNotificationNews,
    clearNotifications,
    deleteNotification,
    deleteNotificationNews
} from "../../store/notifications/thunks"
import { formTranslation } from "../../locales/form"
import { push } from "connected-react-router"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { handleAcceptInviteOperator } from "../../utility/notifications/handleAcceptInviteOperator"

const NotificationList: React.FC = () => {
    const isOpen = useSelector(selectNotificationsListState)
    const notifications = useSelector(selectStackNotifications)
    const newsNotifications = useSelector(selectNewsStackNotifications)
    const newsState = useSelector(selectNewsState)
    const deleteNewsState = useSelector(selectDeleteNewsState)
    const currentProjectId = useSelector(selectCurrentProjectId)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleClearAll = () => {
        dispatch(clearAndUpdateNotificationNews())
        clearNotifications(dispatch)
    }

    const handleDeleteNotification = useCallback(
        (notificationId: string) => {
            deleteNotification(dispatch, notificationId)
        },
        [dispatch]
    )

    const handleDeleteNews = useCallback(
        (notificationId: string) => {
            dispatch(deleteNotificationNews(notificationId))
        },
        [dispatch]
    )

    const handleNotificationClick = useCallback(
        (link: string) => {
            dispatch(push(link))
        },
        [dispatch]
    )

    const settings = [
        <SettingsContextMenuItem
            id="notification-delete-all"
            icon={faTrash}
            text={t(formTranslation.deleteAll)}
            danger
            onClick={handleClearAll}
        />
    ]

    const onClose = useCallback(() => dispatch(actions.closeNotificationsList()), [dispatch])
    return (
        <SidebarRight
            title={t(translation.menuTitle)}
            open={isOpen}
            onClose={onClose}
            className="notification-list"
            settings={settings}
            width={430}
        >
            <NotificationSection
                data={notifications}
                title={t(translation.listTitle)}
                firstViewCount={3}
                onNotificationClose={handleDeleteNotification}
                onNotificationClick={handleNotificationClick}
            />
            <NotificationSection
                data={newsNotifications}
                title={t(translation.newsListTitle)}
                loading={newsState.inProcess}
                onNotificationClose={handleDeleteNews}
                onNotificationClick={handleNotificationClick}
                notificationClosingState={deleteNewsState}
                onAcceptInviteOperator={handleAcceptInviteOperator(currentProjectId)}
            />
        </SidebarRight>
    )
}

export default NotificationList
