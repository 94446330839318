import React, { ReactNode, useContext, useEffect, useState } from "react"
import "./ScenarioGraph.scss"
import ReactFlow, { addEdge, ArrowHeadType, isEdge, isNode, removeElements } from "react-flow-renderer"
import { Connection, Edge, Elements, OnLoadParams } from "react-flow-renderer/dist/types"
import { ScenarioBlockType } from "../../models/scenario"
import CustomEdge from "../Graph/CustomEdge"
import cn from "classnames"
import { ScenarioContext } from "../ScenarioEditor/ScenarioContext"

window["__react-beautiful-dnd-disable-dev-warnings"] = true

const connectionLineStyle = {
    strokeWidth: 2
}

const edgeTypes = {
    custom: CustomEdge
}

interface Props {
    isEditing?: boolean
    nodeTypes: { [key in ScenarioBlockType]?: ReactNode }
    onSelectionChange: (selections: Elements, selectedId: string | null) => void
}

let timer: NodeJS.Timeout | null = null

const ScenarioGraph: React.FC<Props> = props => {
    const [isConnecting, setIsConnecting] = useState(false)

    const { isEditing = true, nodeTypes, onSelectionChange } = props
    const { setInstance, elements, setElements, closeSidebar, setSelectedNode, scenarioTouched, setScenarioTouched } =
        useContext(ScenarioContext)

    const onLoad = (reactFlowInstance: OnLoadParams) => setInstance(reactFlowInstance)

    const onElementsRemove = (elementsToRemove: Elements) => setElements(els => removeElements(elementsToRemove, els))

    const onConnect = (params: Edge | Connection) => {
        setElements(els =>
            addEdge(
                { ...params, arrowHeadType: ArrowHeadType.ArrowClosed, type: "custom" },
                els.filter(e => !(isEdge(e) && e.sourceHandle === params.sourceHandle))
            )
        )
        !scenarioTouched && setScenarioTouched && setScenarioTouched(true)
    }

    const handleSelectionChange = (selections: Elements | null) => {
        if (!isEditing) return

        let selectedId: string | null = null
        if (selections?.length === 1 && isNode(selections[0]) && selections[0].type !== ScenarioBlockType.Start) {
            selectedId = selections[0].id
        }

        setSelectedNode(selectedId)

        setElements(els =>
            els.map(e => {
                if (isEdge(e)) return e
                return {
                    ...e,
                    draggable: e.id !== selectedId
                }
            })
        )

        if (selections) {
            onSelectionChange(selections, selectedId)
            !scenarioTouched && setScenarioTouched && setScenarioTouched(true)
        }
    }

    useEffect(() => {
        if (!isEditing) {
            setSelectedNode(null)
            closeSidebar()
        }

        setElements(els =>
            els.map(e => {
                if (isEdge(e)) return e
                return {
                    ...e,
                    draggable: isEditing
                }
            })
        )
    }, [isEditing, setSelectedNode, setElements, closeSidebar])

    return (
        <div
            className={cn(
                "scenario-editor",
                !isEditing && "scenario-editor_not-editable",
                isConnecting && "scenario-editor_connecting"
            )}
        >
            <ReactFlow
                elements={elements}
                elementsSelectable={isEditing}
                nodesConnectable={isEditing}
                nodesDraggable={isEditing}
                zoomOnScroll={true}
                selectNodesOnDrag={false}
                preventScrolling={false}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onElementsRemove={onElementsRemove}
                onConnect={onConnect}
                onLoad={onLoad}
                onSelectionChange={handleSelectionChange}
                connectionLineStyle={connectionLineStyle}
                onlyRenderVisibleElements={false}
                minZoom={0.1}
                onConnectStart={(_, params) => {
                    if (params.handleType === "source") timer = setTimeout(() => setIsConnecting(true), 300)
                }}
                onConnectEnd={() => {
                    setIsConnecting(false)
                    timer && clearTimeout(timer)
                }}
            />
        </div>
    )
}

export default ScenarioGraph
