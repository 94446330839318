import React, { useEffect, useState } from "react"
import "./SampleQuestions.scss"
import { Button, FormCheck } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import TextareaAutosize from "react-textarea-autosize"
import SampleQuestion from "./SampleQuestion/SampleQuestion"
import { Article, QuestionType, SampleQuestionType } from "../../models/article"
import MenuItemRow from "../MenuItemRow/MenuItemRow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown"
import SidebarContextMenu from "../SidebarContextMenu/SidebarContextMenu"
import { useDispatch, useSelector } from "react-redux"
import { usePagination, useRowSelect, useTable } from "react-table"
import { RESULTS_PER_PAGE, tableColumns } from "../../utility/knowledgeBase/sampleQuestions"
import {
    handleAddArticleFormQuestions,
    handleReduceArticleMarkedQuestionsCount,
    handleRemoveArticleFormQuestions,
    handleRemoveArticleMarkedQuestions,
    handleSetArticleMarkedQuestionsCount
} from "../../store/knowledgeBase/actions"
import { setOperatorQuestions } from "../../store/knowledgeBase/thunks"
import {
    selectArticleFormQuestions,
    selectArticleMarkedMessages,
    selectArticleMarkedMessagesCount
} from "../../store/knowledgeBase/selectors"
import Pagination from "../Pagination/Pagination"

const tNamespace = "knowledgeBase:questions."

interface Props {
    article: Article
    isEditable?: boolean
}

const SampleQuestions: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { article, isEditable } = props
    const dispatch = useDispatch()
    const questions = useSelector(selectArticleFormQuestions)
    const markedMessages = useSelector(selectArticleMarkedMessages)
    const markedMessagesCount = useSelector(selectArticleMarkedMessagesCount)

    const [data, setCurrentTableData] = useState<SampleQuestionType[]>(questions)
    const [pageCount, setPageCount] = useState(article.Questions?.length ?? 0)
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false)
    const [selectedType, setSelectedType] = useState<QuestionType>(QuestionType.All)
    const [inputValue, setInputValue] = useState("")

    const {
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        isAllRowsSelected,
        toggleRowSelected,
        toggleAllRowsSelected,
        selectedFlatRows,
        state: { pageIndex }
    } = useTable<SampleQuestionType>(
        {
            columns: tableColumns,
            data,
            pageCount: Math.ceil(pageCount / RESULTS_PER_PAGE),
            manualPagination: true,
            initialState: { pageSize: RESULTS_PER_PAGE }
        },
        usePagination,
        useRowSelect
    )

    const questionType = [
        { value: QuestionType.All, label: t(`${tNamespace}${QuestionType.All}`) },
        { value: QuestionType.Manual, label: t(`${tNamespace}${QuestionType.Manual}`) },
        { value: QuestionType.Operator, label: t(`${tNamespace}${QuestionType.Operator}`) }
    ]

    const handleAdd = () => {
        if (!inputValue.trim()) return
        const inputQuestionsValues = inputValue.split(/\n+/)

        const questionsToAdd = inputQuestionsValues.map(q => q.trim()).filter(q => q)

        questionsToAdd.length && handleAddArticleFormQuestions(questionsToAdd, dispatch)
        setInputValue("")
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(event.target.value)
    }

    const handleFilterChange = (type: QuestionType) => {
        setSelectedType(type)
    }

    const handleRemove = (messages: SampleQuestionType[]) => {
        if (!messages.length) return

        const messagesMarkupIds = messages.reduce<string[]>((ids, message) => {
            if (message.type === QuestionType.Operator) ids.push(message.id)
            return ids
        }, [])

        if (messagesMarkupIds.length > 0) {
            handleRemoveArticleMarkedQuestions(messagesMarkupIds, dispatch)
            handleReduceArticleMarkedQuestionsCount(messagesMarkupIds.length, dispatch)
        }

        const articleQuestionIds = messages.reduce<string[]>((ids, message) => {
            if (message.type === QuestionType.Manual) ids.push(message.id)
            return ids
        }, [])

        handleRemoveArticleFormQuestions(articleQuestionIds, dispatch)
    }

    const handleRemoveAllQuestions = (messages: SampleQuestionType[]) => {
        const messagesIds = messages.map(message => message.id)

        handleRemoveArticleMarkedQuestions(messagesIds, dispatch)
        handleSetArticleMarkedQuestionsCount(0, dispatch)
        handleRemoveArticleFormQuestions(messagesIds, dispatch)
    }

    const handleRemoveSelection = () => {
        const messages = selectedFlatRows.map(row => row.original)

        isCheckboxSelected ? handleRemoveAllQuestions(messages) : handleRemove(messages)
    }

    const offset = RESULTS_PER_PAGE * pageIndex

    useEffect(() => {
        const count: number = RESULTS_PER_PAGE - (questions.length - offset)
        setOperatorQuestions(article.SymbolCode, count, dispatch, pageIndex)
    }, [pageIndex])

    useEffect(() => {
        switch (selectedType) {
            case QuestionType.All:
                const questionsToAdd = questions.slice(offset, offset + RESULTS_PER_PAGE)
                if (questions.length < offset + RESULTS_PER_PAGE) {
                    setCurrentTableData(questionsToAdd ? [...questionsToAdd, ...markedMessages] : markedMessages)
                    setPageCount(markedMessagesCount + questions.length)
                } else {
                    setCurrentTableData(questionsToAdd)
                    setPageCount(markedMessagesCount + questions.length)
                }
                break
            case QuestionType.Manual:
                setCurrentTableData(questions.slice(offset, offset + RESULTS_PER_PAGE))
                setPageCount(questions.length)
                break
            case QuestionType.Operator:
                setCurrentTableData(markedMessages)
                setPageCount(markedMessagesCount)
                break
        }
    }, [selectedType, markedMessages, questions, dispatch])

    useEffect(() => {
        if (!isAllRowsSelected && isCheckboxSelected) {
            setIsCheckboxSelected(false)
        }
    }, [isAllRowsSelected, isCheckboxSelected])

    return (
        <div className="sample-questions">
            <div className="sample-questions__header">
                {isEditable && (
                    <div className="sample-questions__add-sample">
                        <TextareaAutosize
                            className="sample-questions__textarea"
                            value={inputValue}
                            placeholder={t(formTranslation.enterText)}
                            onChange={handleTextChange}
                            maxRows={20}
                        />
                        <Button className="sample-questions__add-btn" onClick={handleAdd} disabled={!inputValue.trim()}>
                            {t(formTranslation.add)}
                        </Button>
                    </div>
                )}
                <div className="sample-questions__toolbar">
                    <div className="sample-questions__select">
                        {isEditable && (
                            <FormCheck
                                custom
                                id="select-questions"
                                type="checkbox"
                                onChange={() => {
                                    toggleAllRowsSelected(!isCheckboxSelected)
                                    setIsCheckboxSelected(!isCheckboxSelected)
                                }}
                                checked={isCheckboxSelected}
                                disabled={!pageCount}
                            />
                        )}
                        <SidebarContextMenu
                            placement="bottom-start"
                            containerClassName="question-type-select"
                            className="sample-questions__filter"
                            items={questionType.map(type => (
                                <MenuItemRow
                                    key={type.value}
                                    className="article-type-select__item"
                                    title={type.label}
                                    onClick={() => handleFilterChange(type.value)}
                                    selected={selectedType === type.value}
                                />
                            ))}
                        >
                            <div className="sample-questions__filter">
                                <span className="sample-questions__filter-text">
                                    {t(`${tNamespace}${selectedType}`)}
                                </span>
                                <FontAwesomeIcon icon={faAngleDown} className="sample-questions__filter-icon" />
                            </div>
                        </SidebarContextMenu>
                    </div>
                    <Pagination
                        pageIndex={pageIndex}
                        total={pageCount}
                        onNextPage={nextPage}
                        canNextPage={canNextPage}
                        onPreviousPage={previousPage}
                        canPreviousPage={canPreviousPage}
                        resultsPerPage={RESULTS_PER_PAGE}
                    />
                </div>
                {isEditable && (
                    <div className="sample-questions__buttons">
                        <Button
                            variant="light-danger"
                            onClick={handleRemoveSelection}
                            disabled={!selectedFlatRows.length}
                        >
                            {t(formTranslation.delete)}
                        </Button>
                    </div>
                )}
            </div>
            <div className="sample-questions__list">
                {page.map(row => (
                    <SampleQuestion
                        selected={row.isSelected}
                        key={row.values.id}
                        className="sample-questions__list-item"
                        content={row.values.text}
                        onSelect={isEditable ? () => toggleRowSelected(row.id, !row.isSelected) : undefined}
                        onDelete={() => handleRemove([row.original])}
                        isDeletable={isEditable}
                    />
                ))}
            </div>
        </div>
    )
}

export default SampleQuestions
