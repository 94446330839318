import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { Dispatch } from "../../utility/common/storeHelper"
import { setWebChatChannelReady } from "../../store/channels/thunks"
import { logHubError } from "../../utility/common/logError"
import { ChannelReadyEvent } from "../../models/channel"
import { Store } from "../../store/store"

const CHANNEL_READY = "ChannelReady"

const HUB_NAME = "ChannelsHub"

class ChannelsHub {
    private _hub: IHub

    constructor(store: Store) {
        const reduxState = store.getState()
        let useAllTransportSignalR = false

        if (reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR) {
            useAllTransportSignalR = true
        }

        this._hub = new Hub(`/channels-hub`, useAllTransportSignalR)
        this.registerServerEvents(store.dispatch)
    }

    async subscribe(projectId: string) {
        await this._hub.subscribe("Subscribe", projectId)
    }

    async unsubscribe(projectId: string) {
        await this._hub.unsubscribe("Unsubscribe", projectId)
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(CHANNEL_READY, data => {
            try {
                dispatch(setWebChatChannelReady(data as ChannelReadyEvent))
            } catch (e) {
                logHubError(HUB_NAME, CHANNEL_READY, e)
            }
        })
    }
}

export default ChannelsHub
