import { handleHttpException } from "../handleHttpException"
import { UpdateIndividualQueuesRequest, UpdateQueueRequest, CreateQueueRequest } from "../../models/queue"
import { queuesHub } from "../../api/instances"
import { Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import QueuesController from "../../api/controllers/queues"
import { getOperators } from "../operators/thunks"
import queuesController from "../../api/controllers/queues"
import { actions } from "./slice"
import { CreateQueueCategoryRequest, MoveQueueRequest, UpdateQueueCategoryRequest } from "../../models/queueCategory"
import { TreeData } from "@atlaskit/tree"
import { TreeItem } from "@atlaskit/tree/types"
import { QueueSearchInputData } from "../../models/queueSearch"
import { queueFilterOptionToMode } from "../../utility/queues/getFilterOptions"

export function getQueues(tenantId: string) {
    return async (dispatch: Dispatch) => {
        dispatch(actions.getQueuesProcess())
        try {
            const result = await QueuesController.getByTenant(tenantId)
            dispatch(actions.getQueuesSuccess(result.Queues))
            try {
                const overview = await QueuesController.getMonitoringOverview(tenantId)
                dispatch(
                    actions.getQueuesAndMonitoringOverview({ Queues: result.Queues, MonitoringOverview: overview })
                )
            } catch (e) {
                handleHttpException(
                    "Failed to load monitoring overview",
                    constants.FETCH_MONITORING_OVERVIEW_FAILED_MESSAGE,
                    err => actions.getMonitoringOverviewFailed(err),
                    dispatch
                )
            }
        } catch (e) {
            handleHttpException(e, constants.FETCH_QUEUES_FAILED_MESSAGE, err => actions.getQueuesFailed(err), dispatch)
        }
    }
}

export const getMonitoringOverview = (tenantId: string) => async (dispatch: Dispatch) => {
    dispatch(actions.getMonitoringOverviewProcess())
    try {
        const overview = await QueuesController.getMonitoringOverview(tenantId)
        dispatch(actions.getMonitoringOverviewSuccess(overview))
    } catch (e) {
        handleHttpException(
            e,
            constants.FETCH_MONITORING_OVERVIEW_FAILED_MESSAGE,
            err => actions.getMonitoringOverviewFailed(err),
            dispatch
        )
    }
}

export function subscribeOnQueuesData(tenantId: string) {
    return async (dispatch: Dispatch) => {
        await dispatch(getQueueCategories(tenantId))
        await dispatch(getOperators(tenantId))
        await queuesHub.subscribe(tenantId)
    }
}

export function unsubscribeFromQueuesData(tenantId: string) {
    return async () => {
        await queuesHub.unsubscribe(tenantId)
    }
}

export function createQueue(tenantId: string, request: CreateQueueRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.createQueueProcess())
            await queuesController.create(tenantId, request)
            dispatch(actions.createQueueSuccess())
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_QUEUE_FAILED_MESSAGE,
                err => actions.createQueueFailed(err),
                dispatch
            )
        }
    }
}

export function updateQueue(tenantId: string, id: string, request: UpdateQueueRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateQueueProcess())
            await queuesController.update(tenantId, id, request)
            dispatch(actions.updateQueueSuccess())
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_QUEUE_FAILED_MESSAGE,
                err => actions.updateQueueFailed(err),
                dispatch
            )
        }
    }
}

export function updateIndividualQueues(
    tenantId: string,
    id: string,
    request: UpdateIndividualQueuesRequest,
    callback: () => void
) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateIndividualQueuesProcess())
            await queuesController.updateIndividual(tenantId, id, request)
            dispatch(actions.updateIndividualQueuesSuccess())
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_INDIVIDUAL_QUEUES_FAILED_MESSAGE,
                err => actions.updateIndividualQueuesFailed(err),
                dispatch
            )
        }
    }
}

export function deleteQueue(tenantId: string, categoryId: string, queueId: string, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.deleteQueueProcess())
            await queuesController.delete(tenantId, categoryId, queueId)
            dispatch(actions.deleteQueueSuccess({ queueId, categoryId }))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.DELETE_QUEUE_FAILED_MESSAGE,
                err => actions.deleteQueueFailed(err),
                dispatch
            )
        }
    }
}

export function getQueueCategories(tenantId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getQueueCategoriesProcess())
            const response = await queuesController.getCategories(tenantId)
            dispatch(actions.getQueueCategoriesSuccess(response))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_QUEUE_CATEGORIES_FAILED_MESSAGE,
                actions.getQueueCategoriesFailed,
                dispatch
            )
        }
    }
}

export function getQueueExtendedSettings(projectId: string, queueId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getQueueExtendedSettingsProcess())
            const response = await queuesController.getQueueExtendedSettings(projectId, queueId)
            dispatch(actions.getQueueExtendedSettingsSuccess(response))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_QUEUE_EXTENDED_SETTINGS_FAILED_MESSAGE,
                actions.getQueueExtendedSettingsFailed,
                dispatch
            )
        }
    }
}

export function createQueueCategory(tenantId: string, request: CreateQueueCategoryRequest, callbackFn: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.createQueueCategoryProcess())
            const { Category } = await queuesController.createCategory(tenantId, request)
            dispatch(actions.createQueueCategorySuccess(Category))
            callbackFn()
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_QUEUE_CATEGORY_FAILED_MESSAGE,
                actions.createQueueCategoryFailed,
                dispatch
            )
        }
    }
}

export function updateQueueCategory(
    tenantId: string,
    categoryId: string,
    request: UpdateQueueCategoryRequest,
    callbackFn: () => void
) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateQueueCategoryProcess())
            const { Category } = await queuesController.updateCategory(tenantId, categoryId, request)
            dispatch(actions.updateQueueCategorySuccess(Category))
            callbackFn()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_QUEUE_CATEGORY_FAILED_MESSAGE,
                actions.updateQueueCategoryFailed,
                dispatch
            )
        }
    }
}

export function removeQueueCategory(tenantId: string, categoryId: string, callbackFn: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.deleteQueueCategoryProcess())
            await queuesController.removeCategory(tenantId, categoryId)
            dispatch(actions.deleteQueueCategorySuccess(categoryId))
            callbackFn()
        } catch (e) {
            handleHttpException(
                e,
                constants.REMOVE_QUEUE_CATEGORY_FAILED_MESSAGE,
                actions.deleteQueueCategoryFailed,
                dispatch
            )
        }
    }
}

export function moveQueueToAnotherCategory(
    tenantId: string,
    newTree: TreeData,
    sourceParent: TreeItem,
    destinationParent: TreeItem,
    draggableItem: TreeItem
) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.moveQueueProcess())
            const categoryId = String(destinationParent.id)
            const request: MoveQueueRequest = {
                QueueIds: [String(draggableItem.id)]
            }
            const result = await queuesController.moveQueue(tenantId, categoryId, request)
            dispatch(actions.updateQueueCategoriesTree(newTree))
            dispatch(actions.moveQueueSuccess(result))
        } catch (e) {
            handleHttpException(e, constants.MOVE_QUEUE_FAILED_MESSAGE, actions.moveQueueFailed, dispatch)
        }
    }
}

export function performSearch(projectId: string, searchRequest?: QueueSearchInputData) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.performSearchProcess())
            if (!searchRequest || !searchRequest.searchValue) {
                dispatch(actions.clearSearch())
                return
            }
            const mode = queueFilterOptionToMode(searchRequest.filterOption)
            const response = await queuesController.search(projectId, { Mode: mode, Value: searchRequest.searchValue })
            dispatch(actions.performSearchSuccess({ InputData: searchRequest, Response: response }))
        } catch (e) {
            handleHttpException(e, constants.SEARCH_IN_QUEUE_FAILED_MESSAGE, actions.performSearchFailed, dispatch)
        }
    }
}
