import React from "react"
import "./ArticleActions.module.scss"
import useDelay from "../../utility/common/useDelay"
import { ClassProps } from "../../utility/common/props"
import Skeleton from "../Skeleton/Skeleton"

const ArticleActionItemLoader: React.FC<ClassProps> = props => {
    const display = useDelay()
    if (!display) return null

    return (
        <div className={props.className}>
            <Skeleton />
        </div>
    )
}

export default ArticleActionItemLoader
