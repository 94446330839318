import React from "react"
import { AgentValues } from "../../../../models/agent"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { formTranslation } from "../../../../locales/form"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { FormikAgentFormProps } from "../../AgentForm"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"

const tNamespace = "agent:form."

const FormikEmailAgentFormAdvanced: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentOptions, t, handleSubmit, disabled } = props

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="agent-form__section">
                <ValidatableInput
                    as="select"
                    id="formNextAgent"
                    type="text"
                    name={nameof<AgentValues>("NextAgent")}
                    label={t(`${tNamespace}next-agent`)}
                    disabled={!agentOptions.length}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentOptions.map(option => (
                        <option value={option.Id} key={option.Id}>
                            {option.Id}
                        </option>
                    ))}
                </ValidatableInput>
                <DebouncedValidatableInput
                    id="formReplyTo"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "reply-to")}
                    label={t(`${tNamespace}email-for-reply`)}
                    placeholder={`${t(`${tNamespace}enter-value-or-substitute`)} {{slot id}}`}
                />
                <DebouncedValidatableInput
                    id="formCC"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "cc")}
                    label="CC"
                    placeholder={`${t(`${tNamespace}enter-value-or-substitute`)} {{slot id}}`}
                />
                <DebouncedValidatableInput
                    id="formBCC"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "bcc")}
                    label="BCC"
                    placeholder={`${t(`${tNamespace}enter-value-or-substitute`)} {{slot id}}`}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikEmailAgentFormAdvanced
