import { v4 as uuidV4 } from "uuid"
import { ApiMessage, MessageTextType, SendMessageRequest } from "../../models/Dialogs/message"

export const convertRequestToResponse = (message: SendMessageRequest, operatorId: string): ApiMessage => {
    return {
        Case: "Message",
        Fields: {
            ...message,
            Id: uuidV4(),
            MessageType: 1,
            TextType: MessageTextType.Markdown,
            Direction: 1,
            Sender: { Id: operatorId },
            DialogId: "",
            AddedDate: Date.now(),
            Context: [[]],
            Status: 0
        }
    }
}
