import { Catalog } from "./catalog"

export enum SharedType {
    Article = "Article",
    Catalog = "Catalog"
}

export interface SharedDto {
    Id: string
    Type: SharedType
    CatalogCode?: string
    ArticleCode?: string
}

export interface GetShareResponse {
    Share: SharedDto
}

export interface GetSharedCatalogResponse {
    Catalog: Catalog
}

export interface GetSharedRelatedCategoriesRequest {
    Id?: string
    CatalogCode?: string
    CategoryCode?: string
}

export interface SearchSharedArticlesRequest {
    Text?: string
    Type?: string
    Tags?: string[]
    ParameterValues?: string[]
    EnablePayload?: boolean
    PageFrom?: number
    PageSize?: number
}

export type SharedActualEntityRequest = {
    Id: string
    Login: string
    RoleId?: string
}

export interface GetShareForActualEntityResponse {
    Share?: SharedDto
    SharedUrl?: string
}
