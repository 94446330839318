import React, { useCallback, useMemo } from "react"
import usersController from "../../api/controllers/users"
import useAsyncState from "../../utility/common/useAsyncState"
import { GET_USERS_BY_PROJECT_FAILED_MESSAGE } from "../../store/users/constants"
import { useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import ArticleUserViewCard from "../ArticleUserViewCard/ArticleUserViewCard"
import Async from "../Async/Async"
import ArticleUserCardsLoader from "./ArticleUserCardsLoader"
import { ArticleUserView } from "../../models/articleUserViews"
import { viewsToMap } from "../../utility/knowledgeBase/articleUserViews"

interface Props {
    views: ArticleUserView[]
}

const ArticleUserViewCards: React.FC<Props> = props => {
    const { views } = props
    const projectId = useSelector(selectCurrentProjectId)
    const logins = useMemo(() => views.map(v => v.Login), [views])
    const viewsMap = useMemo(() => viewsToMap(views), [views])

    const loadUsers = useCallback(
        () =>
            projectId
                ? usersController.getUsersByLogins(projectId, {
                      Logins: logins
                  })
                : Promise.resolve([]),
        [projectId, logins]
    )
    const { asyncState: usersState } = useAsyncState(loadUsers, GET_USERS_BY_PROJECT_FAILED_MESSAGE)

    return (
        <Async
            dataState={usersState}
            processView={<ArticleUserCardsLoader />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: users }) => (
                <>
                    {users.map((user, index) => (
                        <ArticleUserViewCard key={index} user={user} view={viewsMap[user.Login]} />
                    ))}
                </>
            )}
        </Async>
    )
}

export default ArticleUserViewCards
