import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormikProps, Formik } from "formik"
import { Form } from "react-bootstrap"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import "./ForgotPasswordForm.scss"
import * as Yup from "yup"
import { WithT } from "i18next"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { nameof } from "../../utility/common/nameof"
import { formTranslation } from "../../locales/form"
import { getSystemErrorMessage } from "../../core/error"
import NotificationCard from "../NotificationCard/NotificationCard"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle"
import LoadingButton from "../LoadingButton/LoadingButton"
import { ForgotPasswordRequest } from "../../models/password"
import { selectForgotPasswordState } from "../../store/password/selectors"
import { forgotPassword } from "../../store/password/thunks"
import { testId } from "../../utility/tests/testId"

const tNamespace = "password:forgot-password-form."

interface ForgotPasswordValues {
    email: string
}

const FormikSendSignUpEmailForm: React.FC<FormikProps<ForgotPasswordValues> & WithT> = props => {
    const { t, handleSubmit } = props

    const forgotPasswordState = useSelector(selectForgotPasswordState)
    const processing = forgotPasswordState.inProcess
    const error = forgotPasswordState.error

    return (
        <div className={cn("forgot-password-form")}>
            <h1>{t(`${tNamespace}title`)}</h1>
            <h2>{t(`${tNamespace}form-title`)}</h2>
            <div className="forgot-password-form__container">
                {error && <NotificationCard message={getSystemErrorMessage(error, t)} icon={faExclamationCircle} />}
                <Form noValidate onSubmit={handleSubmit}>
                    <ValidatableInput
                        id="signUpEmailForm"
                        type="text"
                        placeholder="Email"
                        name={nameof<ForgotPasswordValues>("email")}
                        testId={testId.email}
                    />
                    <LoadingButton
                        type="submit"
                        className="forgot-password-form__submit"
                        loading={processing}
                        variant="primary"
                        testId={testId.forgotPasswordSubmit}
                    >
                        {t(formTranslation.continue)}
                    </LoadingButton>
                </Form>
            </div>
        </div>
    )
}

const createRequest = (values: ForgotPasswordValues): ForgotPasswordRequest => {
    return {
        Email: values.email
    }
}

const ForgotPasswordForm: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <Formik
            initialValues={{
                email: ""
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .requiredExcludeEmpty(formTranslation.emailRequired)
                    .email(formTranslation.emailInvalid)
            })}
            onSubmit={(values: ForgotPasswordValues) => {
                dispatch(forgotPassword(createRequest(values)))
            }}
        >
            {formikProps => <FormikSendSignUpEmailForm {...formikProps} t={t} />}
        </Formik>
    )
}

export default ForgotPasswordForm
