import { ParamValues } from "../../models/parameterDeclaration"
import { parseField } from "../common/parametersTranslator"
import { AgentType } from "../../models/agent"
import { defaultRequests } from "./agentValues"
import { defaultAIAssistAgentParams, defaultButtonClassifierThreshold } from "./aiassist2AgentValues"
import { agentApiVersionDefault, AIAssistAgentIdDefault, mergeUsersDefault } from "./agentValuesDefaults"

export const extractParams = (agentType: AgentType, params: ParamValues): ParamValues => {
    switch (agentType) {
        case AgentType.AIAssist2:
            params.button_classifier_threshold =
                parseFloat(params.button_classifier_threshold) || defaultButtonClassifierThreshold
            params.intent_clarification_intent_types_to_exclude = parseField(
                params.intent_clarification_intent_types_to_exclude,
                []
            )

            Object.keys(defaultAIAssistAgentParams).forEach(key => {
                const defaultValue = (defaultAIAssistAgentParams as ParamValues)[key]
                if (params[key] && typeof defaultValue !== "string") {
                    params[key] = parseField(params[key], defaultValue)
                }
            })

            break
        case AgentType.NightAgent:
            params.schedule = parseField(params.schedule, [])
            break
        case AgentType.RoutingAgent:
            params["routing:max_dialogs"] = parseField(params["routing:max_dialogs"], 0)
            params.allow_only_known_agents = parseField(params.allow_only_known_agents, true)
            break
        case AgentType.ABAgent:
            params.probability = parseField(params.probability, 0)
            break
        case AgentType.RestAgent:
            params.requests = parseField(params.requests, defaultRequests, true)
            break
        case AgentType.EmailAgent:
            params["enable-ssl"] = parseField(params["enable-ssl"], true)
            params["enable-auth"] = parseField(params["enable-auth"], true)
            break
        case AgentType.InitialMsgAgent:
            params.required_authorization = parseField(params.required_authorization, false)
            break
        case AgentType.IdentificationAgent:
            params.definition = parseField(params.definition, [])
            break
        case AgentType.JavaScriptAgent:
            params["agent-code"] = params["agent-code"] ?? ""
            params["agent-timeout"] = parseField(params["agent-timeout"], 10000)
            params["agent-api-version"] = params["agent-api-version"] ?? agentApiVersionDefault
            params["aiassist-agent-id"] = params["aiassist-agent-id"] ?? AIAssistAgentIdDefault
            break
        case AgentType.ADFSAgent:
            params["callback-html-content"] = params["callback-html-content"] ?? ""
            params["claims-mapping"] = parseField(params["claims-mapping"], [])
            params["merge-users"] = parseField(params["merge-users"], mergeUsersDefault)
            break
        case AgentType.PsbBssAgent:
            params["continue-session-slot-ids"] = params["continue-session-slot-ids"] ?? ""
            params["allowed-attachment-extensions"] = params["allowed-attachment-extensions"] ?? ""
            params["max-attachment-size-mb"] = parseField(params["max-attachment-size-mb"], 5)
            params["override-channel-type"] = parseField(params["override-channel-type"], {})
            break
    }
    return params
}
