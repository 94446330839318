import React from "react"
import { FormControlProps } from "react-bootstrap/FormControl"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { Form, FormControl, InputGroup } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"
import styles from "./ActionInput.module.scss"
import cn from "classnames"

export interface ActionInputProps extends FormControlProps, ClassProps {
    id: string
    tooltipLabel?: string
    isTooltipShow?: boolean
    actions: React.ReactNode
    error?: string
    placeholder?: string
    testId?: string
}

const ActionInput: React.FC<ActionInputProps> = props => {
    const { id, tooltipLabel, className, actions, isTooltipShow, error, placeholder, testId, ...controlProps } = props

    return (
        <div className={styles.actionInput}>
            <TooltipTrigger
                id={id}
                condition={isTooltipShow}
                placement="bottom"
                content={tooltipLabel}
                show={isTooltipShow}
            >
                <InputGroup>
                    <FormControl
                        {...controlProps}
                        data-test-id={testId}
                        className={cn(styles.actionInput__control, !actions && styles.actionInput__control_empty)}
                        placeholder={placeholder}
                    />
                    {actions && (
                        <InputGroup.Prepend className={styles.actionInput__actions}>{actions}</InputGroup.Prepend>
                    )}
                </InputGroup>
            </TooltipTrigger>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </div>
    )
}

export default ActionInput
