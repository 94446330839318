import React, { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Formik, FormikProps } from "formik"
import { Form } from "react-bootstrap"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import "./SignUpForm.scss"
import * as Yup from "yup"
import { WithT } from "i18next"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { nameof } from "../../utility/common/nameof"
import { passwordMinSize, passwordRegex } from "../../utility/common/password"
import { signUpByEmail, signUpOidc } from "../../store/signUp/thunks"
import { SignUpRequest, SignUpRequestOidc, VerifySignUpCodeResponse } from "../../models/signUp"
import { formTranslation } from "../../locales/form"
import { selectSignUpByEmailState } from "../../store/signUp/selectors"
import NotificationCard from "../NotificationCard/NotificationCard"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle"
import ProjectCard from "../ProjectCard/ProjectCard"
import LoadingButton from "../LoadingButton/LoadingButton"
import { getLanguage } from "../../utility/common/language"
import HaveAccountLink from "../HaveAccountLink/HaveAccountLink"
import PasswordControl from "../PasswordControl/PasswordControl"
import ConfigContext from "../ConfigContext/ConfigContext"
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader"

const tNamespace = "signUp:form."
const nameMinSize = 2

interface SignUpValues {
    email: string
    firstName: string
    lastName: string
    password: string
}

export interface SignUpFormProps {
    verifyResponse: VerifySignUpCodeResponse
}

const FormikSignUpForm: React.FC<SignUpFormProps & FormikProps<SignUpValues> & WithT> = props => {
    const { t, handleSubmit, verifyResponse, values } = props

    const signUpByEmailState = useSelector(selectSignUpByEmailState)
    const processing = signUpByEmailState.inProcess
    const error = signUpByEmailState.error

    return (
        <div className={cn("sign-up-form")}>
            <h1>{t(`${tNamespace}form-title`)}</h1>
            {verifyResponse.Project && (
                <>
                    <h2>{t(`${tNamespace}invite-to-project-title`)}</h2>
                    <ProjectCard project={verifyResponse.Project} className="sign-up-form__project" />
                </>
            )}
            <div className="sign-up-form__container">
                {!!error && (
                    <NotificationCard
                        message={t(`${tNamespace}sign-up-by-email-error-500`)}
                        icon={faExclamationCircle}
                    />
                )}
                <Form onSubmit={handleSubmit} id="sign-up">
                    <ValidatableInput
                        id="signUpFormFirstName"
                        autocomplete="given-name"
                        type="text"
                        name={nameof<SignUpValues>("firstName")}
                        label={t(formTranslation.firstName)}
                        placeholder={t(formTranslation.enterFirstName)}
                    />
                    <ValidatableInput
                        id="signUpFormLastName"
                        autocomplete="family-name"
                        type="text"
                        name={nameof<SignUpValues>("lastName")}
                        label={t(formTranslation.lastName)}
                        placeholder={t(formTranslation.enterLastName)}
                    />
                    <PasswordControl
                        autocomplete="new-password"
                        name={nameof<SignUpValues>("password")}
                        label={t(formTranslation.password)}
                        isPasswordSet={values.password.length > 0}
                        isPasswordValid={!props.errors.password}
                    />
                    <LoadingButton
                        type="submit"
                        className="sign-up-form__submit"
                        loading={processing}
                        variant="primary"
                    >
                        {t(formTranslation.createAccount)}
                    </LoadingButton>
                    <HaveAccountLink t={t} className="sign-up-form__footer" />
                </Form>
            </div>
        </div>
    )
}

const createRequest = (values: SignUpValues, code: string, language: string): SignUpRequest => {
    return {
        Code: code,
        Language: language,
        FirstName: values.firstName,
        LastName: values.lastName,
        Password: values.password
    }
}

const createRequestOidc = (code: string, language: string): SignUpRequestOidc => {
    return {
        Code: code,
        Language: language
    }
}

const SignUpForm: React.FC<SignUpFormProps> = props => {
    const { verifyResponse } = props
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const {
        WebConfig: {
            appSettings: { authMode }
        }
    } = useContext(ConfigContext)

    useEffect(() => {
        document.body.classList.add("ctBody_scroll")
        return () => {
            document.body.classList.remove("ctBody_scroll")
        }
    }, [])

    useEffect(() => {
        if (authMode === "oidc") {
            dispatch(signUpOidc(createRequestOidc(verifyResponse.Code, getLanguage(i18n))))
        }
    }, [dispatch, authMode, verifyResponse, i18n])

    if (authMode === "oidc") {
        return <FullscreenLoader />
    }

    return (
        <Formik
            initialValues={{
                email: "",
                firstName: "",
                lastName: "",
                password: ""
            }}
            validationSchema={Yup.object().shape({
                firstName: Yup.string()
                    .requiredExcludeEmpty(formTranslation.firstNameRequired)
                    .min(nameMinSize, formTranslation.firstNameInvalid),
                lastName: Yup.string()
                    .requiredExcludeEmpty(formTranslation.lastNameRequired)
                    .min(nameMinSize, formTranslation.lastNameInvalid),
                password: Yup.string()
                    .requiredExcludeEmpty(formTranslation.passwordRequired)
                    .min(passwordMinSize, formTranslation.passwordInvalid)
                    .matches(passwordRegex, {
                        message: formTranslation.passwordInvalid,
                        excludeEmptyString: true
                    })
            })}
            onSubmit={(values: SignUpValues) => {
                dispatch(signUpByEmail(createRequest(values, verifyResponse.Code, getLanguage(i18n))))
            }}
        >
            {formikProps => <FormikSignUpForm {...formikProps} t={t} {...props} />}
        </Formik>
    )
}

export default SignUpForm
