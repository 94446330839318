import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import knowledgeBaseCommentsController from "../../api/controllers/knowledgeBaseComments"
import {
    AddArticleCommentRequest,
    AddArticleReplyRequest,
    ArticleComment,
    ArticleCommentInput,
    GetArticleReplyRequest,
    NewArticleReplyAddedPayload
} from "../../models/articleComment"
import { getThreadInputCode } from "../../utility/knowledgeBase/articleComment"

export const getArticleComments =
    (projectId: string, articleCode: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getCurrentCommentsProcess())
        try {
            const articleComments = await knowledgeBaseCommentsController.getArticleComments(projectId, {
                ArticleCode: articleCode
            })
            dispatch(actions.getCurrentCommentsSuccess(articleComments))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_ARTICLE_COMMENTS_FAILED,
                err => actions.getCurrentCommentsFailed(err),
                dispatch
            )
        }
    }

export const getArticleCommentThread =
    (projectId: string, articleComment: ArticleComment): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getCurrentThreadProcess())
        try {
            const request: GetArticleReplyRequest = {
                ArticleCode: articleComment.ArticleCode,
                ThreadId: articleComment.Id
            }
            const response = await knowledgeBaseCommentsController.getArticleCommentThread(projectId, request)
            dispatch(
                actions.getCurrentThreadSuccess({
                    RootComment: articleComment,
                    Items: response
                })
            )
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_ARTICLE_COMMENT_THREAD_FAILED,
                err => actions.getCurrentThreadFailed(err),
                dispatch
            )
        }
    }

export const sendArticleComment =
    (
        projectId: string,
        articleCode: string,
        articleTitle: string,
        message: ArticleCommentInput
    ): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.addCommentProcess())
        try {
            const request: AddArticleCommentRequest = {
                ArticleTitle: articleTitle,
                ArticleCode: articleCode,
                Message: message
            }
            await knowledgeBaseCommentsController.addArticleComment(projectId, request)
            dispatch(actions.addCommentSuccess(articleCode))
        } catch (e) {
            handleHttpException(e, constants.SEND_ARTICLE_COMMENT_FAILED, err => actions.addCommentError(err), dispatch)
        }
    }

export const sendArticleReply =
    (
        projectId: string,
        articleTitle: string,
        rootArticleComment: ArticleComment,
        message: ArticleCommentInput
    ): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.addReplyProcess())
        try {
            const { ArticleCode, Id: ThreadId } = rootArticleComment
            const request: AddArticleReplyRequest = {
                ArticleTitle: articleTitle,
                ArticleCode: ArticleCode,
                ThreadId: ThreadId,
                Message: message
            }
            await knowledgeBaseCommentsController.addArticleReply(projectId, request)
            dispatch(actions.addReplySuccess(getThreadInputCode(ArticleCode, ThreadId)))
        } catch (e) {
            handleHttpException(e, constants.SEND_ARTICLE_COMMENT_FAILED, err => actions.addReplyError(err), dispatch)
        }
    }

export const enableArticleCommentInputMode = (dispatch: Dispatch, articleCode: string) =>
    dispatch(actions.enableInputMode(articleCode))

export const cancelArticleCommentInputIfEmpty = (dispatch: Dispatch, articleCode: string) =>
    dispatch(actions.cancelInputIfEmpty(articleCode))

export const cancelArticleCommentInput = (dispatch: Dispatch, articleCode: string) =>
    dispatch(actions.cancelInput(articleCode))

export const saveArticleComment = (dispatch: Dispatch, comment: ArticleComment) =>
    dispatch(actions.saveComment(comment))

export const saveArticleReply = (dispatch: Dispatch, payload: NewArticleReplyAddedPayload) =>
    dispatch(actions.saveReply(payload))
