import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { selectArticleUserViewsState } from "../../store/knowledgeBase/selectors"
import ArticleUserViewsPopover from "./ArticleUserViewsPopover"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Async from "../Async/Async"
import ArticleUserViewsButtonLoader from "../ArticleUserViewsButton/ArticleUserViewsButtonLoader"

interface Props {
    currentArticleCode: string
}

const ArticleUserViewsPopoverContainer: React.FC<Props> = props => {
    const { currentArticleCode } = props
    const articleUserViewsState = useSelector(selectArticleUserViewsState, shallowEqual)

    return (
        <Async
            dataState={articleUserViewsState}
            processView={<ArticleUserViewsButtonLoader />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: { ArticleCode, Views = [] } }) => (
                <>{currentArticleCode === ArticleCode && <ArticleUserViewsPopover views={Views} />}</>
            )}
        </Async>
    )
}

export default ArticleUserViewsPopoverContainer
