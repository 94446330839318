import { cacher } from "../utility/common/rtkQueryCacheUtils"

export const dialogBadgesTag = "DialogBadges"
export const activeDialogsCountTag = "ActiveDialogsCount"
export const operatorClientsSectionTitleTag = "OperatorClientsSectionTitle"
export const operatorClientTag = "OperatorClient"
export const operatorChannelsTag = "operatorChannels"
export const operatorClientBadgesTag = "OperatorClientBadges"
export const operatorsListTag = "OperatorsList"
export const dialogsTag = "Dialogs"
export const dialogsSurveys = "DialogsSurveys"
export const dialogMessagesTag = "DialogMessages"
export const mockTag = "Mock"
export const customSectionsTag = "CustomSections"
export const customMenusTag = "CustomMenus"
export const dialogTopicsTag = "DialogTopics"
export const userChannelsTag = "UserChannels"

export const tags = [
    operatorClientsSectionTitleTag,
    operatorClientTag,
    operatorsListTag,
    operatorChannelsTag,
    operatorClientBadgesTag,
    dialogBadgesTag,
    activeDialogsCountTag,
    dialogMessagesTag,
    dialogsTag,
    mockTag,
    dialogsSurveys,
    customSectionsTag,
    customMenusTag,
    dialogTopicsTag,
    userChannelsTag,
    ...cacher.defaultTags
]

export default tags
