import { definedMenuItems } from "./definedMenuItems"
import { convertPathToOldFormat } from "../project/frameHelper"
import { MenuItem } from "../../models/menu"

export const mainMenuItems = [
    definedMenuItems.KnowledgeBase,
    definedMenuItems.KnowledgeBaseOld,
    definedMenuItems.Queues,
    definedMenuItems.History,
    definedMenuItems.Stats,
    definedMenuItems.Settings
]

export const removeSidebarMenuItems = (menuItems: MenuItem[]) => {
    const sidebarMenuItem = [definedMenuItems.Dialogs, ...mainMenuItems]

    return menuItems.filter(menuItem => !sidebarMenuItem.some(i => convertPathToOldFormat(i.id) === menuItem.id))
}
