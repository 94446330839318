import { IHub } from "../interfaces/IHub"
import { Dispatch } from "../../utility/common/storeHelper"
import { Hub } from "../hub"
import { ArticleComment, NewArticleReplyAddedPayload } from "../../models/articleComment"
import { saveArticleComment, saveArticleReply } from "../../store/knowledgeBaseComments/thunks"
import * as knowledgeBaseCommentsConstants from "../../store/knowledgeBaseComments/constants"
import { handleHttpExceptionWithoutAction } from "../../store/handleHttpException"
import { ArticleUserViews } from "../../models/articleUserViews"
import { Article } from "../../models/article"
import { actions } from "../../store/knowledgeBase/slice"
import { CategoryAddedEvent, CategoryMutation, RemoveCategoryFromCatalogEvent } from "../../models/category"
import {
    handleArticleUpdatedEvent,
    handleArticleUpdatedLockStateEvent,
    handleCategoriesUpdatedByChildEvent,
    handleCategoriesUpdatedEvent,
    handleCategoryAddedEvent,
    reloadCatalogs,
    reloadCurrentArticle
} from "../../store/knowledgeBase/thunks"
import store, { Store } from "../../store/store"
import { saveNotificationInfo } from "../../store/notifications/thunks"
import { logHubError } from "../../utility/common/logError"

const ADD_NEW_COMMENT = "AddNewComment"
const ADD_NEW_REPLY = "AddNewReply"
const UPDATE_ARTICLE_USER_VIEWS = "UpdateArticleUserViews"
const UPDATE_ARTICLE = "UpdateArticle"
const UPDATE_CATEGORIES = "UpdateCategories"
const ADD_CATEGORY = "AddCategory"
const REMOVE_CATEGORY_FROM_CATALOG = "RemoveCategoryFromCatalog"
const UPDATE_ARTICLE_LOCK_STATE = "UpdateArticleLockState"
const SUCCESSFULLY_RECONNECTED_KEY = "info:hub:successfully-reconnected"

const HUB_NAME = "KnowledgeBaseHub"

class KnowledgeBaseHub {
    protected _hub: IHub
    private readonly _dispatch: Dispatch
    private pageUrl: string

    constructor(store: Store, hubUrl = "/knowledge-base-hub") {
        const reduxState = store.getState()
        let useAllTransportSignalR = false

        if (reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR) {
            useAllTransportSignalR = true
        }

        this._hub = new Hub(hubUrl, useAllTransportSignalR, true)
        this._dispatch = store.dispatch
        this.pageUrl = ""
        this.registerServerEvents(store.dispatch)
    }

    public get isConnected() {
        return this._hub.isConnected
    }

    async connect() {
        await this._hub.connect()
    }

    async disconnect() {
        await this._hub.disconnect()
    }

    async subscribe(articleCode: string) {
        if (this._hub.isConnected) {
            await this._hub.invoke("Subscribe", articleCode).catch(e => {
                handleHttpExceptionWithoutAction(
                    e,
                    knowledgeBaseCommentsConstants.CONNECT_TO_HUB_FAILED,
                    this._dispatch
                )
            })
        } else {
            return Promise.resolve()
        }
    }

    async unsubscribe(articleCode: string) {
        if (this._hub.isConnected) {
            await this._hub.invoke("Unsubscribe", articleCode).catch(e => {
                handleHttpExceptionWithoutAction(
                    e,
                    knowledgeBaseCommentsConstants.DISCONNECT_FROM_HUB_FAILED,
                    this._dispatch
                )
            })
        } else {
            return Promise.resolve()
        }
    }

    setPageUrl(url: string) {
        this.pageUrl = url
    }

    setReconnectingCallback(reconnectingCallback: () => void) {
        this._hub.reconnectingCallback = reconnectingCallback
    }

    setReconnectedCallback(reconnectedCallback: () => void) {
        this._hub.reconnectedCallback = () => {
            const { dispatch, getState } = store
            saveNotificationInfo(dispatch, {
                Title: {
                    Value: SUCCESSFULLY_RECONNECTED_KEY,
                    NeedLocalization: true
                }
            })
            reloadCatalogs(dispatch, getState)
            reloadCurrentArticle(dispatch, getState)
            reconnectedCallback()
        }
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(ADD_NEW_COMMENT, data => {
            try {
                saveArticleComment(dispatch, data as ArticleComment)
            } catch (e) {
                logHubError(HUB_NAME, ADD_NEW_COMMENT, e)
            }
        })
        this._hub.registerEvent(ADD_NEW_REPLY, data => {
            try {
                saveArticleReply(dispatch, data as NewArticleReplyAddedPayload)
            } catch (e) {
                logHubError(HUB_NAME, ADD_NEW_REPLY, e)
            }
        })
        this._hub.registerEvent(UPDATE_ARTICLE_USER_VIEWS, data => {
            try {
                dispatch(actions.updateArticleUserViewsSuccess(data as ArticleUserViews))
            } catch (e) {
                logHubError(HUB_NAME, UPDATE_ARTICLE_USER_VIEWS, e)
            }
        })
        this._hub.registerEvent(UPDATE_ARTICLE, data => {
            try {
                const { ArticleCode, Article } = data as { ArticleCode: string; Article?: Article }
                dispatch(handleArticleUpdatedEvent(this.pageUrl, ArticleCode, Article))
            } catch (e) {
                logHubError(HUB_NAME, UPDATE_ARTICLE, e)
            }
        })
        this._hub.registerEvent(UPDATE_ARTICLE_LOCK_STATE, data => {
            try {
                const { ArticleCode, IsLocked } = data as { ArticleCode: string; IsLocked: boolean }
                dispatch(handleArticleUpdatedLockStateEvent(this.pageUrl, ArticleCode, IsLocked))
            } catch (e) {
                logHubError(HUB_NAME, UPDATE_ARTICLE_LOCK_STATE, e)
            }
        })
        this._hub.registerEvent(UPDATE_CATEGORIES, data => {
            try {
                dispatch(handleCategoriesUpdatedEvent(data as CategoryMutation[]))
            } catch (e) {
                logHubError(HUB_NAME, UPDATE_CATEGORIES, e)
            }
        })
        this._hub.registerEvent(ADD_CATEGORY, data => {
            try {
                dispatch(handleCategoryAddedEvent(data as CategoryAddedEvent))
            } catch (e) {
                logHubError(HUB_NAME, ADD_CATEGORY, e)
            }
        })
        this._hub.registerEvent(REMOVE_CATEGORY_FROM_CATALOG, data => {
            try {
                dispatch(handleCategoriesUpdatedByChildEvent(data as RemoveCategoryFromCatalogEvent))
            } catch (e) {
                logHubError(HUB_NAME, REMOVE_CATEGORY_FROM_CATALOG, e)
            }
        })
    }
}

export default KnowledgeBaseHub
