import React, { memo, useContext } from "react"
import "./ArticleNode.scss"
import CommonNode from "../Common/CommonNode"
import { ScenarioBlockType } from "../../../../models/scenario"
import { ScenarioContext } from "../../../ScenarioEditor/ScenarioContext"
import { NodeComponentProps } from "react-flow-renderer/dist/nocss/types"
import { getArticleUrl } from "../../../../utility/common/urlHelper"

export interface ArticleNodeData {
    ArticleId: string
    ArticleTitle: string
}

type Props = NodeComponentProps<ArticleNodeData>

const ArticleNode: React.FC<Props> = props => {
    const { data, id, isConnectable } = props
    const { projectId } = useContext(ScenarioContext)
    const articleUrl = projectId && getArticleUrl(projectId, data.ArticleId)

    return (
        <CommonNode
            id={id}
            className="article-node"
            headerClassName="article-node__header"
            type={ScenarioBlockType.Article}
            isConnectable={isConnectable}
        >
            <div className="article-node__content">
                <a className="article-node__link" href={articleUrl} target="_blank" rel="noopener noreferrer">
                    {data.ArticleTitle}
                </a>
            </div>
        </CommonNode>
    )
}

export default memo(ArticleNode)
