import React, { ReactNode } from "react"
import { useAccordionToggle } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"
import styles from "./AccordionToggle.module.scss"
import cn from "classnames"

interface AccordionToggleProps extends ClassProps {
    disabled: boolean
    eventKey: string
    onToggle: () => void
    children: ReactNode
}

const AccordionToggle: React.FC<AccordionToggleProps> = props => {
    const { disabled, eventKey, onToggle, children, className } = props
    const onClick = useAccordionToggle(eventKey, onToggle)

    return (
        <button
            type="button"
            className={cn(styles.accordionToggle, className)}
            onClick={!disabled ? onClick : undefined}
        >
            {children}
        </button>
    )
}

export default AccordionToggle
