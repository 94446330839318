import { apiInstance } from "./instances"
import { Store } from "../store/store"
import i18next from "i18next"
import { errorTranslation } from "../locales/error"

const networkService = {
    setupInterceptors: (_: Store) => {
        apiInstance.interceptors.response.use(
            response => {
                return response
            },
            error => {
                const errStatus = error?.response?.status

                try {
                    switch (errStatus) {
                        case 403:
                            error.message = i18next.t(errorTranslation.accessDeniedFeature)
                            break
                        case 404:
                        case 500:
                            error.message = i18next.t(errorTranslation.serverError)
                    }
                } catch (e) {
                } finally {
                    return Promise.reject(error)
                }
            }
        )
    }
}

export default networkService
