import React from "react"
import styles from "./AgentFormLoader.module.scss"
import useDelay from "../../utility/common/useDelay"
import Skeleton from "../Skeleton/Skeleton"
import { duplicateComponent } from "../../utility/common/duplicateComponent"

interface Props {
    count: number
}

const AgentFormLoader: React.FC<Props> = props => {
    const { count } = props
    const display = useDelay()

    if (!display) {
        return null
    }

    return (
        <div className={styles.agentFormLoader}>
            <div className={styles.agentFormLoader__header}>
                <Skeleton />
            </div>
            {duplicateComponent(count)(() => (
                <div className={styles.agentFormLoader__item}>
                    <Skeleton />
                </div>
            ))}
        </div>
    )
}

export default AgentFormLoader
