/*
  В новом рабочем месте оператора есть много мест, где
  нам нужно обновлять имя, фамилию и отчество, согласно значениям системных слотов

  Эти хелперы созданы для того, чтобы помочь в маппинге
*/

export const SYS_FIRSTNAME = "sys_firstname"
export const SYS_LASTNAME = "sys_lastname"
export const SYS_MIDDLENAME = "sys_middlename"

export const SYS_SLOTS_TO_UPDATE_MAPPING: { [id: string]: "Firstname" | "Lastname" | "Middlename" } = {
    sys_firstname: "Firstname",
    sys_lastname: "Lastname",
    sys_middlename: "Middlename"
}

export const SYS_SLOTS_TO_UPDATE = [SYS_FIRSTNAME, SYS_LASTNAME, SYS_MIDDLENAME]

export const isSlotSysFieldToUpdate = (slot: { Id: string; Value: string }): boolean =>
    SYS_SLOTS_TO_UPDATE.includes(slot.Id)
