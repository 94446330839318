import React from "react"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import ArticleUserViewsButton from "../ArticleUserViewsButton/ArticleUserViewsButton"
import "./ArticleUserViewsPopover.scss"
import { ArticleUserView } from "../../models/articleUserViews"
import { extractViewsAndReadConfirmationsCount } from "../../utility/knowledgeBase/articleUserViews"
import ArticleUserViewCards from "../ArticleUserViewCards/ArticleUserViewCards"

interface Props {
    views: ArticleUserView[]
}

const ArticleUserViewsPopover: React.FC<Props> = props => {
    const { views } = props
    const { viewsCount, readConfirmationsCount } = extractViewsAndReadConfirmationsCount(views)

    return (
        <PopoverTrigger
            className="article-user-views-popover"
            id="article-user-views"
            content={<ArticleUserViewCards views={views} />}
            trigger="click"
            placement="bottom"
            rootClose
        >
            <div className="article-user-views-popover__button">
                <ArticleUserViewsButton viewsCount={viewsCount} readConfirmationsCount={readConfirmationsCount} />
            </div>
        </PopoverTrigger>
    )
}

export default ArticleUserViewsPopover
