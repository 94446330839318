import React, { ReactNode, useMemo } from "react"
import "./ScalableList.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { Accordion } from "react-bootstrap"
import AccordionToggle from "../FormAccordion/AccordionToggle"
import { TFunction } from "react-i18next"
import { useOpenedState } from "../../utility/common/useOpenedState"

const tListNamespace = "common:lists."

export interface ScalableListProps<T> extends ClassProps {
    t: TFunction
    items: T[]
    limit: number
    children: (item: T, itemIndex: number) => ReactNode
    mainContainerClassName?: string
    restContainerClassName?: string
    toggleClassName?: string
}

function ScalableList<T>(props: ScalableListProps<T>) {
    const { items, limit, mainContainerClassName, restContainerClassName, className, children, toggleClassName, t } =
        props

    const [opened, toggleOpen] = useOpenedState(false)

    const [main, rest] = useMemo(() => {
        if (items.length <= limit) {
            return [items, []]
        }
        return [items.slice(0, limit), items.slice(limit)]
    }, [items, limit])

    return (
        <div className={cn("scalable-list", className)}>
            <div className={cn("scalable-list__main", mainContainerClassName)}>{main.map(children)}</div>
            {rest.length > 0 && (
                <Accordion className={cn("scalable-list__rest", restContainerClassName)}>
                    <Accordion.Collapse eventKey="0">
                        <div className={cn("scalable-list__rest-collapse")}>{rest.map(children)}</div>
                    </Accordion.Collapse>
                    <AccordionToggle
                        disabled={false}
                        eventKey="0"
                        onToggle={toggleOpen}
                        className={cn("scalable-list__toggle", toggleClassName)}
                    >
                        {opened ? t(`${tListNamespace}show-less`) : t(`${tListNamespace}show-more`)}
                    </AccordionToggle>
                </Accordion>
            )}
        </div>
    )
}

export default ScalableList
