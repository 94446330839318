import React from "react"
import { AgentValues } from "../../../models/agent"
import ValidatableInput from "../../ValidatableInput/ValidatableInput"
import { nameof } from "../../../utility/common/nameof"
import { formTranslation } from "../../../locales/form"
import AgentFormWrapper from "../../AgentFormWrapper/AgentFormWrapper"
import { FormikAgentFormProps } from "../AgentForm"

const tNamespace = "agent:form."

const FormikNextAgentForm: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentOptions, t, handleSubmit, disabled } = props

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="agent-form__section">
                <ValidatableInput
                    as="select"
                    id="formNextAgent"
                    type="text"
                    name={nameof<AgentValues>("NextAgent")}
                    label={t(`${tNamespace}next-agent`)}
                    disabled={!agentOptions.length}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentOptions.map(({ Id }) => (
                        <option value={Id} key={Id}>
                            {Id}
                        </option>
                    ))}
                </ValidatableInput>
            </div>
        </AgentFormWrapper>
    )
}

export default FormikNextAgentForm
