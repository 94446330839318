import React, { useCallback, useContext } from "react"
import ArticleReadConfirmationCard from "./ArticleReadConfirmationCard"
import { useTranslation } from "react-i18next"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { confirmArticleRead } from "../../store/knowledgeBase/thunks"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import { selectArticleUserViewsState } from "../../store/knowledgeBase/selectors"
import useUrlParams from "../../utility/common/useUrlParams"

const tNamespace = "knowledgeBase:"
const notificationParamKey = "notificationId"

interface Props {
    articleCode: string
}

const ArticleReadConfirmationCardContainer: React.FC<Props> = props => {
    const { articleCode } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const params = useUrlParams()
    const articleUserViewsState = useSelector(selectArticleUserViewsState, shallowEqual)
    const { url, projectId } = useContext(KnowledgeBaseContext)
    const notificationId = params[notificationParamKey]

    const handleConfirm = useCallback(
        () =>
            projectId &&
            typeof notificationId === "string" &&
            notificationId.length > 0 &&
            dispatch(confirmArticleRead(projectId, url, articleCode, notificationId)),
        [projectId, notificationId, dispatch, url, articleCode]
    )

    return (
        <ArticleReadConfirmationCard
            title={t(`${tNamespace}article-read-confirmation.title`)}
            buttonTitle={t(`${tNamespace}article-read-confirmation.confirm`)}
            loading={articleUserViewsState.inProcess}
            onConfirm={handleConfirm}
        />
    )
}

export default ArticleReadConfirmationCardContainer
