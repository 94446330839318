import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { usePopoverShown } from "../../../utility/knowledgeBase/usePopoverShown"
import ArticleFilterPopover from "./ArticleFilterPopover"
import { ArticleFilterFormValues } from "../../../models/articleFilter"
import { setCurrentArticlesFilter } from "../../../store/knowledgeBase/slice"

const ArticleFilterPopoverContainer: React.FC = () => {
    const dispatch = useDispatch()

    const { shown, showPopover, hidePopover } = usePopoverShown()

    const handleSubmit = useCallback(
        (values: ArticleFilterFormValues) => {
            dispatch(setCurrentArticlesFilter(values))
            hidePopover()
        },
        [dispatch, hidePopover]
    )

    return <ArticleFilterPopover show={shown} onShow={showPopover} onCancel={hidePopover} onSubmit={handleSubmit} />
}

export default ArticleFilterPopoverContainer
