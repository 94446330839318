import { Dispatch } from "../../utility/common/storeHelper"
import { RootState } from "../rootReducer"
import { actions } from "./slice"
import { performSearch } from "./thunks"

export function viewAllQueues() {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const { queues } = getState()
        const { queuesMap } = queues
        const allQueues = Object.values(queuesMap).flat()
        dispatch(actions.viewAll(allQueues))
    }
}

export function viewQueueCategory(categoryId: string) {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const { queues } = getState()
        const { queuesMap, monitoringOverviewCategoryState } = queues
        if (categoryId === monitoringOverviewCategoryState.CategoryId) {
            dispatch(actions.viewMonitoringOverviewCategory())
        } else {
            const categoryQueues = queuesMap[categoryId]
            dispatch(actions.viewQueues(categoryQueues))
        }
    }
}

export function viewQueue(categoryId: string, queueId: string) {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const { queues } = getState()
        const {
            queuesMap,
            monitoringOverviewCategoryState: { CategoryId }
        } = queues
        if (CategoryId && categoryId === CategoryId) {
            dispatch(actions.viewMonitoringOverviewCategory())
        } else {
            const categoryQueues = queuesMap[categoryId]
            const filteredQueues = categoryQueues.filter(q => q.Id === queueId)
            dispatch(actions.viewQueues(filteredQueues))
        }
    }
}

export function updateSearch(projectId?: string) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const { queues } = getState()
        const { searchRequestData } = queues
        if (!searchRequestData || !searchRequestData.searchValue || !projectId) {
            return
        }
        await dispatch(performSearch(projectId, searchRequestData))
    }
}
