import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { selectCatalogsState } from "../../../store/knowledgeBase/selectors"
import Async from "../../Async/Async"
import CatalogsLoader from "../CatalogsLoader"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import SharedCatalog from "../../Catalog/SharedCatalog/SharedCatalog"
import KnowledgeBaseContext from "../../KnowledgeBaseProvider/KnowledgeBaseContext"
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary"

interface SharedCatalogsProps {
    url: string
}

const SharedCatalogs: React.FC<SharedCatalogsProps> = props => {
    const { url } = props
    const apiState = useSelector(selectCatalogsState)
    const { shareId } = useContext(KnowledgeBaseContext)

    return (
        <Async
            dataState={apiState}
            processView={<CatalogsLoader itemsCount={2} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: catalogIds }) => (
                <ErrorBoundary local>
                    {catalogIds.map(
                        id =>
                            shareId && (
                                <SharedCatalog
                                    key={`${id}-shared-catalog`}
                                    id={id as string}
                                    url={url}
                                    shareId={shareId}
                                />
                            )
                    )}
                </ErrorBoundary>
            )}
        </Async>
    )
}

export default SharedCatalogs
