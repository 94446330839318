import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { Menu, SidebarMenu } from "../../models/menu"
import { resetReducerState } from "../action"

export type MenuState = Readonly<{
    mainMenu: AsyncState<Menu>
    selectedMainMenu?: string
    additionalMenus: Menu[]
    selectedSidebarMenu: SidebarMenu[]
}>

const initialState: MenuState = {
    mainMenu: AsyncState.create(),
    selectedMainMenu: undefined,
    additionalMenus: [],
    selectedSidebarMenu: []
}

const menu = createSlice({
    name: "menu",
    initialState,
    reducers: {
        getMainMenuProcess(state) {
            state.mainMenu = state.mainMenu.toProcess()
        },
        getMainMenuSuccess(state, action: PayloadAction<Menu>) {
            state.mainMenu = state.mainMenu.toSuccess(action.payload)
        },
        getMainMenuFailed(state, action: PayloadAction<SystemError>) {
            state.mainMenu = state.mainMenu.toFailed(action.payload)
        },
        selectMainMenu(state, action: PayloadAction<string>) {
            state.selectedMainMenu = action.payload
        },
        selectMenuSidebarPosition(state, action: PayloadAction<SidebarMenu>) {
            state.selectedSidebarMenu = state.selectedSidebarMenu.filter(m => m.id !== action.payload.id)
            state.selectedSidebarMenu = [...state.selectedSidebarMenu, action.payload]
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export default menu.reducer

export const actions = menu.actions
