import { getChangeRouteMessage, getFrameUrl, getLanguageMessage, RouteMessage } from "../../utility/project/frameHelper"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
    selectCurrentProject,
    selectFrameActivate,
    selectFrameForceUnloaded,
    selectFrameLoadingState
} from "../../store/projects/selectors"
import { getLanguage } from "../../utility/common/language"
import { logDebug, logError } from "../../utility/common/logError"
import { reloadFrame } from "../../store/projects/thunks"
import { actions } from "../../store/projects/slice"
import Frame from "../Frame/Frame"
import styles from "./OldWorkplaceFrame.module.scss"
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader"
import { menuIds } from "../../utility/menu/definedMenuItems"
import { activateFrame } from "../../store/projects/actions"

const frameName = "crafttalk-workplace-old-frame"
const allowedHref = getFrameUrl()

export interface OldWorkplaceFrameProps {
    selectedMenu?: string
    menuId?: string
    isActive?: boolean
    onSetSelected: (value: boolean) => void
    isFrameMenuSelected?: boolean
}

const OldWorkplaceFrame: React.FC<OldWorkplaceFrameProps> = props => {
    const { selectedMenu, menuId, isActive, onSetSelected, isFrameMenuSelected } = props
    const { i18n } = useTranslation()
    const frameState = useSelector(selectFrameLoadingState)
    const currentProject = useSelector(selectCurrentProject)
    const frameForceUnloaded = useSelector(selectFrameForceUnloaded)
    const isFrameActivated = useSelector(selectFrameActivate)
    const [postMessageData, setPostMessageData] = useState<string | RouteMessage | undefined>(undefined)
    const [isFrameMounted, setFrameMounted] = useState<boolean>(false)

    const [lastProjectId, setLastProjectId] = useState<string | undefined>(undefined)
    const dispatch = useDispatch()

    useEffect(() => {
        if (currentProject && currentProject.id !== lastProjectId) {
            if (lastProjectId && menuId) {
                setPostMessageData(getChangeRouteMessage(menuId))
            }

            if (currentProject.id) {
                setLastProjectId(currentProject.id)
            }
        }
    }, [lastProjectId, menuId, currentProject])

    useEffect(() => {
        if (menuId) {
            // if it is a menu from the frame we should activate it
            const isFrameMenuId = !menuIds.find(v => v === menuId)

            setFrameMounted(isFrameMenuId)

            if (isFrameMenuId && !isFrameActivated) {
                dispatch(activateFrame())
            }

            !isActive && onSetSelected(false)

            isFrameMenuId && setPostMessageData(getChangeRouteMessage(menuId))
        }
    }, [dispatch, isActive, isFrameActivated, menuId, onSetSelected, selectedMenu])

    useEffect(() => {
        if (!frameState.inProcess) {
            const language = getLanguage(i18n)

            setPostMessageData(getLanguageMessage(language))
        }
    }, [frameState, i18n])

    const handleFrameLoad = (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
        try {
            if (event.currentTarget.contentWindow?.location.href !== allowedHref) {
                logDebug("Frame location changing detected")

                dispatch(reloadFrame())
            }
        } catch (e) {
            logError(e)

            dispatch(reloadFrame())
        }
    }
    const handleReady = () => dispatch(actions.frameLoadedSuccess())

    return (
        <>
            {isFrameActivated && !frameForceUnloaded && (
                <Frame
                    isMounted={!!menuId && !frameState.inProcess && isFrameMounted && isActive && isFrameMenuSelected}
                    src={getFrameUrl()}
                    name={frameName}
                    frameClassName={styles.oldWorkplaceFrame}
                    postMessageData={postMessageData}
                    onReady={handleReady}
                    onLoad={handleFrameLoad}
                    onSelectMessage={() => onSetSelected(true)}
                />
            )}
            {!!menuId && frameState.inProcess && <FullscreenLoader hideLogo />}
        </>
    )
}

export default OldWorkplaceFrame
