import React, { useCallback, useEffect, useMemo } from "react"
import styles from "./AgentScheduleSettingsInput.module.scss"
import { CloseButton, Col, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useField, useFormikContext } from "formik"
import { NightAgentValues } from "../../models/agent"
import DebouncedCheckBox from "../CheckBoxValidatableInput/DebouncedCheckBox"
import Tag from "../Tag/Tag"
import { formTranslation } from "../../locales/form"

const tNamespace = "agent:form."
const tDaysNamespace = "common:days-of-week."

export interface AgentScheduleSettingsInputProps {
    index: number
    onDelete: () => void
    onChooseDate: () => void
}

const AgentScheduleSettingsInput: React.FC<AgentScheduleSettingsInputProps> = props => {
    const { t } = useTranslation()
    const { index, onDelete, onChooseDate } = props
    const { values, setFieldValue } = useFormikContext<NightAgentValues>()

    const fieldName = useMemo(() => `Schedule[${index}].`, [index])
    const isAnyDaySelected = useMemo(
        () =>
            values.Schedule[index].WeekDays.reduce((sum, v) => sum + v, 0) + values.Schedule[index].Dates.length !== 0,
        [values.Schedule, index]
    )

    const [nightStartHrField, nightStartHrMeta] = useField(`${fieldName}NightStartHr`)
    const [nightStartMinField, nightStartMinMeta] = useField(`${fieldName}NightStartMin`)

    const [nightEndHrField, nightEndHrMeta] = useField(`${fieldName}NightEndHr`)
    const [nightEndMinField, nightEndMinMeta] = useField(`${fieldName}NightEndMin`)

    const handleAddDay = useCallback(
        (tag: number) => {
            setFieldValue(
                `${fieldName}WeekDays`,
                [...values.Schedule[index].WeekDays.map((v, i) => (i === tag - 1 ? 1 : v))],
                false
            )
        },
        [fieldName, index, setFieldValue, values.Schedule]
    )

    const daysOfWeek = useMemo(
        () => [
            { label: t(`${tDaysNamespace}monday`), value: 1 },
            { label: t(`${tDaysNamespace}tuesday`), value: 2 },
            { label: t(`${tDaysNamespace}wednesday`), value: 3 },
            { label: t(`${tDaysNamespace}thursday`), value: 4 },
            { label: t(`${tDaysNamespace}friday`), value: 5 },
            { label: t(`${tDaysNamespace}saturday`), value: 6 },
            { label: t(`${tDaysNamespace}sunday`), value: 7 }
        ],
        [t]
    )

    const handleRemoveWeekday = useCallback(
        (tag: number) => {
            setFieldValue(
                `${fieldName}WeekDays`,
                [...values.Schedule[index].WeekDays.map((v, i) => (i === tag ? 0 : v))],
                false
            )
        },
        [values.Schedule, setFieldValue, fieldName, index]
    )

    const handleRemoveDate = useCallback(
        (tag: number) => {
            setFieldValue(`${fieldName}Dates`, [...values.Schedule[index].Dates.filter((_, i) => i !== tag)], false)
        },
        [values.Schedule, setFieldValue, fieldName, index]
    )

    useEffect(() => {
        if (values.Schedule[index].AllDay) {
            setFieldValue(`${fieldName}NightStartHr`, "00", false)
            setFieldValue(`${fieldName}NightStartMin`, "00", false)
            setFieldValue(`${fieldName}NightEndHr`, "23", false)
            setFieldValue(`${fieldName}NightEndMin`, "59", false)
        }
    }, [setFieldValue, values.Schedule, index, fieldName])

    return (
        <div className={styles["agent-schedule"]}>
            <div className={styles["agent-schedule__header"]}>
                <span className={styles["agent-schedule__title"]}>{t(`${tNamespace}non-working-hours`)}</span>
                <CloseButton onClick={onDelete} />
            </div>
            <Form.Group controlId="formSchedule">
                <Form.Control
                    as="select"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.target.value === "date" ? onChooseDate() : handleAddDay(Number(e.target.value))
                    }}
                    value=""
                >
                    <option value="" hidden>
                        {t(`${tNamespace}select-day`)}
                    </option>
                    {daysOfWeek.map(
                        option =>
                            !values.Schedule[index].WeekDays[option.value - 1] && (
                                <option value={option.value} key={option.value}>
                                    {option.label}
                                </option>
                            )
                    )}
                    <option value="date">{t(formTranslation.selectDate)}</option>
                </Form.Control>
            </Form.Group>
            <div className={styles["agent-schedule__tags"]}>
                {values.Schedule[index].WeekDays.map(
                    (selected: number, day: number) =>
                        !!selected && (
                            <Tag
                                className={styles["agent-schedule__tag"]}
                                key={`${day}-${index}`}
                                index={day}
                                title={daysOfWeek[day].label}
                                onDelete={handleRemoveWeekday}
                            />
                        )
                )}
                {values.Schedule[index].Dates.map((day: string, index: number) => (
                    <Tag
                        className={styles["agent-schedule__tag"]}
                        key={`${day}-${index}`}
                        index={index}
                        title={day}
                        onDelete={handleRemoveDate}
                    />
                ))}
            </div>
            <div className={styles["agent-form_text"]}>{t(`${tNamespace}non-working-hours`)}</div>
            <div className={styles["agent-form__section"]}>
                <Form.Row>
                    <Col md={4}>
                        <Form.Row className={styles["agent-schedule__time"]}>
                            <Form.Label column sm={1} style={{ maxWidth: 35 }}>
                                {t(`${tNamespace}from`)}
                            </Form.Label>
                            <Form.Group as={Col} sm={4} controlId={`formNightStartHr${index}`}>
                                <Form.Control
                                    type="number"
                                    min="0"
                                    max="23"
                                    name={`${fieldName}NightStartHr`}
                                    onChange={nightStartHrField.onChange}
                                    onBlur={nightStartHrField.onBlur}
                                    value={nightStartHrField.value}
                                    isInvalid={nightStartHrMeta.touched && !!nightStartHrMeta.error}
                                    className={styles["agent-schedule__time-input"]}
                                    disabled={values.Schedule[index].AllDay || !isAnyDaySelected}
                                />
                            </Form.Group>
                            <Form.Label column sm={1}>
                                :
                            </Form.Label>
                            <Form.Group as={Col} sm={4} controlId={`formNightStartMin${index}`}>
                                <Form.Control
                                    type="number"
                                    min="0"
                                    max="59"
                                    name={`${fieldName}NightStartMin`}
                                    onChange={nightStartMinField.onChange}
                                    onBlur={nightStartMinField.onBlur}
                                    value={nightStartMinField.value}
                                    isInvalid={nightStartMinMeta.touched && !!nightStartMinMeta.error}
                                    className={styles["agent-schedule__time-input"]}
                                    disabled={values.Schedule[index].AllDay || !isAnyDaySelected}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    <Col md={4}>
                        <Form.Row className={styles["agent-schedule__time"]}>
                            <Form.Label column sm={1}>
                                {t(`${tNamespace}to`)}
                            </Form.Label>
                            <Form.Group as={Col} sm={4} controlId={`formNightEndHr${index}`}>
                                <Form.Control
                                    type="number"
                                    min="00"
                                    max="23"
                                    name={`${fieldName}NightEndHr`}
                                    onChange={nightEndHrField.onChange}
                                    onBlur={nightEndHrField.onBlur}
                                    value={nightEndHrField.value}
                                    isInvalid={nightEndHrMeta.touched && !!nightEndHrMeta.error}
                                    className={styles["agent-schedule__time-input"]}
                                    disabled={values.Schedule[index].AllDay || !isAnyDaySelected}
                                />
                            </Form.Group>
                            <Form.Label column sm={1}>
                                :
                            </Form.Label>
                            <Form.Group as={Col} sm={4} controlId={`formNightEndMin${index}`}>
                                <Form.Control
                                    type="number"
                                    min="00"
                                    max="59"
                                    name={`${fieldName}NightEndMin`}
                                    onChange={nightEndMinField.onChange}
                                    onBlur={nightEndMinField.onBlur}
                                    value={nightEndMinField.value}
                                    isInvalid={nightEndMinMeta.touched && !!nightEndMinMeta.error}
                                    className={styles["agent-schedule__time-input"]}
                                    disabled={values.Schedule[index].AllDay || !isAnyDaySelected}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    <Col md={3} className={styles["agent-schedule__time-checkbox"]}>
                        <DebouncedCheckBox
                            id={`formAllDay${index}`}
                            name={`${fieldName}AllDay`}
                            label={t(`${tNamespace}all-day`)}
                            disabled={!isAnyDaySelected}
                        />
                    </Col>
                </Form.Row>
            </div>
        </div>
    )
}

export default AgentScheduleSettingsInput
