import { errorTranslation } from "../../locales/error"
import { createSystemError } from "../../core/error"
import { Dispatch, ActionResult } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import { saveNotificationError } from "../notifications/thunks"
import menuController from "../../api/controllers/menu"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"

export const getMainMenu = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(actions.getMainMenuProcess())
    try {
        const mainMenu = await menuController.mainMenu()
        dispatch(actions.getMainMenuSuccess(mainMenu))
    } catch (e) {
        handleHttpException(
            e,
            constants.FETCH_MAIN_MENU_FAILED_MESSAGE,
            err => actions.getMainMenuFailed(err),
            dispatch
        )
    }
}

export const selectMainMenu =
    (selectedMainMenuId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        if (selectedMainMenuId) {
            dispatch(actions.selectMainMenu(selectedMainMenuId))
        } else {
            saveNotificationError(
                dispatch,
                createSystemError(constants.SELECT_MAIN_MENU_FAILED_MESSAGE, {
                    reason: "Menu item id is empty",
                    reasonKey: errorTranslation.emptyMenuId
                })
            )
        }
    }
