import React from "react"
import styles from "./ArticleCommentThreadView.module.scss"
import ArticleCommentComponentLoader from "../ArticleComment/ArticleCommentComponentLoader"
import Skeleton from "../Skeleton/Skeleton"
import cn from "classnames"

const ArticleCommentThreadViewLoader: React.FC = () => {
    return (
        <div className={cn(styles.articleCommentThreadView, styles.articleCommentThreadView_loader)}>
            <div className={styles.articleCommentThreadView__replies}>
                {new Array<number>(2).fill(0).map((_, i) => (
                    <ArticleCommentComponentLoader key={i} />
                ))}
            </div>
            <div className={styles.articleCommentThreadView__inputContainer}>
                <Skeleton />
            </div>
        </div>
    )
}

export default ArticleCommentThreadViewLoader
