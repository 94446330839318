import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Route, RouteProps } from "react-router"
import { selectCurrentUserState } from "../../store/users/selectors"
import { useAuthenticationHandling } from "../../utility/auth/useAuthenticationHandling"
import AsyncWithoutData from "../Async/AsyncWithoutData"
import FullscreenError from "../FullscreenError/FullscreenError"
import { useTranslation } from "react-i18next"
import { useUserInitialization } from "../../utility/common/useUserInitialization"

export type ProtectedRouteProps = RouteProps

const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
    const { i18n } = useTranslation()
    const currentUserState = useSelector(selectCurrentUserState)

    useAuthenticationHandling(true)
    const { authenticated } = useUserInitialization()

    useEffect(() => {
        const currentUser = currentUserState.data
        if (currentUser?.Locale) {
            i18n.changeLanguage(currentUser.Locale)
        }
    }, [currentUserState, i18n])

    if (authenticated) {
        return <Route {...props} />
    } else {
        return (
            <Route
                {...props}
                component={undefined}
                render={() => (
                    <AsyncWithoutData
                        state={currentUserState}
                        errorView={({ message }) => <FullscreenError message={message} />}
                    >
                        {null}
                    </AsyncWithoutData>
                )}
            />
        )
    }
}

export default ProtectedRoute
