import React from "react"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import ArticleShareForm, { ArticleShareFormProps } from "../ArticleShareForm/ArticleShareForm"
import "./ArticleSharePopover.scss"

interface Props extends ArticleShareFormProps {
    button: JSX.Element
    show: boolean
}

const ArticleSharePopover: React.FC<Props> = props => {
    const { button, show, ...shareProps } = props

    return (
        <PopoverTrigger
            className="article-share-popover"
            id="article-share"
            content={<ArticleShareForm {...shareProps} />}
            trigger="click"
            placement="bottom"
            show={show}
        >
            {button}
        </PopoverTrigger>
    )
}

export default ArticleSharePopover
