import React from "react"
import PairSettingsControl from "../../../PairSettingsControl/PairSettingsControl"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"
import { nameof2 } from "../../../../utility/common/nameof"
import { AgentValues } from "../../../../models/agent"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { formTranslation } from "../../../../locales/form"
import CheckBoxValidatableInput from "../../../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { WithT } from "i18next"
import { getIn, useFormikContext } from "formik"
import SlotSelectControl from "../../common/SlotSelectControl"

const tNamespace = "agent:form.adfsagent."

interface Props extends WithT {
    index: number
    onDelete: () => void
    disabled?: boolean
}

const ClaimsMappingControl: React.FC<Props> = props => {
    const { values } = useFormikContext()
    const { index, t, onDelete, disabled } = props
    const name = `${nameof2<AgentValues, ParamValues>("Params", "claims-mapping")}[${index}].`

    const formFields = [
        { label: "ID", value: "id" },
        { label: t(formTranslation.firstName), value: "firstname" },
        { label: t(formTranslation.lastName), value: "lastname" },
        { label: t(formTranslation.middleName), value: "middlename" },
        { label: t(formTranslation.phoneNumber), value: "phone" },
        { label: t(formTranslation.email), value: "email" }
    ]

    return (
        <PairSettingsControl
            id={`formDefinition${index}`}
            keyControl={<ValidatableInput id={`formClaim${index}`} type="text" name={`${name}claim`} label="Claim" />}
            valueControl={
                <>
                    <SlotSelectControl
                        id={`formSlot${index}`}
                        name={`${name}slot`}
                        label={t(`${tNamespace}slot`)}
                        t={t}
                    />
                    <CheckBoxValidatableInput
                        id={`formFieldEnabled${index}`}
                        name={`${name}fieldEnabled`}
                        label={t(`${tNamespace}form-field`)}
                        className="agent-form__check-box-margin"
                    />
                    <div className="extra-settings">
                        <ValidatableInput
                            id={`formField${index}`}
                            as="select"
                            name={`${name}field`}
                            disabled={!getIn(values, `${name}fieldEnabled`)}
                        >
                            <option value="" hidden>
                                {t(formTranslation.select)}
                            </option>
                            {formFields.map(option => (
                                <option value={option.value} key={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </ValidatableInput>
                    </div>
                </>
            }
            onDelete={onDelete}
            disabled={disabled}
        />
    )
}

export default ClaimsMappingControl
