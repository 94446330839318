export const FETCH_QUEUES_FAILED_MESSAGE = "error:queues.fetch-queues-failed"
export const CREATE_QUEUE_FAILED_MESSAGE = "error:queues.create-queue-failed"
export const UPDATE_QUEUE_FAILED_MESSAGE = "error:queues.update-queue-failed"
export const UPDATE_INDIVIDUAL_QUEUES_FAILED_MESSAGE = "error:queues.update-individual-queues-failed"
export const DELETE_QUEUE_FAILED_MESSAGE = "error:queues.delete-queue-failed"
export const FETCH_MONITORING_OVERVIEW_FAILED_MESSAGE = "error.queues.fetch-monitoring-overview-failed"
export const SEARCH_IN_QUEUE_FAILED_MESSAGE = "error:queues.search-in-queue-failed"
export const GET_QUEUE_CATEGORIES_FAILED_MESSAGE = "error:queues.get-queue-categories-failed"
export const CREATE_QUEUE_CATEGORY_FAILED_MESSAGE = "error:queues.create-queue-category-failed"
export const UPDATE_QUEUE_CATEGORY_FAILED_MESSAGE = "error:queues.update-queue-category-failed"
export const REMOVE_QUEUE_CATEGORY_FAILED_MESSAGE = "error:queues.remove-queue-category-failed"
export const MOVE_QUEUE_FAILED_MESSAGE = "error:queues.move-queue-failed"
export const GET_QUEUE_EXTENDED_SETTINGS_FAILED_MESSAGE = "error:queues.get-queue-extended-settings-failed"
