import {
    ArticleSubscriptionFormValues,
    UpdateArticleSubscriptionsRequest
} from "../../models/knowledgeBaseSubscription"
import { isKnowledgeBaseSubscription } from "./knowledgeBase"

export const formatSubscriptionDiff = (formValues: ArticleSubscriptionFormValues) => {
    const initialValue: UpdateArticleSubscriptionsRequest = {
        AddedSubscriptions: [],
        RemovedSubscriptions: []
    }
    return Object.values(formValues.SubscriptionDiff).reduce<UpdateArticleSubscriptionsRequest>(
        (previousValue, currentValue) =>
            isKnowledgeBaseSubscription(currentValue.value)
                ? currentValue.action === "added"
                    ? {
                          ...previousValue,
                          AddedSubscriptions: [currentValue.value, ...previousValue.AddedSubscriptions]
                      }
                    : currentValue.action === "removed"
                    ? {
                          ...previousValue,
                          RemovedSubscriptions: [currentValue.value, ...previousValue.RemovedSubscriptions]
                      }
                    : previousValue
                : previousValue,
        initialValue
    )
}
