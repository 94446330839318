import React from "react"
import AsyncSelectBase from "react-select/async"
import CreatableSelectBase, { CreatableProps } from "react-select/creatable"
import SelectBase, { OptionTypeBase } from "react-select"
import "./Select.scss"
import Spinner from "../Spinner/Spinner"
import { LoadingIconProps } from "react-select/src/components/indicators"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import { AsyncProps } from "react-select/src/Async"
import { Props as SelectProps } from "react-select/src/Select"
import cn from "classnames"

export interface SelectCustomProps {
    invalid?: boolean
}

const CustomLoadingIndicator: React.FC<LoadingIconProps<OptionType, false>> = () => (
    <Spinner className="custom-select__spinner" size={15} />
)

export const AsyncSelect: React.FC<SelectProps<OptionType, false> & AsyncProps<OptionType> & SelectCustomProps> =
    props => (
        <AsyncSelectBase
            {...props}
            menuShouldScrollIntoView={false}
            classNamePrefix="custom-select"
            components={{ LoadingIndicator: CustomLoadingIndicator }}
            className={cn(props.className, props.invalid && "custom-select_invalid")}
        />
    )

export const Select: React.FC<SelectProps & SelectCustomProps> = props => (
    <SelectBase
        {...props}
        menuShouldScrollIntoView={false}
        classNamePrefix="custom-select"
        className={cn(props.className, props.invalid && "custom-select_invalid")}
    />
)

export interface CreatableSelectProps extends SelectProps {
    hideDropdownIndicator?: boolean
    isValidNewOption?: CreatableProps<OptionTypeBase, boolean>["isValidNewOption"]
}

export const CreatableSelect: React.FC<CreatableSelectProps> = props => (
    <CreatableSelectBase
        {...props}
        classNamePrefix="custom-select"
        className={cn(props.className, props.hideDropdownIndicator && "custom-select_hide-dropdown-indicator")}
    />
)
