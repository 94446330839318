import React, { useCallback, useMemo, useState } from "react"
import { ClassProps } from "../../utility/common/props"
import { Form } from "react-bootstrap"
import { Select } from "../Select/Select"
import { ValueType } from "react-select"
import AsyncSearchableInput, { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import { formTranslation } from "../../locales/form"
import { TFunction } from "react-i18next"
import {
    optionToKnowledgeBaseAccessType,
    projectsToOptions,
    searchUsersAndRolesByProject
} from "../../utility/knowledgeBase/accessOptions"
import {
    KnowledgeBasePermittedAction,
    KnowledgeBasePermittedActionSelectItem
} from "../../models/knowledgeBasePermission"
import KnowledgeBaseUserAccessItems from "../KnowledgeBaseUserAccessItems/KnowledgeBaseUserAccessItems"
import {
    KnowledgeBaseSubscriptionSelectItem,
    KnowledgeBaseSubscriptionType
} from "../../models/knowledgeBaseSubscription"
import { isSameUserAccessItem, permissionWeightMapper } from "../../utility/knowledgeBase/knowledgeBase"
import { UserAccessPrimitive } from "../../models/knowledgeBaseUserAccess"
import { Project } from "../../models/project"
import { useField } from "formik"
import styles from "./UserAccess.module.scss"

export type UserAssessItemType = "subscription" | "permission"

export interface UserAccessCommonProps {
    subscriptionOptions?: KnowledgeBaseSubscriptionSelectItem[]
    permittedActionOptions?: KnowledgeBasePermittedActionSelectItem[]
    currentProject?: Project
    projects?: Project[]
    disableProjectsSelector?: boolean
}

type Deps = UserAccessCommonProps & ClassProps

export interface UserAccessProps extends Deps {
    t: TFunction
    type: UserAssessItemType
    name: string
    title: string
    onAddToAddedList: (value: UserAccessPrimitive) => void
    onAddToRemovedList: (value: UserAccessPrimitive) => void
}

function UserAccess(props: UserAccessProps) {
    const {
        t,
        type,
        name,
        title,
        projects,
        currentProject,
        subscriptionOptions,
        permittedActionOptions,
        onAddToAddedList,
        onAddToRemovedList,
        disableProjectsSelector = false
    } = props
    const [field, meta, helpers] = useField<UserAccessPrimitive[]>(name)
    const { setValue } = helpers
    const projectOptions = useMemo(() => projectsToOptions(projects || []), [projects])

    const [selectedProjectId, setSelectedProjectId] = useState(currentProject?.id)
    const [selectedProjectTitle, setSelectedProjectTitle] = useState(currentProject?.name)

    const getOptions = useCallback(searchUsersAndRolesByProject(), [])

    const add = (item: UserAccessPrimitive) => {
        const itemList = field.value
        const alreadyAdded = itemList.some(u => isSameUserAccessItem(u, item))
        const updatedItemsList = alreadyAdded ? itemList : [item, ...itemList]
        onAddToAddedList(item)
        setValue(updatedItemsList, true)
    }

    const remove = (item: UserAccessPrimitive) => {
        const itemList = field.value.filter(u => !isSameUserAccessItem(u, item))
        onAddToRemovedList(item)
        setValue(itemList, true)
    }

    const replace = (item: UserAccessPrimitive) => {
        const itemList = field.value.map(u => (isSameUserAccessItem(u, item) ? item : u))
        onAddToAddedList(item)
        setValue(itemList, true)
    }

    const handleUserOrRoleSelect = (option: ValueType<OptionType, false>) => {
        if (selectedProjectId && option) {
            const permissionType = optionToKnowledgeBaseAccessType(option)
            if (permissionType) {
                if (type === "permission") {
                    const action = KnowledgeBasePermittedAction.Edit
                    add({
                        Type: permissionType,
                        Action: action,
                        Value: option.value,
                        ProjectId: selectedProjectId,
                        SearchValue: "",
                        Weight: permissionWeightMapper(permissionType, action)
                    })
                } else if (type === "subscription") {
                    add({
                        Type: permissionType,
                        SubscriptionType: KnowledgeBaseSubscriptionType.Notify,
                        Value: option.value,
                        ProjectId: selectedProjectId
                    })
                }
            }
        }
    }

    const handleProjectSelect = (option: ValueType<OptionType, false>) => {
        if (option) {
            setSelectedProjectId(option.value)
            setSelectedProjectTitle(option.label)
        }
    }

    return (
        <div className={styles.userAccess}>
            {!disableProjectsSelector && (
                <Form.Group>
                    <Form.Label>{title}</Form.Label>
                    <Select
                        isClearable
                        menuPlacement="auto"
                        value={{
                            label: selectedProjectTitle || "",
                            value: selectedProjectId || ""
                        }}
                        isDisabled={!projectOptions.length}
                        options={projectOptions}
                        noOptionsMessage={() => t(formTranslation.noResultsFound)}
                        onChange={handleProjectSelect}
                    />
                </Form.Group>
            )}
            <AsyncSearchableInput
                key={selectedProjectId}
                id="search-users-and-roles-by-project"
                name="search-users-and-roles-by-project"
                optionsMeta={selectedProjectId}
                getOptions={getOptions}
                onChange={handleUserOrRoleSelect}
            />
            <KnowledgeBaseUserAccessItems
                t={t}
                data={field.value}
                subscriptionOptions={subscriptionOptions}
                permittedActionOptions={permittedActionOptions}
                projectId={selectedProjectId}
                onChange={replace}
                onRemove={remove}
            />
            {meta.error && <div className={styles.userAccess__error}>{meta.error}</div>}
        </div>
    )
}

export default UserAccess
