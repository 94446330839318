import React, { useMemo } from "react"
import { FieldArray } from "formik"
import { AIAssist2AgentValues } from "../../../../models/agent"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import { nameof } from "../../../../utility/common/nameof"
import { Form } from "react-bootstrap"
import { formTranslation } from "../../../../locales/form"
import { ClassifierConfigValue } from "../../../../models/classifier"
import AgentClassifierSettingsInput from "../../../AgentClassifierSettingsInput/AgentClassifierSettingsInput"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateAgentState, selectUpdateAgentState } from "../../../../store/agents/selectors"
import { selectClassifiers } from "../../../../store/classifiers/selectors"
import { AIAssist2AgentFormProps } from "./AIAssist2AgentForm"

interface ClassifierOption {
    label: string
    value: string
}

const defaultClassifierWeight = 1
const defaultAssistantClassificationResultThreshold = 0.5
const defaultBotClassificationResultThreshold = 60

const tNamespace = "agent:form.aiassist2."

const FormikAIAssist2AgentFormClassifiers: React.FC<AIAssist2AgentFormProps> = props => {
    const { agent, t, values, handleSubmit, disabled } = props
    const asyncState = useSelector(agent ? selectUpdateAgentState : selectCreateAgentState, shallowEqual)

    const classifiers = useSelector(selectClassifiers)
    const classifierFiltered = useMemo<ClassifierOption[]>(
        () =>
            (classifiers ?? [])
                .map(classifier => ({
                    label: classifier.Name,
                    value: classifier.Name
                }))
                .filter(c => !values.ClassifiersConf.some(v => v.Name === c.value)),
        [classifiers, values.ClassifiersConf]
    )

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="agent-form__section">
                <FieldArray
                    name={nameof<AIAssist2AgentValues>("ClassifiersConf")}
                    render={({ push, remove }) => (
                        <>
                            <Form.Group controlId="formClassifiers">
                                <Form.Label>{t(`${tNamespace}dialog-classifiers`)}</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const classifier = classifiers.find(c => c.Name === e.target.value)
                                        push({
                                            Name: e.target.value,
                                            ClassifierWeight: defaultClassifierWeight,
                                            AssistantClassificationResultThreshold:
                                                defaultAssistantClassificationResultThreshold,
                                            BotClassificationResultThreshold: defaultBotClassificationResultThreshold,
                                            ClassifierWebhook: classifier
                                                ? `${classifier.Url}/message?classifier_name=${classifier.Name}`
                                                : ""
                                        })
                                    }}
                                    disabled={!classifierFiltered.length}
                                    value={""}
                                >
                                    <option value="" hidden>
                                        {t(formTranslation.select)}
                                    </option>
                                    {classifierFiltered.map(c => (
                                        <option key={c.label} value={c.value}>
                                            {c.label}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            {values.ClassifiersConf.map((classifier: ClassifierConfigValue, index: number) => (
                                <AgentClassifierSettingsInput
                                    key={classifier.Name}
                                    index={index}
                                    onDelete={() => remove(index)}
                                />
                            ))}
                        </>
                    )}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikAIAssist2AgentFormClassifiers
