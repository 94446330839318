import {
    BlockTypeWithConditions,
    ChannelScenario,
    ChannelScenarioBlock,
    CommandType,
    MessageBlockButton,
    OpenConditionFormHandler,
    Scenario,
    ScenarioBlock,
    ScenarioBlockType
} from "../../models/scenario"
import { Condition, ConditionStatement, SlotSpecialValue } from "../../models/scenarioCondition"
import { addEdge, ArrowHeadType, Edge, Elements, isEdge, isNode, Node } from "react-flow-renderer"
import { logError } from "../common/logError"
import { MessagedNodeButton, MessagedNodeData } from "../../components/Graph/nodes/Message/MessageNode"
import { ArticleNodeData } from "../../components/Graph/nodes/Article/ArticleNode"
import { handleAddConditions, handleUpdateText } from "./scenarioNode"
import { SetElementsType, UpdateConditionsCallback } from "../../components/ScenarioEditor/ScenarioContext"
import { StartNodeData } from "../../components/Graph/nodes/Start/StartNode"
import { isSelectable } from "./scenarioGraph"
import { FormNodeData } from "../../components/Graph/nodes/Form/FormNode"
import { ConditionsNodeData } from "../../components/Graph/nodes/Conditions/ConditionsNode/ConditionsNode"
import { AgentNodeData } from "../../components/Graph/nodes/Agent/AgentNode"
import { Agent } from "../../models/agent"
import { CommandNodeData } from "../../components/Graph/nodes/Command/CommandNode"
import { ChannelNodeData } from "../../components/Graph/nodes/Channel/ChannelNode"
import { Channel } from "../../models/channel"

export type NodeData =
    | MessagedNodeData
    | ArticleNodeData
    | StartNodeData
    | FormNodeData
    | ConditionsNodeData
    | AgentNodeData
    | CommandNodeData
    | ChannelNodeData

export interface NodeHandlersType {
    updateText: MessagedNodeData["UpdateText"]
    addCondition: ConditionsNodeData["AddCondition"]
}

export const getId = (prefix: string) => `${prefix}_${(~~(Math.random() * 1e8)).toString(16)}`

export const getBlockId = () => getId("block")
export const getConditionId = () => getId("condition")

export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export const handleRemoveNode = (id: string, setElements: SetElementsType) => () =>
    setElements(els => els.filter(e => !(e.id === id || (isEdge(e) && (e.source === id || e.target === id)))))

const parseCommandText = (
    text: string,
    addBlock: (source?: string, sourceHandle?: string) => void
): CommandNodeData => {
    // SET_SLOT_VALUE_slot_id text
    // SET_SLOT_VALUE_WITH_EXPRESSION_slot_id expression
    if (text.startsWith(CommandType.SetSlotValue)) {
        const command = text.startsWith(CommandType.SetSlotValueWithExpression)
            ? CommandType.SetSlotValueWithExpression
            : CommandType.SetSlotValue
        const regexMatch = text.match(new RegExp(`^${command}_(\\S+) (.+)`))

        return {
            Command: command,
            SlotId: regexMatch ? regexMatch[1] : "",
            Value: regexMatch ? regexMatch[2] : "",
            AddBlock: addBlock
        }
    }

    // DELETE_SLOT_VALUES regex
    if (text.startsWith(CommandType.DeleteSlotValues)) {
        return {
            Command: CommandType.DeleteSlotValues,
            SlotId: null,
            Value: text.substr(text.indexOf(" ") + 1),
            AddBlock: addBlock
        }
    }

    // DELETE_SLOT_VALUE_slot_id
    const regexMatch = text.match(/^DELETE_SLOT_VALUE_(\S+)/)
    return {
        Command: CommandType.DeleteSlotValue,
        SlotId: regexMatch ? regexMatch[1] : "",
        Value: null,
        AddBlock: addBlock
    }
}

const createCommandText = (data: CommandNodeData): string => {
    switch (data.Command) {
        case CommandType.SetSlotValue:
        case CommandType.SetSlotValueWithExpression:
            return `${data.Command}_${data.SlotId} ${data.Value}`
        case CommandType.DeleteSlotValue:
            return `${data.Command}_${data.SlotId}`
        case CommandType.DeleteSlotValues:
            return `${data.Command} ${data.Value}`
    }
}

export const isStartScenarioBlock = (block: ScenarioBlock | ChannelScenarioBlock): block is ChannelScenarioBlock =>
    block.Type === ScenarioBlockType.Channel || block.Type === ScenarioBlockType.Article

export const getBlockButtonId = (blockId: string) => getId(`${blockId}_btn`)

const getNodeBlockData = (
    block: ScenarioBlock | ChannelScenarioBlock,
    addBlock: (source?: string) => void,
    nodeHandlers: typeof block extends ScenarioBlock ? NodeHandlersType : undefined,
    agents: Agent[],
    channels: Channel[]
): NodeData => {
    switch (block.Type) {
        case ScenarioBlockType.Start:
            return {
                AddBlock: addBlock
            }
        case ScenarioBlockType.Message:
            return {
                Text: block.BlockData.Message.Text,
                Buttons: block.BlockData.Message.Buttons.map((btn: MessageBlockButton, index) => ({
                    Id: btn.Id ?? `${block.Id}_btn_${index}`,
                    Title: btn.Title
                })),
                UpdateText: nodeHandlers.updateText,
                AddBlock: addBlock
            }
        case ScenarioBlockType.Article:
            return {
                ArticleTitle: block.BlockData.Article.ArticleTitle,
                ArticleId: block.BlockData.Article.ArticleId
            }
        case ScenarioBlockType.Condition:
            return {
                AddBlock: addBlock,
                AddCondition: nodeHandlers.addCondition,
                Conditions: block.BlockData.Condition.Conditions.map(conditions => {
                    if (!conditions.And.length) {
                        conditions.And = [{ Slot: SlotSpecialValue.WithoutConditions }]
                    }
                    return conditions
                })
            }
        case ScenarioBlockType.Form:
            return {
                Slots: block.BlockData.Form.FormSlots.Slots,
                Conditions: block.BlockData.Form.OutputConditions.Conditions,
                AddBlock: addBlock,
                AddFormConditions: nodeHandlers.addCondition
            }
        case ScenarioBlockType.Agent:
            return {
                AgentId: block.BlockData.Agent.AgentId,
                AgentType: block.BlockData.Agent.AgentType,
                Agent: agents.find(a => a.Id === block.BlockData.Agent.AgentId),
                OwnedByThisScenario: block.BlockData.Agent.OwnedByThisScenario,
                AddBlock: addBlock
            }
        case ScenarioBlockType.Command:
            return parseCommandText(block.BlockData.Command.Text, addBlock)
        case ScenarioBlockType.Channel:
            return {
                ChannelType: block.BlockData.Channel.ChannelType,
                ChannelId: block.BlockData.Channel.ChannelId,
                Channel: channels.find(a => a.Id === block.BlockData.Channel.ChannelId),
                AddBlock: addBlock
            }
    }
}

export const getNodeHandlers = (
    setElements: SetElementsType,
    handleOpenConditionForm?: OpenConditionFormHandler
): NodeHandlersType => ({
    updateText: handleUpdateText(setElements),
    addCondition: (id: string, blockType: BlockTypeWithConditions, callback: UpdateConditionsCallback) =>
        handleOpenConditionForm && handleOpenConditionForm(handleAddConditions(setElements, id, callback), blockType)
})

export const articleScenarioToGraph = (
    json: Scenario,
    addBlock: (source?: string) => void,
    handleOpenConditionForm: OpenConditionFormHandler,
    setElements: SetElementsType,
    agents: Agent[] = []
): Elements => {
    return jsonToGraph(
        json.Blocks,
        addBlock,
        getNodeHandlers(setElements, handleOpenConditionForm),
        setElements,
        agents,
        []
    )
}

export const channelScenarioToGraph = (
    json: ChannelScenario,
    addBlock: (source?: string) => void,
    setElements: SetElementsType,
    channels: Channel[] = []
): Elements =>
    jsonToGraph<ChannelScenarioBlock>(json.Blocks, addBlock, getNodeHandlers(setElements), setElements, [], channels)

const getScenarioBlock = (elem: Node): ScenarioBlock | null => {
    const baseNode = {
        Id: elem.id,
        Position: {
            Left: elem.position.x,
            Top: elem.position.y
        },
        Inputs: [],
        Outputs: []
    }

    switch (elem.type) {
        case ScenarioBlockType.Start:
            return {
                ...baseNode,
                Type: ScenarioBlockType.Start
            }
        case ScenarioBlockType.Message:
            return {
                ...baseNode,
                Type: ScenarioBlockType.Message,
                BlockData: {
                    Message: {
                        Text: elem.data.Text,
                        Buttons: elem.data.Buttons.map((btn: MessagedNodeButton) => ({
                            Id: btn.Id,
                            Title: btn.Title || "",
                            Type: "Unknown",
                            Payload: btn.Id
                        }))
                    }
                }
            }
        case ScenarioBlockType.Article:
            return {
                ...baseNode,
                Type: ScenarioBlockType.Article,
                BlockData: {
                    Article: {
                        ArticleId: elem.data.ArticleId,
                        ArticleTitle: elem.data.ArticleTitle
                    }
                }
            }
        case ScenarioBlockType.Form:
            return {
                ...baseNode,
                Type: ScenarioBlockType.Form,
                BlockData: {
                    Form: {
                        FormSlots: { Slots: elem.data.Slots },
                        OutputConditions: { Conditions: elem.data.Conditions }
                    }
                }
            }
        case ScenarioBlockType.Condition:
            elem.data.Conditions.forEach((c: Condition) => {
                c.And = c.And.filter((c: ConditionStatement) => c.Slot !== SlotSpecialValue.WithoutConditions)
            })
            return {
                ...baseNode,
                Type: ScenarioBlockType.Condition,
                BlockData: {
                    Condition: {
                        Conditions: elem.data.Conditions
                    }
                }
            }
        case ScenarioBlockType.Agent:
            return {
                ...baseNode,
                Type: ScenarioBlockType.Agent,
                BlockData: {
                    Agent: {
                        AgentId: elem.data.AgentId,
                        AgentType: elem.data.AgentType,
                        OwnedByThisScenario: elem.data.OwnedByThisScenario
                    }
                }
            }
        case ScenarioBlockType.Command:
            return {
                ...baseNode,
                Type: ScenarioBlockType.Command,
                BlockData: {
                    Command: {
                        Text: createCommandText(elem.data)
                    }
                }
            }
        case ScenarioBlockType.Channel:
            return {
                ...baseNode,
                Type: ScenarioBlockType.Channel,
                BlockData: {
                    Channel: {
                        ChannelId: elem.data.ChannelId,
                        ChannelType: elem.data.ChannelType
                    }
                }
            }
        default:
            return null
    }
}

export const jsonToGraph = <T extends ScenarioBlock | ChannelScenarioBlock>(
    json: T[],
    addBlock: (source?: string) => void,
    nodeHandlers: NodeHandlersType,
    setElements: SetElementsType,
    agents: Agent[],
    channels: Channel[]
): Elements => {
    return json.reduce<Elements>((arr, block) => {
        const elem: Node = {
            id: block.Id,
            type: block.Type,
            position: {
                x: block.Position.Left,
                y: block.Position.Top
            },
            data: getNodeBlockData(block, addBlock, nodeHandlers, agents, channels),
            selectable: isSelectable(block.Type)
        }
        arr.push(elem)

        block.Inputs.forEach(input => {
            const source = input.From,
                sourceHandle = input.FromOutput.Id,
                target = block.Id

            const connection: Edge = {
                id: `reactflow__edge-${source}${sourceHandle}-${target}${target}`,
                source,
                sourceHandle,
                target,
                targetHandle: target,
                arrowHeadType: ArrowHeadType.ArrowClosed,
                type: "custom"
            }
            arr = addEdge(connection, arr)
        })

        return arr
    }, [])
}

export const graphToJson = (elements: Elements): Scenario => {
    try {
        const blocks = elements.reduce<ScenarioBlock[]>((arr, elem) => {
            if (isNode(elem)) {
                const block = getScenarioBlock(elem)
                if (block) {
                    arr.push(block)
                }
            }

            return arr
        }, [])

        elements.forEach(elem => {
            if (isEdge(elem)) {
                const targetBlock = blocks.find(b => b.Id === elem.target)
                const sourceBlock = blocks.find(b => b.Id === elem.source)

                if (!targetBlock || !sourceBlock) return

                if (sourceBlock.Type === ScenarioBlockType.Message) {
                    const sourceHandleBtn = sourceBlock.BlockData.Message.Buttons.find(
                        btn => btn.Payload === elem.sourceHandle
                    )
                    if (!sourceHandleBtn && elem.sourceHandle !== elem.source) return

                    if (sourceHandleBtn) {
                        sourceHandleBtn.Payload = elem.target
                        sourceHandleBtn.Type = "Article"
                    }
                }

                targetBlock.Inputs.push({
                    From: elem.source,
                    FromOutput: {
                        Id: elem.sourceHandle || elem.id,
                        Type: sourceBlock.Type === ScenarioBlockType.Agent ? "Agent" : "Article"
                    }
                })

                sourceBlock.Outputs.push({
                    Id: elem.sourceHandle || elem.id,
                    Type: targetBlock.Type === ScenarioBlockType.Agent ? "Agent" : "Article",
                    To: elem.target
                })
            }
        })

        blocks.forEach(b => {
            if (b.Type === ScenarioBlockType.Message) {
                b.BlockData.Message.Buttons.forEach(btn => {
                    if (btn.Type === "Unknown") {
                        btn.Payload = ""
                    }
                })
            }
        })

        return { Blocks: blocks }
    } catch (e) {
        logError(e)
        return { Blocks: [] }
    }
}
