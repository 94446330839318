import React, { memo, useMemo } from "react"
import "./CommandNode.scss"
import CommonNode from "../Common/CommonNode"
import { CommandType, ScenarioBlockType } from "../../../../models/scenario"
import { NodeComponentProps } from "react-flow-renderer/dist/nocss/types"
import { TFunction, useTranslation } from "react-i18next"
import { Handle, Position } from "react-flow-renderer"
import { useSelector } from "react-redux"
import { selectSlots } from "../../../../store/slots/selectors"
import { getSlotTitle } from "../../../../utility/knowledgeBase/slot"

const tNamespace = "scenarioEditor:"

const getNodeTitle = (data: CommandNodeData, t: TFunction, slotTitle?: string) => {
    switch (data.Command) {
        case CommandType.DeleteSlotValue:
            return `${t(`${tNamespace}delete-slot-value`)}:`
        case CommandType.DeleteSlotValues:
            return `${t(`${tNamespace}delete-slot-values`)}:`
        case CommandType.SetSlotValue:
        case CommandType.SetSlotValueWithExpression:
            return `${slotTitle ?? data.SlotId} =`
    }
}

export interface CommandNodeData {
    Command: CommandType
    SlotId: string | null
    Value: string | null
    AddBlock: (source?: string, sourceHandle?: string) => void
}

type Props = NodeComponentProps<CommandNodeData>

const CommandNode: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { data, id, isConnectable } = props

    const slots = useSelector(selectSlots)
    const slotTitle = useMemo(() => getSlotTitle(data.SlotId, slots), [data.SlotId, slots])

    return (
        <CommonNode
            id={id}
            className="command-node"
            headerClassName="command-node__header"
            type={ScenarioBlockType.Command}
            isConnectable={isConnectable}
        >
            <div className="command-node__content">
                <div className="command-node__title">{getNodeTitle(data, t, slotTitle)}</div>
                {data.Value}
                {data.Command === CommandType.DeleteSlotValue && slotTitle}
            </div>
            <Handle
                id={id}
                type="source"
                position={Position.Right}
                className="command-node__handle"
                onClick={() => data.AddBlock(id, id)}
                isConnectable={isConnectable}
            />
        </CommonNode>
    )
}

export default memo(CommandNode)
