import { TMessageDatagram, TMessageDatagramPayloadUni } from "../../jsagent-bridge"

export class ServiceWorkerCommunicate<T extends unknown> {
    postMessageToSW<S extends TMessageDatagramPayloadUni, K extends unknown>(
        message: TMessageDatagram<S>,
        payloadAddons: Record<string, unknown>
    ) {
        return new Promise<K>((res, rej) => {
            navigator.serviceWorker.ready.then(worker => {
                if (!worker.active) {
                    return rej("SW not activated")
                }

                const messageChannel = new MessageChannel()

                worker.active.postMessage(
                    {
                        type: "PROTO_HANDSHAKE"
                    },
                    [messageChannel.port2]
                )

                messageChannel.port1.onmessage = e => {
                    if (!e.data.error) {
                        return res(e.data.payload)
                    }

                    return rej(e.data.error)
                }

                worker.active.postMessage({
                    ...message,
                    payload: {
                        ...message.payload,
                        ...payloadAddons
                    }
                })
            })
        })
    }
}
