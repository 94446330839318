import ArticleFile from "../../components/ArticleFile/ArticleFile"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperclip } from "@fortawesome/pro-light-svg-icons/faPaperclip"
import { TFunction } from "i18next"
import { articleContentEditorTranslation } from "../../locales/articleContentEditor"
import useRefValues from "../common/useRefValues"
import { ArticleVideo } from "../../components/ArticleVideo/ArticleVideo"
import { ArticleAudio } from "../../components/ArticleAudio/ArticleAudio"
import { ReactComponent as VideoFileIcon } from "../../assets/images/videoFile.svg"
import "../../components/ArticleFile/ArticleFile.scss"
import { faVolumeUp } from "@fortawesome/pro-light-svg-icons"

export const allowedFileExtensionsDefault = [
    "doc",
    "docx",
    "pdf",
    "xls",
    "xlsx",
    "json",
    "zip",
    "rar",
    "csv",
    "ppt",
    "pptx",
    "exe",
    "msi",
    "svg",
    "dmg",
    "txt",
    "vsd",
    "vsdx"
]

export const allowedVideoExtensionsDefault = ["mp4", "webm"]

export const allowedAudioExtensionsDefault = ["mp3", "ogg", "wav"]

export const ALLOWED_FILE_EXTENSIONS_REF_VALUE = "KnowledgeBaseAllowedFileExtensions"

export const useGetAllowedFileExtensions = () => {
    const allowedExtensionsRef = useRefValues(ALLOWED_FILE_EXTENSIONS_REF_VALUE)
    const resultArray =
        Array.isArray(allowedExtensionsRef) && allowedExtensionsRef.length > 0
            ? allowedExtensionsRef
            : allowedFileExtensionsDefault
    return resultArray.filter(ext => !allowedVideoExtensionsDefault.includes(ext))
}

export const useGetAllowedVideoExtensions = () => {
    const allowedExtensionsRef = useRefValues(ALLOWED_FILE_EXTENSIONS_REF_VALUE)
    return Array.isArray(allowedExtensionsRef) && allowedExtensionsRef.length > 0
        ? allowedExtensionsRef.filter(ext => allowedVideoExtensionsDefault.includes(ext))
        : allowedVideoExtensionsDefault
}

export const useGetAllowedAudioExtensions = () => {
    const allowedExtensionsRef = useRefValues(ALLOWED_FILE_EXTENSIONS_REF_VALUE)
    return Array.isArray(allowedExtensionsRef) && allowedExtensionsRef.length > 0
        ? allowedExtensionsRef.filter(ext => allowedAudioExtensionsDefault.includes(ext))
        : allowedAudioExtensionsDefault
}

export const fileEmbedKeyword = "file"
export const videoEmbedKeyword = "video"
export const audioEmbedKeyword = "audio"

export const getEmbedRegexp = (extensions: string[]): RegExp =>
    new RegExp("(?:[^\\/][\\d\\w-]+(?:" + extensions.map(e => `\\.${e}`).join("|") + "))$", "i")

export const getArticleRedactorEmbeds = (
    t: TFunction,
    allowedFileExtensions: string[],
    allowedVideoExtensions: string[],
    allowedAudioExtensions: string[]
) => {
    const filesRegexp = getEmbedRegexp(allowedFileExtensions)
    const videoRegexp = getEmbedRegexp(allowedVideoExtensions)
    const audioRegexp = getEmbedRegexp(allowedAudioExtensions)

    const embeds = []

    if (allowedFileExtensions.length) {
        embeds.push({
            title: t(articleContentEditorTranslation[fileEmbedKeyword]),
            keywords: fileEmbedKeyword,
            defaultHidden: false,
            icon: () => <FontAwesomeIcon icon={faPaperclip} size="lg" className="article-file-menu-icon" />,
            matcher: (url: string) => {
                if (!allowedFileExtensions.length) {
                    return false
                }
                return url.match(filesRegexp) || false
            },
            component: ArticleFile
        })
    }

    if (allowedVideoExtensions.length) {
        embeds.push({
            title: t(articleContentEditorTranslation[videoEmbedKeyword]),
            keywords: videoEmbedKeyword,
            defaultHidden: false,
            icon: () => <VideoFileIcon className="article-file-menu-icon" />,
            matcher: (url: string) => {
                if (!allowedVideoExtensions.length) {
                    return false
                }
                return url.match(videoRegexp) || false
            },
            component: ArticleVideo
        })
    }

    if (allowedAudioExtensions.length) {
        embeds.push({
            title: t(articleContentEditorTranslation[audioEmbedKeyword]),
            keywords: audioEmbedKeyword,
            defaultHidden: false,
            icon: () => (
                <FontAwesomeIcon
                    icon={faVolumeUp}
                    size="lg"
                    className="article-file-menu-icon"
                    color="#333"
                    fill="#333"
                />
            ),
            matcher: (url: string) => {
                if (!allowedAudioExtensions.length) {
                    return false
                }
                return url.match(audioRegexp) || false
            },
            component: ArticleAudio
        })
    }

    return embeds
}
