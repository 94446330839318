import React, { CSSProperties, MouseEventHandler, useEffect } from "react"
import styles from "./SidebarRight.module.scss"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import CloseButton from "../CloseButton/CloseButton"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsButton from "../SettingsButton/SettingsButton"
import { selectCurrentProject } from "../../store/projects/selectors"
import { useSelector } from "react-redux"
import IconControl from "../IconControl/IconControl"
import { faLongArrowLeft } from "@fortawesome/pro-light-svg-icons/faLongArrowLeft"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"

const tooltipDelay = { show: 450, hide: 0 }
const sidebarTitleMaxLength = 40

export interface SidebarRightProps extends ClassProps {
    open: boolean
    title: string
    onClose: () => void
    onCloseClick?: () => void
    settings?: JSX.Element[]
    width?: number
    onBack?: MouseEventHandler<HTMLDivElement>
    classNameClosed?: string
    contentClassName?: string
}

const SidebarRight: React.FC<SidebarRightProps> = props => {
    const {
        children,
        onClose,
        onCloseClick,
        open,
        className,
        title,
        settings,
        width,
        onBack,
        classNameClosed,
        contentClassName
    } = props
    const currentProject = useSelector(selectCurrentProject)

    const addCustomWidth = (width: number, isClosed: boolean): CSSProperties => {
        let style: CSSProperties = { width: `${width}px` }
        if (isClosed) {
            style = { marginRight: `-${width}px`, ...style }
        }
        return style
    }

    useEffect(() => {
        currentProject && onClose()
    }, [currentProject, onClose])

    return (
        <div
            className={cn(
                styles.sidebarRight,
                className,
                !open && styles.sidebarRight_closed,
                !open && classNameClosed
            )}
            style={width ? addCustomWidth(width, !open) : undefined}
        >
            {
                <div className={styles.sidebarRight__header}>
                    {onBack ? (
                        <IconControl
                            icon={faLongArrowLeft}
                            onClick={onBack}
                            className={styles.sidebarRight__backIcon}
                        />
                    ) : (
                        <CloseButton onClick={onCloseClick ?? onClose} className={styles.sidebarRight__closeBtnLeft} />
                    )}
                    <TooltipTrigger
                        id="sidebar-right-title-tooltip"
                        placement="bottom"
                        content={title}
                        condition={title ? title.length > sidebarTitleMaxLength : false}
                        delay={tooltipDelay}
                    >
                        <span
                            className={cn(styles.sidebarRight__title, !settings && styles.sidebarRight__title_adjusted)}
                        >
                            {title}
                        </span>
                    </TooltipTrigger>
                    {settings && (
                        <div className={styles.sidebarRight__settings}>
                            <SettingsContextMenu items={settings}>
                                <SettingsButton />
                            </SettingsContextMenu>
                        </div>
                    )}
                    {onBack && (
                        <CloseButton onClick={onCloseClick ?? onClose} className={styles.sidebarRight__closeBtnRight} />
                    )}
                </div>
            }
            <div className={cn(styles.sidebarRight__content, contentClassName)}>{open && children}</div>
        </div>
    )
}

export default SidebarRight
