import React from "react"
import { useDispatch, connect } from "react-redux"
import { FormikProps, Formik } from "formik"
import { Form } from "react-bootstrap"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import "./SendSignUpEmailForm.scss"
import * as Yup from "yup"
import { WithT } from "i18next"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { nameof } from "../../utility/common/nameof"
import { sendSignUpEmail } from "../../store/signUp/thunks"
import { SendSignUpEmailRequest } from "../../models/signUp"
import { formTranslation } from "../../locales/form"
import { selectSendSignUpEmailState } from "../../store/signUp/selectors"
import { RootState } from "../../store/rootReducer"
import { getSystemErrorMessage, SystemError } from "../../core/error"
import NotificationCard from "../NotificationCard/NotificationCard"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle"
import LoadingButton from "../LoadingButton/LoadingButton"
import { getLanguage } from "../../utility/common/language"
import HaveAccountLink from "../HaveAccountLink/HaveAccountLink"

const tNamespace = "signUp:email-form."

interface SignUpEmailValues {
    email: string
}

interface Props {
    processing: boolean
    error?: SystemError
}

const FormikSendSignUpEmailForm: React.FC<Props & FormikProps<SignUpEmailValues> & WithT> = props => {
    const { t, handleSubmit, processing, error } = props

    return (
        <div className={cn("sign-up-email-form")}>
            <h1>{t(`${tNamespace}title`)}</h1>
            <div className="sign-up-email-form__container">
                {error && <NotificationCard message={getSystemErrorMessage(error, t)} icon={faExclamationCircle} />}
                <Form noValidate onSubmit={handleSubmit}>
                    <ValidatableInput
                        id="signUpEmailForm"
                        type="text"
                        placeholder={t(`${tNamespace}email-placeholder`)}
                        name={nameof<SignUpEmailValues>("email")}
                        label={t(formTranslation.email)}
                    />
                    <LoadingButton
                        type="submit"
                        className="sign-up-email-form__submit"
                        loading={processing}
                        variant="primary"
                    >
                        {t(formTranslation.continue)}
                    </LoadingButton>
                    <HaveAccountLink t={t} className="sign-up-email-form__footer" />
                </Form>
            </div>
        </div>
    )
}

const createRequest = (values: SignUpEmailValues, language: string): SendSignUpEmailRequest => {
    return {
        Email: values.email,
        Language: language
    }
}

const SendSignUpEmailForm: React.FC<Props> = props => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    return (
        <Formik
            initialValues={{
                email: ""
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .requiredExcludeEmpty(formTranslation.emailRequired)
                    .email(formTranslation.emailInvalid)
            })}
            onSubmit={(values: SignUpEmailValues) => {
                dispatch(sendSignUpEmail(createRequest(values, getLanguage(i18n))))
            }}
        >
            {formikProps => <FormikSendSignUpEmailForm {...formikProps} t={t} {...props} />}
        </Formik>
    )
}

const mapStateToProps = (state: RootState) => ({
    processing: selectSendSignUpEmailState(state).inProcess,
    error: selectSendSignUpEmailState(state).error
})

export default connect(mapStateToProps)(SendSignUpEmailForm)
