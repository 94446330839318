import React from "react"
import { AIAssist2AgentValues } from "../../../../models/agent"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectCreateAgentState, selectUpdateAgentState } from "../../../../store/agents/selectors"
import { Form } from "react-bootstrap"
import { nameof2 } from "../../../../utility/common/nameof"
import { ParamValues } from "../../../../models/parameterDeclaration"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../../CheckBoxValidatableInput/DebouncedCheckBox"
import Slider from "../../../Slider/Slider"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import ArticleSelect from "../../../ArticleSelect/ArticleSelect"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import { AIAssist2AgentFormProps } from "./AIAssist2AgentForm"

const tNamespace = "agent:form.aiassist2."

const FormikAIAssistAgentFormAdvanced: React.FC<AIAssist2AgentFormProps> = props => {
    const { agent, t, values, setFieldValue, handleSubmit, disabled, defaultArticleOptions } = props

    const dispatch = useDispatch()
    const asyncState = useSelector(agent ? selectUpdateAgentState : selectCreateAgentState, shallowEqual)
    const projectId = useSelector(selectCurrentProjectId)

    const handleMaxIntentRepetitionChange = (v: number) => {
        setFieldValue(nameof2<AIAssist2AgentValues, ParamValues>("Params", "max_intent_repetition"), v, false)
    }

    const handleButtonClassifierThresholdChange = (v: number) => {
        setFieldValue(nameof2<AIAssist2AgentValues, ParamValues>("Params", "button_classifier_threshold"), v, false)
    }

    const handleDictionarySlotMaxButtonsChange = (v: number) => {
        setFieldValue(nameof2<AIAssist2AgentValues, ParamValues>("Params", "dictionary_slot_max_buttons"), v, false)
    }

    const handleDictionarySlotShortlistButtonsChange = (v: number) => {
        setFieldValue(
            nameof2<AIAssist2AgentValues, ParamValues>("Params", "dictionary_slot_shortlist_buttons"),
            v,
            false
        )
    }

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="agent-form__section">
                <DebouncedCheckBox
                    id="formMaxIntentRepetitionEnabled"
                    name={nameof2<AIAssist2AgentValues, ParamValues>("Params", "max_intent_repetition_enabled")}
                    label={t(`${tNamespace}maximum-impressions-per-response`)}
                />
                <div className="extra-settings">
                    <Form.Group controlId="formMaxIntentRepetition">
                        <Slider
                            min={0}
                            max={10}
                            step={1}
                            marks={{ 0: "0", 10: "10" }}
                            onChange={handleMaxIntentRepetitionChange}
                            value={values.Params.max_intent_repetition}
                            disabled={!values.Params.max_intent_repetition_enabled}
                        />
                    </Form.Group>
                    <ArticleSelect
                        id="formMaxIntentRepetitionIntent"
                        name={nameof2<AIAssist2AgentValues, ParamValues>("Params", "max_intent_repetition_intent")}
                        label={t(`${tNamespace}intent-for-translation`)}
                        disabled={!values.Params.max_intent_repetition_enabled}
                        projectId={projectId}
                        searchBySymbolCode
                        allowSearchWithoutPermissions
                        dispatch={dispatch}
                        defaultOptions={defaultArticleOptions}
                    />
                </div>
                <DebouncedValidatableInput
                    id="formCancelButtonTitle"
                    type="text"
                    name={nameof2<AIAssist2AgentValues, ParamValues>("Params", "cancel_button_title")}
                    label={t(`${tNamespace}button-label-to-exit-bot-script`)}
                />
                <Form.Group controlId="formButtonClassifierThreshold">
                    <Form.Label>{t(`${tNamespace}button-classifier-threshold`)}</Form.Label>
                    <Slider
                        min={0}
                        max={100}
                        step={1}
                        marks={{ 0: "0", 100: "100" }}
                        onChange={handleButtonClassifierThresholdChange}
                        value={values.Params.button_classifier_threshold}
                    />
                </Form.Group>
                <Form.Group controlId="formDictionarySlotMaxButtons">
                    <Form.Label>{t(`${tNamespace}dictionary-slot-max-buttons`)}</Form.Label>
                    <Slider
                        min={0}
                        max={20}
                        step={1}
                        marks={{ 0: "0", 20: "20" }}
                        onChange={handleDictionarySlotMaxButtonsChange}
                        value={values.Params.dictionary_slot_max_buttons}
                    />
                </Form.Group>
                <Form.Group controlId="formDictionarySlotShortlistButtons">
                    <Form.Label>{t(`${tNamespace}dictionary-slot-shortlist-buttons`)}</Form.Label>
                    <Slider
                        min={0}
                        max={20}
                        step={1}
                        marks={{ 0: "0", 20: "20" }}
                        onChange={handleDictionarySlotShortlistButtonsChange}
                        value={values.Params.dictionary_slot_shortlist_buttons}
                    />
                </Form.Group>
            </div>
        </AgentFormWrapper>
    )
}

export default FormikAIAssistAgentFormAdvanced
