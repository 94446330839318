import { apiCallExceptionToSystemError } from "./apiCallExceptionToSystemError"
import { useCallback, useEffect, useRef, useState } from "react"
import AsyncState from "../../core/asyncState"
import memoize from "lodash-es/memoize"

export interface UseAsyncStateOptions {
    loadOnce: boolean
}

function useAsyncState<T>(load: () => Promise<T>, errorMsg: string, options?: UseAsyncStateOptions) {
    const [asyncState, setAsyncState] = useState<AsyncState<T>>(AsyncState.create())
    const [memoFetchData, setMemoFetchData] = useState<(() => Promise<T>) | null>(null)
    const loadOnceOption = options?.loadOnce ?? false
    const isMounted = useRef(false)

    const loadData = useCallback(async () => {
        try {
            setAsyncState(AsyncState.createProcess<T>())
            const data = memoFetchData ? await memoFetchData() : await load()
            isMounted.current && setAsyncState(AsyncState.createSuccess(data))
        } catch (e) {
            const error = apiCallExceptionToSystemError(errorMsg, e)
            setAsyncState(AsyncState.createFailed<T>(error))
        }
    }, [load, errorMsg, memoFetchData])

    useEffect(() => {
        isMounted.current = true

        if (!loadOnceOption || memoFetchData) {
            loadData()
        }

        return () => {
            isMounted.current = false
        }
    }, [loadData, memoFetchData, loadOnceOption])

    useEffect(() => {
        if (loadOnceOption && memoFetchData === null) {
            const fetchData = memoize(load)
            setMemoFetchData(() => fetchData)
        }
    }, [load, loadOnceOption, memoFetchData])

    return { asyncState, reload: loadData }
}

export default useAsyncState
