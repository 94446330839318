import { AppError, createSystemError, isError, SystemError } from "../../core/error"
import { isErrorResponse } from "../../models/errorResponse"
import { AxiosError } from "axios"
import { tNamespace } from "../../locales/error"

export const isAxiosError = (error: unknown): error is AxiosError => {
    if (typeof error !== "object" || error === null) {
        return false
    }

    return (
        "config" in error &&
        typeof (error as AxiosError).config === "object" &&
        "isAxiosError" in error &&
        "toJSON" in error &&
        typeof (error as AxiosError).toJSON === "function"
    )
}

const apiCallExceptionToAppError = (error: unknown): AppError => {
    if (isAxiosError(error)) {
        const errorResponse = error.response

        if (!errorResponse || !errorResponse.data) {
            return error
        }

        if (isErrorResponse(errorResponse.data)) {
            const error = errorResponse.data.error
            return {
                reason: error.message,
                reasonKey: `${tNamespace}${error.key}`
            }
        } else {
            return JSON.stringify(errorResponse.data)
        }
    }

    if (isError(error)) {
        return error
    }

    return JSON.stringify(error)
}

export const apiCallExceptionToSystemError = (messageKey: string, error: unknown): SystemError => {
    return createSystemError(messageKey, apiCallExceptionToAppError(error))
}
