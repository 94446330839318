import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import {
    DialogBadge,
    ISearchOperatorsByCriterionRequest,
    ISearchOperatorsByCriterionResponse
} from "../../models/Dialogs/dialog"
import { GetUpdatedMessagesRequest, UpdatedMessage } from "../../models/Dialogs/message"
import { SystemError } from "../../core/error"
import { LegacyDialog } from "../../models/Dialogs/legacyDialog"
import { SurveyForm } from "../../models/Dialogs/surveyForm"
import { dialogDraftsLocalStoreDriver, dialogsApi } from "../../api/controllers/dialogs"
import { convertDialogTopicsToTree, getListOfSelectedIds } from "../../utility/dialogs/dialog-topics"
import { IAISuggestItemResponse } from "../../models/Dialogs/aiSuggestDTOs"
import { resetReducerState } from "../action"
import { ChannelTypeString, SimpleChannel } from "../../models/channel"

export interface DialogTopicsTreeData {
    rootId: string
    items: Record<string, DialogTopicsTreeItem>
}
export interface DialogTopicsTreeItemData {
    isSelected: boolean
    parentId: string
    title: string
}
export interface DialogTopicsTreeItem {
    id: string
    children: string[]
    hasChildren?: boolean
    isExpanded?: boolean
    isChildrenLoading?: boolean
    data: DialogTopicsTreeItemData
}

export interface IAIAssistHint {
    alarm: boolean
    message: string
    supportArticles: Array<Record<string, string>>
}

export type DialogsSlice = Readonly<{
    aiAssistHint: AsyncState<IAIAssistHint>
    aiSuggest: AsyncState<IAISuggestItemResponse[]>
    aiAssistRequestTypes: AsyncState<string[]>
    operatorDialogs: AsyncState<DialogBadge[]>
    current: AsyncState<LegacyDialog>
    messages: AsyncState<UpdatedMessage[]>
    survey: AsyncState<SurveyForm>
    selectedDialogId?: string
    selectedOperatorClientId?: string
    messagesPaginationState?: GetUpdatedMessagesRequest
    searchCriterion?: string
    searchOperatorByCriterionResponse: ISearchOperatorsByCriterionResponse[]
    searchOperatorByCriterionPrevArgs: ISearchOperatorsByCriterionRequest
    topics: {
        isTopicsSidebarOpened: boolean
        treeData: DialogTopicsTreeData
    }
    inputMessage: string
    inputDrafted: string
    knowledgeBase: {
        isKnowledgeBaseSidebarOpened: boolean
        isArticleSidebarOpened: boolean
    }
    lastMessageChannel: SimpleChannel
}>

const initTopicsTree: DialogTopicsTreeData = {
    rootId: "root",
    items: {
        root: {
            id: "root",
            children: [],
            data: {
                parentId: "",
                title: "",
                isSelected: false
            }
        }
    }
}

const initialState: DialogsSlice = {
    aiAssistHint: AsyncState.create(),
    aiSuggest: AsyncState.create(),
    aiAssistRequestTypes: AsyncState.create(),
    operatorDialogs: AsyncState.create(),
    current: AsyncState.create(),
    messages: AsyncState.create(),
    survey: AsyncState.create(),
    searchOperatorByCriterionPrevArgs: {
        query: "",
        statuses: [],
        queues: [],
        roles: [],
        includeCurrentUser: false
    },
    searchOperatorByCriterionResponse: [],
    topics: {
        isTopicsSidebarOpened: false,
        treeData: initTopicsTree
    },
    inputMessage: "",
    inputDrafted: "",
    knowledgeBase: {
        isKnowledgeBaseSidebarOpened: false,
        isArticleSidebarOpened: false
    },
    lastMessageChannel: {
        Id: "",
        Type: ChannelTypeString.Unknown,
        Title: ""
    }
}

const dialogs = createSlice({
    name: "dialogs",
    initialState,
    reducers: {
        getAIAssistHintProcess(state) {
            state.aiAssistHint = state.aiAssistHint.toProcess()
        },
        getAIAssistHintSuccess(state, action: PayloadAction<IAIAssistHint>) {
            state.aiAssistHint = state.aiAssistHint.toSuccess(action.payload)
        },
        getAIAssistHintFailed(state, action: PayloadAction<SystemError>) {
            state.aiAssistHint = state.aiAssistHint.toFailed(action.payload)
        },
        getAIAssistHintReset(state) {
            state.aiAssistHint = AsyncState.create()
        },
        getAISuggestProcess(state) {
            state.aiSuggest = state.aiSuggest.toProcess()
        },
        getAISuggestSuccess(state, action: PayloadAction<IAISuggestItemResponse[]>) {
            state.aiSuggest = state.aiSuggest.toSuccess(action.payload)
        },
        getAISuggestFailed(state, action: PayloadAction<SystemError>) {
            state.aiSuggest = state.aiSuggest.toFailed(action.payload)
        },
        getAISuggestReset(state) {
            state.aiSuggest = AsyncState.create()
        },
        getAIAssistRequestTypesProcess(state) {
            state.aiAssistRequestTypes = state.aiAssistRequestTypes.toProcess()
        },
        getAIAssistRequestTypesSuccess(state, action: PayloadAction<string[]>) {
            state.aiAssistRequestTypes = state.aiAssistRequestTypes.toSuccess(action.payload)
        },
        getAIAssistRequestTypesFailed(state, action: PayloadAction<SystemError>) {
            state.aiAssistRequestTypes = state.aiAssistRequestTypes.toFailed(action.payload)
        },
        getOperatorDialogsProcess(state) {
            state.operatorDialogs = state.operatorDialogs.toProcess()
        },
        getOperatorDialogsSuccess(state, action: PayloadAction<DialogBadge[]>) {
            state.operatorDialogs = state.operatorDialogs.toSuccess(action.payload)
        },
        getOperatorDialogsFailed(state, action: PayloadAction<SystemError>) {
            state.operatorDialogs = state.operatorDialogs.toFailed(action.payload)
        },
        getCurrentDialogProcess(state) {
            state.current = state.current.toProcess()
        },
        getCurrentDialogSuccess(state, action: PayloadAction<LegacyDialog>) {
            state.current = state.current.toSuccess(action.payload)
        },
        getCurrentDialogFailed(state, action: PayloadAction<SystemError>) {
            state.current = state.current.toFailed(action.payload)
        },
        getDialogMessagesProcess(state) {
            state.messages = state.messages.toProcess()
        },
        getDialogMessagesSuccess(state, action: PayloadAction<UpdatedMessage[]>) {
            state.messages = state.messages.toSuccess(action.payload)
        },
        getDialogMessagesFailed(state, action: PayloadAction<SystemError>) {
            state.messages = state.messages.toFailed(action.payload)
        },
        setCurrentOperatorClientId(state, action: PayloadAction<string>) {
            state.selectedOperatorClientId = action.payload
        },
        unsetCurrentOperatorClientId(state) {
            state.selectedOperatorClientId = undefined
        },
        setCurrentDialogId(state, action: PayloadAction<string>) {
            state.selectedDialogId = action.payload
        },
        unsetCurrentDialogId(state) {
            state.selectedDialogId = undefined
        },
        setSearchCriterion(state, action: PayloadAction<string>) {
            state.searchCriterion = action.payload
        },
        getClientSurveyProcess(state) {
            state.survey = state.survey.toProcess()
        },
        getClientSurveySuccess(state, action: PayloadAction<SurveyForm>) {
            state.survey = state.survey.toSuccess(action.payload)
        },
        getClientSurveyFailed(state, action: PayloadAction<SystemError>) {
            state.survey = state.survey.toFailed(action.payload)
        },
        setMessagesPaginationState(state, action: PayloadAction<Partial<GetUpdatedMessagesRequest>>) {
            const newState = {
                ...state.messagesPaginationState,
                ...action.payload
            } as GetUpdatedMessagesRequest
            state.messagesPaginationState = newState
        },
        resetSearchOperatorByCriterionRelatedData(state) {
            state.searchOperatorByCriterionPrevArgs = { ...initialState.searchOperatorByCriterionPrevArgs }
            state.searchOperatorByCriterionResponse = []
        },
        openTopicsSidebar(state) {
            state.topics.isTopicsSidebarOpened = true
        },
        closeTopicsSidebar(state) {
            state.topics.isTopicsSidebarOpened = false
        },
        openKnowledgeBaseSidebar(state) {
            state.knowledgeBase.isKnowledgeBaseSidebarOpened = true
        },
        closeKnowledgeBaseSidebar(state) {
            state.knowledgeBase.isKnowledgeBaseSidebarOpened = false
        },
        openArticleSidebar(state) {
            state.knowledgeBase.isArticleSidebarOpened = true
        },
        closeArticleSidebar(state) {
            state.knowledgeBase.isArticleSidebarOpened = false
        },
        selectDialogTopic(state, action: PayloadAction<DialogTopicsTreeItem>) {
            const { treeData } = state.topics
            const { items, rootId } = treeData

            const treeDataIds = Object.keys(items).filter(itemId => itemId !== rootId)

            const selectedIds = getListOfSelectedIds(treeData, action.payload)

            treeDataIds.forEach(topicId => {
                const newSelectedState = selectedIds.includes(topicId)
                const { data } = items[topicId]
                data.isSelected = newSelectedState
            })
        },
        toggleExpandDialogTopic(state, action: PayloadAction<string>) {
            const topicId = action.payload
            const prevState = state.topics.treeData.items[topicId].isExpanded
            state.topics.treeData.items[topicId].isExpanded = !prevState
        },
        clearMessageInput(state) {
            state.inputMessage = initialState.inputMessage
        },
        setMessageInput(state, action: PayloadAction<string>) {
            state.inputMessage = action.payload
        },
        setLastMessageChannel(state, action: PayloadAction<SimpleChannel>) {
            state.lastMessageChannel = action.payload
        },
        setDraftedInput(state, action: PayloadAction<string>) {
            state.inputDrafted = action.payload
        }
    },
    extraReducers: builder => {
        if (!dialogsApi?.endpoints) {
            return
        }

        builder
            .addCase(resetReducerState, () => {
                return initialState
            })
            .addMatcher(
                action => action.type.endsWith("setDraftedInput"),
                (state, action) => {
                    const draftEntityId = state.selectedDialogId ?? state.selectedOperatorClientId

                    if (!draftEntityId) {
                        return
                    }

                    if (action.payload) {
                        dialogDraftsLocalStoreDriver.insert(draftEntityId, action.payload)
                    } else {
                        dialogDraftsLocalStoreDriver.remove(draftEntityId)
                    }
                }
            )
            .addMatcher(dialogsApi.endpoints.searchOperatorByCriterion.matchFulfilled, (state, rawData) => {
                const originalArgs = rawData.meta.arg.originalArgs

                state.searchOperatorByCriterionResponse = rawData.payload

                state.searchOperatorByCriterionPrevArgs = {
                    ...state.searchOperatorByCriterionPrevArgs,
                    ...originalArgs
                }
            })
            .addMatcher(dialogsApi.endpoints.getDialogTopics.matchFulfilled, (state, { payload, ...p }) => {
                state.topics.treeData = convertDialogTopicsToTree(payload)
            })
    }
})

export default dialogs.reducer
export const { actions } = dialogs
