import { selectCurrentPermissions } from "../../store/users/selectors"
import { useSelector } from "react-redux"

export const isPermissionExists = (permission: string, currentPermissions: Set<string>) =>
    currentPermissions.has(permission)

function usePermissionsCheck(perms: string[], operation: "and" | "or" = "and") {
    const currentPerms = useSelector(selectCurrentPermissions)

    const hasPermission = (permission: string) => {
        return currentPerms.has(permission)
    }

    return operation === "and" ? perms.every(hasPermission) : perms.some(hasPermission)
}

export default usePermissionsCheck
