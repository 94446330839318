import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { HELP_LINK_BASE } from "../../utility/common/urlHelper"
import ExternalLink from "../ExternalLink/ExternalLink"

export interface DocLinkProps {
    type: string
}

const DocLink: React.FC<DocLinkProps> = props => {
    const { i18n } = useTranslation()
    const { type, children } = props

    const docUrl = useMemo(() => `${HELP_LINK_BASE}${i18n.language}/${type}`, [i18n, type])

    return <ExternalLink url={docUrl}>{children}</ExternalLink>
}

export default DocLink
