import React from "react"
import UserCard from "../UserCard/UserCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SimpleUser } from "../../models/user"
import { faCheck, faEye } from "@fortawesome/pro-light-svg-icons"
import { ArticleUserView } from "../../models/articleUserViews"
import "./ArticleUserViewCard.scss"
import cn from "classnames"

interface Props {
    user: SimpleUser
    view: ArticleUserView
}

const ArticleUserViewCard: React.FC<Props> = props => {
    const { user, view } = props

    return (
        <div className="article-user-view-card">
            <UserCard firstName={user.FirstName} lastName={user.LastName} picture={user.Picture} />
            <div className="article-user-view-card__confirms">
                <FontAwesomeIcon
                    className={cn(
                        "article-user-view-card__icon article-user-view-card__icon-viewed",
                        view.ViewedAt && "article-user-view-card__icon_active"
                    )}
                    icon={faEye}
                    size="lg"
                />
                <FontAwesomeIcon
                    className={cn(
                        "article-user-view-card__icon article-user-view-card__icon-read-confirmed",
                        view.ReadConfirmedAt && "article-user-view-card__icon_active"
                    )}
                    icon={faCheck}
                    size="lg"
                />
            </div>
        </div>
    )
}

export default ArticleUserViewCard
