const TRANSLATION_NAMESPACE = "error:channels."

export const FETCH_CHANNELS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-channels-failed`
export const FETCH_CHANNEL_SCENARIO_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-channel-scenario-failed`
export const FETCH_CHANNELS_DECLARATIONS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-channels-declarations-failed`
export const CREATE_CHANNEL_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}create-channel-failed`
export const UPDATE_CHANNEL_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-channel-failed`
export const DELETE_CHANNEL_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}delete-channel-failed`
export const GET_WEBCHAT_CHANNEL_ADDITIONAL_DATA_MESSAGE = `${TRANSLATION_NAMESPACE}get-webchat-channel-additional-data-failed`
export const SUBSCRIBE_ON_CHANNELS_READY_FAILED = `${TRANSLATION_NAMESPACE}subscribe-on-channels-failed`
export const UNSUBSCRIBE_ON_CHANNELS_READY_FAILED = `${TRANSLATION_NAMESPACE}unsubscribe-on-channels-failed`
export const FETCH_CHANNELS_WEBHOOKS_PREFIXES = `${TRANSLATION_NAMESPACE}fetch-channels-webhooks-prefixes-failed`
export const FETCH_CHANNELS_NAMES_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-channels-names-failed`
