import React from "react"
import HorizontalInputGroup, {
    HorizontalInputGroupProps
} from "../FormHelpers/HorizontalInputGroup/HorizontalInputGroup"
import { FormValue } from "../../types/FormValue"
import ValidatableInputBase, { BaseValidatableInputProps } from "../ValidatableInputBase/ValidatableInputBase"

export interface HorizontalValidatableInputProps
    extends Omit<HorizontalInputGroupProps, "children">,
        BaseValidatableInputProps {
    children?: React.ReactNode
}

function HorizontalValidatableInput<Value extends FormValue>(props: HorizontalValidatableInputProps) {
    const { id, icon, label, labelCol, inputCol, ...inputProps } = props

    return (
        <HorizontalInputGroup inputCol={inputCol} labelCol={labelCol} label={label} icon={icon} controlId={id}>
            <ValidatableInputBase<Value> {...inputProps} />
        </HorizontalInputGroup>
    )
}

export default HorizontalValidatableInput
