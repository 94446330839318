import React, { useMemo } from "react"
import styles from "./OperatorStatusSelector.module.scss"
import SidebarContextMenu from "../SidebarContextMenu/SidebarContextMenu"
import MenuItemRow from "../MenuItemRow/MenuItemRow"
import cn from "classnames"
import { mapOperatorStatusToClassName } from "../../utility/operatorStatus/mapOperatorStatusToClassName"
import { useTranslation } from "react-i18next"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { WithT } from "i18next"
import {
    OperatorStatus,
    OperatorStatusChange,
    OperatorStatusValue,
    SelectedOperatorStatus
} from "../../models/operatorStatus"
import { handleLocalizedTextTranslation } from "../../utility/common/handleLocalizedTextTranslation"
import OperatorStatusContainer from "../OperatorStatusContainer/OperatorStatusContainer"
import Spinner from "../Spinner/Spinner"
import ContentLoader from "react-content-loader"

const OPERATOR_STATUS_PREFIX = "operatorStatusSelector__item_"
const tLoadingNamespace = "common:loading."

export interface OperatorStatusSelectorProps {
    currentStatus: SelectedOperatorStatus
    statuses: OperatorStatus[]
    onChange: (newStatus: OperatorStatusChange) => void
    inHeader?: boolean
}

const OperatorStatusSelector: React.FC<OperatorStatusSelectorProps> = props => {
    const { currentStatus, statuses, onChange, inHeader } = props
    const { t } = useTranslation()
    const selectableStatuses = useMemo(
        () =>
            statuses
                .filter(s => s.Description !== currentStatus.Description)
                .filter(s => {
                    switch (currentStatus.Value) {
                        case OperatorStatusValue.Offline:
                            return s.Value === OperatorStatusValue.Online || s.Value === OperatorStatusValue.Offline
                        case OperatorStatusValue.Online:
                            return true
                        case OperatorStatusValue.DoNotRoute:
                            return s.Value === OperatorStatusValue.Offline || s.Value === OperatorStatusValue.DoNotRoute
                        default:
                            return false
                    }
                }),
        [currentStatus.Description, currentStatus.Value, statuses]
    )
    return (
        <SidebarContextMenu
            placement="bottom-end"
            containerClassName={cn(styles.operatorStatusSelector, !inHeader && styles.operatorStatusSelector_sidebar)}
            className={styles.operatorStatusSelector__context}
            items={selectableStatuses.map(status => (
                <MenuItemRow
                    icon={<div className={styles.operatorStatusSelector__circle} />}
                    title={handleLocalizedTextTranslation(status.Title, t)}
                    className={cn(
                        styles.operatorStatusSelector__item,
                        styles[mapOperatorStatusToClassName(status.Description, OPERATOR_STATUS_PREFIX)]
                    )}
                    onClick={() => onChange(status)}
                    key={status.Description}
                    testId={`${status.Description}-operator-status`}
                />
            ))}
        >
            <button
                className={cn(
                    styles.operatorStatusSelector__toggle,
                    inHeader && styles.operatorStatusSelector_inHeader
                )}
            >
                <OperatorStatusContainer
                    className={styles.operatorStatusSelector__container}
                    status={currentStatus}
                    t={t}
                    sideElement={
                        <FontAwesomeIcon icon={faAngleDown} className={styles.operatorStatusSelector__selectedIcon} />
                    }
                    inHeader={inHeader}
                />
            </button>
        </SidebarContextMenu>
    )
}

interface LoadingProps extends WithT {
    inHeader?: boolean
}

export const OperatorStatusSelectorLoading: React.FC<LoadingProps> = ({ t, inHeader }) => {
    return inHeader ? (
        <Spinner className={styles.operatorStatusSelector__spinner} />
    ) : (
        <ContentLoader
            height={15}
            title={t(tLoadingNamespace + "operator-status")}
            className={styles.operatorStatusSelector}
        >
            <rect x="0" y="0" rx="3" ry="3" width="80" height="15" />
        </ContentLoader>
    )
}

export default OperatorStatusSelector
