import { ScenarioBlockBase, ScenarioBlockType } from "./scenario"

export interface ConditionBlock extends ScenarioBlockBase<ScenarioBlockType.Condition> {
    BlockData: ConditionBlockData
}

export interface ConditionBlockData {
    Condition: ConditionPayload
}

export interface ConditionPayload {
    Conditions: Condition[]
}

export interface Condition {
    Id: string
    And: ConditionStatement[]
}

export interface ConditionStatement {
    Slot: string
    Condition?: ConditionType
    Value?: string
}

export enum ConditionType {
    Equal = "=",
    Greater = ">",
    GreaterEqual = ">=",
    Less = "<",
    LessEqual = "<=",
    Filled = "__FILLED__",
    NotFilled = "__NOT_FILLED__",
    Attempts = "ATTEMPTS"
}

const conditionTypes: string[] = Object.values(ConditionType)

export const isConditionType = (condition?: string): condition is ConditionType =>
    !!condition && conditionTypes.includes(condition)

export enum SlotSpecialValue {
    AllMandatoryFilledIn = "__ALL_MANDATORY_FILLED_IN__",
    ArbitraryExpression = "__ARBITRARY_EXPRESSION__",
    WithoutConditions = "__WITHOUT_CONDITIONS__"
}

export interface ConditionValue {
    Id: string
    And: ConditionStatementValue[]
}

export interface ConditionStatementValue {
    Type: ConditionStatementType
    Slot: string
    Condition: ConditionType
    Attempts: number
    Value: string
}

export enum ConditionStatementType {
    Filled = "filled",
    Attempts = "attempts",
    Value = "value"
}

export interface ConditionsFormValues {
    Conditions: ConditionStatementValue[]
}
