import React, { useMemo } from "react"
import { AgentValues, AIAssist2AgentValues, IntentSettingsValues } from "../../../../models/agent"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectCreateAgentState, selectUpdateAgentState } from "../../../../store/agents/selectors"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import DocLink from "../../../DocLink/DocLink"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import ArticleSelect from "../../../ArticleSelect/ArticleSelect"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import InfoIcon from "../../../InfoIcon/InfoIcon"
import { ParamValues } from "../../../../models/parameterDeclaration"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { Form } from "react-bootstrap"
import Slider from "../../../Slider/Slider"
import { AIAssist2AgentFormProps } from "./AIAssist2AgentForm"
import { Select } from "../../../Select/Select"
import { formTranslation } from "../../../../locales/form"
import { ValueType } from "react-select"
import { OptionType } from "../../../AsyncSearchableInput/AsyncSearchableInput"
import { selectArticleTypes } from "../../../../store/knowledgeBase/selectors"
import DebouncedCheckBox from "../../../CheckBoxValidatableInput/DebouncedCheckBox"
import TextItemSetting from "../../../TextItemSetting/TextItemSetting"
import { FieldArray } from "formik"

const tNamespace = "agent:form.aiassist2."
const tFormNamespace = "agent:form."
const tLinkNamespace = "agent:link."
const tTooltipNamespace = "agent:tooltip."

const defaultArticleTypes = { Types: [] }

const FormikAIAssistAgentFormGeneral: React.FC<AIAssist2AgentFormProps> = props => {
    const {
        agent,
        agentType,
        t,
        handleSubmit,
        values,
        disabled,
        ownedByThisScenario,
        setFieldValue,
        defaultArticleOptions
    } = props

    const dispatch = useDispatch()
    const asyncState = useSelector(agent ? selectUpdateAgentState : selectCreateAgentState, shallowEqual)
    const projectId = useSelector(selectCurrentProjectId)

    const articleTypes = useSelector(selectArticleTypes)
    const articleTypeOptions = (articleTypes || defaultArticleTypes).Types.map(Type => ({
        label: Type.Title,
        value: Type.Id
    }))

    const articleTypesMap = useMemo(
        () =>
            (articleTypes || defaultArticleTypes).Types.reduce((obj: { [key: string]: string }, type) => {
                obj[type.Id] = type.Title
                return obj
            }, {}),
        [articleTypes]
    )

    const getArticleSelect = (id: string, label: string, name: string, disabled?: boolean) => (
        <ArticleSelect
            id={id}
            label={label}
            name={name}
            disabled={ownedByThisScenario || disabled}
            projectId={projectId}
            searchBySymbolCode
            allowSearchWithoutPermissions
            defaultOptions={defaultArticleOptions}
            dispatch={dispatch}
        />
    )

    const handleMaxClarificationIntentsChange = (v: number) => {
        setFieldValue(nameof2<AIAssist2AgentValues, ParamValues>("Params", "max_clarification_intents"), v, false)
    }

    const handleMaxSubsectionsChange = (v: number) => {
        setFieldValue(
            nameof2<AIAssist2AgentValues, ParamValues>("Params", "knowledge_base_mode_max_subsections"),
            v,
            false
        )
    }

    return (
        <AgentFormWrapper
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
            agentType={agentType}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <ValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AIAssist2AgentValues>("Id")}
                    label={t(`${tFormNamespace}title`)}
                />
            </div>
            <div className="agent-form__section agent-form__section_border">
                {getArticleSelect(
                    "formFailIntent",
                    t(`${tNamespace}intent-not-know-the-answer`),
                    nameof2<AIAssist2AgentValues, IntentSettingsValues>("IntentSettings", "fail_intent")
                )}
                {getArticleSelect(
                    "formStopIntent",
                    t(`${tNamespace}intent-declined-question`),
                    nameof2<AIAssist2AgentValues, IntentSettingsValues>("IntentSettings", "stop_sf_intent")
                )}
                {getArticleSelect(
                    "formFileInputIntent",
                    t(`${tNamespace}intent-receiving-images-and-files`),
                    nameof2<AIAssist2AgentValues, IntentSettingsValues>("IntentSettings", "file_input_intent")
                )}
            </div>
            <div className="agent-form__section agent-form__section_indent">
                <DebouncedCheckBox
                    id="formEnableTwoStepIntentClarification"
                    className="agent-form__checkbox"
                    name={nameof2<AIAssist2AgentValues, ParamValues>("Params", "enable_two_step_intent_clarification")}
                    label={t(`${tNamespace}two-step-clarification`)}
                    icon={
                        <InfoIcon
                            id="iconEnableTwoStepIntentClarification"
                            content={t(`${tTooltipNamespace}enable-two-step-intent-clarification`)}
                        />
                    }
                />
                <div className="agent-form__subfield">
                    {getArticleSelect(
                        "formTwoStepIntentClarificationIntent",
                        t(`${tNamespace}two-step-clarification-intent`),
                        nameof2<AIAssist2AgentValues, ParamValues>("Params", "two_step_intent_clarification_intent"),
                        !values.Params.enable_two_step_intent_clarification
                    )}
                </div>
                <DebouncedCheckBox
                    id="formClarifyIntent"
                    className="agent-form__checkbox"
                    name={nameof2<AIAssist2AgentValues, ParamValues>("Params", "clarify_intent")}
                    label={t(`${tNamespace}clarify-intent`)}
                    icon={<InfoIcon id="iconClarifyIntent" content={t(`${tTooltipNamespace}clarify-intent`)} />}
                />
                <div className="agent-form__subfield">
                    <DebouncedCheckBox
                        id="formClarifyIntentInReply"
                        className="agent-form__checkbox"
                        name={nameof2<AIAssist2AgentValues, ParamValues>("Params", "clarify_intent_in_reply")}
                        label={t(`${tNamespace}clarify-intent-if-there-is-answer`)}
                        disabled={!values.Params.clarify_intent}
                    />
                    <DebouncedValidatableInput
                        id="formMessageB"
                        as="textarea"
                        name={nameof2<AgentValues, ParamValues>("Params", "message_text_for_intent_clarification")}
                        label={t(`${tNamespace}message-for-intent-clarification`)}
                        className="agent-form__message-textarea"
                        disabled={!values.Params.clarify_intent}
                    />
                    <Form.Group controlId="formMaxClarificationIntents">
                        <Form.Label>{t(`${tNamespace}max-clarification-intents`)}</Form.Label>
                        <Slider
                            min={2}
                            max={5}
                            step={1}
                            marks={{
                                2: "2",
                                5: "5"
                            }}
                            onChange={handleMaxClarificationIntentsChange}
                            value={values.Params.max_clarification_intents}
                            disabled={!values.Params.clarify_intent}
                        />
                    </Form.Group>
                    <FieldArray
                        name={nameof2<AIAssist2AgentValues, ParamValues>(
                            "Params",
                            "intent_clarification_intent_types_to_exclude"
                        )}
                        render={({ push, remove }) => (
                            <>
                                <Form.Group controlId="formIntentTypesToExclude">
                                    <Form.Label>{t(`${tNamespace}intents-to-exclude-during-clarification`)}</Form.Label>
                                    <Select
                                        value={{ label: t(`${tFormNamespace}select-article-type`), value: "" }}
                                        disabled={!values.Params.clarify_intent}
                                        options={articleTypeOptions}
                                        isDisabled={!values.Params.clarify_intent || !articleTypeOptions.length}
                                        noOptionsMessage={() => t(formTranslation.noResultsFound)}
                                        onChange={(option: ValueType<OptionType, false>) => {
                                            if (option) {
                                                push(option.value)
                                            }
                                        }}
                                    />
                                </Form.Group>
                                {values.Params.intent_clarification_intent_types_to_exclude?.map((type, index) => (
                                    <TextItemSetting
                                        key={index}
                                        title={articleTypesMap[type] ?? type}
                                        onDelete={() => remove(index)}
                                        disabled={!values.Params.clarify_intent}
                                    />
                                ))}
                            </>
                        )}
                    />
                </div>
                <div className="agent-form__section">
                    <DebouncedCheckBox
                        id="formKnowledgeBaseMode"
                        className="agent-form__checkbox"
                        name={nameof2<AIAssist2AgentValues, ParamValues>("Params", "knowledge_base_mode")}
                        label={t(`${tNamespace}knowledge-base-mode`)}
                        icon={
                            <InfoIcon
                                id="iconKnowledgeBaseMode"
                                content={t(`${tTooltipNamespace}knowledge-base-mode`)}
                            />
                        }
                    />
                </div>
                <div className="agent-form__subfield">
                    <Form.Group controlId="formMaxClarificationIntents">
                        <Form.Label>{t(`${tNamespace}knowledge-base-mode-max-subsections`)}</Form.Label>
                        <Slider
                            min={4}
                            max={10}
                            step={1}
                            marks={{
                                4: "4",
                                10: "10"
                            }}
                            onChange={handleMaxSubsectionsChange}
                            value={values.Params.knowledge_base_mode_max_subsections}
                            disabled={!values.Params.knowledge_base_mode}
                        />
                    </Form.Group>
                    <DebouncedCheckBox
                        id="formKnowledgeBaseModeUseBackButton"
                        className="agent-form__checkbox"
                        name={nameof2<AIAssist2AgentValues, ParamValues>(
                            "Params",
                            "knowledge_base_mode_use_back_button"
                        )}
                        label={t(`${tNamespace}knowledge-base-mode-use-back-button`)}
                        disabled={!values.Params.knowledge_base_mode}
                    />
                </div>
            </div>
        </AgentFormWrapper>
    )
}

export default FormikAIAssistAgentFormGeneral
