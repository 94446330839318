import { createAction } from "@reduxjs/toolkit"
import { Project } from "../../models/project"
import { User } from "../../models/user"
import { Dispatch } from "../../utility/common/storeHelper"
import { ProjectSettings } from "../../models/projectSettings"
import { actions } from "./slice"

export interface SelectProjectSuccessPayload {
    project: Project
    user: User
}

export const selectProjectSuccess = createAction<SelectProjectSuccessPayload>("projects/selectProjectSuccess")
export const updateProjectSettings = (dispatch: Dispatch, settings: ProjectSettings) => {
    dispatch(actions.updateProjectSettingsSuccess(settings))
}
export const activateFrame = () => (dispatch: Dispatch) => {
    dispatch(actions.activateFrame())
    dispatch(actions.frameLoadedProcess())
}
