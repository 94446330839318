import { Queue } from "../../models/queue"
import { QueueCategory } from "../../models/queueCategory"

export type QueuesMap = {
    [categoryId: string]: Queue[]
}

export const getQueuesMap = (queueCategories: QueueCategory[]): QueuesMap => {
    return queueCategories.reduce<QueuesMap>(
        (acc, category) => ({
            ...acc,
            [category.Id]: category.Queues
        }),
        {}
    )
}
