import { ReactElement } from "react"

interface ConditionalWrapperProps<T> {
    conditionValue?: T
    wrapper: (child: ReactElement | null, value: T) => ReactElement | null
    children: ReactElement | null
}

function ConditionalWrapper<T>(props: ConditionalWrapperProps<T>): ReactElement | null {
    const { conditionValue, wrapper, children } = props
    return conditionValue !== undefined ? wrapper(children, conditionValue) : children
}

export default ConditionalWrapper
