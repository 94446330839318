import React from "react"
import { FormikAgentFormProps } from "../../AgentForm"
import { AgentValues } from "../../../../models/agent"
import { nameof2 } from "../../../../utility/common/nameof"
import { formTranslation } from "../../../../locales/form"
import { ParamValues } from "../../../../models/parameterDeclaration"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../../CheckBoxValidatableInput/DebouncedCheckBox"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"

const tNamespace = "agent:form."

const FormikEmailAgentFormCustomization: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, t, values, handleSubmit, disabled } = props

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="agent-form__section">
                <div className="agent-form__section-subtitle">{t(`${tNamespace}smtp-protocol`)}</div>
                <DebouncedValidatableInput
                    id="formSmtpHost"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "smtp-host")}
                    label={t(`${tNamespace}server`)}
                />
                <DebouncedValidatableInput
                    id="formSmtpPort"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "smtp-port")}
                    label={t(`${tNamespace}port`)}
                />
                <DebouncedCheckBox
                    id="formEnableAuth"
                    name={nameof2<AgentValues, ParamValues>("Params", "enable-auth")}
                    label={t(`${tNamespace}auth-enabled`)}
                />
                <div className="extra-settings">
                    <DebouncedValidatableInput
                        id="formSmtpLogin"
                        type="text"
                        name={nameof2<AgentValues, ParamValues>("Params", "smtp-login")}
                        label={t(formTranslation.login)}
                        disabled={!values.Params["enable-auth"]}
                    />
                    <DebouncedValidatableInput
                        id="formSmtpPassword"
                        type="password"
                        name={nameof2<AgentValues, ParamValues>("Params", "smtp-password")}
                        label={t(formTranslation.password)}
                        disabled={!values.Params["enable-auth"]}
                    />
                </div>
                <DebouncedCheckBox
                    id="formEnableSsl"
                    name={nameof2<AgentValues, ParamValues>("Params", "enable-ssl")}
                    label={t(`${tNamespace}verify-ssl-certificate`)}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikEmailAgentFormCustomization
