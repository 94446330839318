import { FormSlot, ScenarioBlockType } from "../../models/scenario"
import { Condition, ConditionStatement } from "../../models/scenarioCondition"
import { MessagedNodeButton } from "../../components/Graph/nodes/Message/MessageNode"
import { SetElementsType, UpdateConditionsCallback } from "../../components/ScenarioEditor/ScenarioContext"
import { getConditionId } from "./scenario"
import { ElementId, isEdge } from "react-flow-renderer"

// Message
export const handleUpdateText = (setElements: SetElementsType) => (text: string, id: string | null) => {
    setElements(els =>
        els.map(e => {
            if (e.type !== ScenarioBlockType.Message) return e
            return {
                ...e,
                data: {
                    ...e.data,
                    Text: e.id === id ? text : e.data.Text
                }
            }
        })
    )
}

export const handleUpdateButtons = (setElements: SetElementsType) => (updated: MessagedNodeButton[], id: ElementId) => {
    setElements(els =>
        els.map(e => {
            if (e.type !== ScenarioBlockType.Message) return e
            return {
                ...e,
                data: {
                    ...e.data,
                    Buttons: e.id === id ? updated : e.data.Buttons
                }
            }
        })
    )
}

// Form
export const handleUpdateFormFields = (setElements: SetElementsType) => (updated: FormSlot[], id: ElementId) => {
    setElements(els =>
        els.map(e => {
            if (e.id !== id) return e
            return {
                ...e,
                data: {
                    ...e.data,
                    Slots: updated
                }
            }
        })
    )
}

// Conditions
export const handleUpdateConditions =
    (setElements: SetElementsType) => (updated: Condition[], id: string, conditionIdToDelete?: string) => {
        setElements(els => {
            let updatedElements = els.map(e => {
                if (e.id !== id) return e
                return {
                    ...e,
                    data: {
                        ...e.data,
                        Conditions: updated
                    }
                }
            })
            if (conditionIdToDelete) {
                updatedElements = updatedElements.filter(e => !(isEdge(e) && e.sourceHandle === conditionIdToDelete))
            }
            return updatedElements
        })
    }

export const handleAddConditions =
    (setElements: SetElementsType, id: string, callback: UpdateConditionsCallback) =>
    (newConditions: ConditionStatement[]) => {
        const newItem = { Id: getConditionId(), And: newConditions }

        setElements(els =>
            els.map(e => {
                if (e.id !== id) return e

                const updatedConditions = [...e.data.Conditions, newItem]
                callback(updatedConditions)
                return {
                    ...e,
                    data: {
                        ...e.data,
                        Conditions: updatedConditions
                    }
                }
            })
        )
    }

export const handleUpdateCondition =
    (setElements: SetElementsType, blockId: ElementId, conditionId: string, callback: UpdateConditionsCallback) =>
    (newConditions: ConditionStatement[]) => {
        setElements(els =>
            els.map(e => {
                if (isEdge(e) || e.id !== blockId || !e.data.Conditions) return e

                const updatedConditions = e.data.Conditions.map((c: Condition) =>
                    c.Id === conditionId ? { Id: conditionId, And: newConditions } : c
                )

                callback(updatedConditions)
                return {
                    ...e,
                    data: {
                        ...e.data,
                        Conditions: updatedConditions
                    }
                }
            })
        )
    }
