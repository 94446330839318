export async function promiseDelay<T>(promise: Promise<T>, minimumDelay: number, { delayRejection = true } = {}) {
    let timerId = null
    const delayPromise = new Promise<string>(resolve => {
        timerId = setTimeout(() => {
            resolve("success")
        }, minimumDelay)
    })
    await (delayRejection ? delayPromise : Promise.all([promise, delayPromise]))
    timerId && clearTimeout(timerId)
    return promise
}
