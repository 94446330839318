import React, { useMemo } from "react"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { nameof } from "../../../utility/common/nameof"
import { SlotType, SlotValues } from "../../../models/slot"
import { formTranslation } from "../../../locales/form"
import CheckBoxValidatableInput from "../../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { Form } from "react-bootstrap"
import { FormikSlotFormProps, tSlotFormNamespace } from "../SlotForm/SlotForm"
import CalenderSettings from "../controls/CalenderSettings"
import LoadingButton from "../../LoadingButton/LoadingButton"
import { checkIsRegex } from "../../../utility/knowledgeBase/slotForm"
import IdField from "../../IdField/IdField"
import InfoIcon from "../../InfoIcon/InfoIcon"
import DocLink from "../../DocLink/DocLink"

const tNamespace = "slot:form."
const tTooltipNamespace = "slot:tooltip."

const SlotFormGeneral: React.FC<FormikSlotFormProps> = props => {
    const { asyncState, values, handleSubmit, t, slot } = props

    const slotTypes = useMemo(
        () =>
            Object.values(SlotType).map((type: string) => ({
                type,
                title: t(`slot:slot-data-types.${type.toLowerCase()}`)
            })),
        [t]
    )

    const externalIdDisabled = !!slot

    return (
        <Form className="slot-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="slot-form__content">
                {externalIdDisabled && <IdField id={values.ExternalId} className="slot-form__section" />}
                <div className="slot-form__section">
                    <DocLink type="slots">{t(`${tNamespace}doc-link`)}</DocLink>
                </div>
                <div className="slot-form__section">
                    {!externalIdDisabled && (
                        <DebouncedValidatableInput
                            id="formExternalId"
                            type="text"
                            name={nameof<SlotValues>("ExternalId")}
                            label={t(`${tSlotFormNamespace}id`)}
                        />
                    )}
                    <DebouncedValidatableInput
                        id="formTitle"
                        type="text"
                        name={nameof<SlotValues>("Title")}
                        label={t(formTranslation.title)}
                    />
                    <DebouncedValidatableInput
                        id="formSlotValue"
                        as="select"
                        name={nameof<SlotValues>("Type")}
                        label={t(`${tSlotFormNamespace}slot-type`)}
                    >
                        <option value="" hidden />
                        {slotTypes.map(t => (
                            <option value={t.type} key={t.type}>
                                {t.title}
                            </option>
                        ))}
                    </DebouncedValidatableInput>
                </div>
                <div className="slot-form__section">
                    <CheckBoxValidatableInput
                        id="formPermanent"
                        name={nameof<SlotValues>("Permanent")}
                        label={t(`${tSlotFormNamespace}save-to-user-profile`)}
                        icon={<InfoIcon id="tooltipPermanent" content={t(`${tTooltipNamespace}permanent`)} />}
                    />
                </div>
                <div className="slot-form__section">
                    <DebouncedValidatableInput
                        id="formQuestion"
                        as="textarea"
                        name={nameof<SlotValues>("Question")}
                        label={t(`${tSlotFormNamespace}question-to-clarify-the-slot`)}
                        className="slot-form__textarea"
                    />
                    <DebouncedValidatableInput
                        id="formFailedValidationQuestion"
                        as="textarea"
                        name={nameof<SlotValues>("FailedValidationQuestion")}
                        label={t(`${tSlotFormNamespace}question-to-re-clarify-the-slot`)}
                        className="slot-form__textarea"
                    />
                </div>
                {values.Type === SlotType.Calendar && <CalenderSettings t={t} />}
                {checkIsRegex(values.Type) && (
                    <DebouncedValidatableInput
                        id="formRegex"
                        as="textarea"
                        name={nameof<SlotValues>("Regex")}
                        label={t(`${tSlotFormNamespace}regular-expression`)}
                    />
                )}
            </div>
            <div className="slot-form__footer">
                <LoadingButton type="submit" variant="primary" block loading={asyncState.inProcess}>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default SlotFormGeneral
