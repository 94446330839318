import React from "react"
import Tree, { RenderItemParams, TreeData, TreeDestinationPosition, TreeSourcePosition } from "@atlaskit/tree"
import type { ItemId, TreeItem } from "@atlaskit/tree/types"
import { Category, OnTitleClick } from "../Category/Category"
import "./Catalog.scss"
import { KnowledgeBasePermittedAction } from "../../models/knowledgeBasePermission"
import type { IconProp } from "@fortawesome/fontawesome-svg-core"

export interface CatalogProps {
    tree: TreeData
    onTitleClick: OnTitleClick
    onCanEdit: (permittedAction?: KnowledgeBasePermittedAction) => boolean
    extensions?: JSX.Element[]
    onGetItemExtensions?: (item: TreeItem) => JSX.Element[]
    editingId?: string
    onDragStart?: (itemId: ItemId) => void
    onDragEnd?: (sourcePosition: TreeSourcePosition, destinationPosition?: TreeDestinationPosition) => void
    isDragEnabled?: boolean | ((item: TreeItem) => boolean)
    onExpandCategory?: (itemId: ItemId, onExpand: (itemId: ItemId) => void) => void
    onToggle: (itemId: ItemId, isExpanded: boolean) => void
    onRootItemTitleClick?: OnTitleClick
    categoryIcon?: IconProp | ((item: TreeItem) => IconProp)
    isNestingEnabled?: boolean
}

const Catalog: React.FC<CatalogProps> = props => {
    const {
        tree,
        extensions,
        onTitleClick,
        onCanEdit,
        onDragEnd,
        onDragStart,
        isDragEnabled,
        onExpandCategory,
        onGetItemExtensions,
        onToggle,
        onRootItemTitleClick,
        categoryIcon,
        editingId,
        isNestingEnabled = true
    } = props

    const rootItem = tree.items[tree.rootId]

    const handleCollapse = (itemId: ItemId) => {
        onToggle(itemId, false)
    }

    const handleExpand = (itemId: ItemId) => {
        onToggle(itemId, true)
    }

    const handleExpandCategory = (itemId: ItemId) => {
        if (onExpandCategory) {
            onExpandCategory(itemId, handleExpand)
        } else {
            handleExpand(itemId)
        }
    }

    const renderItem = (params: RenderItemParams) => {
        const { provided, snapshot, item, ...rest } = params

        return (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <Category
                    {...rest}
                    item={item}
                    onTitleClick={onTitleClick}
                    onCanEdit={onCanEdit}
                    extensions={onGetItemExtensions?.(item)}
                    icon={categoryIcon}
                    selected={editingId === item.id}
                    showExtensions={editingId === item.id}
                />
            </div>
        )
    }

    return (
        <div className="catalog">
            <Category
                item={rootItem}
                onTitleClick={onRootItemTitleClick}
                onExpand={handleExpand}
                onCanEdit={onCanEdit}
                onCollapse={handleCollapse}
                titleClassName="catalog__title"
                selected={editingId === rootItem.id}
                showExtensions={editingId === rootItem.id}
                extensions={extensions}
                isRoot
            />
            {rootItem.isExpanded && (
                <Tree
                    tree={tree}
                    renderItem={renderItem}
                    onExpand={handleExpandCategory}
                    onCollapse={handleCollapse}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    offsetPerLevel={20}
                    isDragEnabled={isDragEnabled}
                    isNestingEnabled={isNestingEnabled}
                />
            )}
        </div>
    )
}

export default Catalog
