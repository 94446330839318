import React from "react"
import "./ConditionNode.scss"
import { ClassProps } from "../../../../../utility/common/props"
import { ConditionStatement } from "../../../../../models/scenarioCondition"
import ButtonItem from "../../Button/ButtonItem"
import ConditionNodeLines from "../ConditionNodeLines/ConditionNodeLines"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"

interface Props extends ClassProps {
    id: string
    conditions: ConditionStatement[]
    onAddBlock: (sourceHandle: string) => void
    onRemove: (conditionId: string) => void
    isConnectable: boolean
    selected: boolean
    onSelect: () => void
    dragHandleProps?: DraggableProvidedDragHandleProps
}

const ConditionNode: React.FC<Props> = props => {
    const { className, conditions, onSelect, ...itemProps } = props

    return (
        <div className="condition-node">
            <ButtonItem className={className} onClick={onSelect} {...itemProps}>
                <ConditionNodeLines conditions={conditions} />
            </ButtonItem>
        </div>
    )
}

export default ConditionNode
