import { logError } from "../utility/common/logError"
import reportWebVitals from "./reportWebVitals"
import metricsController from "../api/controllers/metrics"
import { getPerformanceContext } from "@aibotdev/ct-analytics"

function initGlobalPerformanceMeasuring() {
    try {
        reportWebVitals(metricsController.sendSystemMetrics)
        const observer = getPerformanceObserver("Global")
        observer.observe({ type: "measure", buffered: true })
    } catch (e) {
        logError("Failed to initialize performance metrics", e)
    }
}

export const { getPerformanceObserver } = getPerformanceContext(metricsController.sendSystemMetrics)

export function logPerformanceObserverInitializingError(name: string, e: unknown) {
    logError(`Failed to initialize ${name} PerformanceObserver`, e)
}

export default initGlobalPerformanceMeasuring
