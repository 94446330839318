import { LocalizedText } from "../types/localizedText"
import { SelectedOperatorStatusDto } from "./operatorStatusDto"

export interface SelectedOperatorStatus {
    Value: OperatorStatusValue
    Description: string
    Title: LocalizedText
    UpdatedAt: number
}

export enum OperatorStatusValue {
    Online,
    Offline,
    DoNotRoute
}

export const operatorStatusChangeReasons = {
    userClick: "user_click"
}
export type OperatorStatusChange = {
    Value: OperatorStatusValue
    Description: string
}
export type OperatorStatusChangeWithReason = {
    Reason: string
} & OperatorStatusChange
export type OperatorStatus = {
    Value: OperatorStatusValue
    Description: string
    Title: LocalizedText
}

export interface GetOperatorStatusesResponse {
    CurrentStatus: SelectedOperatorStatusDto
    Statuses: OperatorStatus[]
}
