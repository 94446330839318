import {
    Agent,
    AgentType,
    AIAssist2AgentParamValues,
    AIAssist2AgentValues,
    ButtonTitleSource
} from "../../models/agent"
import { extractParams } from "./agentTranslator"
import { ClassifierConfigValue } from "../../models/classifier"
import { parseField } from "../common/parametersTranslator"
import { tl } from "../common/language"

export const defaultButtonClassifierThreshold = 0.8

export const getValuesFromAIAssist2Agent = (agent: Agent, language: string): AIAssist2AgentValues => {
    const { stop_sf_intent, fail_intent, file_input_intent, ...params } = extractParams(
        AgentType.AIAssist2,
        agent.Params
    )

    const max_intent_repetition_enabled =
        Number.isInteger(params.max_intent_repetition) &&
        params.max_intent_repetition >= 0 &&
        params.max_intent_repetition <= 10

    const classifiersConf: ClassifierConfigValue[] = parseField(params["classifiers_conf"], [])
    classifiersConf.forEach(c => {
        const threshold = c.BotClassificationResultThreshold || 0
        c.BotClassificationResultThreshold = Math.floor(threshold * 100)
        c.Name = c.Name || ""
        c.ClassifierWeight = c.ClassifierWeight || 0
    })

    return {
        Id: agent.Id,
        IntentSettings: {
            stop_sf_intent: stop_sf_intent ?? "",
            fail_intent: fail_intent ?? "",
            file_input_intent: file_input_intent ?? ""
        },
        ClassifiersConf: classifiersConf,
        Params: {
            ...getDefaultAIAssist2AgentParams(language),
            ...params,
            max_intent_repetition: max_intent_repetition_enabled ? params.max_intent_repetition : 5,
            max_intent_repetition_enabled,
            button_classifier_threshold: Math.floor(params.button_classifier_threshold * 100)
        }
    }
}

export const defaultAIAssistAgentParams = {
    max_intent_repetition: 999,
    max_intent_repetition_enabled: false,
    max_intent_repetition_intent: "",
    cancel_button_title: "",
    enable_two_step_intent_clarification: false,
    two_step_intent_clarification_intent: "",
    intent_clarification_button_title_source: ButtonTitleSource.Parameter,
    intent_clarification_intent_parameter_id: "clarification_title",
    clarify_intent: true,
    clarify_intent_in_reply: true,
    max_clarification_intents: 3,
    knowledge_base_mode: true,
    knowledge_base_mode_max_subsections: 4,
    knowledge_base_mode_use_back_button: true,
    dictionary_slot_max_buttons: 5,
    dictionary_slot_shortlist_buttons: 4,
    use_user_input_slot_during_slot_filling: true
}

const getDefaultAIAssist2AgentParams = (language: string): AIAssist2AgentParamValues => ({
    ...defaultAIAssistAgentParams,
    button_classifier_threshold: defaultButtonClassifierThreshold * 100,
    message_text_for_intent_clarification: tl(`agent:default-value.message-text-for-intent-clarification`, language),
    intent_clarification_intent_types_to_exclude: []
})

export const getDefaultAIAssist2AgentValues = (language: string): AIAssist2AgentValues => ({
    Id: "",
    IntentSettings: {
        stop_sf_intent: "",
        fail_intent: "",
        file_input_intent: ""
    },
    ClassifiersConf: [],
    Params: getDefaultAIAssist2AgentParams(language)
})
