import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import accountController from "../../api/controllers/account"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import { ForgotPasswordRequest, ResetPasswordRequest } from "../../models/password"

export const forgotPassword =
    (request: ForgotPasswordRequest): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.forgotPasswordProcess())
        try {
            await accountController.forgotPassword(request)
            dispatch(actions.forgotPasswordSuccess(request.Email))
        } catch (e) {
            handleHttpException(
                e,
                constants.FORGOT_PASSWORD_FAILED_MESSAGE,
                err => actions.forgotPasswordFailed(err),
                dispatch
            )
        }
    }

export const verifyResetPasswordCode =
    (code: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(actions.verifyResetPasswordCodeProcess())
            const response = await accountController.verifyResetPasswordCode(code)
            dispatch(actions.verifyResetPasswordCodeSuccess(response))
        } catch (e) {
            handleHttpException(
                e,
                constants.VERIFY_RESET_PASSWORD_CODE_FAILED_MESSAGE,
                err => actions.verifyResetPasswordCodeFailed(err),
                dispatch
            )
        }
    }

export const resetPassword =
    (request: ResetPasswordRequest): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.resetPasswordProcess())
        try {
            await accountController.resetPassword(request)
            dispatch(actions.resetPasswordSuccess())
        } catch (e) {
            handleHttpException(
                e,
                constants.RESET_PASSWORD_FAILED_MESSAGE,
                err => actions.resetPasswordFailed(err),
                dispatch
            )
        }
    }
