import { TMessageDatagram, TMessageDatagramPayloadUni } from "../../jsagent-bridge"
import { ServiceWorkerCommunicate } from "./ServiceWorkerCommunicate"

export class JsAgentSWBridge<T extends unknown> extends ServiceWorkerCommunicate<T> {
    _customerId?: string
    _agentId?: string
    _nodeEnv?: string

    constructor(customerId: string, agentId: string, nodeEnv?: string) {
        super()

        this._customerId = customerId
        this._agentId = agentId
        this._nodeEnv = nodeEnv
    }

    postMessageToSW<S extends TMessageDatagramPayloadUni, K extends unknown>(message: TMessageDatagram<S>): Promise<K> {
        return super.postMessageToSW<S, K>(message, {
            calleMode: this._nodeEnv ?? process.env.NODE_ENV,
            customerId: this._customerId,
            agentId: this._agentId
        })
    }

    setCustomerId(customerId: string) {
        this._customerId = customerId
    }

    setAgentId(agentId: string) {
        this._agentId = agentId
    }
}
