import React from "react"
import styles from "./TypeFormCard.module.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"

export interface TypeFormCardProps extends ClassProps {
    icon: JSX.Element
    onSelect: () => void
    title: string
    description?: string
    iconClassName?: string
    mini?: boolean
    testId?: string
}

const TypeFormCard: React.FC<TypeFormCardProps> = props => {
    const { icon, title, description, onSelect, className, iconClassName, mini, testId } = props

    return (
        <div
            className={cn(styles.typeFormCard, mini && styles.typeFormCard_mini, className)}
            onClick={onSelect}
            data-test-id={testId}
        >
            <div className={cn(styles.typeFormCard__icon, iconClassName)}>{icon}</div>
            <div className={styles.typeFormCard__text}>
                <span className={styles.typeFormCard__title}>{title}</span>
                {description && <span className={styles.typeFormCard__desc}>{description}</span>}
            </div>
        </div>
    )
}

export default TypeFormCard
