import React, { ReactNode } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown"
import SidebarContextMenu from "../SidebarContextMenu/SidebarContextMenu"
import { ClassProps } from "../../utility/common/props"
import "./SidebarContextMenuSelect.scss"

interface Props extends ClassProps {
    items: ReactNode
    selected: ReactNode | string
    containerClassName?: string
}

const SidebarContextMenuSelect: React.FC<Props> = props => {
    const { items, selected } = props

    return (
        <SidebarContextMenu
            placement="bottom-start"
            containerClassName="sidebar-context-menu-select"
            className="sidebar-context-menu-select__menu"
            items={items}
        >
            <div className="sidebar-context-menu-select__toggle">
                <div className="sidebar-context-menu-select__selected-item">{selected}</div>
                <div className="sidebar-context-menu-select__icon">
                    <FontAwesomeIcon icon={faAngleDown} className="sidebar-context-menu-select__icon-toggle" />
                </div>
            </div>
        </SidebarContextMenu>
    )
}

export default SidebarContextMenuSelect
