import React, { memo, useContext, useEffect, useRef, useState } from "react"
import { EdgeProps, getBezierPath, getEdgeCenter, getMarkerEnd, isEdge, Position, Rect } from "react-flow-renderer"
import trashSvg from "../../assets/images/trash.svg"
import { ScenarioContext } from "../ScenarioEditor/ScenarioContext"

const TARGET_X_INDENT = 20

interface EdgeIconProps {
    x: number
    y: number
    onClick: () => void
}

const TrashIcon: React.FC<EdgeIconProps> = props => {
    const { x, y, onClick } = props

    const edgeRef = useRef<SVGImageElement>(null)
    const [edgeTextBbox, setEdgeTextBbox] = useState<Rect>({
        x: 0,
        y: 0,
        width: 0,
        height: 0
    })

    useEffect(() => {
        if (edgeRef.current) {
            const textBbox = edgeRef.current.getBBox()

            setEdgeTextBbox({
                x: textBbox.x,
                y: textBbox.y,
                width: textBbox.width,
                height: textBbox.height
            })
        }
    }, [])

    return (
        <g
            style={{ zIndex: 100 }}
            transform={`translate(${x - edgeTextBbox.width / 2} ${y - edgeTextBbox.height / 2})`}
            className="react-flow__edge-icon-wrapper"
            onClick={onClick}
        >
            <image y={edgeTextBbox.height} ref={edgeRef} href={trashSvg} className="react-flow__edge-icon" />
        </g>
    )
}

const TrashIconMemo = memo(TrashIcon)

interface GetBezierPathParams {
    sourceX: number
    sourceY: number
    targetX: number
    targetY: number
    centerX?: number
    centerY?: number
    selfEdge?: boolean
}

function getControlsPoints({ sourceX, sourceY, targetX, targetY, selfEdge }: GetBezierPathParams) {
    const dist = Math.sqrt((targetX - sourceX) ** 2 + (targetY - sourceY) ** 2)

    return {
        x1: sourceX + dist * (selfEdge ? 0.9 : 0.7),
        y1: sourceY - dist * (selfEdge ? 0.9 : 0),
        x2: targetX - dist * (selfEdge ? 0.7 : 0.5),
        y2: targetY - dist * (selfEdge ? 0.9 : 0)
    }
}

function getCustomBezierPath(props: GetBezierPathParams) {
    const { sourceX, sourceY, targetX, targetY } = props
    const { x1, x2, y1, y2 } = getControlsPoints(props)

    return `M${sourceX},${sourceY} C${x1},${y1} ${x2},${y2} ${targetX},${targetY}`
}

function getCustomEdgeCenter(props: GetBezierPathParams) {
    const { sourceX, sourceY, targetX, targetY } = props

    const { x1, x2, y1, y2 } = getControlsPoints(props)

    // B(1/2) = 1/8 P0 + 3/8 P1 + 3/8 P2 + 1/8 P3
    const centerX = (sourceX + 3 * x1 + 3 * x2 + targetX) / 8
    const centerY = (sourceY + 3 * y1 + 3 * y2 + targetY) / 8

    return [centerX, centerY]
}

const CustomEdge: React.FC<EdgeProps> = props => {
    const {
        id,
        source,
        target,
        sourceX,
        sourceY,
        targetX,
        targetY,
        style = {},
        arrowHeadType,
        markerEndId,
        sourcePosition,
        targetPosition
    } = props

    const pathRef = useRef<SVGPathElement | null>(null)
    const { setElements } = useContext(ScenarioContext)

    let edgePath, center
    if (targetPosition === Position.Top) {
        edgePath = getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY: targetY + TARGET_X_INDENT,
            targetPosition
        })
        center = getEdgeCenter(props)
    } else {
        edgePath = getCustomBezierPath({
            sourceX,
            sourceY,
            targetX: targetX + TARGET_X_INDENT,
            targetY,
            selfEdge: source === target
        })
        center = getCustomEdgeCenter(props)
    }

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)

    const handleRemoveEdge = () => {
        setElements(els => els.filter(e => !(e.id === id || (isEdge(e) && (e.source === id || e.target === id)))))
    }

    return (
        <>
            <path
                ref={pathRef}
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <path id={`${id}-hidden`} className="react-flow__edge-path react-flow__edge-path_hidden" d={edgePath} />
            <TrashIconMemo x={center[0]} y={center[1]} onClick={handleRemoveEdge} />
        </>
    )
}

export default CustomEdge
