import React, { useCallback, useEffect, useState } from "react"
import "./CheckBoxValidatableInput.scss"
import { Form } from "react-bootstrap"
import { useField } from "formik"
import cn from "classnames"
import debounce from "lodash/debounce"
import { CheckBoxValidatableInputProps } from "./CheckBoxValidatableInput"

interface DebouncedCheckBoxProps extends Omit<CheckBoxValidatableInputProps, "onChange"> {
    icon?: JSX.Element
    onChange?: (v: boolean) => void
    formikUntouched?: boolean
}

const DebouncedCheckBox: React.FC<DebouncedCheckBoxProps> = props => {
    const { id, className, name, label, icon, onChange, formikUntouched, checked, ...inputProps } = props

    const [field, meta] = useField<boolean>(name)
    const [innerValue, setInnerValue] = useState(field.value)

    const formLabel = (label || icon) && (
        <span className="checkbox-input__label">
            {label}
            {icon}
        </span>
    )

    useEffect(() => {
        if (formikUntouched) {
            return setInnerValue(Boolean(checked))
        }
        field.value ? setInnerValue(field.value) : setInnerValue(false)
    }, [field.value, formikUntouched, checked])

    const debounced = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(event)
    }, 200)

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist()
            setInnerValue(event.currentTarget.checked)
            onChange?.(event.currentTarget.checked)
            debounced(event)
        },
        [debounced, onChange]
    )

    return (
        <Form.Check
            {...inputProps}
            custom
            type="checkbox"
            id={`${id}-input`}
            label={formLabel}
            name={field.name}
            onChange={handleOnChange}
            checked={innerValue ?? false}
            isInvalid={!!meta.error}
            className={cn("checkbox-input", className)}
        />
    )
}

export default DebouncedCheckBox
