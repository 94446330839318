import React from "react"
import "./GlobalUsers.scss"
import { useTranslation } from "react-i18next"
import { GlobalUser } from "../../models/globalUser"
import { CellProps, Column } from "react-table"
import SettingsButton from "../SettingsButton/SettingsButton"
import UserCard from "../UserCard/UserCard"
import { formTranslation } from "../../locales/form"
import { faPen } from "@fortawesome/pro-light-svg-icons/faPen"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import SettingsTableWithoutPagination from "../SettingsTable/SettingsTableWithoutPagination/SettingsTableWithoutPagination"
import { GlobalUserSurvey } from "../../models/globalUserSurvey"

interface Props {
    users: GlobalUser[]
    onSettingsClick: (user: GlobalUser) => void
    globalUserSurvey: GlobalUserSurvey
}

const GlobalUsers: React.FC<Props> = props => {
    const { users, onSettingsClick } = props
    const { t } = useTranslation()

    const getItems = (role: GlobalUser) => {
        return (
            <SettingsContextMenuItem
                id="global-users-edit"
                icon={faPen}
                text={t(formTranslation.edit)}
                onClick={() => onSettingsClick(role)}
            />
        )
    }

    const columns: Array<Column<GlobalUser>> = [
        {
            Header: t(formTranslation.user).toString(),
            id: "User",
            accessor: original => original.FirstName + " " + original.LastName,
            Cell: ({ row: { original } }: CellProps<GlobalUser>) => (
                <UserCard firstName={original.FirstName} lastName={original.LastName} picture={original.Picture} />
            )
        },
        {
            Header: t(formTranslation.login).toString(),
            accessor: "Login"
        },
        {
            id: "Settings",
            disableSortBy: true,
            Cell: (props: CellProps<GlobalUser>) => (
                <SettingsContextMenu items={getItems(props.row.original)}>
                    <SettingsButton />
                </SettingsContextMenu>
            )
        }
    ]

    return (
        <div className="project-users">
            <SettingsTableWithoutPagination columns={columns} data={users} onRowClick={onSettingsClick} />
        </div>
    )
}

export default GlobalUsers
