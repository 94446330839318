export const tNamespace = "error:"

export const errorTranslation = {
    default: `${tNamespace}default-error`,
    title: `${tNamespace}title`,
    config: `${tNamespace}load-config-failed`,
    serverError: `${tNamespace}server-error`,
    unknownError: `${tNamespace}unknown-error`,
    emptyMenuId: `${tNamespace}empty-menu-id`,
    webchatDeclarationsNotExist: `${tNamespace}webchat-declarations-not-exist`,
    agentDeclarationsNotExist: `${tNamespace}agent-declarations-not-exist`,
    userNotFound: `${tNamespace}user-not-found`,
    projectNotFound: `${tNamespace}project-not-found`,
    accessDenied: `${tNamespace}access-denied`,
    accessDeniedFeature: `${tNamespace}access-denied-feature`,
    jsonInvalid: `${tNamespace}json-invalid`,
    nonEmptyCatalog: `${tNamespace}non-empty-catalog`,
    noCatalog: `${tNamespace}no-catalog`,
    noParentCategory: `${tNamespace}no-parent-category`,
    emptyPermissions: `${tNamespace}empty-permissions`,
    onlyViewPermission: `${tNamespace}only-view-permission`,
    articleNotFound: `${tNamespace}knowledgeBase.article-not-found`
}
