export interface IntersectionObserverEntry {
    readonly isIntersecting: boolean
    readonly target: Element
}

export interface IntersectionObserverInit {
    root?: Element | Document | null
    rootMargin?: string
    threshold?: number | number[]
}

export interface IntersectionObserverCallback {
    (entries: IntersectionObserverEntry[]): void
}

export class IntersectionObserver {
    _observableElements: Element[] = []

    _observerOpts: IntersectionObserverInit = {}

    _observerCallback: IntersectionObserverCallback

    constructor(callback: IntersectionObserverCallback, options?: IntersectionObserverInit) {
        if (options) {
            this._observerOpts = options
        }

        this._observerCallback = callback

        if (options?.root) {
            options.root.addEventListener("scroll", this._handleScroll.bind(this), false)
        } else {
            window.addEventListener("scroll", this._handleScroll.bind(this), false)
        }
    }

    _isElementInViewport(element: Element) {
        const rect = element.getBoundingClientRect()
        const { rootMargin } = this._observerOpts

        const rootMarginMatch = rootMargin ? rootMargin.match(/(.*)px\s+(.*)px\s+(.*)px\s+(.*)px/i) : null
        const [offsetTop, , offsetBottom] = rootMarginMatch
            ? [...rootMarginMatch].slice(1).map(x => parseInt(x))
            : [0, 0, 0, 0]

        return rect.top >= Math.abs(offsetTop) && rect.bottom <= this._getClientHeight() - Math.abs(offsetBottom)
    }

    _getClientHeight() {
        return window.innerHeight || document.documentElement.clientHeight
    }

    _getClientWidth() {
        return window.innerWidth || document.documentElement.clientWidth
    }

    _handleScroll(): void {
        const observableElements = this._observableElements
        const intersectionEntries: IntersectionObserverEntry[] = []

        for (const element of observableElements) {
            intersectionEntries.push({
                target: element,
                isIntersecting: this._isElementInViewport(element)
            })
        }

        this._observerCallback(intersectionEntries)
    }

    public observe(target: Element): void {
        this._observableElements.push(target)
    }

    public disconnect(): void {
        this._observableElements = []
    }
}
