import { apiInstance } from "../instances"
import {
    ClassifierConfigDto,
    CreateClassifierRequest,
    RollbackClassifierRequest,
    StartClassifierRequest,
    UpdateClassifierRequest
} from "../../models/classifier"
import { ClassifierModelDto } from "../../models/classifierEvent"

const classifiersController = {
    getClassifiers: (projectId: string): Promise<ClassifierConfigDto[]> =>
        apiInstance.get(`${projectId}/classifiers/shared`).then(response => response.data),
    createClassifier: (projectId: string, request: CreateClassifierRequest): Promise<ClassifierConfigDto[]> =>
        apiInstance.post(`${projectId}/classifiers`, request).then(response => response.data),
    updateClassifier: (
        projectId: string,
        configId: string,
        request: UpdateClassifierRequest
    ): Promise<ClassifierConfigDto> =>
        apiInstance.put(`${projectId}/classifiers/${configId}`, request).then(response => response.data),
    deleteClassifier: (projectId: string, configId: string): Promise<void> =>
        apiInstance.delete(`${projectId}/classifiers/${configId}`).then(response => response.data),
    activateClassifier: (projectId: string, configId: string): Promise<ClassifierConfigDto> =>
        apiInstance.post(`${projectId}/classifiers/${configId}/activate`).then(response => response.data),
    deactivateClassifier: (projectId: string, configId: string): Promise<ClassifierConfigDto> =>
        apiInstance.post(`${projectId}/classifiers/${configId}/deactivate`).then(response => response.data),
    getServers: (projectId: string): Promise<string[]> =>
        apiInstance.get(`${projectId}/classifiers/servers`).then(response => response.data),
    startTraining: (
        projectId: string,
        configId: string,
        request: StartClassifierRequest
    ): Promise<ClassifierConfigDto> =>
        apiInstance.post(`${projectId}/classifiers/${configId}/start`, request).then(response => response.data),
    stopTraining: (projectId: string, configId: string): Promise<ClassifierConfigDto> =>
        apiInstance.post(`${projectId}/classifiers/${configId}/stop`).then(response => response.data),
    rollbackClassifier: (
        projectId: string,
        configId: string,
        request: RollbackClassifierRequest
    ): Promise<ClassifierConfigDto> =>
        apiInstance.post(`${projectId}/classifiers/${configId}/rollback`, request).then(response => response.data),
    getClassifierModels: (projectId: string, configId: string): Promise<ClassifierModelDto[]> =>
        apiInstance.get(`${projectId}/classifiers/${configId}/classifiers`).then(response => response.data)
}

export default classifiersController
