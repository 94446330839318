import React from "react"
import "./AgentNodeOutputs.scss"
import { Handle, Position } from "react-flow-renderer"
import { AgentType } from "../../../../models/agent"
import { TFunction, useTranslation } from "react-i18next"
import TooltipTrigger from "../../../TooltipTrigger/TooltipTrigger"
import cn from "classnames"

const tNamespace = "scenarioEditor:agent-outputs:"

interface AgentNodeOutput {
    id: string
    title: string
}

const getNodeOutputs = (type: string, t: TFunction): AgentNodeOutput[] => {
    const addPrefix = (base: string) => `output_${base}`

    switch (type) {
        case AgentType.InitialMsgAgent:
            return [
                {
                    id: addPrefix("text"),
                    title: t(`${tNamespace}user-entered-the-chat`)
                },
                {
                    id: addPrefix("default_agent"),
                    title: t(`${tNamespace}another-message-received`)
                }
            ]
        case AgentType.AIAssist2:
            return [
                {
                    id: addPrefix("fail_intent"),
                    title: t(`${tNamespace}answer-not-found`)
                },
                {
                    id: addPrefix("stop_sf_intent"),
                    title: t(`${tNamespace}refusal-to-answer-question`)
                },
                {
                    id: addPrefix("file_input_intent"),
                    title: t(`${tNamespace}file-or-image-received`)
                }
            ]
        case AgentType.NightAgent:
            return [
                {
                    id: addPrefix("default_agent"),
                    title: t(`${tNamespace}working-hours`)
                },
                {
                    id: addPrefix("message"),
                    title: t(`${tNamespace}non-working-hours`)
                }
            ]
        case AgentType.ABAgent:
            return [
                {
                    id: addPrefix("message_a"),
                    title: `${t(`${tNamespace}output`)} А`
                },
                {
                    id: addPrefix("message_b"),
                    title: `${t(`${tNamespace}output`)} B`
                }
            ]
        case AgentType.RestAgent:
            return [
                {
                    id: addPrefix("success-message"),
                    title: t(`${tNamespace}successful-execution`)
                },
                {
                    id: addPrefix("failure-message"),
                    title: t(`${tNamespace}call-error`)
                }
            ]
        case AgentType.EmailAgent:
            return [
                {
                    id: addPrefix("success-message"),
                    title: t(`${tNamespace}email-sent-successfully`)
                },
                {
                    id: addPrefix("failure-message"),
                    title: t(`${tNamespace}error-sending`)
                }
            ]
        default:
            return []
    }
}

interface Props {
    blockId: string
    agentType: AgentType
    disabled?: boolean
    isConnectable: boolean
    onAddBlock: (source: string, sourceHandle: string) => void
}

const AgentNodeOutputs: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { blockId, agentType, disabled, isConnectable, onAddBlock } = props

    return (
        <div className="agent-node-outputs">
            {getNodeOutputs(agentType, t).map(output => {
                const id = `${blockId}_${output.id}`
                return (
                    <TooltipTrigger
                        key={id}
                        id={`tooltip-${output.id}`}
                        placement="bottom"
                        content={t("scenarioEditor:tooltip:agent-blocked")}
                        show={disabled ? undefined : false}
                    >
                        <div
                            className={cn(
                                "agent-node-outputs__output",
                                disabled && "agent-node-outputs__output_disabled"
                            )}
                        >
                            {output.title}
                            <Handle
                                id={id}
                                type="source"
                                position={Position.Right}
                                className="agent-node-outputs__output-handle"
                                onClick={() => onAddBlock(blockId, id)}
                                isConnectable={!disabled && isConnectable}
                            />
                        </div>
                    </TooltipTrigger>
                )
            })}
        </div>
    )
}

export default AgentNodeOutputs
