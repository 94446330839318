import React from "react"
import styles from "./CodeBlockValidatableInput.module.scss"
import { useField } from "formik"
import cn from "classnames"
import { Form } from "react-bootstrap"
import CodeBlockInput from "../CodeBlockInput/CodeBlockInput"

export interface CodeBlockValidatableInputProps {
    id: string
    name: string
    label: string
    disabled?: boolean
}

const EDITOR_PADDING = 20

const CodeBlockValidatableInput: React.FC<CodeBlockValidatableInputProps> = props => {
    const [field, meta, helpers] = useField<string>(props)
    const { id, label, disabled } = props

    return (
        <Form.Group className={styles.codeBlockValidatableInput} controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}
            <CodeBlockInput
                value={field.value ?? ""}
                initial={false}
                isEditable={true}
                onChange={code => helpers.setValue(code)}
                className={cn(
                    styles.codeBlockValidatableInput__control,
                    meta.touched && meta.error && styles.codeBlockValidatableInput__control_invalid,
                    disabled && styles.codeBlockValidatableInput__control_disabled
                )}
                padding={EDITOR_PADDING}
                disabled={disabled}
            />
        </Form.Group>
    )
}

export default CodeBlockValidatableInput
