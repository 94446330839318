import React from "react"
import styles from "./UserCard.module.scss"
import loaderStyles from "./UserCardLoader.module.scss"
import Skeleton from "../Skeleton/Skeleton"
import cn from "classnames"

const UserCardLoader: React.FC = () => {
    return (
        <div className={cn(styles.userCard, loaderStyles.userCardLoader)}>
            <div className={styles.userCard__pictureContainer}>
                <div className={cn(styles.userCard__picture)}>
                    <Skeleton />
                </div>
            </div>
            <div className={cn(styles.userCard__info, loaderStyles.userCardLoader__info)}>
                <div className={cn(styles.userCard__introduction, loaderStyles.userCardLoader__introduction)}>
                    <Skeleton />
                </div>
                <div className={loaderStyles.userCardLoader__meta}>
                    <Skeleton />
                </div>
            </div>
        </div>
    )
}

export default UserCardLoader
