import React, { useEffect, useState } from "react"
import KnowledgeBaseUserAccessItem from "../KnowledgeBaseUserAccessItem/KnowledgeBaseUserAccessItem"
import type { SimpleUser } from "../../models/user"
import type { SimpleRole } from "../../models/role"
import {
    getUsersAndRolesByKnowledgeBasePermissions,
    rolesToMap,
    usersToMap
} from "../../utility/knowledgeBase/permissionsUsersAndRoles"
import "./KnowledgeBaseUserAccessItems.scss"
import type { KnowledgeBaseSubscriptionSelectItem } from "../../models/knowledgeBaseSubscription"
import type { OnChangeAccessItemFunction, UserAccessPrimitive } from "../../models/knowledgeBaseUserAccess"
import type { TFunction } from "react-i18next"
import { KnowledgeBasePermittedActionSelectItem } from "../../models/knowledgeBasePermission"

interface Props {
    t: TFunction
    data: UserAccessPrimitive[]
    subscriptionOptions?: KnowledgeBaseSubscriptionSelectItem[]
    permittedActionOptions?: KnowledgeBasePermittedActionSelectItem[]
    projectId?: string
    onChange: OnChangeAccessItemFunction
    onRemove: OnChangeAccessItemFunction
}

const KnowledgeBaseUserAccessItems: React.FC<Props> = props => {
    const { t, data, subscriptionOptions, permittedActionOptions, projectId, onChange, onRemove } = props

    const [userMap, setUsersMap] = useState<{ [login: string]: SimpleUser }>({})
    const [roleMap, setRolesMap] = useState<{ [id: string]: SimpleRole }>({})

    useEffect(() => {
        const loadData = async () => {
            if (projectId && data?.length > 0) {
                const { Users, Roles } = await getUsersAndRolesByKnowledgeBasePermissions(data, projectId)
                setUsersMap(usersToMap(Users))
                setRolesMap(rolesToMap(Roles))
            }
        }
        loadData()
    }, [data, projectId])

    return (
        <div className="knowledge-base-permissions">
            {data.map((item, index) => (
                <KnowledgeBaseUserAccessItem
                    key={index}
                    t={t}
                    className="knowledge-base-permissions__item"
                    data={item}
                    subscriptionOptions={subscriptionOptions}
                    permittedActionOptions={permittedActionOptions}
                    role={roleMap[item.Value]}
                    user={userMap[item.Value]}
                    onChange={onChange}
                    onRemove={onRemove}
                />
            ))}
        </div>
    )
}

export default KnowledgeBaseUserAccessItems
