import { OperatorDto } from "../../models/operatorDto"
import { Operator } from "../../models/operator"
import { OperatorStatus } from "../../models/operatorStatus"
import { toSelectedOperatorStatusModel } from "../operatorStatus/convert"

export const OperatorDtoConverter = {
    toOperator(value: OperatorDto, allStatuses: OperatorStatus[]): Operator {
        return {
            ...value,
            Status: toSelectedOperatorStatusModel(value.Status, allStatuses)
        }
    }
}
