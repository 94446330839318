import { useCallback, useState } from "react"

export interface PopoverShownControls {
    shown: boolean
    showPopover: () => void
    hidePopover: () => void
}

export const usePopoverShown = (): PopoverShownControls => {
    const [shown, setShown] = useState(false)

    const showPopover = useCallback(() => setShown(true), [])
    const hidePopover = useCallback(() => setShown(false), [])

    return {
        shown,
        showPopover,
        hidePopover
    }
}
