import React, { useMemo } from "react"
import "./AgentForm.scss"
import { Agent, AgentType, AgentValues } from "../../models/agent"
import { useTranslation } from "react-i18next"
import ValidatableFormTabs, { ValidateTabsCallback } from "../ValidatableFormTabs/ValidatableFormTabs"
import AIAssist2AgentForm from "./agents/AIAssist2Agent/AIAssist2AgentForm"
import RoutingAgentForm from "./agents/RoutingAgent/RoutingAgentForm"
import { TabEntry } from "../../types/tabEntry"
import CommonAgentForm from "../CommonAgentForm/CommonAgentForm"
import FormikInitialMsgAgentFormGeneral from "./agents/InitialMsgAgent/InitialMsgAgentGeneral"
import FormikEmailAgentFormGeneral from "./agents/EmailAgent/EmailAgentGeneral"
import FormikEmailAgentFormCustomization from "./agents/EmailAgent/EmailAgentCustomization"
import FormikEmailAgentFormAdvanced from "./agents/EmailAgent/EmailAgentAdvanced"
import FormikABAgentFormGeneral from "./agents/ABAgent/ABAgentGeneral"
import FormikNightAgentFormGeneral from "./agents/NightAgent/NightAgentGeneral"
import FormikNightAgentFormAdvanced from "./agents/NightAgent/NightAgentAdvanced"
import RestAgentForm from "./agents/RestAgent/RestAgentForm"
import FormikRemoteWorkplaceAgentFormGeneral from "./agents/RemoteWorkplaceAgent/RemoteWorkplaceAgentGeneral"
import FormikFixedReplyAgentFormGeneral from "./agents/FixedReplyAgent/FixedReplyAgentForm"
import FormikFinishDialogAgentGeneral from "./agents/FinishDialogAgent/FinishDialogAgentForm"
import { FormikProps } from "formik"
import { TFunction, WithT } from "i18next"
import FormikIdentificationAgentFormGeneral from "./agents/IdentificationAgent/IdentificationAgentForm"
import FormikJqAgentFormGeneral from "./agents/JqAgent/JqAgentForm"
import FormikYandexMapGeocodeAgentFormGeneral from "./agents/YandexMapGeocodeAgent/YandexMapGeocodeAgentForm"
import FormikJavaScriptAgentFormGeneral from "./agents/JavaScriptAgent/JavaScriptAgentForm"
import FormikADFSAgentFormGeneral from "./agents/ADFSAgent/ADFSAgentForm"
import FormikPsbBssAgentFormGeneral from "./agents/PsbBssAgent/PsbBssAgentForm"
import FormikMEDODSIntegrationFormGeneral from "./agents/MEDODSIntegration/MEDODSIntegrationForm"
import FormikYandexMapAgentFormGeneral from "./agents/YandexMapAgent/YandexMapAgentForm"
import AsyncState from "../../core/asyncState"
import FormikNextAgentForm from "./common/NextAgentForm"
import FormikSurveysAgentFormGeneral from "./agents/SurveysAgent/SurveysAgentForm"
import { OptionsType } from "react-select/src/types"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import UnavailableAgentForm from "./common/UnavailableAgentForm"
import useRefCheck from "../../utility/common/useRefCheck"
import { BSS_AGENT_REF_REQUIRED, JS_AGENT_REF_REQUIRED } from "../../utility/common/flags"

const tNamespace = "agent:form."

export interface AgentFormProps extends Props {
    agentType: AgentType
    tabEntries: TabEntry[]
    validateTabs?: ValidateTabsCallback
}

interface Props {
    agent?: Agent
    agentType: AgentType
    submitCallback: (agent: Agent) => void
    disabled?: boolean
    ownedByThisScenario?: boolean
}

export interface FormikAgentFormProps extends AgentFormProps, FormikProps<AgentValues>, WithT {
    asyncState: AsyncState<void>
    agentOptions: Agent[]
    defaultArticleOptions: OptionsType<OptionType>
}

const getTabEntries = (type: string, t: TFunction): TabEntry[] => {
    const baseTabs = [
        { key: "general", value: t(`${tNamespace}general`) },
        { key: "advanced", value: t(`${tNamespace}advanced`) }
    ]

    const customizationTab = {
        key: "customization",
        value: t(`${tNamespace}customization`)
    }
    const authorizationTab = {
        key: "authorization",
        value: t(`${tNamespace}authorization`)
    }
    const classifiersTab = {
        key: "classifiers",
        value: t(`${tNamespace}classifiers`)
    }

    switch (type) {
        case AgentType.InitialMsgAgent:
            return [baseTabs[0]]
        case AgentType.EmailAgent:
            return [baseTabs[0], customizationTab, baseTabs[1]]
        case AgentType.RestAgent:
            return [baseTabs[0], authorizationTab, baseTabs[1]]
        case AgentType.AIAssist2:
            return [baseTabs[0], classifiersTab, baseTabs[1]]
        default:
            return baseTabs
    }
}

const AgentForm: React.FC<Props> = props => {
    const { agentType } = props
    const { t } = useTranslation()

    const isJSAgentAvailable = useRefCheck(JS_AGENT_REF_REQUIRED)
    const isBSSAgentAvailable = useRefCheck(BSS_AGENT_REF_REQUIRED)

    const tabEntries = useMemo(() => {
        const tabs = getTabEntries(agentType, t)

        switch (agentType) {
            case AgentType.JavaScriptAgent:
                return !isJSAgentAvailable ? [tabs[0]] : tabs
            case AgentType.PsbBssAgent:
                return !isBSSAgentAvailable ? [tabs[0]] : tabs
            default:
                return tabs
        }
    }, [agentType, t, isJSAgentAvailable, isBSSAgentAvailable])

    const renderAgentForm = (): JSX.Element | null => {
        switch (agentType) {
            case AgentType.AIAssist2:
                return <AIAssist2AgentForm {...props} tabEntries={tabEntries} />
            case AgentType.RoutingAgent:
                return <RoutingAgentForm {...props} tabEntries={tabEntries} />
            case AgentType.InitialMsgAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikInitialMsgAgentFormGeneral]}
                    </CommonAgentForm>
                )
            case AgentType.EmailAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikEmailAgentFormGeneral, FormikEmailAgentFormCustomization, FormikEmailAgentFormAdvanced]}
                    </CommonAgentForm>
                )
            case AgentType.ABAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikABAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.NightAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikNightAgentFormGeneral, FormikNightAgentFormAdvanced]}
                    </CommonAgentForm>
                )
            case AgentType.RestAgent:
                return <RestAgentForm {...props} tabEntries={tabEntries} />
            case AgentType.RemoteWorkplaceAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikRemoteWorkplaceAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.FixedReplyAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikFixedReplyAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.FinishDialogAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikFinishDialogAgentGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.SurveysAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikSurveysAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.IdentificationAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikIdentificationAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.JqAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikJqAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.JavaScriptAgent:
                if (!isJSAgentAvailable)
                    return <UnavailableAgentForm agentType={AgentType.JavaScriptAgent} t={t} tabEntry={tabEntries[0]} />

                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikJavaScriptAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.MEDODSIntegration:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikMEDODSIntegrationFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.ADFSAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikADFSAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.PsbBssAgent:
                if (!isBSSAgentAvailable)
                    return <UnavailableAgentForm agentType={AgentType.PsbBssAgent} t={t} tabEntry={tabEntries[0]} />

                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikPsbBssAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.YandexMapAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikYandexMapAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            case AgentType.YandexMapGeocodeAgent:
                return (
                    <CommonAgentForm {...props} tabEntries={tabEntries}>
                        {[FormikYandexMapGeocodeAgentFormGeneral, FormikNextAgentForm]}
                    </CommonAgentForm>
                )
            default:
                return null
        }
    }

    return (
        <ValidatableFormTabs id="agent-settings-tabs" alwaysReload={false} entries={tabEntries}>
            {renderAgentForm()}
        </ValidatableFormTabs>
    )
}

export default AgentForm
