import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import { actions, getArticleProcess } from "./slice"
import sharedKnowledgeBaseController from "../../api/controllers/sharedKnowledgeBase"
import { handleHttpException } from "../handleHttpException"
import { ItemId } from "@atlaskit/tree/types"
import { getArticleTypesBase, getCategoriesBase, getRelatedCategoriesBase, searchArticleBase } from "./rootThunks"
import { GetSharedRelatedCategoriesRequest, SearchSharedArticlesRequest } from "../../models/sharing"
import { push } from "connected-react-router"
import { buildSharedArticleActionUrl } from "../../utility/knowledgeBase/articleUrlHelper"

export const getSharedType =
    (shareId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getSharedTypeProcess())
        try {
            const response = await sharedKnowledgeBaseController.getSharedType(shareId)
            dispatch(actions.getSharedTypeSuccess(response.Share.Type))
        } catch (e) {
            handleHttpException(e, constants.GET_SHARED_TYPE_FAILED, err => actions.getSharedTypeFailed(err), dispatch)
        }
    }

export const getSharedCatalog =
    (shareId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getCatalogsProcess())
        try {
            const response = await sharedKnowledgeBaseController.getSharedCatalog(shareId)
            dispatch(actions.getCatalogsSuccess([response.Catalog]))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_CATALOGS_FAILED_MESSAGE,
                err => actions.getCatalogsFailed(err),
                dispatch
            )
        }
    }

export const getSharedArticle =
    (shareId: string, url: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(getArticleProcess())
        try {
            const article = await sharedKnowledgeBaseController.getSharedArticle(shareId)
            dispatch(actions.getSharedArticleSuccess({ Article: article }))
            dispatch(push(buildSharedArticleActionUrl(url, article.SymbolCode)))
        } catch (e) {
            handleHttpException(e, constants.GET_ARTICLE_FAILED_MESSAGE, err => actions.getArticleFailed(err), dispatch)
        }
    }

export const getSharingArticleByCode =
    (shareId: string, articleCode: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(getArticleProcess())
        try {
            const article = await sharedKnowledgeBaseController.getArticle(shareId, articleCode)
            dispatch(actions.getSharedArticleSuccess({ Article: article }))
        } catch (e) {
            handleHttpException(e, constants.GET_ARTICLE_FAILED_MESSAGE, err => actions.getArticleFailed(err), dispatch)
        }
    }

export const getSharedCategories = (
    shareId: string,
    parentCategoryId: ItemId,
    catalogId: ItemId
): ActionResult<Promise<void>> => {
    const func = () => sharedKnowledgeBaseController.getCategories(shareId, parentCategoryId as string)
    return getCategoriesBase(parentCategoryId, catalogId, func)
}

export const getSharedRelatedCategories = (
    shareId: string,
    categoryId?: string,
    categoryCode?: string,
    catalogCode?: string
): ActionResult<Promise<void>> => {
    const func = () => {
        const request: GetSharedRelatedCategoriesRequest = {
            Id: categoryId,
            CatalogCode: catalogCode,
            CategoryCode: categoryCode
        }
        return sharedKnowledgeBaseController.getRelatedCategories(shareId, request)
    }
    return getRelatedCategoriesBase(func)
}

export const getSharedArticleTypes = (shareId: string): ActionResult<Promise<void>> => {
    const func = () => {
        return sharedKnowledgeBaseController.getArticleTypes(shareId)
    }
    return getArticleTypesBase(func)
}

export const searchSharedArticles = (
    shareId: string,
    request: SearchSharedArticlesRequest
): ActionResult<Promise<void>> => {
    const func = () => {
        return sharedKnowledgeBaseController.searchArticles(shareId, {
            ...request,
            EnablePayload: true
        })
    }
    return searchArticleBase(func)
}
