import React from "react"
import styles from "./ErrorMessage.module.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"

export interface ErrorMessageProps extends ClassProps {
    text: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = props => {
    return <div className={cn(styles.errorMessage, props.className)}>{props.text}</div>
}

export default ErrorMessage
