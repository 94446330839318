import React, { createRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Formik } from "formik"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Dispatch } from "../../utility/common/storeHelper"
import { TabPaneElement } from "../ValidatableFormTabs/ValidatableFormTabs"
import { getValidationSchema } from "../../utility/agents/agentValidation"
import { getDefaultValues, getValuesFromAgent } from "../../utility/agents/agentValues"
import { selectCurrentProject } from "../../store/projects/selectors"
import OnSubmitValidationError from "../OnSubmitValidationError/OnSubmitValidationError"
import Tab from "react-bootstrap/Tab"
import { buildAgentRequest } from "../../utility/agents/agentRequest"
import { createAgent, getAgentDeclarations, updateAgent } from "../../store/agents/thunks"
import { AgentFormProps } from "../AgentForm/AgentForm"
import { AgentType, AgentValues, IdentificationAgentConfigItem } from "../../models/agent"
import {
    selectAgentDeclarations,
    selectAgents,
    selectCreateAgentState,
    selectUpdateAgentState
} from "../../store/agents/selectors"
import AgentFormLoader from "../AgentForm/AgentFormLoader"
import { searchArticles } from "../../utility/knowledgeBase/articleSelect"
import { ARTICLE_OPTIONS_DEFAULT_PAGE_SIZE } from "../ArticleSelect/ArticleSelect"
import { OptionsType } from "react-select/src/types"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"

interface Props {
    children: React.ElementType[]
}

const agentsWithMultipleArticles: string[] = [AgentType.JqAgent, AgentType.ADFSAgent]

const CommonAgentForm: React.FC<AgentFormProps & Props> = props => {
    const { t } = useTranslation()
    const { agent, agentType, tabEntries, submitCallback, validateTabs, children, ownedByThisScenario } = props
    const [defaultArticles, setDefaultArticles] = useState<OptionsType<OptionType>>([])

    const dispatch = useDispatch<Dispatch>()
    const project = useSelector(selectCurrentProject)
    const declarations = useSelector(selectAgentDeclarations)

    const refs = useRef<RefObject<TabPaneElement>[]>(tabEntries.map(() => createRef()))
    const validateChannelTabs = useCallback(() => {
        validateTabs?.(refs.current)
    }, [refs, validateTabs])

    const validationSchema = useMemo(
        () => getValidationSchema(agentType, ownedByThisScenario),
        [agentType, ownedByThisScenario]
    )

    const initialValues = useMemo(
        () =>
            declarations?.[agentType] && project
                ? agent
                    ? getValuesFromAgent(agent, declarations[agentType], project.language)
                    : getDefaultValues(agentType, declarations[agentType], project.language)
                : null,
        [agent, agentType, declarations, project]
    )

    const asyncState = useSelector(agent ? selectUpdateAgentState : selectCreateAgentState, shallowEqual)
    const agents = useSelector(selectAgents)

    const agentOptions = useMemo(() => (agents ?? []).filter(a => !agent || a.Id !== agent.Id), [agent, agents])

    useEffect(() => {
        if (project && (!declarations || !declarations[agentType])) {
            dispatch(getAgentDeclarations(project.id, agentType))
        }
    }, [project, dispatch, declarations, agentType])

    useEffect(() => {
        if (!project || !agentsWithMultipleArticles.includes(agentType)) return

        const updateDefaultArticleOptions = async () => {
            const articles = await searchArticles(
                dispatch,
                t,
                project.id,
                true,
                true,
                ARTICLE_OPTIONS_DEFAULT_PAGE_SIZE
            )("")
            setDefaultArticles(articles)
        }

        updateDefaultArticleOptions()
    }, [dispatch, t, project, agentType])

    if (!project) {
        return null
    }

    if (!initialValues) {
        return <AgentFormLoader count={3} />
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: AgentValues) => {
                const { agentType } = props
                let newValues = values

                if (agentType === AgentType.IdentificationAgent) {
                    newValues = {
                        ...values,
                        Params: {
                            ...values.Params,
                            default_agent: values.NextAgent,
                            definition: values.Params.definition?.map((item: IdentificationAgentConfigItem) => ({
                                ...item,
                                Action: item.Action.startsWith("/switchredirect")
                                    ? item.Action
                                    : `/switchredirect ${item.Action}`
                            }))
                        }
                    }
                }

                const request = buildAgentRequest(project.id, agentType, newValues)

                if (agent) {
                    dispatch(updateAgent(request, submitCallback))
                } else {
                    dispatch(createAgent(request, submitCallback))
                }
            }}
        >
            {formikProps => (
                <>
                    <OnSubmitValidationError formikProps={formikProps} onCallback={validateChannelTabs} />
                    {children.map((Component: React.ElementType, i) => (
                        <Tab.Pane eventKey={tabEntries[i].key} ref={refs.current[i]} key={tabEntries[i].key}>
                            <Component
                                {...props}
                                {...formikProps}
                                t={t}
                                asyncState={asyncState}
                                agentOptions={agentOptions}
                                defaultArticleOptions={defaultArticles}
                            />
                        </Tab.Pane>
                    ))}
                </>
            )}
        </Formik>
    )
}

export default CommonAgentForm
