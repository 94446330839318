import React, { useCallback, useMemo, useState } from "react"
import { ModalContext } from "./ModalContext"

export interface ModalProviderProps {
    children: React.ReactNode
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
    const [modal, setModal] = useState<React.FC | null>(null)

    const showModal = useCallback((modal: React.FC) => setModal(modal), [])
    const hideModal = useCallback(() => setModal(null), [])

    const contextValue = useMemo(() => ({ showModal, hideModal }), [showModal, hideModal])

    return (
        <ModalContext.Provider value={contextValue}>
            <>
                {children}
                {modal}
            </>
        </ModalContext.Provider>
    )
}
