import React from "react"
import { FormikAgentFormProps } from "../../AgentForm"
import { FormikProps } from "formik"
import { AgentValues, NightAgentValues } from "../../../../models/agent"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { formTranslation } from "../../../../locales/form"
import { ParamValues } from "../../../../models/parameterDeclaration"
import InfoIcon from "../../../InfoIcon/InfoIcon"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import SlotSelectControl from "../../common/SlotSelectControl"

const tNamespace = "agent:form."
const tTooltipNamespace = "agent:tooltip."

const FormikNightAgentFormAdvanced: React.FC<FormikAgentFormProps & FormikProps<NightAgentValues>> = props => {
    const { asyncState, agentOptions, t, handleSubmit, disabled } = props

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="agent-form__section">
                <ValidatableInput
                    as="select"
                    id="formNextAgent"
                    type="text"
                    name={nameof<AgentValues>("NextAgent")}
                    label={t(`${tNamespace}next-agent`)}
                    disabled={!agentOptions.length}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentOptions.map(option => (
                        <option value={option.Id} key={option.Id}>
                            {option.Id}
                        </option>
                    ))}
                </ValidatableInput>
                <SlotSelectControl
                    id="formIsNightActiveSlotId"
                    name={nameof2<NightAgentValues, ParamValues>("Params", "is_night_active_slot_id")}
                    label={t(`${tNamespace}agent-fill-slot`)}
                    icon={<InfoIcon id="tooltipAgentFillSlot" content={t(`${tTooltipNamespace}agent-fill-slot`)} />}
                    t={t}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikNightAgentFormAdvanced
