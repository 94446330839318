import React from "react"
import { AsyncDataProps } from "../../utility/common/asyncHelper"
import AsyncState from "../../core/asyncState"
import useOmniLoader from "../../hooks/useOmniLoader"

interface Props<T> {
    dataState: AsyncState<T>
    /** If empty, <OmniLoader /> will be used automatically */
    processView?: JSX.Element
    children: (props: AsyncDataProps<T>) => JSX.Element
}

function AsyncWithoutError<T>(props: Props<T>): JSX.Element {
    const { dataState, processView, children } = props

    useOmniLoader(dataState.inProcess, !processView)

    if (dataState.inProcess) {
        if (processView) return processView
    }

    return <>{dataState.data ? children({ data: dataState.data }) : null}</>
}

export default AsyncWithoutError
