import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { selectAuthRequested } from "../../store/auth/selectors"
import { getCurrentUser } from "../../store/users/thunks"

export const useAuthenticationHandling = (redirect: boolean) => {
    const authRequested = useSelector(selectAuthRequested)
    const dispatch = useDispatch()
    useEffect(() => {
        !authRequested && dispatch(getCurrentUser(redirect))
    }, [authRequested, dispatch, redirect])
}
