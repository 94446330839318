import { DialogPerson } from "../../models/Dialogs/dialog"

export const getClientFullname = (client?: DialogPerson) => {
    if (!client) {
        return ""
    }

    const fullnameTokens = [client.Lastname, client.Firstname, client.Middlename].filter(name => name)

    return fullnameTokens.join(" ")
}
