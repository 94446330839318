import React from "react"
import cn from "classnames"
import styles from "./Stepper.module.scss"

export interface StepperProps {
    steps: string[]
    activeStep: number
}

const Stepper: React.FC<StepperProps> = props => {
    const { steps, activeStep } = props

    return (
        <div className={styles.stepper}>
            {steps.map((step, index) => (
                <div
                    className={cn(
                        styles.stepper__stepContainer,
                        index <= activeStep && styles.stepper__stepContainer_selected
                    )}
                    key={index}
                >
                    <div className={styles.stepper__step}>
                        <span
                            className={cn(
                                styles.stepper__circle,
                                index <= activeStep && styles.stepper__circle_selected
                            )}
                        >
                            {index + 1}
                        </span>
                        <span className={styles.stepper__text}>{step}</span>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Stepper
