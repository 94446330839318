import { KnowledgeBaseAccessType } from "./knowledgeBasePermission"
import { UserAccessDifference } from "./knowledgeBaseUserAccess"

export enum KnowledgeBaseSubscriptionType {
    Notify = "Notify",
    WithConfirmation = "WithConfirmation"
}

export interface KnowledgeBaseSubscriptionValue {
    Type: KnowledgeBaseAccessType
    SubscriptionType: KnowledgeBaseSubscriptionType
    Value: string
    ProjectId: string
}

export interface KnowledgeBaseArticleSubscriptions {
    Id: string
    ArticleCode: string
    Subscriptions: KnowledgeBaseSubscriptionValue[]
}

export interface KnowledgeBaseSubscriptionSelectItem {
    Type: KnowledgeBaseSubscriptionType
    Title: string
}

export type AddAccessItemPayload = {
    type: KnowledgeBaseAccessType
    value: string
}

export interface UpdateArticleSubscriptionsRequest {
    AddedSubscriptions: KnowledgeBaseSubscriptionValue[]
    RemovedSubscriptions: KnowledgeBaseSubscriptionValue[]
}

export interface GetUserArticleSubscriptionResponse {
    Status: boolean
}

export interface ArticleSubscriptionFormValues {
    Subscriptions: KnowledgeBaseSubscriptionValue[]
    SubscriptionDiff: UserAccessDifference
}
