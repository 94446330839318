import React from "react"
import { ArticleContext, articleContextDefaultValue } from "../../../pages/KnowledgeBase/KnowledgeBase"
import {
    ArticleScenarioContext,
    articleScenarioContextDefaultValue,
    ScenarioContext,
    scenarioContextDefaultValue
} from "../../ScenarioEditor/ScenarioContext"
import KnowledgeBaseContext, { knowledgeBaseContextDefaultValue } from "../KnowledgeBaseContext"
import { onChooseAnswerFunc } from "../../../types/knowledgeBaseFunctions"
import { SharedType } from "../../../models/sharing"

export interface SharedKnowledgeBaseContextType {
    onChooseAnswer: onChooseAnswerFunc
    shareId?: string
    url: string
    sharedType?: SharedType
}

export interface SharedKnowledgeBaseContextProviderProps {
    value: SharedKnowledgeBaseContextType
    children: React.ReactNode
}

const SharedKnowledgeBaseContextProvider: React.FC<SharedKnowledgeBaseContextProviderProps> = props => {
    const { value, children } = props

    return (
        <KnowledgeBaseContext.Provider
            value={{
                ...knowledgeBaseContextDefaultValue,
                canModifyKnowledgeBase: false,
                url: value.url,
                shareId: value.shareId,
                sharedType: value.sharedType
            }}
        >
            <ScenarioContext.Provider value={scenarioContextDefaultValue}>
                <ArticleScenarioContext.Provider value={articleScenarioContextDefaultValue}>
                    <ArticleContext.Provider
                        value={{
                            ...articleContextDefaultValue,
                            onChooseAnswer: value.onChooseAnswer
                        }}
                    >
                        {children}
                    </ArticleContext.Provider>
                </ArticleScenarioContext.Provider>
            </ScenarioContext.Provider>
        </KnowledgeBaseContext.Provider>
    )
}

export default SharedKnowledgeBaseContextProvider
