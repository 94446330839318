import React, { useMemo } from "react"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import { selectAgents, selectCreateAgentState, selectUpdateAgentState } from "../../../../store/agents/selectors"
import { AgentFormProps } from "../../AgentForm"
import { DefaultSettingsValues, RoutingAgentValues } from "../../../../models/agent"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { formTranslation } from "../../../../locales/form"
import CheckBoxValidatableInput from "../../../CheckBoxValidatableInput/CheckBoxValidatableInput"

const tNamespace = "agent:form."

const FormikRoutingAgentFormAdvanced: React.FC<AgentFormProps & FormikProps<RoutingAgentValues> & WithT> = props => {
    const { agent, t, handleSubmit, disabled } = props

    const asyncState = useSelector(agent ? selectUpdateAgentState : selectCreateAgentState, shallowEqual)
    const agents = useSelector(selectAgents)

    const agentOptions = useMemo(
        () =>
            (agents ?? []).filter(a => !agent || a.Id !== agent.Id).map(agent => ({ name: agent.Id, value: agent.Id })),
        [agent, agents]
    )

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="agent-form__section agent-form__section_border">
                <ValidatableInput
                    as="select"
                    id="formTitle"
                    type="text"
                    name={nameof<RoutingAgentValues>("FailOverAgent")}
                    label={t(`${tNamespace}agent-if-failure-current`)}
                    disabled={!agentOptions.length}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentOptions.map(option => (
                        <option value={option.value} key={option.value}>
                            {option.name}
                        </option>
                    ))}
                </ValidatableInput>
            </div>
            <div className="agent-form__section">
                <ValidatableInput
                    id="formTitle"
                    type="number"
                    name={nameof2<RoutingAgentValues, DefaultSettingsValues>("DefaultSettings", "max_dialogs")}
                    label={t(`${tNamespace}default-max-number-of-queries`)}
                />
                <CheckBoxValidatableInput
                    id="formSynchronizeWithChat"
                    name={nameof<RoutingAgentValues>("allow_only_known_agents")}
                    label={t(`${tNamespace}accept-messages-from-known-agents`)}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikRoutingAgentFormAdvanced
