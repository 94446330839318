import React from "react"
import AgentIcon from "../AgentIcon/AgentIcon"
import { WithT } from "i18next"
import { AgentType } from "../../models/agent"
import styles from "./AgentFormHeader.module.scss"

const tNamespace = "agent:"

export interface AgentFormHeaderProps extends WithT {
    agentType: AgentType
}

const AgentFormHeader: React.FC<AgentFormHeaderProps> = props => {
    const { agentType, t } = props

    return (
        <div className={styles.agentFormHeader}>
            <div className={styles.agentFormHeader__content}>
                <div className={styles.agentFormHeader__icon}>
                    <AgentIcon type={agentType} />
                </div>
                {t(`${tNamespace}agent-type.${agentType}`)}
            </div>
        </div>
    )
}

export default AgentFormHeader
