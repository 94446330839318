export const fetchFileVirtual = async (url: string, filename: string) => {
    try {
        const data = await fetch(url)
        const blob = await data.blob()
        const objectUrl = URL.createObjectURL(blob)

        const link = document.createElement("a")

        link.setAttribute("href", objectUrl)
        link.setAttribute("download", filename)
        link.style.display = "none"

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
    } catch (e) {
        console.log("Error while trying to download file within fetchFileVirtual helper - ", e)
    }
}

export enum EFetchPublicFile {
    JSON = "json",
    TXT = "text"
}

export const fetchPublicFile = async (filename: string, ftype = EFetchPublicFile.JSON) => {
    try {
        const response = await fetch(`${process.env.PUBLIC_URL}/${filename}`, {
            headers: {
                "Cache-Control": "no-cache",
                Expires: "-1"
            }
        })

        switch (ftype) {
            case EFetchPublicFile.JSON:
                return await response.json()
            default:
                return await response.text()
        }
    } catch (e: unknown) {
        console.error(`Can't fetch ${filename}`)
    }
}
