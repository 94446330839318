import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectIsOperatorConnected } from "../../store/userOperator/selectors"
import { selectAuthStatus, selectLogoutState } from "../../store/auth/selectors"
import { subscribeOnUsersData } from "../../store/userOperator/thunks"
import { selectNewsState } from "../../store/notifications/selectors"
import { getNotificationNews } from "../../store/notifications/thunks"

export const useUserInitialization = () => {
    const isConnected = useSelector(selectIsOperatorConnected)
    const logoutState = useSelector(selectLogoutState)
    const authenticated = useSelector(selectAuthStatus)
    const userNewsState = useSelector(selectNewsState)
    const dispatch = useDispatch()
    useEffect(() => {
        if (authenticated && !userNewsState.data) {
            dispatch(getNotificationNews())
        }
    }, [authenticated, dispatch, userNewsState.data])
    useEffect(() => {
        if (authenticated && !isConnected && !logoutState.inProcess && userNewsState.data) {
            dispatch(subscribeOnUsersData())
        }
    }, [authenticated, dispatch, isConnected, logoutState.inProcess, userNewsState.data])
    return { isConnected, authenticated }
}
