import React, { useContext, useMemo } from "react"
import { Button, Form } from "react-bootstrap"
import { Select } from "../Select/Select"
import { formTranslation } from "../../locales/form"
import ParameterValueControl from "../ParameterValueControl/ParameterValueControl"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import { FormikProps } from "formik/dist/types"
import { TFunction, WithT } from "i18next"
import { ScenarioBlockType } from "../../models/scenario"
import { useSelector } from "react-redux"
import { selectSlots } from "../../store/slots/selectors"
import { ValueType } from "react-select"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import { nameof } from "../../utility/common/nameof"
import { FieldArray } from "formik"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import InfoIcon from "../InfoIcon/InfoIcon"
import { ChooseConditionsFormProps } from "./ChooseConditionsForm"
import { ArticleScenarioContext } from "../ScenarioEditor/ScenarioContext"
import { SlotsMapType, SlotType } from "../../models/slot"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import {
    ConditionsFormValues,
    ConditionStatement,
    ConditionStatementType,
    ConditionStatementValue,
    ConditionType,
    SlotSpecialValue
} from "../../models/scenarioCondition"
import { conditionsWithValues, DEFAULT_ATTEMPTS } from "../../utility/scenario/scenarioConditions"
import DictionarySlotSelect from "./controls/DictionarySlotSelect"
import AttemptsControl from "./controls/AttemptsControl"

const tNamespace = "scenarioEditor:"

const slotSpecialValues: string[] = Object.values(SlotSpecialValue)

const getSlotTitle = (condition: ConditionStatement, slotsMap: SlotsMapType, t: TFunction) => {
    switch (condition.Slot) {
        case SlotSpecialValue.AllMandatoryFilledIn:
            return t(`${tNamespace}all-required-fields-filled`)
        case SlotSpecialValue.ArbitraryExpression:
            return t(`${tNamespace}arbitrary-expression`)
        case SlotSpecialValue.WithoutConditions:
            return t(`${tNamespace}without-conditions`)
        default:
            return slotsMap[condition.Slot].Title
    }
}

const FormikChooseConditionsForm: React.FC<ChooseConditionsFormProps & FormikProps<ConditionsFormValues> & WithT> =
    props => {
        const { t, handleSubmit, setFieldValue, values, blockType } = props

        const slots = useSelector(selectSlots)
        const { slotsMap } = useContext(ArticleScenarioContext)
        const isWithoutConditions =
            values.Conditions.length === 1 && values.Conditions[0].Slot === SlotSpecialValue.WithoutConditions

        const specialValues: OptionType[] = useMemo(() => {
            if (isWithoutConditions) {
                return []
            }

            const arbitraryExpressionOption = {
                label: t(`${tNamespace}arbitrary-expression`),
                value: SlotSpecialValue.ArbitraryExpression
            }

            if (blockType === ScenarioBlockType.Form)
                return [
                    arbitraryExpressionOption,
                    {
                        label: t(`${tNamespace}all-required-fields-filled`),
                        value: SlotSpecialValue.AllMandatoryFilledIn
                    }
                ]

            if (values.Conditions.length > 0) return [arbitraryExpressionOption]

            return [
                arbitraryExpressionOption,
                {
                    label: t(`${tNamespace}without-conditions`),
                    value: SlotSpecialValue.WithoutConditions
                }
            ]
        }, [t, blockType, values.Conditions, isWithoutConditions])

        const slotOptions = useMemo(
            () =>
                specialValues
                    .concat(slots.map(s => ({ label: s.Title, value: s.ExternalId })))
                    .filter(
                        s =>
                            !values.Conditions.find(c =>
                                c.Slot ? c.Slot === s.value : s.value === SlotSpecialValue.AllMandatoryFilledIn
                            )
                    ),
            [slots, values.Conditions, specialValues]
        )

        const handleSelect = (option: ValueType<OptionType, false>) => {
            if (option) {
                switch (option.value) {
                    case SlotSpecialValue.AllMandatoryFilledIn:
                        setFieldValue(
                            nameof<ConditionsFormValues>("Conditions"),
                            [...values.Conditions, { Slot: SlotSpecialValue.AllMandatoryFilledIn, Condition: "" }],
                            false
                        )
                        break
                    case SlotSpecialValue.ArbitraryExpression:
                        setFieldValue(
                            nameof<ConditionsFormValues>("Conditions"),
                            [
                                ...values.Conditions,
                                {
                                    Slot: SlotSpecialValue.ArbitraryExpression,
                                    Value: "",
                                    Condition: "",
                                    Type: ConditionStatementType.Value
                                }
                            ],
                            false
                        )
                        break
                    case SlotSpecialValue.WithoutConditions:
                        setFieldValue(
                            nameof<ConditionsFormValues>("Conditions"),
                            [...values.Conditions, { Slot: SlotSpecialValue.WithoutConditions }],
                            false
                        )
                        break
                    default:
                        const defaultCondition: ConditionStatementValue = {
                            Type: ConditionStatementType.Filled,
                            Slot: option.value,
                            Value: "",
                            Attempts: DEFAULT_ATTEMPTS,
                            Condition: ConditionType.Equal
                        }
                        setFieldValue(
                            nameof<ConditionsFormValues>("Conditions"),
                            [...values.Conditions, defaultCondition],
                            false
                        )
                }
            }
        }

        return (
            <Form className="choose-conditions-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
                <div className="choose-conditions-form__content">
                    <Form.Group controlId="chooseCondition">
                        <Select
                            value={{
                                label: t(`${tNamespace}choose-condition`),
                                value: ""
                            }}
                            isDisabled={isWithoutConditions || !slotOptions.length}
                            options={slotOptions}
                            noOptionsMessage={() => t(formTranslation.noResultsFound)}
                            onChange={handleSelect}
                        />
                    </Form.Group>
                    <FieldArray
                        name={nameof<ConditionsFormValues>("Conditions")}
                        render={({ name, remove, form }) =>
                            values.Conditions.map((c: ConditionStatementValue, index: number) => {
                                return (
                                    <ParameterValueControl
                                        title={getSlotTitle(c, slotsMap, t)}
                                        onDelete={() => remove(index)}
                                        key={index}
                                    >
                                        {c.Slot === SlotSpecialValue.ArbitraryExpression && (
                                            <DebouncedValidatableInput
                                                id={`formParameterValue${index}`}
                                                type="text"
                                                name={`${name}.${index}.Value`}
                                            />
                                        )}
                                        {!conditionsWithValues.includes(c.Slot) &&
                                            c.Slot !== SlotSpecialValue.ArbitraryExpression && (
                                                <>
                                                    <RadioButtonValidatableInput
                                                        value={ConditionStatementType.Filled}
                                                        id={`formTypeFilled${index}`}
                                                        name={`${name}.${index}.Type`}
                                                        label={t(`${tNamespace}filled`)}
                                                    />

                                                    {blockType === ScenarioBlockType.Form && (
                                                        <>
                                                            <RadioButtonValidatableInput
                                                                value={ConditionStatementType.Attempts}
                                                                id={`formTypeAttempts${index}`}
                                                                name={`${name}.${index}.Type`}
                                                                label={t(`${tNamespace}attempts`)}
                                                            />
                                                            <AttemptsControl
                                                                name={`${name}.${index}`}
                                                                conditionStatement={c}
                                                            />
                                                        </>
                                                    )}

                                                    <RadioButtonValidatableInput
                                                        value={ConditionStatementType.Value}
                                                        id={`formTypeValue${index}`}
                                                        name={`${name}.${index}.Type`}
                                                        label={t(`${tNamespace}value`)}
                                                        icon={
                                                            slotsMap[c.Slot]?.Type ===
                                                            SlotType.Dictionary ? undefined : (
                                                                <InfoIcon
                                                                    id={`formValueInfo${index}`}
                                                                    content={t(`${tNamespace}tooltip:condition-value`, {
                                                                        interpolation: { skipOnVariables: true }
                                                                    })}
                                                                />
                                                            )
                                                        }
                                                    />

                                                    <div className="choose-conditions-form__subfield">
                                                        {!slotSpecialValues.includes(c.Slot) &&
                                                        slotsMap[c.Slot]?.Type === SlotType.Dictionary ? (
                                                            <DictionarySlotSelect
                                                                name={`${name}.${index}`}
                                                                slotsMap={slotsMap}
                                                                conditionStatement={c}
                                                                errors={form.errors}
                                                            />
                                                        ) : (
                                                            <DebouncedValidatableInput
                                                                id={`formParameterValue${index}`}
                                                                type="text"
                                                                name={`${name}.${index}.Value`}
                                                                disabled={c.Type !== ConditionStatementType.Value}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                    </ParameterValueControl>
                                )
                            })
                        }
                    />
                </div>
                <div className="choose-conditions-form__footer">
                    <Button type="submit" disabled={!values.Conditions.length} variant="primary" block>
                        {t(formTranslation.save)}
                    </Button>
                </div>
            </Form>
        )
    }

export default FormikChooseConditionsForm
