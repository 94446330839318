import React from "react"
import styles from "./ProjectWizardError.module.scss"
import Can from "../../components/Can/Can"
import { Supervisor } from "../../permissions"
import somethingWentWrongImage from "../../assets/images/ctSomethingWentWrong.png"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import PublicPage from "../../components/PublicPage/PublicPage"

const tNamespace = "wizard:"
const tErrorNamespace = "wizard:error-page."

interface Props {
    onReset: () => void
}

const ProjectWizardError: React.FC<Props> = ({ onReset }) => {
    const { t } = useTranslation()

    return (
        <PublicPage contentInCenter title={t(`${tNamespace}title`)}>
            <PublicPage.Message
                className={styles.projectWizardError}
                title={t(`${tErrorNamespace}title`)}
                text={t(`${tErrorNamespace}description`)}
            >
                <div className={styles.projectWizardError__image}>
                    <img src={somethingWentWrongImage} alt="" />
                </div>
                <Can permission={Supervisor}>
                    <Button variant="primary" onClick={onReset} block className={styles.projectWizardError__btn}>
                        {t(`${tErrorNamespace}create-again`)}
                    </Button>
                </Can>
            </PublicPage.Message>
        </PublicPage>
    )
}

export default ProjectWizardError
