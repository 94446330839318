import React, { useCallback } from "react"
import styles from "./SearchQueryArticle.module.scss"
import { ArticleView, SearchArticleHighlights } from "../../models/article"
import { putHighlightsIntoArticleTitleAndText } from "../../utility/knowledgeBase/knowledgeBase"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { getDateLocale } from "../../locales"
import { getLanguage } from "../../utility/common/language"

interface Props {
    article: ArticleView
    highlights?: SearchArticleHighlights
    onArticleClick: (articleCode: string) => void
}

const SearchQueryArticle: React.FC<Props> = props => {
    const { article, highlights, onArticleClick } = props
    const { i18n } = useTranslation()
    const locale = { locale: getDateLocale(getLanguage(i18n)) }

    const formatModificationDate = (modifiedDate: number) =>
        modifiedDate && format(modifiedDate, "dd.MM.yyyy HH:mm", locale)

    const articleText = article.Answers && article.Answers.length > 0 ? article.Answers[0].Text : ""
    const [title, text] = putHighlightsIntoArticleTitleAndText(article.Title, articleText, highlights)

    const handleClick = useCallback(() => {
        onArticleClick(article.SymbolCode)
    }, [onArticleClick, article.SymbolCode])

    return (
        <div className={styles.searchQueryArticle} onClick={handleClick}>
            <div className={styles.searchQueryArticle__header} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={styles.searchQueryArticle__body} dangerouslySetInnerHTML={{ __html: text }} />
            <div className={styles.searchQueryArticle__footer}>
                <div className={styles.searchQueryArticle__modificationDate}>
                    {formatModificationDate(article.ModifiedDate)}
                </div>
            </div>
        </div>
    )
}

export default SearchQueryArticle
