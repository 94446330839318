import { Notification } from "../../models/notification"

const notificationStackSort = (rawStack: Notification[]): Notification[] =>
    rawStack.sort((n1, n2) =>
        n1.Priority === n2.Priority
            ? n1.Timestamp < n2.Timestamp
                ? 1
                : n1.Timestamp > n2.Timestamp
                ? -1
                : 0
            : n1.Priority < n2.Priority
            ? 1
            : -1
    )

export default notificationStackSort
