import { SlotDto } from "../../models/slot"

export const getSlotById = (slotId: string, slots: SlotDto[]) => slots.find(s => s.ExternalId === slotId)

export const getSlotTitle = (slotId: string | null, slots: SlotDto[]): string => {
    if (!slotId) return ""

    const slot = getSlotById(slotId, slots)
    return slot ? slot.Title : slotId
}

export const getSlotDomainTitle = (slotValues: string[], slot?: SlotDto) => {
    if (!slot || !slot.Domain) {
        return slotValues
    }
    return slotValues.map(sv => {
        const domain = slot.Domain?.find(d => d.Id === sv)
        return domain ? domain.Title : sv
    })
}

export const getSlotDomainInString = (slotValues: string[], slot?: SlotDto, emptyString?: string) => {
    const values = getSlotDomainTitle(slotValues, slot)
    return values.length === 0 ? emptyString ?? "" : values.length === 1 ? values[0] : values.join(", ")
}

export const getSlotTitlesMap = (slots: SlotDto[]) =>
    slots.reduce((obj: { [key: string]: string }, s) => {
        obj[s.ExternalId] = s.Title
        return obj
    }, {})

export const getSlotTypesMap = (slots: SlotDto[]) =>
    slots.reduce((obj: { [key: string]: string }, s) => {
        obj[s.ExternalId] = s.Type
        return obj
    }, {})
