import { Dispatch } from "../../utility/common/storeHelper"
import { actions as dialogActions, actions } from "./slice"

export function backToDialogList() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
    }
}

export function clearDialogsState() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
        dispatch(actions.setSearchCriterion(""))
    }
}

export const closeKnowledgeBaseSidebar = () => {
    return (dispatch: Dispatch) => {
        dispatch(dialogActions.closeArticleSidebar())
        dispatch(dialogActions.closeKnowledgeBaseSidebar())
    }
}

export const setMessageInputText = (text: string) => {
    return (dispatch: Dispatch) => {
        dispatch(actions.setMessageInput(text))
        dispatch(actions.setDraftedInput(text))
    }
}
