import fakeLoader from "./fake-loader"
import { Menu } from "../../models/menu"
import {
    ChannelScenarioData,
    ClassifierConfigurationData,
    fakeClientSurvey,
    fakeDialogMessages,
    FakeQueueCategories,
    getScenarioArticleData,
    MainMenu,
    MarkedMessagesData,
    ProjectData,
    SlotPageData
} from "./data"
import { Project } from "../../models/project"
import { accessTokenKey } from "../../utility/auth/credentialHelper"
import { ClassifierConfigDto } from "../../models/classifier"
import { Article, GetMarkedMessagesResponse } from "../../models/article"
import { ChannelScenario } from "../../models/scenario"
import { GetSlotPageResponse } from "../../models/slot"
import { QueueCategory } from "../../models/queueCategory"
import { DialogBadge } from "../../models/Dialogs/dialog"
import { mockedDialogBadges } from "../../utility/tests/mockedObjects"
import { GetUpdatedMessagesResponse } from "../../models/Dialogs/message"
import { SurveyForm } from "../../models/Dialogs/surveyForm"

export const Controller = {
    getMainMenu: fakeLoader<Menu>(MainMenu),
    getProjects: fakeLoader<Project[]>(ProjectData),
    getClassifiers: () => fakeLoader<ClassifierConfigDto[]>(ClassifierConfigurationData),
    signIn: (login: string, pass: string) =>
        new Promise<string>((resolve, reject) => {
            setTimeout(() => {
                if (login === "test" && pass === "test") {
                    const token = "aabbcc"
                    document.cookie = `${accessTokenKey}=${token}; path=/`
                    resolve(token)
                } else reject()
            }, 1000)
        }),
    getScenarioArticle: (projectId: string, articleCode: string) =>
        fakeLoader<Article>(getScenarioArticleData(projectId, articleCode)),
    getChannelScenario: fakeLoader<ChannelScenario>(ChannelScenarioData),
    getMarkedMessages: fakeLoader<GetMarkedMessagesResponse>(MarkedMessagesData),
    getSlotPage: fakeLoader<GetSlotPageResponse>(SlotPageData),
    getQueueCategories: fakeLoader<QueueCategory[]>(FakeQueueCategories),
    getDialogBadges: () => fakeLoader<DialogBadge[]>(mockedDialogBadges),
    getDialogMessages: (dialogId: string) => fakeLoader<GetUpdatedMessagesResponse>(fakeDialogMessages),
    getClientSurvey: (projectId: string) => fakeLoader<SurveyForm>(fakeClientSurvey)
}
