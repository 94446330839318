import React, { ReactNode } from "react"
import styles from "./PairSettingsControl.module.scss"
import { CloseButton } from "react-bootstrap"

export interface PairSettingsControlProps {
    id: string
    keyControl: ReactNode
    valueControl: ReactNode
    onDelete: () => void
    disabled?: boolean
}

const PairSettingsControl: React.FC<PairSettingsControlProps> = props => {
    const { keyControl, valueControl, onDelete, disabled } = props

    return (
        <div className={styles.pairSettingsControl}>
            {keyControl}
            {valueControl}
            <CloseButton className={styles.pairSettingsControl__closeIcon} onClick={onDelete} disabled={disabled} />
        </div>
    )
}

export default PairSettingsControl
