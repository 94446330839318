import React, { memo } from "react"
import "./StartNode.scss"
import { useTranslation } from "react-i18next"
import { Handle, Position } from "react-flow-renderer"
import { NodeComponentProps } from "react-flow-renderer/dist/nocss/types"

export interface StartNodeData {
    AddBlock: (source: string, sourceHandle: string) => void
}

type Props = NodeComponentProps<StartNodeData>

const StartNode: React.FC<Props> = ({ data, id, isConnectable }) => {
    const { t } = useTranslation()

    return (
        <div className="start-node">
            {t("scenarioEditor:block-title.Start")}
            <Handle
                id={id}
                type="source"
                position={Position.Right}
                className="start-node__handle"
                onClick={() => data.AddBlock(id, id)}
                isConnectable={isConnectable}
            />
        </div>
    )
}

export default memo(StartNode)
