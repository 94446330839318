import { ru, enUS, de } from "date-fns/locale"

export type Locale = {
    id: string
    shortName: string
    name: string
}

export enum LocaleId {
    En = "en",
    Ru = "ru"
}

export const locales: Locale[] = [
    {
        id: LocaleId.Ru,
        shortName: "Ru",
        name: "Русский"
    },
    {
        id: LocaleId.En,
        shortName: "En",
        name: "English"
    }
]

export const getDateLocale = (lang: string) => {
    switch (lang) {
        case "ru":
            return ru
        case "en":
            return enUS
        case "de":
            return de
        default:
            return enUS
    }
}
