const tNamespace = "notification:"

const notificationTranslations = {
    menuTitle: `${tNamespace}menu-title`,
    listTitle: `${tNamespace}list-title`,
    newsListTitle: `${tNamespace}news-list-title`,
    errorTitle: `${tNamespace}error`,
    descriptionNewArticle: `${tNamespace}descriptions.new-article`,
    descriptionArticleEdited: `${tNamespace}descriptions.article-edited`,
    descriptionNewCommentAdded: `${tNamespace}descriptions.new-comment-added`,
    descriptionNewsAdded: `${tNamespace}descriptions.article-news-added`
}

export default notificationTranslations
