import React from "react"
import PairSettingsControl from "../../../PairSettingsControl/PairSettingsControl"
import { nameof2 } from "../../../../utility/common/nameof"
import { AgentValues } from "../../../../models/agent"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { formTranslation } from "../../../../locales/form"
import { WithT } from "i18next"
import { channelTypes } from "../../../ChannelTypeForm/ChannelTypeForm"
import { overrideChannelType } from "./PsbBssAgentForm"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"

const tNamespace = "agent:form.psbbssagent."

export type OverrideChannelTypeParamType = { [key: string]: string }

export interface OverrideChannelTypeValue {
    from: string
    to: string
}

interface Props extends WithT {
    index: number
    onDelete: () => void
    disabled?: boolean
}

const OverrideChannelTypeControl: React.FC<Props> = props => {
    const { index, t, onDelete, disabled } = props

    return (
        <PairSettingsControl
            id={`formDefinition${index}`}
            keyControl={
                <DebouncedValidatableInput
                    id={`formChannelTypeFrom${index}`}
                    as="select"
                    name={`${nameof2<AgentValues, ParamValues>("Params", overrideChannelType)}[${index}].from`}
                    label={t(`${tNamespace}channel`)}
                >
                    <option value={""} hidden>
                        {t(formTranslation.select)}
                    </option>
                    {channelTypes.map(type => (
                        <option value={type} key={type}>
                            {t(`channel:channel-type.${type}`)}
                        </option>
                    ))}
                </DebouncedValidatableInput>
            }
            valueControl={
                <DebouncedValidatableInput
                    id={`formChannelTypeTo${index}`}
                    type="text"
                    name={`${nameof2<AgentValues, ParamValues>("Params", overrideChannelType)}[${index}].to`}
                    label={t(`${tNamespace}substitute-for`)}
                />
            }
            onDelete={onDelete}
            disabled={disabled}
        />
    )
}

export default OverrideChannelTypeControl
