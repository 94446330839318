export const setCaretAtStartEnd = (target: HTMLElement, isStart = false) => {
    const range = document.createRange()
    const sel = window.getSelection()

    if (!sel) {
        return
    }

    if (isStart) {
        const newText = document.createTextNode("")
        target.appendChild(newText)
        range.setStart(target.childNodes[0], 0)
    } else {
        range.selectNodeContents(target)
    }

    range.collapse(isStart)

    sel.removeAllRanges()
    sel.addRange(range)

    target.focus()
}

export function escapeRegExpCharacters(input: string): string {
    return input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

export function uncapitalize(str: string): string {
    if (str === "") {
        return str
    }

    return str.charAt(0).toLowerCase() + str.slice(1)
}
