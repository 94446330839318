import React, { MouseEventHandler } from "react"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { ButtonProps } from "react-bootstrap"
import IconButton from "../IconButton/IconButton"
import styles from "./AddButton.module.scss"

export interface AddButtonProps extends ClassProps {
    text?: string
    iconClassName?: string
    onClick: MouseEventHandler
    variant?: ButtonProps["variant"]
    disabled?: boolean
    testId?: string
}

const defaultVariant = "secondary"

const AddButton: React.FC<AddButtonProps> = props => {
    const { onClick, text, className, iconClassName, disabled, testId, variant = defaultVariant } = props

    return (
        <IconButton
            variant={variant}
            className={cn(styles.btnAdd, className)}
            onClick={onClick}
            icon={faPlus}
            iconClassName={iconClassName}
            disabled={disabled}
            testId={testId}
        >
            {text}
        </IconButton>
    )
}

export default AddButton
