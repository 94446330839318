import React, { ReactNode } from "react"
import styles from "./SidebarContextMenu.module.scss"
import { Placement } from "react-bootstrap/Overlay"
import ContextMenu from "../ContextMenu/ContextMenu"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"

interface Props extends ClassProps {
    children: ReactNode
    items: ReactNode
    placement?: Placement
    containerClassName?: string
    selected?: boolean
}

const SidebarContextMenu: React.FC<Props> = props => {
    const { children, items, placement = "auto", className, containerClassName, selected } = props
    return (
        <ContextMenu
            items={items}
            placement={placement}
            className={className}
            containerClassName={cn(
                containerClassName,
                styles.sidebarContextMenu,
                selected && styles.sidebarContextMenu
            )}
        >
            {children}
        </ContextMenu>
    )
}

export default SidebarContextMenu
