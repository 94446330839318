import React, { ReactNode } from "react"
import "./ParameterValueControl.scss"
import { CloseButton } from "react-bootstrap"

interface Props {
    title: ReactNode | string
    onDelete: () => void
}

const ParameterValueControl: React.FC<Props> = props => {
    const { title, onDelete, children } = props

    return (
        <>
            <div className="parameter-value-control">
                <span className="parameter-value-control__title">{title}</span>
                <CloseButton onClick={onDelete} />
            </div>
            {children}
        </>
    )
}

export default ParameterValueControl
