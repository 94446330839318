import { RootState } from "../rootReducer"

export const selectOperatorDialogsState = (state: RootState) => state.dialogs.operatorDialogs
export const selectDialogId = (state: RootState) => state.dialogs.selectedDialogId
export const selectDialogMessagesState = (state: RootState) => state.dialogs.messages
export const selectOperatorClientId = (state: RootState) => state.dialogs.selectedOperatorClientId
export const selectMessagesPaginationState = (state: RootState) => state.dialogs.messagesPaginationState
export const selectSearchOperatorByCriterionPrevArgs = (state: RootState) =>
    state.dialogs.searchOperatorByCriterionPrevArgs
export const selectSearchOperatorByCriterionResponse = (state: RootState) =>
    state.dialogs.searchOperatorByCriterionResponse
export const selectDialogTopicsState = (state: RootState) => state.dialogs.topics
export const selectDialogKnowledgeBaseState = (state: RootState) => state.dialogs.knowledgeBase
export const selectDialogActiveState = (state: RootState) => !!state.dialogs.selectedDialogId
export const selectMessageInput = (state: RootState) => state.dialogs.inputMessage
export const selectAIAssistHintMessage = (state: RootState) => state.dialogs.aiAssistHint
export const selectAIAssistRequestTypes = (state: RootState) => state.dialogs.aiAssistRequestTypes
export const selectAISuggest = (state: RootState) => state.dialogs.aiSuggest
export const selectSearchingState = (state: RootState) => Boolean(state.dialogs.searchCriterion)
export const selectIsKnowledgeBaseSidebarOpened = (state: RootState) =>
    state.dialogs.knowledgeBase.isKnowledgeBaseSidebarOpened
export const selectIsArticleSidebarOpened = (state: RootState) => state.dialogs.knowledgeBase.isArticleSidebarOpened
export const selectLastMessageChannel = (state: RootState) => state.dialogs.lastMessageChannel
