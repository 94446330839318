import useSidebar, { SidebarContentBase } from "../../utility/common/useSidebar"
import React, { useCallback } from "react"
import { onChooseAnswerFunc } from "../../types/knowledgeBaseFunctions"
import { TFunction } from "i18next"
import ArticleAnswers from "../../components/ArticleAnswers/ArticleAnswers"

export enum SidebarContentType {
    chooseAnswer
}

interface ChooseAnswer extends SidebarContentBase {
    type: SidebarContentType.chooseAnswer
    onSidebarClose: () => void
    isEdit?: boolean
    isOpenAddForm?: boolean
    onSelectAnswer?: (answerId: string) => void
}

export type SharedKnowledgeBaseSidebarContent = ChooseAnswer

const tKnowledgeBaseNamespace = "knowledgeBase:"

export default function useSharedKnowledgeBaseSidebar(t: TFunction) {
    const { handleChangeSidebarContent, closeSidebar, sidebarContent, sidebarClosed } =
        useSidebar<SharedKnowledgeBaseSidebarContent>()

    const handleChooseAnswer = useCallback<onChooseAnswerFunc>(
        (isEdit?: boolean, isOpenAddForm?: boolean, onSelectAnswer?: (answerId: string) => void) => {
            handleChangeSidebarContent({
                type: SidebarContentType.chooseAnswer,
                title: t(`${tKnowledgeBaseNamespace}answers-modal.title`),
                onSidebarClose: closeSidebar,
                isEdit,
                isOpenAddForm,
                onSelectAnswer
            })
        },
        [closeSidebar, handleChangeSidebarContent, t]
    )

    const renderSidebarContent = () => {
        if (!sidebarContent) return null

        switch (sidebarContent.type) {
            case SidebarContentType.chooseAnswer:
                return (
                    <ArticleAnswers
                        isEdit={sidebarContent.isEdit}
                        isOpenAddForm={sidebarContent.isOpenAddForm}
                        onSelectAnswer={sidebarContent.onSelectAnswer}
                    />
                )
        }
    }

    return {
        sidebarClosed,
        sidebarContent,
        closeSidebar,
        renderSidebarContent,
        handleChooseAnswer
    }
}
