import { ColorSchema } from "./projectSettings"

export interface FrontendConfig {
    WebConfig: Config
}

export interface ThemeSettings {
    fontPath: string
    fontName: string
    faviconPath: string
    loginIcon: string
    fontPrefetch: boolean
    instanceTitle: string
    fallbackColorSchema: ColorSchema
}

export interface Config {
    isDefault: boolean
    appSettings: AppSettings
    queue: QueueConfig
    webChat: WebChatConfig
    themeSettings: ThemeSettings
}

interface AppSettings {
    signUpEnabled: boolean
    inviteSignUpEnabled: boolean
    selfSignUpEnabled: boolean
    authMode: "cookie" | "oidc"
    customHtmlBody?: string
    externalScript?: string
    useAllTransportSignalR?: boolean
}

interface QueueConfig {
    tenantId: string
}

interface WebChatConfig {
    advancedSettingsEnabled: boolean
}

export const defaultWebConfig: Config = {
    isDefault: true,
    appSettings: {
        inviteSignUpEnabled: true,
        selfSignUpEnabled: false,
        signUpEnabled: true,
        authMode: "cookie"
    },
    queue: {
        tenantId: "cloud"
    },
    webChat: {
        advancedSettingsEnabled: false
    },
    themeSettings: {
        fontPath: "",
        fontName: "Ubuntu",
        faviconPath: "",
        loginIcon: "",
        instanceTitle: "",
        fontPrefetch: false,
        fallbackColorSchema: {
            ColorSchemaUsing: false,
            ColorSchemaBaseColor: "",
            ColorSchemaEditorColor: "",
            ColorSchemaLinkColor: ""
        }
    }
}

export const defaultConfig: FrontendConfig = {
    WebConfig: defaultWebConfig
}
