import React from "react"
import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import Spinner from "../Spinner/Spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import "./CategoryAction.scss"

const tooltipDelay = { show: 450, hide: 0 }

interface Props {
    id: string
    tooltipText?: string
    icon?: IconDefinition
    onClick?: () => void
    iconSize?: SizeProp
    loading?: boolean
    testId?: string
}

const CategoryAction: React.FC<Props> = props => {
    const { id, tooltipText, icon, onClick, iconSize = "1x", loading, children, testId } = props

    const categoryAction = (
        <div className="category-action" onClick={onClick} data-test-id={testId}>
            {loading ? (
                <Spinner className="category-action__spinner" />
            ) : icon ? (
                <FontAwesomeIcon icon={icon} size={iconSize} className="category-action__icon" />
            ) : (
                children
            )}
        </div>
    )

    if (tooltipText) {
        return (
            <TooltipTrigger id={id} placement="top" content={tooltipText} delay={tooltipDelay}>
                {categoryAction}
            </TooltipTrigger>
        )
    }

    return categoryAction
}

export default CategoryAction
