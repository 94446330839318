import { Dispatch, ActionResult } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import controller from "../../api/controllers/operatorStatus"
import { usersHub, usersHubV2 } from "../../api/instances"
import { actions } from "./slice"
import { handleHttpException, handleHttpExceptionWithoutAction } from "../handleHttpException"
import {
    OperatorStatusChange,
    operatorStatusChangeReasons,
    OperatorStatusChangeWithReason,
    OperatorStatusValue
} from "../../models/operatorStatus"
import { SelectedOperatorStatusDto } from "../../models/operatorStatusDto"
import { RootState } from "../rootReducer"
import { dialogsApi } from "../../api/controllers/dialogs"

export const subscribeOnUsersData = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    try {
        await usersHub.connect()
        await usersHubV2.connect()
        dispatch(actions.setConnected())
    } catch (e) {
        handleHttpExceptionWithoutAction(e, constants.CONNECTED_FAILED_MESSAGE, dispatch)
    }
}

export const unsubscribeFromUsersData = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    try {
        await usersHub.disconnect()
        await usersHubV2.disconnect()
        dispatch(actions.setDisconnected())
    } catch (e) {
        handleHttpExceptionWithoutAction(e, constants.DISCONNECT_HUB_FAILED_MESSAGE, dispatch)
    }
}

export const updateOperatorStatus =
    (change: OperatorStatusChange): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(
            actions.updateStatusProcess({
                retryCallback: () => dispatch(getOperatorStatus()),
                retryAfterMs: 5000
            })
        )

        if (change.Value === OperatorStatusValue.Offline) {
            dispatch(dialogsApi.util.upsertQueryData("getDialogBadges", undefined, []))
        }

        try {
            const changeWithReason: OperatorStatusChangeWithReason = {
                ...change,
                Reason: operatorStatusChangeReasons.userClick
            }
            await controller.update(changeWithReason)
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_STATUS_FAILED_MESSAGE,
                err => actions.updateStatusFailed(err),
                dispatch
            )
        }
    }

export const setOperatorStatusUpdated =
    (updatedStatus: SelectedOperatorStatusDto): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const state = getState()
            dispatch(
                actions.updateStatusSuccess({ CurrentStatus: updatedStatus, Statuses: state.userOperator.statuses })
            )
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_STATUS_FAILED_MESSAGE,
                err => actions.updateStatusFailed(err),
                dispatch
            )
        }
    }

export const getOperatorStatus = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(actions.updateStatusProcess())
    try {
        const response = await controller.get()
        dispatch(actions.updateStatusesSuccess(response))
    } catch (e) {
        handleHttpException(e, constants.GET_STATUS_FAILED_MESSAGE, err => actions.updateStatusFailed(err), dispatch)
    }
}
