import { RootState } from "../rootReducer"
import { getItemById } from "../../utility/common/storeHelper"
import { MenuItem } from "../../models/menu"

export const selectMainMenu = (state: RootState) => state.menu.mainMenu.data
export const selectMainMenuItem = (state: RootState, mainMenuItemId: string) =>
    state.menu.mainMenu.data && getItemById<MenuItem>(state.menu.mainMenu.data.items, mainMenuItemId)
export const selectCurrentMainMenu = (state: RootState) => state.menu.selectedMainMenu
export const selectGetMainMenuState = (state: RootState) => state.menu.mainMenu
export const selectGetSidebarMenuState = (state: RootState, id: string) =>
    state.menu.selectedSidebarMenu.find(s => s.id === id)
