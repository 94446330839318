import { ClassifierStatus } from "./classifier"

export interface ClassifierEventDto {
    EventId: string
    ClassifierId: string
    ClassifierConfigId: string
    ProjectId: string
    EventType: ClassifierEventType
    NewStatus?: ClassifierStatus
    Metrics?: ModelMetrics
    Error?: string
    TriggeredByUser?: Initiator
    ReceivedAt: string
}

export interface ModelMetrics {
    Accuracy: number
    Precision: number
    Recall: number
    F1: number
    NumIntents: number
    NumSamples: number
    ConfusionOther: string
    ConfusionNoOther: string
}

export interface Initiator {
    Login: string
    FirstName: string
    LastName: string
}

export enum ClassifierEventType {
    StartingRequested = "StartingRequested",
    RollbackRequested = "RollbackRequested",
    Starting = "Starting",
    StartingFailed = "StartingFailed",
    ActivationRequested = "ActivationRequested",
    Activated = "Activated",
    DeactivationRequested = "DeactivationRequested",
    Deactivated = "Deactivated",
    Loading = "Loading",
    LoadingFailed = "LoadingFailed",
    Training = "Training",
    TrainingFailed = "TrainingFailed",
    Metrics = "Metrics",
    MetricsFailed = "MetricsFailed",
    Done = "Done",
    DoneFailed = "DoneFailed"
}

export interface ClassifierModelDto {
    ClassifierId: string
    CustomerId: string
    LearnedAt: string
    LearningStartedAt: string
    LearningStartedByUser?: Initiator
    Metrics?: ModelMetrics
    UsedConfigs: UsedConfig[]
}

export interface ClassifierModels {
    [configId: string]: ClassifierModelDto[]
}

export interface GetClassifierModelsSuccessPayload {
    configId: string
    models: ClassifierModelDto[]
}

interface UsedConfig {
    ConfigId: string
}

export interface UpdateClassifierStatusPayload {
    ClassifierConfigId: string
    NewStatus: ClassifierStatus
}
