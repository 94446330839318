import {
    AdditionalSlotQuestionDto,
    CreateSlotRequest,
    SlotDomainValueDto,
    SlotDto,
    SlotType,
    SlotValues,
    SynonymDto,
    UpdateSlotRequest
} from "../../models/slot"
import { v4 as uuidV4 } from "uuid"

const typesWithRegex = [SlotType.Regex, SlotType.RegexMatch, SlotType.Attachment]

export const checkIsRegex = (type: SlotType) => typesWithRegex.includes(type)

export const emptyDomainValue = () => ({
    Id: "",
    PermanentId: uuidV4(),
    Title: "",
    Synonyms: [],
    SynonymIds: [],
    IsEmpty: true
})

export const getDefaultSlotValues = (): SlotValues => ({
    Title: "",
    ExternalId: "",
    Type: SlotType.Text,
    Permanent: false,
    Question: "",
    FailedValidationQuestion: "",
    Prefill: true,
    DontShowButtons: false,
    SkipClassification: false,
    ShowCancelButton: false,
    UsesMultipleValues: false,
    NextValueQuestion: "",
    Regex: "",
    RegexId: "",
    Domain: [emptyDomainValue()],
    UseExternalDomainSource: false,
    CalendarQuestions: {
        DatetimeFailedQuestion: "",
        TimeQuestion: "",
        NoScheduleQuestion: ""
    }
})

export const DATETIME_FAILED_QUESTION_ID = "datetime_failed_question"
export const TIME_QUESTION_ID = "time_question"
export const NO_SCHEDULE_QUESTION_ID = "no_schedule_question"

export const getAdditionalQuestion = (id: string, questions?: AdditionalSlotQuestionDto[]) =>
    questions?.find(q => q.Id === NO_SCHEDULE_QUESTION_ID)?.Question.Text ?? ""

export const getInitialValues = (slot?: SlotDto): SlotValues => {
    const defaultValues = getDefaultSlotValues()
    if (!slot) return defaultValues

    const regexDomain = (slot?.Domain && slot.Domain[0]) ?? { Id: "", Title: "" }
    const isWithRegex = checkIsRegex(slot.Type)

    const values: SlotValues = {
        Title: slot.Title,
        ExternalId: slot.ExternalId,
        Type: slot.Type,
        Permanent: slot.Permanent ?? defaultValues.UseExternalDomainSource,
        Question: slot.Question?.Text ?? "",
        FailedValidationQuestion: slot.FailedValidationQuestion?.Text ?? "",
        Prefill: slot.Prefill ?? defaultValues.Prefill,
        DontShowButtons: slot.DontShowButtons ?? defaultValues.DontShowButtons,
        SkipClassification: slot.SkipClassification ?? defaultValues.SkipClassification,
        ShowCancelButton: slot.ShowCancelButton ?? defaultValues.ShowCancelButton,
        UsesMultipleValues: slot.UsesMultipleValues ?? defaultValues.UsesMultipleValues,
        NextValueQuestion: slot.NextValueQuestion?.Text ?? "",
        CalendarQuestions: {
            DatetimeFailedQuestion: getAdditionalQuestion(DATETIME_FAILED_QUESTION_ID, slot.AdditionalQuestions),
            TimeQuestion: getAdditionalQuestion(TIME_QUESTION_ID, slot.AdditionalQuestions),
            NoScheduleQuestion: getAdditionalQuestion(NO_SCHEDULE_QUESTION_ID, slot.AdditionalQuestions)
        },
        Regex: isWithRegex ? regexDomain.Title : "",
        RegexId: isWithRegex ? regexDomain.Id : "",
        Domain: isWithRegex
            ? []
            : (slot.Domain ?? []).map(v => ({
                  Id: v.Id,
                  PermanentId: uuidV4(),
                  Title: v.Title,
                  Synonyms: v.Synonyms?.map(s => s.Value) ?? [],
                  SynonymIds: v.Synonyms?.map(s => s.Id) ?? []
              })),
        UseExternalDomainSource: slot.UseExternalDomainSource ?? defaultValues.UseExternalDomainSource
    }

    values.Domain.push(emptyDomainValue())
    return values
}

export const buildCreateSlotRequest = (values: SlotValues): CreateSlotRequest => {
    const request = buildUpdateSlotRequest(values) as CreateSlotRequest
    request.ExternalId = values.ExternalId

    return request
}

export const buildUpdateSlotRequest = (values: SlotValues): UpdateSlotRequest => {
    const slotType = values.Type

    const request: UpdateSlotRequest = {
        Title: values.Title,
        Type: slotType,
        Permanent: values.Permanent,
        Question: {
            Text: values.Question
        },
        FailedValidationQuestion: {
            Text: values.FailedValidationQuestion
        },
        Prefill: values.Prefill,
        DontShowButtons: values.DontShowButtons,
        SkipClassification: values.SkipClassification,
        ShowCancelButton: values.ShowCancelButton,
        UsesMultipleValues: slotType === SlotType.Attachment && values.UsesMultipleValues,
        NextValueQuestion: {
            Text: slotType === SlotType.Attachment ? values.NextValueQuestion : ""
        }
    }

    if (slotType === SlotType.Dictionary) {
        request.Domain = values.Domain.filter(v => !v.IsEmpty).map<SlotDomainValueDto>(v => ({
            Id: v.Id,
            Title: v.Title,
            Synonyms: v.Synonyms.map<SynonymDto>((s, index) => ({
                Id: v.SynonymIds[index],
                Value: s
            }))
        }))
    }

    if (checkIsRegex(slotType)) {
        request.Domain = [{ Id: "regex", Title: values.Regex }]
    }

    if (slotType === SlotType.Calendar) {
        request.UseExternalDomainSource = values.UseExternalDomainSource
        request.AdditionalQuestions = []

        request.AdditionalQuestions.push({
            Id: DATETIME_FAILED_QUESTION_ID,
            Question: {
                Text: values.CalendarQuestions.DatetimeFailedQuestion
            }
        })

        request.AdditionalQuestions.push({
            Id: TIME_QUESTION_ID,
            Question: {
                Text: values.CalendarQuestions.TimeQuestion
            }
        })

        request.AdditionalQuestions.push({
            Id: NO_SCHEDULE_QUESTION_ID,
            Question: {
                Text: values.CalendarQuestions.NoScheduleQuestion
            }
        })
    }

    return request
}
