import React from "react"
import "./CheckBoxValidatableInput.scss"
import { Form } from "react-bootstrap"
import { useField } from "formik"
import cn from "classnames"
import { FormCheckProps } from "react-bootstrap/FormCheck"

export interface CheckBoxValidatableInputProps extends FormCheckProps {
    id: string
    label?: string
    name: string
    children?: never
    disabled?: boolean
    inline?: boolean
    icon?: JSX.Element
}

function CheckBoxValidatableInput<Value extends boolean>(props: CheckBoxValidatableInputProps) {
    const [field, meta] = useField<Value>(props)
    const { id, className, label, disabled, inline, icon, ...inputProps } = props

    const formLabel = (label || icon) && (
        <>
            {label}
            {icon}
        </>
    )

    return (
        <Form.Check
            {...inputProps}
            custom
            inline={inline}
            disabled={disabled}
            type="checkbox"
            id={`${id}-input`}
            label={formLabel}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            checked={field.value}
            isInvalid={meta.touched && !!meta.error}
            className={cn("checkbox-input", className)}
        />
    )
}

export default CheckBoxValidatableInput
