import React from "react"
import "./ChooseConditionForm.scss"
import { useTranslation } from "react-i18next"
import { OpenConditionFormCallback } from "../ScenarioEditor/ScenarioContext"
import { Formik } from "formik"
import FormikChooseConditionsForm from "./FormikChooseConditionsForm"
import * as Yup from "yup"
import { formTranslation } from "../../locales/form"
import { BlockTypeWithConditions } from "../../models/scenario"
import { Condition, ConditionsFormValues, ConditionStatementType } from "../../models/scenarioCondition"
import {
    conditionsWithValues,
    getConditionValues,
    processConditionsFormValues
} from "../../utility/scenario/scenarioConditions"

export interface ChooseConditionsFormProps {
    projectId?: string
    onSelect: () => void
    onSubmit: OpenConditionFormCallback
    blockType: BlockTypeWithConditions
    condition?: Condition
}

const ChooseConditionsForm: React.FC<ChooseConditionsFormProps> = props => {
    const { t } = useTranslation()
    const { onSelect, onSubmit, condition } = props

    const handleSubmit = (values: ConditionsFormValues) => {
        const conditions = processConditionsFormValues(values.Conditions)
        onSelect()
        onSubmit(conditions)
    }

    const initialValues = {
        Conditions: getConditionValues(condition)
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values: ConditionsFormValues) => {
                if (values.Conditions.length) {
                    handleSubmit(values)
                }
            }}
            validationSchema={Yup.object().shape({
                Conditions: Yup.array().of(
                    Yup.object().shape({
                        Value: Yup.string().when(["Type", "Slot"], {
                            is: (Type, Slot) =>
                                !conditionsWithValues.includes(Slot) && Type === ConditionStatementType.Value,
                            then: Yup.string().requiredExcludeEmpty(formTranslation.enterText)
                        })
                    })
                )
            })}
        >
            {formikProps => <FormikChooseConditionsForm {...formikProps} {...props} t={t} />}
        </Formik>
    )
}

export default ChooseConditionsForm
