import React from "react"
import { Col, ColProps, FormGroup, Row } from "react-bootstrap"
import InputLabel, { InputLabelSharedProps } from "../InputLabel/InputLabel"
import { ClassProps } from "../../../utility/common/props"
import { FormGroupProps } from "react-bootstrap/FormGroup"

export interface HorizontalInputGroupProps extends InputLabelSharedProps {
    inputCol: ColProps
    labelCol?: ColProps
}

const HorizontalInputGroup: React.FC<HorizontalInputGroupProps & FormGroupProps & ClassProps> = props => {
    const { inputCol, labelCol, children, className, controlId, ...inputLabelProps } = props

    return (
        <FormGroup className={className} controlId={controlId} as={Row}>
            <InputLabel {...inputLabelProps} col={labelCol} column />
            <Col {...inputCol}>{children}</Col>
        </FormGroup>
    )
}

export default HorizontalInputGroup
