import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const tNamespace = "knowledgeBase:"

function useDocumentTitle(title?: string): void {
    const { t } = useTranslation()

    useEffect(() => {
        const defaultTitle = document.title
        document.title = (title ?? defaultTitle) || t(`${tNamespace}untitled`)
        return () => {
            document.title = defaultTitle
        }
    }, [t, title])
}

export default useDocumentTitle
