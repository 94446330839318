import { Suspense } from "react"
import {
    injectFontFaceFamilyOverAttach,
    injectFontFaceFamilyOverFetch,
    injectGoogleFontFamily
} from "../../helpers/fonts"
import { SuspensableImage } from "../../helpers/image"
import Spinner from "../../components/Spinner/Spinner"
import { ColorSchema } from "../../models/projectSettings"

export class ThemingEngine {
    public static cssVarsBOM = {
        fontName: "--font-family-sans-serif"
    }

    public static cssSelectors = {
        favicon: "link[rel~='icon']"
    }

    public static installFontByName(name: string) {
        const root = document.querySelector<HTMLElement>(":root")

        if (!root) {
            return
        }

        root.style.setProperty(ThemingEngine.cssVarsBOM.fontName, name)
    }

    public static async installGoogleFont(name: string) {
        try {
            await injectGoogleFontFamily(name)
        } catch (e: unknown) {}

        this.installFontByName(name)
    }

    public static async installFontFace(name: string, path: string, prefetch?: boolean) {
        if (prefetch) {
            await injectFontFaceFamilyOverFetch(name, path)
        } else {
            await injectFontFaceFamilyOverAttach(name, path)
        }

        this.installFontByName(name)
    }

    public static swapFavicon(path: string) {
        return new Promise((resolve, reject) => {
            let link = document.querySelector<HTMLLinkElement>(ThemingEngine.cssSelectors.favicon)

            if (!link) {
                link = document.createElement("link")
                link.rel = "icon"
                document.getElementsByTagName("head")[0].appendChild(link)
            }

            if (link.href !== path) {
                link.href = path
            }

            link.onload = () => resolve(link)
            link.onerror = () => reject(new Error(`Style load error for ${link?.href}`))
        })
    }

    public static swapTitle(title: string) {
        return new Promise((resolve, reject) => {
            document.title = title
            document.onload = () => resolve(document)
            document.onerror = () => reject(new Error(`Style load error for document title`))
        })
    }

    public static setThemeColors(colorSchema: ColorSchema) {
        document.documentElement.style.setProperty("--primary", colorSchema.ColorSchemaBaseColor)
        document.documentElement.style.setProperty("--link", colorSchema.ColorSchemaLinkColor)
        document.documentElement.style.setProperty("--editor", colorSchema.ColorSchemaEditorColor)
    }

    public static renderImage(path: string, fallback = <Spinner size={24} />) {
        return (
            <Suspense fallback={fallback}>
                <SuspensableImage src={path} />
            </Suspense>
        )
    }
}
