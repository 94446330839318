import { isKnowledgeBaseAccessType, KnowledgeBaseAccessType } from "./knowledgeBasePermission"
import { Queue, QueueDto } from "./queue"
import { ItemId } from "@atlaskit/tree/types"
import { isArrayOfType } from "../types/isArrayOfType"

export enum AllowedActionDto {
    None,
    View,
    Modify
}

export type AllowedRightsDto = {
    Id: string
    Action: AllowedActionDto
    Type: KnowledgeBaseAccessType
    ProjectId: string
}

export function isAllowedRightsDto(obj: unknown): obj is AllowedRightsDto {
    if (typeof obj !== "object" || obj === null) {
        return false
    }

    const { Id, Action, Type, ProjectId } = obj as Partial<AllowedRightsDto>

    return (
        typeof Id === "string" &&
        typeof Action === "number" &&
        Object.values(AllowedActionDto).includes(Action) &&
        isKnowledgeBaseAccessType(Type) &&
        typeof ProjectId === "string"
    )
}

export type QueueCategory = {
    Id: string
    Name: string
    Queues: Queue[]
    Access: AllowedRightsDto[]
}

export type QueueCategoryDto = {
    Id: string
    Name: string
    Queues: QueueDto[]
    Access: AllowedRightsDto[]
}

export type QueueCategoryWithoutQueues = Omit<QueueCategory, "Queues">

export function isQueueCategoryWithoutQueues(value: unknown): value is QueueCategoryWithoutQueues {
    if (typeof value !== "object" || value === null) {
        return false
    }

    const category = value as Partial<QueueCategoryWithoutQueues>

    return (
        "Id" in category &&
        typeof category.Id === "string" &&
        "Name" in category &&
        typeof category.Name === "string" &&
        "Access" in category &&
        isArrayOfType(category.Access, isAllowedRightsDto)
    )
}

export type ToggleQueueCategoryPayload = {
    ToggleCategoryId: ItemId
    IsExpanded: boolean
}

export interface DeleteQueuePayload {
    queueId: string
    categoryId: string
}

export interface GetQueueCategoriesResponse {
    AllCategories: QueueCategory[]
    TaskOnBotsCategoryId?: string
}

export interface CreateQueueCategoryRequest {
    Name: string
    Queues: string[]
    Access: AllowedRightsDto[]
}

export interface CreateQueueCategoryResponse {
    Category: QueueCategory
}

export interface UpdateQueueCategoryRequest {
    Name: string
    Queues: string[]
    Access: AllowedRightsDto[]
}

export interface UpdateQueueCategoryResponse {
    Category: QueueCategory
}

export interface MoveQueueRequest {
    QueueIds: string[]
}

export interface MoveQueueResponse {
    CategorySource: QueueCategory
    CategoryDestination?: QueueCategory
}

export interface QueueCategoryRemoveResponse {
    CategoryId: string
}
