import React, { useCallback } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectSearchArticlesState } from "../../store/knowledgeBase/selectors"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Async from "../Async/Async"
import SearchQueryArticles from "../SearchQueryArticles/SearchQueryArticles"
import { useRouteMatch } from "react-router-dom"
import { push } from "connected-react-router"
import { buildArticleActionUrl, buildSharedArticleActionUrl } from "../../utility/knowledgeBase/articleUrlHelper"
import { ArticleAction } from "../../models/article"
import SearchQueryArticlesLoader from "./SearchQueryArticlesLoader"
import { clearCurrentBranch } from "../../store/knowledgeBase/slice"
import { CatalogPlacement } from "../Catalogs/Catalogs"

interface Props {
    shareId?: string
    catalogPlacement?: CatalogPlacement
    onArticleClick?: (code: string) => void
}

const SearchQueryArticlesContainer: React.FC<Props> = props => {
    const { shareId, catalogPlacement, onArticleClick } = props
    const { url } = useRouteMatch()
    const dispatch = useDispatch()
    const searchArticlesState = useSelector(selectSearchArticlesState, shallowEqual)

    const handleArticleClick = useCallback(
        (articleCode: string) => {
            dispatch(clearCurrentBranch())
            if (shareId) {
                dispatch(push(buildSharedArticleActionUrl(url, articleCode)))
            } else {
                if (catalogPlacement === CatalogPlacement.workplace) {
                    onArticleClick && onArticleClick(articleCode)
                } else {
                    dispatch(push(buildArticleActionUrl(url, ArticleAction.View, articleCode)))
                }
            }
        },
        [dispatch, shareId, url, onArticleClick, catalogPlacement]
    )

    return (
        <Async
            dataState={searchArticlesState}
            processView={<SearchQueryArticlesLoader />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: { Articles, Highlights, Scores } }) => (
                <SearchQueryArticles
                    articles={Articles}
                    highlights={Highlights}
                    scores={Scores}
                    onArticleClick={handleArticleClick}
                />
            )}
        </Async>
    )
}

export default SearchQueryArticlesContainer
