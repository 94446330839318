import React from "react"
import CopyInput from "../CopyInput/CopyInput"
import styles from "./CreateSharingLinkForm.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import LoadingButton from "../LoadingButton/LoadingButton"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"

export interface CreateSharingLinkFormProps extends ClassProps {
    id: string
    title: string
    value?: string
    onCreate: () => void
    onDelete: () => void
    loading?: boolean
    deleteLoading?: boolean
    showWarning?: boolean
    warningTitle?: string
    warningMessage?: string
}

function CreateSharingLinkForm(props: CreateSharingLinkFormProps) {
    const {
        id,
        value,
        title,
        warningTitle = "",
        warningMessage,
        onCreate,
        onDelete,
        className,
        loading = false,
        deleteLoading = false,
        showWarning = true
    } = props
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            title={warningTitle}
            message={warningMessage}
            onSubmit={handleDelete}
            show={modalOpen}
            onClose={closeModal}
            onExited={onExited}
            variant="danger"
        />
    ))

    const handleCreate = () => {
        if (!value || !loading) {
            onCreate()
        }
    }

    const handleDelete = () => {
        if (value) {
            onDelete()
        }
    }

    const getTopButton = () => {
        if (!value) {
            return (
                <LoadingButton
                    as="div"
                    loading={loading}
                    variant="outline-light"
                    onClick={handleCreate}
                    className={styles.createSharingLinkForm__addButton}
                    testId="add-catalog-sharing-link"
                >
                    <FontAwesomeIcon icon={faPlus} />
                </LoadingButton>
            )
        }
        return null
    }

    return (
        <div className={cn(styles.createSharingLinkForm, className)}>
            <div className={styles.createSharingLinkForm__title}>
                <label className={styles.createSharingLinkForm__label}>{title}</label>
                <div>{getTopButton()}</div>
            </div>
            {value && (
                <CopyInput
                    id={id}
                    value={value}
                    onDelete={showWarning ? openModal : handleDelete}
                    deleteLoading={deleteLoading}
                />
            )}
        </div>
    )
}

export default CreateSharingLinkForm
