import { GetUsersByLoginsRequest, SimpleUser, UpdateUserRequest, User, UserType } from "../../models/user"
import { apiInstance, apiInstanceRemoteWorkplace, apiInstanceV1 } from "../instances"
import { FileUpload } from "../../models/file"
import { ProjectUser, UpdateProjectUserRequest } from "../../models/projectUser"
import { GlobalUser, UpdateGlobalUserRequest } from "../../models/globalUser"
import { processUser } from "../../utility/common/processUser"
import { PaginationRequest, PaginationResponse } from "../../models/pagination"

const usersController = {
    update: (request: UpdateUserRequest): Promise<User> =>
        apiInstance
            .put("/users", request)
            .then(response => response.data)
            .then(processUser),
    updateAvatar: (avatar?: FileUpload<Blob>): Promise<User> => {
        const formData = new FormData()
        if (avatar) {
            formData.append("avatar", avatar.Content, avatar.Name)
        }

        return apiInstance
            .put(`user/avatar`, formData)
            .then(response => response.data)
            .then(processUser)
    },
    updateProjectUser: (projectId: string, request: UpdateProjectUserRequest): Promise<ProjectUser> =>
        apiInstance
            .put(`projects/${projectId}/users`, request)
            .then(response => response.data)
            .then(processUser),
    updateGlobalUser: (request: UpdateGlobalUserRequest): Promise<GlobalUser> =>
        apiInstance
            .put(`/users/global`, request)
            .then(response => response.data)
            .then(processUser),
    changeProject: (projectId: string): Promise<User> => {
        const config = { headers: { "Content-Type": "application/json" } }
        return apiInstance
            .post(`users/change_project`, `"${projectId}"`, config)
            .then(response => response.data)
            .then(processUser)
    },
    getUsersByLogins: (projectId: string, request: GetUsersByLoginsRequest): Promise<SimpleUser[]> => {
        return apiInstance
            .post(`projects/${projectId}/users/by-logins`, request)
            .then(response => response.data)
            .then(users => users.map(processUser))
    },
    getCurrentUser: (): Promise<User> => {
        return apiInstance
            .get("users/current")
            .then(response => response.data)
            .then(processUser)
    },
    getUsersByProject: (projectId: string): Promise<ProjectUser[]> =>
        apiInstance
            .get(`projects/${projectId}/users`)
            .then(response => response.data)
            .then(users => users.map(processUser)),
    getPaginatedUsersByProject: (
        projectId: string,
        paginationParams: PaginationRequest
    ): Promise<PaginationResponse<ProjectUser>> =>
        apiInstance
            .post(`${projectId}/users/page`, paginationParams)
            .then(response => response.data)
            .then((response: PaginationResponse<ProjectUser>) => {
                return {
                    ...response,
                    Items: response.Items.map(processUser)
                }
            }),
    getAll: (): Promise<GlobalUser[]> =>
        apiInstance
            .get(`users`)
            .then(response => response.data)
            .then(users => users.map(processUser)),
    getCurrentUserRoleId: (): Promise<string | undefined> => {
        return apiInstance.get("users/current/role_id").then(response => response.data)
    },
    getRemoteWorkplaceAuthToken: (): Promise<string> =>
        apiInstanceRemoteWorkplace.get("get-token").then(response => response.data),
    refreshRemoteWorkplaceAuthToken: (): Promise<string> =>
        apiInstanceRemoteWorkplace.post("refresh-token").then(response => response.data),
    getUserTypes: (customerId: string): Promise<UserType[]> =>
        apiInstanceV1
            .get("users/user_types", {
                params: { customer_id: customerId }
            })
            .then(response => response.data)
}

export default usersController
