import React from "react"
import { FormikAgentFormProps } from "../../AgentForm"
import { AgentValues, EmailType } from "../../../../models/agent"
import { useDispatch, useSelector } from "react-redux"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import RadioButtonValidatableInput from "../../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { ParamValues } from "../../../../models/parameterDeclaration"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import DocLink from "../../../DocLink/DocLink"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import ArticleSelect from "../../../ArticleSelect/ArticleSelect"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"

const tNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const FormikEmailAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, values, handleSubmit, disabled, ownedByThisScenario } = props

    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    return (
        <AgentFormWrapper
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
            agentType={agentType}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section agent-form__section_border">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tNamespace}title`)}
                />
                <DebouncedValidatableInput
                    id="formFrom"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "from")}
                    label={t(`${tNamespace}sender-email`)}
                    placeholder={`${t(`${tNamespace}enter-value-or-substitute`)} {{slot id}}`}
                />
                <DebouncedValidatableInput
                    id="formFromName"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "from-name")}
                    label={t(`${tNamespace}sender-name`)}
                    placeholder={`${t(`${tNamespace}enter-value-or-substitute`)} {{slot id}}`}
                />
            </div>
            <div className="agent-form__section agent-form__section_border">
                <DebouncedValidatableInput
                    id="formTo"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "to")}
                    label={t(`${tNamespace}recipient-email`)}
                    placeholder={`${t(`${tNamespace}enter-value-or-substitute`)} {{slot id}}`}
                />
                <DebouncedValidatableInput
                    id="formToName"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "to-name")}
                    label={t(`${tNamespace}recipient-name`)}
                    placeholder={`${t(`${tNamespace}enter-value-or-substitute`)} {{slot id}}`}
                />
            </div>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formSubject"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "subject")}
                    label={t(`${tNamespace}email-subject`)}
                    placeholder={`${t(`${tNamespace}enter-value-or-substitute`)} {{slot id}}`}
                />
                <RadioButtonValidatableInput
                    value={EmailType.Text}
                    id="formEmailType1"
                    name={nameof2<AgentValues, ParamValues>("Params", "email-type")}
                    label={t(`${tNamespace}email-text`)}
                />
                <div className="extra-settings">
                    <div>{t(`${tNamespace}text-type`)}</div>
                    <RadioButtonValidatableInput
                        inline
                        value="text"
                        id="formBodyType1"
                        name={nameof2<AgentValues, ParamValues>("Params", "body-type")}
                        label={t(`${tNamespace}text`)}
                        disabled={values.Params["email-type"] !== EmailType.Text}
                    />
                    <RadioButtonValidatableInput
                        inline
                        value="HTML"
                        id="formBodyType2"
                        name={nameof2<AgentValues, ParamValues>("Params", "body-type")}
                        label="HTML"
                        disabled={values.Params["email-type"] !== EmailType.Text}
                    />
                    <div className="agent-form__section">
                        <DebouncedValidatableInput
                            id="formBody"
                            as="textarea"
                            name={nameof2<AgentValues, ParamValues>("Params", "body")}
                            label={t(`${tNamespace}message-template`)}
                            className="agent-form__template-textarea"
                            disabled={values.Params["email-type"] !== EmailType.Text}
                        />
                    </div>
                </div>
                <RadioButtonValidatableInput
                    value={EmailType.Template}
                    id="formEmailType2"
                    name={nameof2<AgentValues, ParamValues>("Params", "email-type")}
                    label={t(`${tNamespace}template-article-kb`)}
                />
                <div className="extra-settings">
                    <ArticleSelect
                        id="formIntentId"
                        name={nameof2<AgentValues, ParamValues>("Params", "intent-id")}
                        disabled={values.Params["email-type"] !== EmailType.Template}
                        projectId={projectId}
                        searchBySymbolCode
                        allowSearchWithoutPermissions
                        dispatch={dispatch}
                    />
                </div>
            </div>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formSuccessMessage"
                    as="textarea"
                    name={nameof2<AgentValues, ParamValues>("Params", "success-message")}
                    label={t(`${tNamespace}success-message`)}
                    className="agent-form__message-textarea"
                    disabled={ownedByThisScenario}
                />
                <DebouncedValidatableInput
                    id="formFailureMessage"
                    as="textarea"
                    name={nameof2<AgentValues, ParamValues>("Params", "failure-message")}
                    label={t(`${tNamespace}failure-message`)}
                    className="agent-form__message-textarea"
                    disabled={ownedByThisScenario}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikEmailAgentFormGeneral
