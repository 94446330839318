import { operatorsLangParser } from "./operatorsLangParser"
import { FilledSlot } from "../../models/slot"
import { DialogPerson } from "../../models/Dialogs/dialog"

export const fillSlots = (text: string, operator: Partial<DialogPerson> | undefined, slots: FilledSlot[]) => {
    return text.replace(/{{[a-zA-Z0-9_-]*}}/g, substring => {
        const withoutBrace = substring.slice(2, -2)

        if (operator) {
            const operatorsParseResult = operatorsLangParser(operator, withoutBrace, substring)
            if (operatorsParseResult.isFulfilled) return operatorsParseResult.substring
        }

        const matchedClientSlot = slots.find(element => element.Id === withoutBrace)
        if (matchedClientSlot && matchedClientSlot.Value && matchedClientSlot.Value.length > 0) {
            return matchedClientSlot.Value
        }

        return substring
    })
}
