import React from "react"
import newProject from "../../assets/images/newProject.png"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import styles from "./ArticleBlocked.module.scss"

const tArticleBlocked = "knowledgeBase:article-blocked:"

export interface ArticleBlockedProps {
    onReloadArticle: () => void
}

const ArticleBlocked: React.FC<ArticleBlockedProps> = props => {
    const { onReloadArticle } = props

    const { t } = useTranslation()

    return (
        <div className={styles.articleBlocked}>
            <div className={styles.articleBlocked__header}>
                <h1 className={styles.articleBlocked__title}>{t(`${tArticleBlocked}title`)}</h1>
            </div>
            <div className={styles.articleBlocked__body}>
                <div className={styles.articleBlocked__message}>{t(`${tArticleBlocked}message`)}</div>
                <div className={styles.articleBlocked__image}>
                    <img src={newProject} alt="" />
                </div>
                <Button className="welcome__button" variant="primary" onClick={onReloadArticle}>
                    {t(`${tArticleBlocked}action`)}
                </Button>
            </div>
        </div>
    )
}

export default ArticleBlocked
