export enum DocType {
    Pdf = "Pdf",
    Word = "Word"
}

export interface CatalogToDocRequest {
    CatalogSymbolCode: string
    ArticleSymbolCode?: string
    Login: string
    RoleId?: string
    Type: DocType
}
