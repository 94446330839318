import React from "react"
import styles from "./NotificationCard.module.scss"
import { ClassProps } from "../../utility/common/props"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"

export interface NotificationCardProps extends ClassProps {
    message: string
    icon?: IconProp
}

const NotificationCard: React.FC<NotificationCardProps> = props => {
    const { message, icon, className } = props
    return (
        <div className={cn(styles.notificationCard, className)}>
            {icon && <FontAwesomeIcon icon={icon} size="2x" className={styles.notificationCard__icon} />}
            <span className={styles.notificationCard__message}>{message}</span>
        </div>
    )
}

export default NotificationCard
