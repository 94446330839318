import {
    ClassifierConfigDto,
    ClassifierConfigurationProcessed,
    ClassifierFormValues,
    ClassifierLanguage,
    ClassifierStatus,
    ClassifierType,
    CurrentClassifier
} from "../../models/classifier"
import useRefCheck from "../common/useRefCheck"

const defaultThreshold = 0.9

const ENABLE_BERT_CLASSIFIER_REFERENCE = "enable_bert_classifier"
const ENABLE_KNN_ELASTIC_CLASSIFIER_REFERENCE = "enable_knn_elastic_classifier"
const ENABLE_GRU_CLASSIFIER_REFERENCE = "enable_gru_classifier"
const ENABLE_KNN_CLASSIFIER_REFERENCE = "enable_knn_classifier"

export const useClassifierEnabledCheck = (type: ClassifierType) => {
    const getReference = () => {
        switch (type) {
            case ClassifierType.BERT:
                return ENABLE_BERT_CLASSIFIER_REFERENCE
            case ClassifierType.KNN_ELASTIC:
                return ENABLE_KNN_ELASTIC_CLASSIFIER_REFERENCE
            case ClassifierType.GRU:
                return ENABLE_GRU_CLASSIFIER_REFERENCE
            case ClassifierType.KNN:
                return ENABLE_KNN_CLASSIFIER_REFERENCE
            default:
                return ""
        }
    }

    const ref = getReference()
    const refCheck = useRefCheck(ref)
    return ref ? refCheck : true
}

export const mapTypeToLabel = {
    [ClassifierType.WMD]: "WMD",
    [ClassifierType.KNN]: "KNN",
    [ClassifierType.BERT]: "BERT",
    [ClassifierType.LOGREG]: "LOGREG",
    [ClassifierType.GRU]: "GRU",
    [ClassifierType.KNN_ELASTIC]: "KNN ELASTIC",
    [ClassifierType.HAN]: "HAN",
    [ClassifierType.W2V_NN]: "W2V NN",
    [ClassifierType.MATCH]: "MATCH",
    [ClassifierType.COMPOUND]: "COMPOUND",
    [ClassifierType.RULE_BASED]: "REGEXP"
}

export const getValuesFromClassifier = (classifier: ClassifierConfigurationProcessed): ClassifierFormValues => {
    return {
        ClassifierId: classifier.ClassifierId,
        ClassifierConfigId: classifier.ClassifierConfigId,
        Type: classifier.Type,
        Url: classifier.Url,
        Language: classifier.Language,
        Name: classifier.Name,
        Preprocessor: classifier.PreprocessorBuilder,
        UseSpellCorrection: classifier.UseSpellCorrection,
        DoSynonymousAugmentation: classifier.DoSynonymousAugmentation,
        SynonymousAugmentationSlots: classifier.SynonymousAugmentationSlots,
        SlotThreshold: Math.floor((classifier.SlotThreshold ?? defaultThreshold) * 100),
        PlusStopWords: classifier?.PlusStopWords ?? [],
        MinusStopWords: classifier?.MinusStopWords ?? [],
        Tags: classifier.Tags,
        ExcludeTags: classifier.ExcludeTags,
        ArticleTypes: classifier.ArticleTypes,
        ExcludeArticleTypes: classifier.ExcludeArticleTypes
    }
}

export const getDefaultValues = (
    classifierConfigId: string,
    classifierType: ClassifierType,
    language: ClassifierLanguage,
    servers?: string[]
): ClassifierFormValues => {
    return {
        ClassifierId: "",
        ClassifierConfigId: classifierConfigId,
        Type: classifierType,
        Url: servers?.length === 1 ? servers[0] : "",
        Language: language,
        Name: "",
        Preprocessor: "default",
        UseSpellCorrection: true,
        DoSynonymousAugmentation: true,
        SynonymousAugmentationSlots: [],
        SlotThreshold: defaultThreshold * 100,
        PlusStopWords: [],
        MinusStopWords: [],
        Tags: [],
        ExcludeTags: [],
        ArticleTypes: [],
        ExcludeArticleTypes: []
    }
}

export const getLinkedClassifier = (classifiers: ClassifierConfigDto[], classifier?: ClassifierConfigDto | null) => {
    switch (classifier?.Type) {
        case ClassifierType.KNN:
        case ClassifierType.GRU:
            return classifiers.find(c => c.Folder === classifier.Folder)?.ClassifierConfigId
        default:
            return undefined
    }
}

export const IsDeletable = (classifier: CurrentClassifier, linkedClassifier?: ClassifierConfigDto): boolean =>
    (classifier?.Type !== ClassifierType.GRU || !linkedClassifier) && classifier.Status === ClassifierStatus.Stopped
