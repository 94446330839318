import React, { useCallback, useState } from "react"
import "./UserManagement.scss"
import PageLayout from "../../components/PageLayout/PageLayout"
import GlobalUsersContainer from "../../components/GlobalUsers/GlobalUsersContainer"
import GlobalUserForm from "../../components/GlobalUserForm/GlobalUserForm"
import { WithTitle } from "../../utility/common/withTitle"
import { useTranslation } from "react-i18next"
import Can from "../../components/Can/Can"
import { Supervisor } from "../../permissions"
import { GlobalUser } from "../../models/globalUser"
import { errorTranslation } from "../../locales/error"
import ContentError from "../ContentError/ContentError"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"
import { GlobalUserSurvey } from "../../models/globalUserSurvey"

const tProjectNamespace = "project:"

interface UpdateGlobalUserFormContent extends WithTitle {
    user: GlobalUser
    allGlobalPermissions: string[]
    globalUserSurvey: GlobalUserSurvey
}

const UserManagement: React.FC = () => {
    const { t } = useTranslation()

    const [sidebarClosed, setSidebarClosed] = useState(true)
    const [sidebarContent, setSidebarContent] = useState<UpdateGlobalUserFormContent | null>(null)

    const closeSidebar = useCallback(() => setSidebarClosed(true), [])

    const openSidebar = useCallback(() => setSidebarClosed(false), [])

    const handleUpdateGlobalUser = (
        user: GlobalUser,
        allGlobalPermissions: string[],
        globalUserSurvey: GlobalUserSurvey
    ) => {
        closeSidebar()
        setSidebarContent({
            user,
            allGlobalPermissions,
            globalUserSurvey,
            title: t(`${tProjectNamespace}user-view`)
        })
        openSidebar()
    }

    return (
        <Can permission={Supervisor} no={() => <ContentError message={t(errorTranslation.accessDenied)} />}>
            <PageLayout isSidebarCollapsed={sidebarClosed}>
                <PageLayout.Content className="user-management">
                    <div className="user-management__header">
                        <span className="user-management__title">
                            {t(`${tProjectNamespace}${definedMenuItems.UserManagement.title}`)}
                        </span>
                    </div>
                    <GlobalUsersContainer onSettingsClick={handleUpdateGlobalUser} />
                </PageLayout.Content>
                <PageLayout.Sidebar title={sidebarContent ? sidebarContent.title : ""} onClose={closeSidebar}>
                    {sidebarContent && (
                        <GlobalUserForm
                            user={sidebarContent.user}
                            allGlobalPermissions={sidebarContent.allGlobalPermissions}
                            globalUserSurvey={sidebarContent.globalUserSurvey}
                            onClose={closeSidebar}
                        />
                    )}
                </PageLayout.Sidebar>
            </PageLayout>
        </Can>
    )
}

export default UserManagement
