import React from "react"
import { Route, RouteProps } from "react-router"
import { useRedirect } from "../../utility/common/useRedirect"
import { useAuthenticationHandling } from "../../utility/auth/useAuthenticationHandling"

interface OpenRouteProps extends RouteProps {
    preventRedirect?: boolean
}

const OpenRoute: React.FC<OpenRouteProps> = props => {
    const { preventRedirect = false } = props
    useRedirect(preventRedirect)
    useAuthenticationHandling(false)
    return <Route {...props} />
}

export default OpenRoute
