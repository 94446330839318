import React from "react"
import { ClassProps } from "../../utility/common/props"
import {
    faBell,
    faCogs,
    faCommentAltLines,
    faCommentsAlt,
    faFileSearch,
    faIdBadge,
    faPoll,
    faSignOut,
    faStop,
    faUserFriends
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"

export interface MenuIconProps extends ClassProps {
    menuId: string
    iconUrl?: string
}

const MenuIcon: React.FC<MenuIconProps> = props => {
    const { menuId, className, iconUrl } = props
    switch (menuId) {
        case definedMenuItems.Dialogs.id:
        case definedMenuItems.DialogsOld.id:
            return <FontAwesomeIcon icon={faCommentAltLines} className={className} />
        case definedMenuItems.History.id:
            return <FontAwesomeIcon icon={faCommentsAlt} className={className} />
        case definedMenuItems.KnowledgeBase.id:
        case definedMenuItems.KnowledgeBaseOld.id:
            return <FontAwesomeIcon icon={faFileSearch} className={className} />
        case definedMenuItems.Queues.id:
            return <FontAwesomeIcon icon={faUserFriends} className={className} />
        case definedMenuItems.Stats.id:
            return <FontAwesomeIcon icon={faPoll} className={className} />
        case definedMenuItems.Profile.id:
            return <FontAwesomeIcon icon={faIdBadge} className={className} />
        case definedMenuItems.NotificationList.id:
            return <FontAwesomeIcon icon={faBell} className={className} />
        case definedMenuItems.LogOut.id:
            return <FontAwesomeIcon icon={faSignOut} className={className} />
        case definedMenuItems.Settings.id:
            return <FontAwesomeIcon icon={faCogs} className={className} />
        default:
            if (iconUrl && /(http[s]?:\/\/\S+|\/\S+)/.test(iconUrl)) {
                return (
                    <img
                        height="28px"
                        width="28px"
                        src={iconUrl}
                        style={{ objectFit: "contain" }}
                        alt={`${menuId} icon`}
                    />
                )
            }
            return <FontAwesomeIcon icon={faStop} className={className} />
    }
}

export default MenuIcon
