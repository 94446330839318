import React, { useMemo } from "react"
import { AgentFormProps } from "../../AgentForm"
import { FormikProps } from "formik"
import { RestAgentValues } from "../../../../models/agent"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectAgents, selectCreateAgentState, selectUpdateAgentState } from "../../../../store/agents/selectors"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"
import { nameof } from "../../../../utility/common/nameof"
import { formTranslation } from "../../../../locales/form"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"

const tNamespace = "agent:form."

const FormikRestAgentFormAdvanced: React.FC<AgentFormProps & FormikProps<RestAgentValues> & WithT> = props => {
    const { agent, t, handleSubmit, disabled } = props

    const asyncState = useSelector(agent ? selectUpdateAgentState : selectCreateAgentState, shallowEqual)
    const agents = useSelector(selectAgents)

    const agentOptions = useMemo(() => (agents ?? []).filter(a => !agent || a.Id !== agent.Id), [agent, agents])

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="agent-form__section">
                <ValidatableInput
                    as="select"
                    id="formTitle"
                    type="text"
                    name={nameof<RestAgentValues>("NextAgent")}
                    label={t(`${tNamespace}next-agent`)}
                    disabled={!agentOptions.length}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentOptions.map(option => (
                        <option value={option.Id} key={option.Id}>
                            {option.Id}
                        </option>
                    ))}
                </ValidatableInput>
            </div>
        </AgentFormWrapper>
    )
}

export default FormikRestAgentFormAdvanced
