import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./ArticleVideo.module.scss"

const tNamespace = "knowledgeBase:article-content-editor."

export type ArticleVideoProps = {
    attrs: {
        href: string
    }
}

export const ArticleVideo = (props: ArticleVideoProps) => {
    const { t } = useTranslation()
    const {
        attrs: { href }
    } = props

    return (
        <video className={styles.articleVideo} controls>
            <source src={href} type="video/mp4" />
            <source src={href} type="video/ogg" />
            <source src={href} type="video/webm" />
            <p>{t(`${tNamespace}video-format-warning`)}</p>
        </video>
    )
}
