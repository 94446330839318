import React from "react"
import { Select } from "../../Select/Select"
import { getIn, useFormikContext } from "formik"
import { formTranslation } from "../../../locales/form"
import {
    ConditionsFormValues,
    ConditionStatementType,
    ConditionStatementValue
} from "../../../models/scenarioCondition"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { ValueType } from "react-select"
import { OptionType } from "../../AsyncSearchableInput/AsyncSearchableInput"
import { FormikErrors } from "formik/dist/types"
import { SlotsMapType } from "../../../models/slot"

interface Props {
    name: string
    conditionStatement: ConditionStatementValue
    slotsMap: SlotsMapType
    invalid?: boolean
    errors: FormikErrors<ConditionsFormValues>
}

const DictionarySlotSelect: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { name, conditionStatement, slotsMap, errors } = props
    const { values, setFieldValue } = useFormikContext<ConditionsFormValues>()

    const handleSelect = (name: string) => (option: ValueType<OptionType, false>) => {
        setFieldValue(name, option?.value ?? "", false)
    }

    const value = getIn(values, `${name}.Value`)
    const domainMap = slotsMap[conditionStatement.Slot]?.DomainMap

    return (
        <Form.Group controlId={`dictionarySlotSelect${name}`}>
            <Select
                value={{
                    label: domainMap[value]?.Title ?? t(formTranslation.select),
                    value
                }}
                isDisabled={
                    conditionStatement.Type !== ConditionStatementType.Value ||
                    !slotsMap[conditionStatement.Slot].Domain?.length
                }
                options={(slotsMap[conditionStatement.Slot]?.Domain ?? []).map(d => ({
                    label: d.Title,
                    value: d.Id
                }))}
                noOptionsMessage={() => t(formTranslation.noResultsFound)}
                onChange={handleSelect(`${name}.Value`)}
                invalid={getIn(errors, `${name}.Value`)}
            />
        </Form.Group>
    )
}

export default DictionarySlotSelect
