import React from "react"
import { ClassProps } from "../../utility/common/props"
import {
    faBallot,
    faBox,
    faClipboardCheck,
    faCommentLines,
    faFileAlt,
    faNetworkWired,
    faComment
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { ScenarioBlockType } from "../../models/scenario"

interface Props extends ClassProps {
    type: ScenarioBlockType
    size?: SizeProp
}

const BlockIcon: React.FC<Props> = props => {
    const { type, className, size } = props

    switch (type) {
        case ScenarioBlockType.Message:
            return <FontAwesomeIcon icon={faCommentLines} className={className} size={size} />
        case ScenarioBlockType.Agent:
            return <FontAwesomeIcon icon={faBox} className={className} size={size} />
        case ScenarioBlockType.Article:
            return <FontAwesomeIcon icon={faFileAlt} className={className} size={size} />
        case ScenarioBlockType.Form:
            return <FontAwesomeIcon icon={faBallot} className={className} size={size} />
        case ScenarioBlockType.Condition:
            return <FontAwesomeIcon icon={faNetworkWired} className={className} size={size} />
        case ScenarioBlockType.Command:
            return <FontAwesomeIcon icon={faClipboardCheck} className={className} size={size} />
        case ScenarioBlockType.Channel:
            return <FontAwesomeIcon icon={faComment} className={className} size={size} />
        default:
            return null
    }
}

export default BlockIcon
