import {
    ClassifierConfigDto,
    ClassifierConfigDtoWithoutId,
    ClassifierConfigurationProcessed,
    ClassifierFormValues,
    ClassifierStatus,
    CreateClassifierRequest,
    DatasetFilter,
    DatasetFilterKind,
    TypeForTraining,
    UpdateClassifierRequest,
    ClassifierFilterValueType,
    ClassifierFilterCompareKind
} from "../../models/classifier"
import { ArticleStatusType } from "../../models/article"

export const getDatasetFilters = (
    values: ClassifierFormValues,
    classifier?: ClassifierConfigurationProcessed
): DatasetFilter[] => {
    const fields = [
        ...values.ArticleTypes.map(type => ({
            FieldName: ClassifierFilterValueType.RecordType,
            Value: type,
            Compare: ClassifierFilterCompareKind.Fnmatch
        })),
        ...values.ExcludeArticleTypes.map(type => ({
            FieldName: ClassifierFilterValueType.RecordType,
            Value: type,
            Compare: ClassifierFilterCompareKind.Ne
        })),
        ...values.Tags.map(type => ({
            FieldName: ClassifierFilterValueType.Tags,
            Value: type,
            Compare: ClassifierFilterCompareKind.Fnmatch
        })),
        ...values.ExcludeTags.map(type => ({
            FieldName: ClassifierFilterValueType.Tags,
            Value: type,
            Compare: ClassifierFilterCompareKind.Ne
        }))
    ]

    return [
        ...(classifier?.DatasetFilters || []),
        {
            FilterKind: DatasetFilterKind.ByFieldValue,
            ElasticType: TypeForTraining.IntentType,
            Fields: fields
        }
    ]
}

export const buildClassifierConfigDto = (
    projectId: string,
    values: ClassifierFormValues,
    classifier?: ClassifierConfigurationProcessed
): ClassifierConfigDtoWithoutId => {
    return {
        ClassifierConfigId: values.ClassifierConfigId,
        Name: values.Name,
        ProjectId: projectId,
        Url: values.Url,
        UseSpellCorrection: values.UseSpellCorrection,
        DoSynonymousAugmentation: values.DoSynonymousAugmentation,
        SynonymousAugmentationSlots: values.SynonymousAugmentationSlots,
        ...(values.DoSynonymousAugmentation && { SlotThreshold: values.SlotThreshold / 100 }),
        PlusStopWords: values.PlusStopWords,
        MinusStopWords: values.MinusStopWords,
        PreprocessorBuilder: values.Preprocessor,
        Language: values.Language,
        TypesForTraining: classifier?.TypesForTraining ?? [TypeForTraining.IntentType, TypeForTraining.MessageType],
        Type: values.Type,
        Status: classifier?.Status ?? ClassifierStatus.Stopped,
        Active: classifier?.Active ?? false,
        ...(classifier?.CompoundClassifiers && { CompoundClassifiers: classifier.CompoundClassifiers }),
        DatasetFilters: getDatasetFilters(values, classifier),
        ChangedAt: new Date().toISOString(),
        Version: classifier?.Version.toString() ?? "0"
    }
}

export const buildCreateClassifierRequest = (
    projectId: string,
    values: ClassifierFormValues
): CreateClassifierRequest => {
    return {
        Classifier: buildClassifierConfigDto(projectId, values)
    }
}

export const buildUpdateClassifierRequest = (
    projectId: string,
    values: ClassifierFormValues,
    classifier: ClassifierConfigurationProcessed
): UpdateClassifierRequest => {
    return {
        Classifier: {
            ClassifierId: classifier.ClassifierId,
            ...buildClassifierConfigDto(projectId, values, classifier)
        }
    }
}

export const processFilters = (filters: DatasetFilter[]) => {
    const checkType = (filter: DatasetFilter) => {
        return filter.FilterKind === DatasetFilterKind.ByFieldValue && filter.ElasticType === TypeForTraining.IntentType
    }

    const otherFilters: DatasetFilter[] = []
    const includeTypes: string[] = []
    const excludeTypes: string[] = []
    const tags: string[] = []
    const excludeTags: string[] = []
    let isDraftArticles: boolean | null = null

    filters.forEach(filter => {
        if (!checkType(filter)) {
            otherFilters.push(filter)
            return
        }

        if (!filter.Fields) return

        filter.Fields.forEach(field => {
            switch (field.FieldName) {
                case ClassifierFilterValueType.RecordType:
                    switch (field.Compare) {
                        case ClassifierFilterCompareKind.Fnmatch:
                            includeTypes.push(field.Value)
                            break
                        case ClassifierFilterCompareKind.Ne:
                            excludeTypes.push(field.Value)
                            break
                    }
                    break
                case ClassifierFilterValueType.Tags:
                    switch (field.Compare) {
                        case ClassifierFilterCompareKind.Fnmatch:
                            tags.push(field.Value)
                            break
                        case ClassifierFilterCompareKind.Ne:
                            excludeTags.push(field.Value)
                            break
                    }
                    break
                case ClassifierFilterValueType.Status:
                    if (!isDraftArticles) {
                        switch (field.Value) {
                            case ArticleStatusType.Active:
                                isDraftArticles = false
                                break
                            case ArticleStatusType.Approval:
                            case ArticleStatusType.Draft:
                                isDraftArticles = true
                                break
                        }
                    }
            }
        })

        filter.Fields = filter.Fields.filter(
            f => f.FieldName !== ClassifierFilterValueType.RecordType && f.FieldName !== ClassifierFilterValueType.Tags
        )
    })

    return {
        filters: otherFilters.filter(f => !checkType(f) || (f.Fields && f.Fields.length > 0)),
        excludeTypes,
        includeTypes,
        tags,
        excludeTags,
        isDraftArticles
    }
}

export const processClassifier = (
    classifier: ClassifierConfigDto,
    linkedClassifierConfigId?: string
): ClassifierConfigurationProcessed => {
    const helperValues = processFilters(classifier.DatasetFilters)

    return {
        ...classifier,
        DatasetFilters: helperValues.filters,
        Tags: helperValues.tags,
        ExcludeTags: helperValues.excludeTags,
        ArticleTypes: helperValues.includeTypes,
        ExcludeArticleTypes: helperValues.excludeTypes,
        LinkedClassifierConfigId: linkedClassifierConfigId
    }
}
