import React from "react"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import { useSelector } from "react-redux"
import { selectSlots } from "../../store/slots/selectors"
import { Button, Form } from "react-bootstrap"
import { Formik } from "formik"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import InfoIcon from "../InfoIcon/InfoIcon"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { nameof } from "../../utility/common/nameof"
import { CommandNode, CommandType } from "../../models/scenario"
import CheckBoxValidatableInput from "../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { CommandNodeData } from "../Graph/nodes/Command/CommandNode"

const tNamespace = "scenarioEditor:"

interface SetSlotValueFormValues {
    isExpression: boolean
    slotId: string
    value: string
}

interface Props {
    onSelect: () => void
    commandNode?: CommandNode
    setCommand: (command: CommandType, slotId: string | null, text: string | null) => void
}

const getInitialValues = (data?: CommandNodeData): SetSlotValueFormValues => {
    if (data && [CommandType.SetSlotValueWithExpression, CommandType.SetSlotValue].includes(data.Command)) {
        return {
            isExpression: data.Command === CommandType.SetSlotValueWithExpression,
            slotId: data.SlotId ?? "",
            value: data.Value ?? ""
        }
    }

    return {
        isExpression: false,
        slotId: "",
        value: ""
    }
}

const SetSlotValueForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSelect, setCommand, commandNode } = props

    const slots = useSelector(selectSlots)

    return (
        <Formik
            initialValues={getInitialValues(commandNode?.data)}
            onSubmit={(values: SetSlotValueFormValues) => {
                onSelect()
                setCommand(
                    values.isExpression ? CommandType.SetSlotValueWithExpression : CommandType.SetSlotValue,
                    values.slotId,
                    values.value
                )
            }}
        >
            {({ handleSubmit, values }) => (
                <Form className="command-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
                    <div className="command-form__content">
                        <ValidatableInput
                            id="formSlotId"
                            as="select"
                            name={nameof<SetSlotValueFormValues>("slotId")}
                            label={t(`${tNamespace}slot`)}
                            disabled={!slots.length}
                        >
                            <option value="" hidden>
                                {t(`${tNamespace}select-slot`)}
                            </option>
                            {slots.map(slot => (
                                <option key={slot.Id} value={slot.ExternalId}>
                                    {slot.Title}
                                </option>
                            ))}
                        </ValidatableInput>
                        <Form.Group controlId="formIsExpression">
                            <CheckBoxValidatableInput
                                id="formIsMultiple"
                                name={nameof<SetSlotValueFormValues>("isExpression")}
                                label={t(`${tNamespace}enter-expression`)}
                                icon={
                                    <InfoIcon
                                        id="popoverSlotValue"
                                        content={t(`${tNamespace}tooltip:set-slot-value`)}
                                    />
                                }
                            />
                        </Form.Group>
                        <ValidatableInput
                            id="formSlotValue"
                            type="text"
                            name={nameof<SetSlotValueFormValues>("value")}
                            label={t(`${tNamespace}slot-value`)}
                        />
                    </div>
                    <div className="command-form__footer">
                        <Button type="submit" disabled={!values.slotId || !values.value} variant="primary" block>
                            {t(formTranslation.save)}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default SetSlotValueForm
