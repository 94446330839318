import { User } from "../../models/user"
import { ProjectUser } from "../../models/projectUser"
import unescape from "lodash-es/unescape"

export function processUser<T extends User | ProjectUser>(userDto: T): T {
    return {
        ...userDto,
        FirstName: unescape(userDto.FirstName),
        LastName: unescape(userDto.LastName)
    }
}
