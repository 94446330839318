export const getUrlParameter = (key: string, search: string) => {
    if (!search) search = window.location.href
    key = key.replace(/[\]]/g, "\\$&")
    const regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(search)
    if (!results) return undefined
    if (!results[2]) return ""
    return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export const removeFirstSlash = (url: string) => url.replace(/^\/+/, "")
export const removeLastSlash = (url: string) => url.replace(/\/+$/, "")

export const HELP_LINK_BASE = "https://craft-talk.com/help/"

export const openExternalLink = (url: string) => window.open(url, "_blank", "noreferrer")

export const getArticleUrl = (projectId: string, symbolCode: string) =>
    `/app/project/${projectId}/knowledge-base/article/view/${symbolCode}`

export const getADFSAgentCallback = () => `${window.location.origin}/webhooks/adfsagent/callback`
