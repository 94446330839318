import { TFunction } from "i18next"

export enum SettingsType {
    Roles,
    Logging
}

export const handleSettingsLabelTranslation = (tNameSpace: string, value: string, type: SettingsType, t: TFunction) => {
    switch (type) {
        case SettingsType.Roles:
            return t(`${tNameSpace}permissions-list.${value.replaceAll("_", "-")}`)
        default:
            return t(tNameSpace.concat(value))
    }
}
