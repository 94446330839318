import { ArticleAction } from "../../models/article"
import { articlePath } from "../../routerPaths"

export const buildArticleActionUrl = (url: string, action: ArticleAction, symbolCode: string) => {
    return `${url.replace(/\/+$/, "")}${articlePath}/${action}/${symbolCode}`
}

export const buildSharedArticleActionUrl = (url: string, symbolCode: string) =>
    `${url.replace(/\/+$/, "")}${articlePath}/${symbolCode}`

export const normalizeArticleSharedUrl = (sharedUrl: string) =>
    `${window.location.protocol}//${window.location.host}/app${sharedUrl}`
