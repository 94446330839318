import React, { useEffect, useRef } from "react"
import styles from "./MessageInput.module.scss"
import { User } from "../../models/user"
import { Formik } from "formik"
import { Button, Form } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import UserCard from "../UserCard/UserCard"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import LoadingButton from "../LoadingButton/LoadingButton"
import LazyContentEditor from "../ContentEditor/LazyContentEditor"
import { FormikProps } from "formik/dist/types"
import {
    useGetAllowedAudioExtensions,
    useGetAllowedFileExtensions,
    useGetAllowedVideoExtensions
} from "../../utility/knowledgeBase/articleRedactorEmbeds"
import { useHotkeys } from "react-hotkeys-hook"
import { testId } from "../../utility/tests/testId"

interface MessageInputValue {
    text: string
}

export interface MessageInputProps extends ClassProps {
    loading?: boolean
    user?: User
    onCancel?: () => void
    onSend: (message: string) => void
    initialValue?: string
    placeholder?: string
    inputClassName?: string
    isAdvanced?: boolean
    readonlyChat?: boolean
}

const MessageInput: React.FC<MessageInputProps> = props => {
    const {
        className,
        inputClassName,
        placeholder,
        user,
        onSend,
        onCancel,
        loading,
        initialValue = "",
        isAdvanced = false,
        readonlyChat = false
    } = props

    const { t } = useTranslation()
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const allowedFileExtensions = useGetAllowedFileExtensions()
    const allowedVideoExtensions = useGetAllowedVideoExtensions()
    const allowedAudioExtensions = useGetAllowedAudioExtensions()

    interface MessageValues {
        text: string
    }

    const formRef = useRef<FormikProps<MessageValues>>(null)

    useHotkeys(
        "ctrl+enter",
        () => {
            if (formRef.current) formRef.current.handleSubmit()
        },
        {
            enableOnFormTags: true,
            enableOnContentEditable: true
        }
    )

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const getEditor = (formikProps: FormikProps<MessageInputValue>) => {
        if (isAdvanced) {
            const handleAdvancedChange = (getContent: () => string) => {
                // regex: removing all empty new lines and backslashes(markdown moment) before any characters
                formikProps.setFieldValue("text", getContent().replace(/^(\\+\n)*/g, ""))
            }
            return (
                <LazyContentEditor
                    onChange={handleAdvancedChange}
                    content={initialValue}
                    t={t}
                    className={cn(styles.messageInput__control, styles.messageInput__control_advanced, inputClassName)}
                    allowedFileExtensions={allowedFileExtensions}
                    allowedVideoExtensions={allowedVideoExtensions}
                    allowedAudioExtensions={allowedAudioExtensions}
                    isEditable
                />
            )
        } else {
            return (
                <Form.Control
                    as="textarea"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    name="text"
                    value={formikProps.values.text}
                    className={cn(styles.messageInput__control, inputClassName)}
                    placeholder={placeholder}
                    ref={inputRef}
                />
            )
        }
    }

    return (
        <Formik<MessageInputValue>
            innerRef={formRef}
            initialValues={{ text: initialValue }}
            onSubmit={(values, { resetForm }) => {
                if (!values.text) return

                onSend(values.text)

                if (isAdvanced) {
                    // for rich-markdown-editor
                    const editorInput = document.querySelector(`.${styles.messageInput__control_advanced}`)
                    const proseMirrorDiv = editorInput?.querySelector(".ProseMirror")

                    if (proseMirrorDiv) {
                        while (proseMirrorDiv.lastChild) {
                            proseMirrorDiv.removeChild(proseMirrorDiv.lastChild)
                        }
                    }
                } else {
                    // for textarea
                    resetForm({ values: { text: initialValue } })
                }
            }}
        >
            {formikProps => (
                <Form
                    className={cn(styles.messageInput, className)}
                    onSubmit={formikProps.handleSubmit}
                    onKeyPress={preventSubmitOnEnter}
                    data-test-id={testId.messageInput}
                >
                    {user && (
                        <div className={styles.messageInput__user}>
                            <UserCard lastName={user.LastName} firstName={user.FirstName} picture={user.Picture} />
                        </div>
                    )}
                    {getEditor(formikProps)}
                    <div className={styles.messageInput__buttonGroup}>
                        {onCancel && (
                            <Button variant="light" onClick={onCancel}>
                                {t(formTranslation.cancel)}
                            </Button>
                        )}
                        <LoadingButton
                            variant="primary"
                            type="submit"
                            className={styles.messageInput__submit}
                            loading={loading}
                            testId="message-input_submit-button"
                            disabled={readonlyChat}
                        >
                            {t(formTranslation.send)}
                        </LoadingButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default MessageInput
