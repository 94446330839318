import React from "react"
import "./SettingsTableLoader.scss"
import Skeleton from "../Skeleton/Skeleton"
import useDelay from "../../utility/common/useDelay"

interface Props {
    columns: number
    rows: number
    rowHeight: number
}

const SettingsTableLoader: React.FC<Props> = props => {
    const { columns, rows, rowHeight } = props

    const display = useDelay()

    if (!display) {
        return null
    }

    return (
        <div className="settings-table-loader">
            <div className="settings-table-loader__header">
                <div className="settings-table-loader__title">
                    <Skeleton />
                </div>
                <div className="settings-table-loader__btn-add">
                    <Skeleton />
                </div>
            </div>
            <div className="settings-table-loader__body">
                <div className="settings-table-loader__search">
                    <Skeleton />
                </div>
                <div className="table">
                    <div className="table__head">
                        {new Array<number>(columns).fill(0).map((_, i) => (
                            <div key={i} className="table__column">
                                <Skeleton />
                            </div>
                        ))}
                    </div>
                    <div className="table__body">
                        {new Array<number>(rows).fill(0).map((_, i) => (
                            <div key={i} className="table__row" style={{ height: rowHeight }}>
                                <Skeleton />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsTableLoader
