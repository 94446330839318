import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { Agent, AgentDeclarations } from "../../models/agent"
import { ParameterDeclarationDto } from "../../models/parameterDeclaration"
import { resetReducerState } from "../action"

export type AgentsState = Readonly<{
    agents: AsyncState<Agent[]>
    agentDeclarations: AgentDeclarations
    getAgentDeclarations: AsyncState<void>
    createAgent: AsyncState<void>
    updateAgent: AsyncState<void>
    deleteAgent: AsyncState<void>
}>
const initialState: AgentsState = {
    agents: AsyncState.create(),
    agentDeclarations: {},
    getAgentDeclarations: AsyncState.create(),
    createAgent: AsyncState.create(),
    updateAgent: AsyncState.create(),
    deleteAgent: AsyncState.create()
}

export type GetAgentDeclarationsSuccessPayload = {
    type: string
    declaration: ParameterDeclarationDto[]
}

const agents = createSlice({
    name: "agents",
    initialState,
    reducers: {
        getAgentsProcess(state) {
            state.agents = state.agents.toProcess()
        },
        getAgentsSuccess(state, action: PayloadAction<Agent[]>) {
            state.agents = state.agents.toSuccess(action.payload)
        },
        getAgentsFailed(state, action: PayloadAction<SystemError>) {
            state.agents = state.agents.toFailed(action.payload)
        },
        getAgentDeclarationsProcess(state) {
            state.getAgentDeclarations = state.getAgentDeclarations.toProcess()
        },
        getAgentDeclarationsSuccess(state, action: PayloadAction<GetAgentDeclarationsSuccessPayload>) {
            state.getAgentDeclarations = state.getAgentDeclarations.toSuccess()
            const agentDeclaration = action.payload
            state.agentDeclarations = {
                ...state.agentDeclarations,
                [agentDeclaration.type]: agentDeclaration.declaration
            }
        },
        getAgentDeclarationsFailed(state, action: PayloadAction<SystemError>) {
            state.getAgentDeclarations = state.getAgentDeclarations.toFailed(action.payload)
        },
        createAgentProcess(state) {
            state.createAgent = state.createAgent.toProcess()
        },
        createAgentSuccess(state, action: PayloadAction<Agent>) {
            state.createAgent = state.createAgent.toSuccess()
            state.agents = state.agents.map(v => [...v, action.payload])
        },
        createAgentFailed(state, action: PayloadAction<SystemError>) {
            state.createAgent = state.createAgent.toFailed(action.payload)
        },
        updateAgentProcess(state) {
            state.updateAgent = state.updateAgent.toProcess()
        },
        updateAgentSuccess(state, action: PayloadAction<Agent>) {
            state.updateAgent = state.updateAgent.toSuccess()
            state.agents = state.agents.map(v => v.map(c => (c.Id === action.payload.Id ? action.payload : c)))
        },
        updateAgentFailed(state, action: PayloadAction<SystemError>) {
            state.updateAgent = state.updateAgent.toFailed(action.payload)
        },
        deleteAgentProcess(state) {
            state.deleteAgent = state.deleteAgent.toProcess()
        },
        deleteAgentSuccess(state, action: PayloadAction<string>) {
            state.deleteAgent = state.deleteAgent.toSuccess()
            state.agents = state.agents.map(v => v.filter(agent => agent.Id !== action.payload))
        },
        deleteAgentFailed(state, action: PayloadAction<SystemError>) {
            state.deleteAgent = state.deleteAgent.toFailed(action.payload)
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export default agents.reducer

export const actions = agents.actions
