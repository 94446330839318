const prefix = "Workplace"

export const logError = (error: unknown, ...args: unknown[]) => {
    console.error(`${prefix} error:`, ...args, error)
}

export const logDebug = (...args: unknown[]) => {
    console.debug(`${prefix} debug:`, ...args)
}

export function logHubError(hub: string, event: string, error: unknown) {
    logError(`${hub}. ${event}. Unexpected error`, error)
}
