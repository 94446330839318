import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { selectRelatedCategoriesState } from "../../store/knowledgeBase/selectors"
import SubArticles from "./SubArticles"
import { useTranslation } from "react-i18next"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Async from "../Async/Async"
import CategoriesLoader from "../CategoryContainer/CategoriesLoader"
import "./SubArticles.scss"
import { TreeItem } from "@atlaskit/tree/types"

interface SubArticlesContainerProps {
    onSubArticleClick: (category: TreeItem) => void
}

const SubArticlesContainer: React.FC<SubArticlesContainerProps> = props => {
    const { t } = useTranslation()
    const relatedArticlesState = useSelector(selectRelatedCategoriesState, shallowEqual)

    return (
        <Async
            dataState={relatedArticlesState}
            processView={
                <div className="sub-articles-loader">
                    <CategoriesLoader itemsCount={3} />
                </div>
            }
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: { ChildCategories } }) => (
                <>{!!ChildCategories?.length && <SubArticles {...props} subArticles={ChildCategories} t={t} />}</>
            )}
        </Async>
    )
}

export default SubArticlesContainer
