import React from "react"
import "./ChannelTypeForm.scss"
import { ChannelTypeString, WhatsappChannelType } from "../../models/channel"
import { useTranslation } from "react-i18next"
import ChannelIconLarge from "../ChannelIconLarge/ChannelIconLarge"
import TypeFormCard from "../TypeFormCard/TypeFormCard"

const tNamespace = "channel:"

export const channelTypes: string[] = [
    ChannelTypeString.WebChat,
    ChannelTypeString.MSBot,
    ChannelTypeString.Teams,
    ChannelTypeString.Telegram,
    ChannelTypeString.Viber,
    ChannelTypeString.VK,
    WhatsappChannelType.InfoBIP,
    ChannelTypeString.Odnoklassniki,
    ChannelTypeString.Facebook,
    ChannelTypeString.Email,
    ChannelTypeString.YandexAlisa,
    ChannelTypeString.Instagram
]

interface Props {
    onSelect: (type: string) => void
}

const ChannelTypeForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSelect } = props

    return (
        <div className="channel-type-form">
            <div className="channel-type-form__content">
                {channelTypes.map(type => (
                    <TypeFormCard
                        key={type}
                        onSelect={() => onSelect(type)}
                        icon={<ChannelIconLarge type={type} className="channel-type-form__icon" />}
                        title={t(`${tNamespace}channel-type.${type}`)}
                        description={
                            type === ChannelTypeString.WebChat ? t(`${tNamespace}channel-type-desc.${type}`) : undefined
                        }
                        className="channel-type-form__card"
                        testId={`${type}-channel-type`}
                    />
                ))}
            </div>
        </div>
    )
}

export default ChannelTypeForm
