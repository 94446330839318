import { RootState } from "../rootReducer"

export const selectGetClassifiersState = (state: RootState) => state.classifiers.classifiers
export const selectClassifiers = (state: RootState) => state.classifiers.classifiers.data ?? []
export const selectGetClassifierConfigurationsState = (state: RootState) => state.classifiers.classifiers
export const selectClassifierConfigurations = (state: RootState) => state.classifiers.classifiers.data
export const selectClassifierServers = (state: RootState) => state.classifiers.classifierServers.data ?? []
export const selectClassifierModels = (state: RootState) => state.classifiers.models
export const selectGetClassifierModelsState = (state: RootState) => state.classifiers.getModels
export const selectCurrentTrainingClassifier = (state: RootState) => state.classifiers.trainingClassifier
export const selectStartTrainingClassifierState = (state: RootState) => state.classifiers.startTraining
export const selectStopTrainingClassifierState = (state: RootState) => state.classifiers.stopTraining
export const selectCreateClassifierState = (state: RootState) => state.classifiers.createClassifier
export const selectUpdateClassifierState = (state: RootState) => state.classifiers.updateClassifier
export const selectActivateClassifierState = (state: RootState) => state.classifiers.activateClassifier
export const selectDeactivateClassifierState = (state: RootState) => state.classifiers.deactivateClassifier
export const selectCurrentClassifier = (state: RootState) => state.classifiers.currentClassifier
