import React from "react"
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader"
import { selectIsOmniLoading } from "../../store/app/selectors"
import { useSelector } from "react-redux"

const OmniLoader: React.FC = () => {
    const isOmniLoading = useSelector(selectIsOmniLoading)

    return isOmniLoading ? <FullscreenLoader hideLogo /> : null
}

export default OmniLoader
