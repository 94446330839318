import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog, faVolumeMute, faVolumeUp } from "@fortawesome/pro-light-svg-icons"

import { fetchFileVirtual } from "../../helpers/file"
import { ReactComponent as PlayIcon } from "../../assets/images/audio-player/play.svg"
import { ReactComponent as PauseIcon } from "../../assets/images/audio-player/pause.svg"
import { ReactComponent as RepeatIcon } from "../../assets/images/audio-player/repeat.svg"
import { ReactComponent as DownloadIcon } from "../../assets/images/audio-player/download.svg"
import styles from "./ArticleAudio.module.scss"

export type ArticleAudioProps = {
    attrs: {
        href: string
        payload: {
            fileName: string
            fileType: string
        }
    }
}

const tNamespace = "knowledgeBase:article-content-editor"

export const ArticleAudio = (props: ArticleAudioProps) => {
    const { t } = useTranslation()

    const [isPlaying, setPlaying] = useState(false)
    const [isMuted, setMuted] = useState(false)
    const [currentTimeStr, setCurrentTimeStr] = useState("00:00")
    const [totalTimeStr, setTotalTimeStr] = useState("00:00")
    const [isMoving, setMoving] = useState(false)
    const [isSettingsOpened, setSettingsOpened] = useState(false)

    const audioRef = useRef<HTMLAudioElement>(null)
    const lineRef = useRef<HTMLDivElement>(null)
    const progressRef = useRef<HTMLDivElement>(null)
    const thumbRef = useRef<HTMLDivElement>(null)

    const handlePlay = () => {
        if (audioRef.current) {
            setPlaying(true)
            audioRef.current.play()
        }
    }

    const handlePause = () => {
        if (audioRef.current) {
            setPlaying(false)
            audioRef.current.pause()
        }
    }

    const toggleMute = () => {
        if (audioRef.current) {
            if (isMuted) {
                setMuted(false)
                audioRef.current.volume = 1
            } else {
                setMuted(true)
                audioRef.current.volume = 0
            }
        }
    }

    const getTimeMinutesOrSecondsStr = (value: number, adder: string, length: number) => {
        return (new Array(length + 1).join(adder) + value).slice(-length)
    }

    const getTimeStrFromNumber = (time: number): string => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time - minutes * 60)

        const timeStr = getTimeMinutesOrSecondsStr(minutes, "0", 2) + ":" + getTimeMinutesOrSecondsStr(seconds, "0", 2)
        return timeStr
    }

    const handleTimeUpdate = (
        event: React.SyntheticEvent<HTMLAudioElement> & { target: { currentTime: number; duration: number } }
    ) => {
        const currentTime = getTimeStrFromNumber(event.target.currentTime)
        setCurrentTimeStr(currentTime)

        if (!isMoving && lineRef.current && progressRef.current && thumbRef.current && audioRef.current) {
            const newValue = Math.max(0, Math.min(100, (event.target.currentTime / event.target.duration) * 100))
            const newValueStr = newValue + "%"
            progressRef.current.style.width = newValueStr
            thumbRef.current.style.left = newValueStr
        }
    }

    const handleCanPlay = (event: React.SyntheticEvent<HTMLAudioElement> & { target: { duration: number } }) => {
        setTotalTimeStr(getTimeStrFromNumber(event.target.duration))
    }

    const toggleSettingsOpened = () => {
        setSettingsOpened(prev => !prev)
    }

    const handleMouseMove = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>, isFirst?: boolean) => {
            if (
                (isMoving || isFirst) &&
                lineRef.current &&
                progressRef.current &&
                thumbRef.current &&
                audioRef.current
            ) {
                e.preventDefault()

                let newValue =
                    ((e.clientX - lineRef.current.getBoundingClientRect().left) / lineRef.current.offsetWidth) * 100
                newValue = Math.max(0, Math.min(100, newValue))

                const newValueStr = newValue + "%"

                audioRef.current.currentTime = (newValue / 100) * audioRef.current.duration

                progressRef.current.style.width = newValueStr
                thumbRef.current.style.left = newValueStr
            }
        },
        [isMoving]
    )

    const handleLineMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setMoving(true)
            handleMouseMove(e, true)
        },
        [handleMouseMove]
    )

    const onPause = () => {
        setPlaying(false)
    }

    const handleRepeat = () => {
        if (lineRef.current && progressRef.current && thumbRef.current && audioRef.current) {
            const newValueStr = "0%"
            setCurrentTimeStr("00:00")

            progressRef.current.style.width = newValueStr
            thumbRef.current.style.left = newValueStr

            audioRef.current.currentTime = 0
            handlePlay()
        }
    }

    const handleDownload = () => {
        fetchFileVirtual(props.attrs.href, props.attrs.payload.fileName)
    }

    useEffect(() => {
        const onWindowMouseMove = (e: MouseEvent) => {
            if (isMoving) {
                e.preventDefault()

                handleMouseMove(e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>)
            }
        }
        const onWindowMouseUp = () => {
            setMoving(false)
        }

        window.addEventListener("mousemove", onWindowMouseMove)
        window.addEventListener("mouseup", onWindowMouseUp)

        return () => {
            window.removeEventListener("mousemove", onWindowMouseMove)
            window.removeEventListener("mouseup", onWindowMouseUp)
        }
    }, [lineRef, isMoving, handleMouseMove])

    return (
        <>
            <div className={styles.articleAudio}>
                <div className={styles.articleAudio__playBtnContainer} onClick={isPlaying ? handlePause : handlePlay}>
                    {isPlaying ? <PauseIcon /> : <PlayIcon className={styles.articleAudio__playIcon} />}
                </div>
                <div className={styles.articleAudio__duration}>{`${currentTimeStr} / ${totalTimeStr}`}</div>
                <div className={styles.articleAudio__lineContainer} onMouseDown={handleLineMouseDown} ref={lineRef}>
                    <div className={styles.articleAudio__line}>
                        <div className={styles.articleAudio__thumb} ref={thumbRef}></div>
                        <div className={styles.articleAudio__progress} ref={progressRef}></div>
                    </div>
                </div>
                <button className={styles.articleAudio__controlBtn}>
                    <FontAwesomeIcon
                        icon={isMuted ? faVolumeMute : faVolumeUp}
                        size="lg"
                        className={styles.articleAudio__volumeIcon}
                        onClick={toggleMute}
                    />
                </button>
                <button className={`${styles.articleAudio__controlBtn} ${styles.articleAudio__cogBtn}`}>
                    <FontAwesomeIcon
                        icon={faCog}
                        size="lg"
                        className={styles.articleAudio__cogIcon}
                        onClick={toggleSettingsOpened}
                    />
                </button>
                {isSettingsOpened && (
                    <div className={styles.articleAudio__settingsContainer}>
                        <DownloadIcon onClick={handleDownload} />
                        <RepeatIcon onClick={handleRepeat} />
                    </div>
                )}
            </div>
            <audio
                controls
                ref={audioRef}
                onTimeUpdate={handleTimeUpdate}
                onCanPlayThrough={handleCanPlay}
                onPause={onPause}
                hidden
            >
                <source src={props.attrs.href} type={props.attrs.payload.fileType} />
                <div className={styles.articleAudio__fallbackContainer}>
                    {`${t(`${tNamespace}.audio-format-warning`)} - `}
                    <a href={props.attrs.href}>{t(`${tNamespace}.audio-format-fallback-link`)}</a>
                </div>
            </audio>
        </>
    )
}
