import { CustomMenusValue } from "../../models/projectSettings"
import { MenuItem } from "../../models/menu"

export const remapCustomMenus = (menus: CustomMenusValue): MenuItem[] => {
    const remappedMenus: MenuItem[] = []

    menus.Values.map(menu =>
        remappedMenus.push({
            title: menu.Title,
            id: menu.Id,
            icon: menu.Icon,
            submenus: []
        })
    )
    return remappedMenus
}
