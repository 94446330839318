export interface ParameterDeclarationDto {
    name: string
    type: ParameterType
    description: string
    default_value: string
}

export interface ParameterDeclaration extends ParameterDeclarationDto {
    default_value: string
}

export interface Declarations {
    [type: string]: ChannelDeclaration
}

export interface WebChatDeclarations {
    webchat?: WebChatChannelDeclaration
}

export type ChannelDeclarations = Declarations & WebChatDeclarations

export interface CommonChannelDeclaration {
    Params: ParameterDeclaration[]
}

export interface WebChatChannelDeclaration extends CommonChannelDeclaration {
    MainConfig: ParameterDeclaration[]
    ClientConfig: ParameterDeclaration[]
}

export type ChannelDeclaration = CommonChannelDeclaration | WebChatChannelDeclaration

export interface ChannelDeclarationsDto {
    [type: string]: ParameterDeclarationDto[]
}

export enum ParameterType {
    String = "String",
    Number = "Number",
    Boolean = "Boolean",
    IntentId = "IntentId",
    AgentId_ = "AgentId_",
    ClassifiersConfiguration = "ClassifiersConfiguration",
    ActionClassifiersConfiguration = "ActionClassifiersConfiguration",
    Multiline = "Multiline"
}

export type ParamValues = {
    // TODO Сделать typeguard для всех типов параметров агентов в настройках
    // eslint-disable-next-line
    [key: string]: any
}

export type WebhooksPrefixes = Record<string, string>
