import React, { useCallback } from "react"
import {
    isKnowledgeBasePermission,
    KnowledgeBaseAccessType,
    KnowledgeBasePermission,
    KnowledgeBasePermittedActionSelectItem
} from "../../models/knowledgeBasePermission"
import UserCard from "../UserCard/UserCard"
import { SimpleUser } from "../../models/user"
import { SimpleRole } from "../../models/role"
import "./KnowledgeBaseUserAccessItem.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import SidebarContextMenuSelect from "../SidebarContextMenuSelect/SidebarContextMenuSelect"
import { KnowledgeBaseSubscriptionSelectItem } from "../../models/knowledgeBaseSubscription"
import MenuItemRow from "../MenuItemRow/MenuItemRow"
import { OnChangeAccessItemFunction, UserAccessPrimitive } from "../../models/knowledgeBaseUserAccess"
import { isKnowledgeBaseSubscription } from "../../utility/knowledgeBase/knowledgeBase"
import { TFunction } from "react-i18next"
import { formTranslation } from "../../locales/form"
import CloseButton from "../CloseButton/CloseButton"

interface Props extends ClassProps {
    t: TFunction
    data: UserAccessPrimitive
    subscriptionOptions?: KnowledgeBaseSubscriptionSelectItem[]
    permittedActionOptions?: KnowledgeBasePermittedActionSelectItem[]
    role?: SimpleRole
    user?: SimpleUser
    onChange: OnChangeAccessItemFunction
    onRemove: OnChangeAccessItemFunction
}

const tProjectNamespace = "project:"

const KnowledgeBaseUserAccessItem: React.FC<Props> = props => {
    const { className, data, subscriptionOptions, permittedActionOptions, role, user, onRemove, onChange, t } = props

    const currentOptionType = isKnowledgeBaseSubscription(data)
        ? data.SubscriptionType
        : isKnowledgeBasePermission(data)
        ? data.Action
        : undefined

    const selectedOption =
        currentOptionType && subscriptionOptions
            ? subscriptionOptions.find(el => el.Type === currentOptionType)
            : permittedActionOptions
            ? permittedActionOptions.find(el => el.Action === currentOptionType)
            : undefined

    const handleSelectSubscriptionOption = (option: KnowledgeBaseSubscriptionSelectItem) => () => {
        if (isKnowledgeBaseSubscription(data)) {
            const updatedItem = { ...data, SubscriptionType: option.Type }
            onChange(updatedItem)
        }
    }

    const handleSelectPermissionOption = (option: KnowledgeBasePermittedActionSelectItem) => () => {
        if (isKnowledgeBasePermission(data)) {
            const updatedItem: KnowledgeBasePermission = {
                ...data,
                Action: option.Action
            }
            onChange(updatedItem)
        }
    }

    const getCardInfo = () => {
        const empty = ["", "", ""]
        switch (data.Type) {
            case KnowledgeBaseAccessType.Login:
                return user
                    ? [user.FirstName, user.LastName, user.Picture ?? ""]
                    : [data.Value, t(formTranslation.user), ""]
            case KnowledgeBaseAccessType.Role:
                return role ? ["", role.Title, ""] : [data.Value, t(formTranslation.role), ""]
            case KnowledgeBaseAccessType.All:
                return ["", t(formTranslation.all), ""]
            default:
                return empty
        }
    }

    const [firstName, lastName, picture] = getCardInfo()

    const handleRemove = useCallback(() => {
        onRemove(data)
    }, [onRemove, data])

    const options = subscriptionOptions
        ? subscriptionOptions?.map(option => (
              <MenuItemRow
                  key={option.Type}
                  className="knowledge-base-permission__option"
                  title={option.Title}
                  onClick={handleSelectSubscriptionOption(option)}
              />
          ))
        : permittedActionOptions
        ? permittedActionOptions?.map(option => (
              <MenuItemRow
                  key={option.Action}
                  className="knowledge-base-permission__option"
                  title={option.Title}
                  onClick={handleSelectPermissionOption(option)}
              />
          ))
        : undefined

    return (
        <div className={cn("knowledge-base-permission", className)}>
            <UserCard
                className="knowledge-base-permission__card"
                firstName={firstName}
                lastName={lastName}
                picture={picture}
                userCardMaxLength={15}
            >
                <div className="knowledge-base-permission__project">
                    {`${t(`${tProjectNamespace}project`)} ${data.ProjectId}`}
                </div>
            </UserCard>
            {options && <SidebarContextMenuSelect items={options} selected={selectedOption?.Title} />}
            <div className="knowledge-base-permission__remove">
                <CloseButton onClick={handleRemove} />
            </div>
        </div>
    )
}

export default KnowledgeBaseUserAccessItem
