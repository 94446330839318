import {
    Agent,
    AgentType,
    AgentValues,
    AIAssist2AgentValues,
    ClaimsMappingValue,
    EmailType,
    ExtractParametersType,
    NightAgentValues,
    RestAgentValues,
    RoutingAgentValues
} from "../../models/agent"
import { Time } from "../common/time"
import {
    OverrideChannelTypeParamType,
    OverrideChannelTypeValue
} from "../../components/AgentForm/agents/PsbBssAgent/OverrideChannelTypeControl"
import { ParamValues } from "../../models/parameterDeclaration"

export const isNightAgentValues = (values: AgentValues): values is NightAgentValues => values.hasOwnProperty("Schedule")

const buildRedirectReplyWithIntentCommand = (indentId: string) =>
    indentId ? `/redirect aiassist2 intent_id="${indentId}"` : ""

const buildSwitchRedirectReplyWithIntentCommand = (indentId: string) =>
    indentId ? `/switchredirect aiassist2 intent_id="${indentId}"` : ""

export const buildAgentRequest = (projectId: string, agentType: AgentType, values: AgentValues): Agent => {
    const request: Agent = {
        Id: values.Id,
        ProjectId: projectId,
        Type: agentType,
        ...(values.NextAgent && { NextAgent: values.NextAgent }),
        ...(values.FailOverAgent && { FailOverAgent: values.FailOverAgent }),
        Params: { ...values.Params }
    }

    switch (agentType) {
        case AgentType.InitialMsgAgent:
            request.Params.text = buildSwitchRedirectReplyWithIntentCommand(values.Params.text)
            request.Params.required_authorization = values.Params.required_authorization
            break
        case AgentType.ABAgent:
            request.Params.probability = `${request.Params.probability / 100}`
            break
        case AgentType.EmailAgent:
            if (request.Params["email-type"] === EmailType.Text) {
                request.Params["intent-id"] = ""
            } else {
                request.Params["body"] = ""
            }
            delete request.Params["email-type"]

            if (!request.Params["enable-auth"]) {
                request.Params["smtp-login"] = ""
                request.Params["smtp-password"] = ""
            }
            break
        case AgentType.NightAgent:
            const formatTime = (v: number) => v.toString().padStart(2, "0")

            if (isNightAgentValues(values)) {
                request.Params.schedule = values.Schedule.map(s => ({
                    NightStartTime: `${formatTime(s.NightStartHr)}:${formatTime(s.NightStartMin)}`,
                    NightEndTime: `${formatTime(s.NightEndHr)}:${formatTime(s.NightEndMin)}`,
                    WeekDays: s.WeekDays.reduce((arr: number[], next, index) => {
                        next && arr.push(index + 1)
                        return arr
                    }, []),
                    Dates: s.Dates
                })).filter(s => s.WeekDays.length + s.Dates.length > 0)
            }
            break
        case AgentType.JavaScriptAgent:
            request.Params["agent-timeout"] = Time.secondsToMs(request.Params["agent-timeout"])
            break
        case AgentType.PsbBssAgent:
            request.Params["continue-session-slot-ids"] = request.Params["continue-session-slot-ids"].join(" ")

            const allowedExtensions = request.Params["allowed-attachment-extensions"]
            request.Params["allowed-attachment-extensions"] = allowedExtensions.length
                ? "." + allowedExtensions.join(" .")
                : ""

            request.Params["override-channel-type"] = request.Params["override-channel-type"].reduce(
                (obj: OverrideChannelTypeParamType, v: OverrideChannelTypeValue) => {
                    obj[v.from] = v.to
                    return obj
                },
                {}
            )

            break
        case AgentType.ADFSAgent:
            request.Params["claims-mapping"] = request.Params["claims-mapping"].map((v: ClaimsMappingValue) => ({
                claim: v.claim,
                slot: v.slot,
                field: v.fieldEnabled ? v.field : ""
            }))
            break
        case AgentType.FixedReplyAgent:
            request.Params.text = buildRedirectReplyWithIntentCommand(request.Params.text)
            break
    }

    const params = request.Params
    Object.keys(params).forEach(key => {
        if (typeof params[key] !== "string") {
            params[key] = JSON.stringify(params[key])
        }
    })
    return request
}

export const buildRoutingAgentRequest = (projectId: string, values: RoutingAgentValues): Agent => ({
    Id: values.Id,
    ProjectId: projectId,
    Type: AgentType.RoutingAgent,
    FailOverAgent: values.FailOverAgent,
    Params: {
        "routing:cc": values.DefaultSettings.call_center,
        "routing:lang": values.DefaultSettings.lang,
        "routing:skill": values.DefaultSettings.skill,
        "routing:group": values.DefaultSettings.group,
        "routing:max_dialogs": values.DefaultSettings.max_dialogs.toString(),
        "routing:operator_id": values.DefaultSettings.operator_id,
        allow_only_known_agents: values.allow_only_known_agents.toString(),
        queue_id: values.DefaultSettings.queue_id,
        "routing:reply_after_routing_command": buildRedirectReplyWithIntentCommand(
            values.DefaultSettings.reply_after_routing
        )
    }
})

export const buildAIAssist2AgentRequest = (projectId: string, values: AIAssist2AgentValues): Agent => {
    const classifiersConf = values.ClassifiersConf.map(c => {
        const classifier = { ...c }
        classifier.BotClassificationResultThreshold /= 100
        return classifier
    })
    const { max_intent_repetition_enabled, ...params } = values.Params as ParamValues

    Object.keys(params).forEach(key => {
        if (typeof params[key] !== "string") {
            params[key] = JSON.stringify(params[key])
        }
    })

    return {
        Id: values.Id,
        ProjectId: projectId,
        Type: AgentType.AIAssist2,
        Params: {
            ...params,
            stop_sf_intent: values.IntentSettings.stop_sf_intent,
            fail_intent: values.IntentSettings.fail_intent,
            file_input_intent: values.IntentSettings.file_input_intent,
            classifiers_conf: JSON.stringify(classifiersConf),
            max_intent_repetition: max_intent_repetition_enabled ? params.max_intent_repetition.toString() : "999",
            button_classifier_threshold: (values.Params.button_classifier_threshold / 100).toString()
        }
    }
}

export const buildRestAgentRequest = (projectId: string, values: RestAgentValues): Agent => {
    const getRequest = (type: "Requests" | "AuthorizationRequest") => {
        const param = values[type]
        return {
            BaseUri: param.BaseUri,
            Method: param.Method,
            Query: param.Query,
            Headers: param.Headers,
            Body: param.BodyEnabled || type === "AuthorizationRequest" ? param.Body : "",
            ...(type === "Requests" && {
                TimeoutSec: param.TimeoutSecEnabled ? param.TimeoutSec : ""
            }),
            ResponseParameters: {
                HttpCodeToSlot: param.HttpCodeToSlot,
                ExtractParameters: param.ExtractParameters.map(v => ({
                    ...(v.Type === ExtractParametersType.Header && {
                        Header: v.Header
                    }),
                    ...(v.Type === ExtractParametersType.JsonPaths &&
                        !v.WholeResponse && {
                            JsonPaths: v.JsonPaths
                        }),
                    SlotId: v.SlotId
                }))
            }
        }
    }

    const Requests = getRequest("Requests")

    return {
        Id: values.Id,
        ProjectId: projectId,
        Type: AgentType.RestAgent,
        NextAgent: values.NextAgent,
        Params: {
            requests: JSON.stringify(
                values.RequestTokenEnabled
                    ? { Requests: [Requests], AuthorizationRequest: getRequest("AuthorizationRequest") }
                    : { Requests: [Requests] }
            ),
            "custom-api-key": values.CustomApiKeyEnabled ? values.Params["custom-api-key"] : "",
            "success-message": values.Params["success-message"],
            "failure-message": values.Params["failure-message"]
        }
    }
}
