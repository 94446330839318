import React, { ReactNode, useCallback } from "react"
import "./ButtonItem.scss"
import { ClassProps } from "../../../../utility/common/props"
import CloseButton from "../../../CloseButton/CloseButton"
import { Handle, Position } from "react-flow-renderer"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripLinesVertical } from "@fortawesome/pro-light-svg-icons/faGripLinesVertical"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"

interface Props extends ClassProps {
    id: string
    children: ReactNode
    onAddBlock: (sourceHandle: string) => void
    onRemove: (conditionId: string) => void
    isConnectable: boolean
    selected?: boolean
    onClick: () => void
    dragHandleProps?: DraggableProvidedDragHandleProps
}

const ButtonItem: React.FC<Props> = props => {
    const { id, className, children, onAddBlock, onRemove, isConnectable, selected, onClick, dragHandleProps } = props

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()
            onClick()
        },
        [onClick]
    )

    const handleSourceClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()
            onAddBlock(id)
        },
        [onAddBlock, id]
    )

    const handleRemove = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()
            isConnectable && onRemove(id)
        },
        [isConnectable, onRemove, id]
    )

    return (
        <div className={cn("button-item", selected && "button-item_removable", className)} onClick={handleClick}>
            {selected && (
                <span {...dragHandleProps} className="buttons-list-item__drag-icon">
                    <FontAwesomeIcon icon={faGripLinesVertical} />
                </span>
            )}
            <div className="button-item__content">{children}</div>
            <CloseButton onClick={handleRemove} />
            <Handle
                id={id}
                type="source"
                position={Position.Right}
                className="button-item__handle"
                onClick={handleSourceClick}
                isConnectable={isConnectable}
            />
        </div>
    )
}

export default ButtonItem
