import { RootState } from "../rootReducer"

export const selectCurrentUserState = (state: RootState) => state.users.currentUser
export const selectCurrentUser = (state: RootState) => state.users.currentUser.data
export const selectCurrentUserLogin = (state: RootState) => state.users.currentUser.data?.Login
export const selectCurrentPermissions = (state: RootState) => state.users.currentPermissions
export const selectCurrentUserProjects = (state: RootState) => {
    const currentUser = state.users.currentUser.data
    return currentUser ? currentUser.Projects : []
}
export const selectProjectUsersState = (state: RootState) => state.users.projectUsers
export const selectAllUsersState = (state: RootState) => state.users.allUsers
export const selectUpdateUserState = (state: RootState) => state.users.updateUser
export const selectUpdateAvatarState = (state: RootState) => state.users.updateAvatar
export const selectUpdateProjectUser = (state: RootState) => state.users.updateProjectUser
export const selectUpdateGlobalUser = (state: RootState) => state.users.updateGlobalUser
export const selectRemoteWorkplaceAuthTokenState = (state: RootState) => state.users.remoteWorkplaceAuthToken
export const selectClientTypes = (state: RootState) => state.users.clientTypes.data ?? []
