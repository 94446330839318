import React, { useMemo } from "react"
import { WithT } from "i18next"
import { getPermittedActionOptions } from "../../utility/knowledgeBase/knowledgeBase"
import KnowledgeBaseContext from "./KnowledgeBaseContext"
import { useModifyKnowledgeBase } from "../../utility/knowledgeBase/useModifyPermitted"
import { onOpenSharingCatalogFunc } from "../../types/knowledgeBaseFunctions"

export interface KnowledgeBaseProviderProps extends WithT {
    onOpenSharingCatalog: onOpenSharingCatalogFunc
    projectId?: string
    url: string
}

const KnowledgeBaseProvider: React.FC<KnowledgeBaseProviderProps> = props => {
    const { projectId, url, t, children, onOpenSharingCatalog } = props
    const canModifyKnowledgeBase = useModifyKnowledgeBase()
    const permittedActionOptions = useMemo(() => getPermittedActionOptions(t), [t])

    return (
        <KnowledgeBaseContext.Provider
            value={{
                projectId: projectId,
                url: url,
                canModifyKnowledgeBase: canModifyKnowledgeBase,
                permittedActionOptions: permittedActionOptions,
                onOpenSharingCatalog
            }}
        >
            {children}
        </KnowledgeBaseContext.Provider>
    )
}

export default KnowledgeBaseProvider
