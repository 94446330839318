import React from "react"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import { useSelector } from "react-redux"
import { selectSlots } from "../../store/slots/selectors"
import { Button, Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { nameof } from "../../utility/common/nameof"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import InfoIcon from "../InfoIcon/InfoIcon"
import { Formik } from "formik"
import CheckBoxValidatableInput from "../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { CommandNode, CommandType } from "../../models/scenario"
import { CommandNodeData } from "../Graph/nodes/Command/CommandNode"

const tNamespace = "scenarioEditor:"

interface DeleteSlotValueFormValues {
    isMultiple: boolean
    slotId: string
    regex: string
}

interface Props {
    onSelect: () => void
    commandNode?: CommandNode
    setCommand: (command: CommandType, slotId: string | null, text: string | null) => void
}

const getInitialValues = (data?: CommandNodeData): DeleteSlotValueFormValues => {
    if (data) {
        switch (data.Command) {
            case CommandType.DeleteSlotValues:
                return {
                    isMultiple: true,
                    slotId: "",
                    regex: data.Value ?? ""
                }
            case CommandType.DeleteSlotValue:
                return {
                    isMultiple: false,
                    slotId: data.SlotId ?? "",
                    regex: ""
                }
        }
    }
    return {
        isMultiple: false,
        slotId: "",
        regex: ""
    }
}

const DeleteSlotValueForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSelect, setCommand, commandNode } = props

    const slots = useSelector(selectSlots)

    return (
        <Formik
            initialValues={getInitialValues(commandNode?.data)}
            onSubmit={(values: DeleteSlotValueFormValues) => {
                onSelect()
                values.isMultiple
                    ? setCommand(CommandType.DeleteSlotValues, null, values.regex)
                    : setCommand(CommandType.DeleteSlotValue, values.slotId, null)
            }}
        >
            {({ handleSubmit, values }) => (
                <Form className="command-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
                    <div className="command-form__content">
                        <Form.Group controlId="formIsMultiple">
                            <CheckBoxValidatableInput
                                id="formIsMultiple"
                                name={nameof<DeleteSlotValueFormValues>("isMultiple")}
                                label={t(`${tNamespace}delete-multiple-slots`)}
                            />
                        </Form.Group>
                        {values.isMultiple ? (
                            <ValidatableInput
                                type="text"
                                id="formRegex"
                                name={nameof<DeleteSlotValueFormValues>("regex")}
                                label={t(`${tNamespace}regex`)}
                                placeholder={t(formTranslation.enterRegex)}
                                icon={
                                    <InfoIcon
                                        id="popoverSlotId"
                                        content={t(`${tNamespace}tooltip:delete-slot-value`)}
                                    />
                                }
                            />
                        ) : (
                            <ValidatableInput
                                id="formSlotId"
                                as="select"
                                name={nameof<DeleteSlotValueFormValues>("slotId")}
                                label={t(`${tNamespace}slot`)}
                                disabled={!slots.length}
                            >
                                <option value="" hidden>
                                    {t(`${tNamespace}select-slot`)}
                                </option>
                                {slots.map(slot => (
                                    <option key={slot.Id} value={slot.ExternalId}>
                                        {slot.Title}
                                    </option>
                                ))}
                            </ValidatableInput>
                        )}
                    </div>
                    <div className="command-form__footer">
                        <Button
                            type="submit"
                            disabled={!(values.isMultiple ? values.regex : values.slotId)}
                            variant="primary"
                            block
                        >
                            {t(formTranslation.save)}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default DeleteSlotValueForm
