import { TFunction } from "i18next"
import { QueueFilterOptions } from "../../types/queueFilterOptions"
import { QueueSearchMode } from "../../models/queueSearch"

const operator = "operator"
const dialog = "dialog"

export const getFilterOptions = (t: TFunction): QueueFilterOptions => ({
    [operator]: t("queues:operator.operator"),
    [dialog]: t("queues:dialog")
})

export const queueFilterOptionToMode = (filterOption: string): QueueSearchMode => {
    if (filterOption === operator) {
        return QueueSearchMode.Operators
    } else if (filterOption === dialog) {
        return QueueSearchMode.Tasks
    } else {
        throw Error("Unknown filtering option")
    }
}
