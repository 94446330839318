import React from "react"
import PopoverTrigger from "../../PopoverTrigger/PopoverTrigger"
import "./ArticleFilterPopover.scss"
import { ArticleFilterFormValues } from "../../../models/articleFilter"
import { faFilter } from "@fortawesome/pro-light-svg-icons/faFilter"
import IconButton from "../../IconButton/IconButton"
import ArticleFilterForm from "../ArticleFilterForm/ArticleFilterForm"
import { useSelector } from "react-redux"
import { selectCurrentArticlesFilter } from "../../../store/knowledgeBase/selectors"

interface Props {
    show: boolean
    onShow: () => void
    onCancel: () => void
    onSubmit: (values: ArticleFilterFormValues) => void
}

const getNumberOfFilters = (articlesFilter: ArticleFilterFormValues) => {
    const result = Object.keys(articlesFilter).length
    return articlesFilter.DateFrom ? result - 1 : result
}

const ArticleFilterPopover: React.FC<Props> = props => {
    const { show, onShow, onCancel, onSubmit } = props
    const articlesFilter = useSelector(selectCurrentArticlesFilter)

    return (
        <PopoverTrigger
            rootClose
            className="article-filter-popover"
            id="article-filter"
            content={<ArticleFilterForm onSubmit={onSubmit} onCancel={onCancel} articlesFilter={articlesFilter} />}
            trigger="click"
            placement="bottom"
            show={show}
            onExited={onCancel}
        >
            <IconButton
                variant="outline-light"
                onClick={onShow}
                icon={faFilter}
                iconClassName="article-filter-popover__open-filters-icon"
                notifications={getNumberOfFilters(articlesFilter)}
            >
                {""}
            </IconButton>
        </PopoverTrigger>
    )
}

export default ArticleFilterPopover
