import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styles from "./SettingsContextMenuItem.module.scss"
import cn from "classnames"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import Toggle from "../Toggle/Toggle"

export interface SettingsContextMenuItemProps {
    icon?: IconProp
    text: string
    id: string
    useSeparator?: boolean
    danger?: boolean
    onClick?: () => void
    testId?: string
    unavailableMessage?: string
    toggle?: {
        enabled: boolean
        active: boolean
    }
}

const tooltipDelay = { show: 450, hide: 0 }

const SettingsContextMenuItem: React.FC<SettingsContextMenuItemProps> = props => {
    const { id, icon, text, useSeparator, danger, onClick, testId, unavailableMessage, toggle } = props

    return (
        <TooltipTrigger
            id={id}
            condition={!!unavailableMessage}
            content={unavailableMessage}
            placement="auto"
            delay={tooltipDelay}
        >
            <div
                className={cn(
                    styles.settingsContextMenuItem,
                    useSeparator && styles.settingsContextMenuItem_separated,
                    danger && styles.settingsContextMenuItem_danger,
                    !!unavailableMessage && styles.settingsContextMenuItem_disabled
                )}
                onClick={!unavailableMessage ? onClick : undefined}
                data-test-id={testId}
            >
                {icon && (
                    <div className={styles.settingsContextMenuItem__icon}>
                        <FontAwesomeIcon icon={icon} />
                    </div>
                )}
                <div className={styles.settingsContextMenuItem__text}>{text}</div>
                {toggle?.enabled && (
                    <Toggle
                        checked={toggle?.active}
                        onChange={onClick}
                        small
                        className={styles.settingsContextMenuItem__toggle}
                        disabled={!!unavailableMessage}
                    ></Toggle>
                )}
            </div>
        </TooltipTrigger>
    )
}

export default SettingsContextMenuItem
