import React from "react"
import styles from "./OperatorStatusContainer.module.scss"
import selectorStyles from "../OperatorStatusSelector/OperatorStatusSelector.module.scss"
import cn from "classnames"
import { SelectedOperatorStatus } from "../../models/operatorStatus"
import { mapOperatorStatusToClassName } from "../../utility/operatorStatus/mapOperatorStatusToClassName"
import { handleLocalizedTextTranslation } from "../../utility/common/handleLocalizedTextTranslation"
import { TFunction } from "i18next"
import { ClassProps } from "../../utility/common/props"
import { testId } from "../../utility/tests/testId"

export interface OperatorStatusContainerProps extends ClassProps {
    status: SelectedOperatorStatus
    t: TFunction
    sideElement?: JSX.Element
    testId?: string
    inHeader?: boolean
}

const OperatorStatusContainer: React.FC<OperatorStatusContainerProps> = props => {
    const { status, sideElement, t, className, inHeader } = props
    return (
        <>
            {inHeader ? (
                <>
                    <div
                        className={cn(
                            selectorStyles.operatorStatusSelector__circle,
                            styles[mapOperatorStatusToClassName(status.Description, "operatorStatus_")]
                        )}
                    />
                    <div className={styles.operatorStatus__title} data-test-id={testId.currentStatus}>
                        {handleLocalizedTextTranslation(status.Title, t)}
                    </div>
                    {sideElement && <div className={styles.operatorStatus__sideElement}>{sideElement}</div>}
                </>
            ) : (
                <div
                    className={cn(
                        styles.operatorStatus,
                        styles[mapOperatorStatusToClassName(status.Description, "operatorStatus_")],
                        className
                    )}
                >
                    <div className={styles.operatorStatus__title} data-test-id={testId.currentStatus}>
                        {handleLocalizedTextTranslation(status.Title, t)}
                    </div>
                    {sideElement && <div className={styles.operatorStatus__sideElement}>{sideElement}</div>}
                </div>
            )}
        </>
    )
}

export default OperatorStatusContainer
