import { ItemId } from "@atlaskit/tree/types"
import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import { actions, mutateCurrentBranch } from "./slice"
import { handleHttpException } from "../handleHttpException"
import * as constants from "./constants"
import { GetCategoriesResponse, GetRelatedCategoriesResponse } from "../../models/category"
import { GetArticleTypesResponse } from "../../models/articleType"
import { SearchArticlesResponse } from "../../models/article"

export type GetRootCategoriesFunc = () => Promise<GetCategoriesResponse>

export const getCategoriesBase =
    (parentCategoryId: ItemId, catalogId: ItemId, getCategories: GetRootCategoriesFunc): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        const catalogCode = catalogId as string
        try {
            dispatch(
                actions.getCategoriesProcess({
                    CatalogId: catalogCode,
                    ParentCategoryId: parentCategoryId
                })
            )

            const { Categories } = await getCategories()

            dispatch(
                actions.getCategoriesSuccess({
                    CatalogId: catalogCode,
                    ParentCategoryId: parentCategoryId,
                    Categories: Categories
                })
            )
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_CATEGORIES_FAILED_MESSAGE,
                err =>
                    actions.getCategoriesFailed({
                        CatalogId: catalogCode,
                        ParentCategoryId: parentCategoryId,
                        Error: err
                    }),
                dispatch
            )
        }
    }

export const getRelatedCategoriesBase =
    (getRelatedCategories: () => Promise<GetRelatedCategoriesResponse>): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getRelatedCategoriesProcess())
            const response = await getRelatedCategories()
            dispatch(actions.getRelatedCategoriesSuccess(response))

            const {
                Id,
                ParentCategory: { CatalogCode }
            } = response

            dispatch(mutateCurrentBranch({ CurrentCategoryId: Id, CurrentCatalogCode: CatalogCode }))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_CATEGORIES_FAILED_MESSAGE,
                err => actions.getRelatedCategoriesFailed(err),
                dispatch
            )
        }
    }

export const getArticleTypesBase =
    (getArticleTypesFunc: () => Promise<GetArticleTypesResponse>): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getArticleTypesProcess())
        try {
            const response = await getArticleTypesFunc()
            dispatch(actions.getArticleTypesSuccess(response))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_ARTICLE_TYPES_FAILED_MESSAGE,
                err => actions.getArticleTypesFailed(err),
                dispatch
            )
        }
    }

export const searchArticleBase =
    (searchArticleFunc: () => Promise<SearchArticlesResponse>): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.searchArticlesProcess())
        try {
            const response = await searchArticleFunc()
            dispatch(actions.searchArticlesSuccess(response))
        } catch (e) {
            handleHttpException(
                e,
                constants.SEARCH_ARTICLES_FAILED_MESSAGE,
                err => actions.searchArticlesFailed(err),
                dispatch
            )
        }
    }
