import React from "react"
import styles from "./ArticleInformationForm.module.scss"
import { Article } from "../../models/article"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import { Button, Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import IdField from "../IdField/IdField"
import { formTranslation } from "../../locales/form"
import { selectEditedArticleExtId } from "../../store/knowledgeBase/selectors"
import { nameof } from "../../utility/common/nameof"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { setEditedArticleExtId } from "../../store/knowledgeBase/actions"

const tNamespace = "knowledgeBase:article-edit-form."

interface InformationFormValues {
    ExtId: string
}

interface Props {
    projectId?: string
    article: Article
    onClose: () => void
    isEdit?: boolean
}

const ArticleInformationForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { projectId, article, onClose, isEdit } = props

    const editedExtId = useSelector(selectEditedArticleExtId)

    if (!projectId) {
        return null
    }

    if (!isEdit)
        return (
            <div className={styles.articleInformationForm__content}>
                <div className={styles.articleInformationForm__section}>
                    <IdField
                        id={article.ExtId}
                        label={t(`${tNamespace}article-ext-id`)}
                        className={styles.articleInformationForm__section}
                    />
                </div>
            </div>
        )

    return (
        <Formik
            initialValues={{
                ExtId: editedExtId
            }}
            onSubmit={(values: InformationFormValues) => {
                setEditedArticleExtId(dispatch, values.ExtId)
                onClose()
            }}
        >
            {({ handleSubmit }) => (
                <Form
                    className={styles.articleInformationForm}
                    onSubmit={handleSubmit}
                    onKeyPress={preventSubmitOnEnter}
                >
                    <div className={styles.articleInformationForm__content}>
                        <div className={styles.articleInformationForm__section}>
                            <ValidatableInput
                                id="formExtId"
                                type="text"
                                name={nameof<InformationFormValues>("ExtId")}
                                label={t(`${tNamespace}article-ext-id`)}
                            />
                        </div>
                    </div>
                    {isEdit && (
                        <div className={styles.articleInformationForm__footer}>
                            <Button type="submit" variant="primary" block>
                                {t(formTranslation.save)}
                            </Button>
                        </div>
                    )}
                </Form>
            )}
        </Formik>
    )
}

export default ArticleInformationForm
