import loadable from "@loadable/component"
import CatalogsLoader from "../Catalogs/CatalogsLoader"
import React from "react"
import { promiseDelay } from "../../utility/common/promiseDelay"

const LazyCatalog = loadable(() => promiseDelay(import("./Catalog"), 200), {
    fallback: <CatalogsLoader itemsCount={3} />
})

export default LazyCatalog
