import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import * as constants from "./constants"
import slotController from "../../api/controllers/slot"
import { CreateSlotRequest, GetSlotPageRequest, SlotDto, UpdateSlotRequest } from "../../models/slot"

export const getSlots =
    (projectId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            const request: GetSlotPageRequest = {
                PageFrom: 0,
                PageSize: 1000
            }
            dispatch(actions.getSlotsProcess())
            const { Items } = await slotController.getSlotPage(projectId, request)
            dispatch(actions.getSlotsSuccess(Items))
        } catch (e) {
            handleHttpException(e, constants.GET_SLOT_PAGE_FAILED, err => actions.getSlotsFailed(err), dispatch)
        }
    }

export const createSlot =
    (projectId: string, request: CreateSlotRequest, callback: (slot: SlotDto) => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(actions.createSlotProcess())
            const { CreatedSlot } = await slotController.create(projectId, request)
            dispatch(actions.createSlotSuccess(CreatedSlot))
            callback(CreatedSlot)
        } catch (e) {
            handleHttpException(e, constants.CREATE_SLOT_FAILED, err => actions.createSlotFailed(err), dispatch)
        }
    }

export const updateSlot =
    (
        projectId: string,
        slotId: string,
        request: UpdateSlotRequest,
        callback: (slot: SlotDto) => void
    ): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateSlotProcess())
            const { UpdatedSlot } = await slotController.update(projectId, slotId, request)
            dispatch(actions.updateSlotSuccess(UpdatedSlot))
            callback(UpdatedSlot)
        } catch (e) {
            handleHttpException(e, constants.UPDATE_SLOT_FAILED, err => actions.updateSlotFailed(err), dispatch)
        }
    }

export const deleteSlot =
    (projectId: string, slotId: string, callback: () => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(actions.deleteSlotProcess())
            await slotController.delete(projectId, slotId)
            dispatch(actions.deleteSlotSuccess(slotId))
            callback()
        } catch (e) {
            handleHttpException(e, constants.DELETE_SLOT_FAILED, err => actions.deleteSlotFailed(err), dispatch)
        }
    }
