import { actions } from "./slice"
import { ClassifierEventDto, ClassifierEventType } from "../../models/classifierEvent"
import { Dispatch } from "../../utility/common/storeHelper"
import { UpdateCurrentClassifierPayload } from "../../models/classifier"

export const processNewEvent = (dispatch: Dispatch, event: ClassifierEventDto) => {
    dispatch(actions.updateTrainingClassifier(event))
    switch (event.EventType) {
        case ClassifierEventType.StartingRequested:
            dispatch(actions.addNewModel(event))
            break
        case ClassifierEventType.Done:
            dispatch(actions.updateClassifierId(event))
            dispatch(actions.updateModelReceivedAt(event))
            dispatch(actions.updateModelUser(event))
            break
        case ClassifierEventType.ActivationRequested:
        case ClassifierEventType.DeactivationRequested:
        case ClassifierEventType.RollbackRequested:
            dispatch(actions.updateModelUser(event))
            break
        case ClassifierEventType.Metrics:
            dispatch(actions.updateModelMetrics(event))
            break
    }

    if (event.NewStatus) {
        dispatch(
            actions.updateClassifierStatus({ ClassifierConfigId: event.ClassifierConfigId, NewStatus: event.NewStatus })
        )
    }
}

export const updateCurrentClassifier = (
    dispatch: Dispatch,
    classifier?: UpdateCurrentClassifierPayload["Classifier"],
    replace = false
) => dispatch(actions.updateCurrentClassifier({ Classifier: classifier, Replace: replace }))
