import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Redirect, Route, Switch } from "react-router"
import * as routerPath from "./routerPaths"
import Home from "./pages/Home/Home"
import Project from "./pages/Project/Project"
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute"
import ProjectWizard from "./pages/ProjectWizard/ProjectWizard"
import Async from "./components/Async/Async"
import { selectConfigState } from "./store/config/selectors"
import FullscreenError from "./components/FullscreenError/FullscreenError"
import ConfigContext from "./components/ConfigContext/ConfigContext"
import { FrontendConfig } from "./models/config"
import NotificationContainer from "./components/NotificationContainer/NotificationContainer"
import Welcome from "./components/Welcome/Welcome"
import { ModalProvider } from "./components/ModalContext/ModalProvider"
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword"
import OpenRoute from "./components/OpenRoute/OpenRoute"
import SharedKnowledgeBase from "./pages/SharedKnowledgeBase/SharedKnowledgeBase"
import ResetPassword from "./pages/ResetPassword/ResetPassword"
import Login from "./pages/Login/Login"
import AccessDeniedPage from "./pages/AccessDenied/AccessDenied"
import SignUp from "./pages/SignUp/SignUp"
import ProjectCopyWizard from "./pages/ProjectCopyWizard/ProjectCopyWizard"
import SendSignUpEmail from "./pages/SendSignUpEmail/SendSignUpEmail"
import { useNotificationListeners } from "./utility/common/TabNotifications"
import { ThemingInjector } from "./components/ThemingInjector"
import { injectScriptToDOM } from "./helpers/dom"
import { selectAuthStatus } from "./store/auth/selectors"
import OmniLoader from "./components/OmniLoader/OmniLoader"
import { useProjectStarter } from "./hooks/useProjectStarter"

const externalScriptId = "externalScript"

const App: React.FC = () => {
    const configState = useSelector(selectConfigState)
    const authenticated = useSelector(selectAuthStatus)

    useProjectStarter()

    useEffect(() => {
        if (configState.data) {
            const { externalScript } = configState.data.WebConfig.appSettings

            if (externalScript) {
                injectScriptToDOM(externalScriptId, externalScript)
            }
        }
    }, [configState.data])

    useEffect(() => {
        document.body.classList.add("ctBody")
    }, [])

    const getCustomHtmlBody = (config: FrontendConfig) => {
        const { customHtmlBody } = config.WebConfig.appSettings
        if (customHtmlBody) {
            return <div id="customHtml" dangerouslySetInnerHTML={{ __html: customHtmlBody }} />
        }
    }
    const skipSignUpRedirect = (config: FrontendConfig) =>
        config.WebConfig.appSettings.authMode === "oidc" && config.WebConfig.appSettings.signUpEnabled

    const skipLoginPage = (config: FrontendConfig) => config.WebConfig.appSettings.authMode !== "oidc"

    useNotificationListeners(configState.data?.WebConfig)

    return (
        <>
            <OmniLoader />
            <Async<FrontendConfig>
                dataState={configState}
                errorView={({ message }) => <FullscreenError message={message} />}
            >
                {({ data }) => (
                    <>
                        {getCustomHtmlBody(data)}
                        <NotificationContainer />
                        <ConfigContext.Provider value={data}>
                            <ThemingInjector isAuthorized={authenticated} />
                            <ModalProvider>
                                <Switch>
                                    {skipLoginPage(data) && <OpenRoute path={routerPath.loginPath} component={Login} />}
                                    <OpenRoute
                                        path={routerPath.signUpPath + "/:code"}
                                        component={SignUp}
                                        preventRedirect={skipSignUpRedirect(data)}
                                    />
                                    <OpenRoute
                                        exact
                                        path={routerPath.sendSignUpEmailPath}
                                        component={SendSignUpEmail}
                                    />
                                    <ProtectedRoute exact path="/" component={Home} />
                                    <ProtectedRoute
                                        path={routerPath.projectPath + "/:projectId?"}
                                        component={Project}
                                    />
                                    <ProtectedRoute path={routerPath.createProjectPath} component={ProjectWizard} />
                                    <ProtectedRoute
                                        path={routerPath.createProjectCopyPath}
                                        component={ProjectCopyWizard}
                                    />
                                    <ProtectedRoute path={routerPath.welcomePath} component={Welcome} />
                                    <ProtectedRoute path={routerPath.accessDeniedPath} component={AccessDeniedPage} />
                                    <OpenRoute path={routerPath.forgotPasswordPath} component={ForgotPassword} />
                                    <OpenRoute
                                        path={routerPath.resetPasswordPath + "/:code"}
                                        component={ResetPassword}
                                    />
                                    <OpenRoute
                                        path={routerPath.sharedKnowledgeBasePath + "/:shareId"}
                                        component={SharedKnowledgeBase}
                                        preventRedirect
                                    />
                                    <Route render={() => <Redirect to="/" />} />
                                </Switch>
                            </ModalProvider>
                        </ConfigContext.Provider>
                    </>
                )}
            </Async>
        </>
    )
}

export default App
