import React from "react"
import { FormikAgentFormProps } from "../../AgentForm"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { AgentValues } from "../../../../models/agent"
import ArticleSelect from "../../../ArticleSelect/ArticleSelect"
import { ParamValues } from "../../../../models/parameterDeclaration"
import ExternalLink from "../../../ExternalLink/ExternalLink"
import SlotSelectControl from "../../common/SlotSelectControl"

const tNamespace = "agent:form.jqagent."
const tFormNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const jsonPayloadSlotId = "json-payload-slot-id"
const jqUrl = "https://stedolan.github.io/jq/"

const FormikJqAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, disabled, ownedByThisScenario, defaultArticleOptions } = props

    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    return (
        <AgentFormWrapper
            agentType={agentType}
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tFormNamespace}title`)}
                />
                <SlotSelectControl
                    id={jsonPayloadSlotId}
                    name={nameof2<AgentValues, ParamValues>("Params", jsonPayloadSlotId)}
                    label={t(`${tNamespace}slot-with-json`)}
                    t={t}
                />
                <DebouncedValidatableInput
                    id="formJsonQuery"
                    as="textarea"
                    className="agent-form__json-body-template"
                    name={nameof2<AgentValues, ParamValues>("Params", "json-query")}
                    label={t(`${tNamespace}jq-query`)}
                />
                <ExternalLink url={jqUrl}>{t(`${tNamespace}query-format`)}</ExternalLink>
                <ArticleSelect
                    id="formText"
                    name={nameof2<AgentValues, ParamValues>("Params", "success-message")}
                    label={t(`${tNamespace}response-on-success`)}
                    disabled={ownedByThisScenario}
                    projectId={projectId}
                    searchBySymbolCode
                    allowSearchWithoutPermissions
                    dispatch={dispatch}
                    defaultOptions={defaultArticleOptions}
                />
                <ArticleSelect
                    id="formText"
                    name={nameof2<AgentValues, ParamValues>("Params", "failure-message")}
                    label={t(`${tNamespace}response-on-failure`)}
                    disabled={ownedByThisScenario}
                    projectId={projectId}
                    searchBySymbolCode
                    allowSearchWithoutPermissions
                    dispatch={dispatch}
                    defaultOptions={defaultArticleOptions}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikJqAgentFormGeneral
