export type IPushNotificationArgs = {
    title: string
    body: string
    onClick?: (ev: Event) => void
    timeout?: number
}

export const pushNotification = async ({ title, body, onClick, timeout = 5000 }: IPushNotificationArgs) => {
    const perm = await Notification.requestPermission()

    if (perm !== "granted") {
        return
    }

    const message = new Notification(title, {
        body
    })

    message.onshow = () =>
        setTimeout(() => {
            message.close()
        }, timeout)

    if (onClick) {
        message.onclick = (e: Event) => {
            onClick(e)
            message.close()
        }
    }

    return message
}
