import React, { MouseEventHandler } from "react"
import styles from "./IconControl.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

export interface IconControlProps extends ClassProps {
    icon: IconProp
    onClick?: MouseEventHandler<HTMLDivElement>
    size?: "lg" | "xs" | "sm"
    disabled?: boolean
    testId?: string
}

const IconControl: React.FC<IconControlProps> = props => {
    const { icon, className, onClick, size = "lg", disabled, testId } = props

    return (
        <div
            className={cn(styles.iconControl, disabled && styles.iconControl_disabled, className)}
            onClick={disabled ? undefined : onClick}
            data-testid={testId}
        >
            <FontAwesomeIcon icon={icon} size={size} className="icon-control__icon" />
        </div>
    )
}

export default IconControl
