import React, { ReactNode, useCallback } from "react"
import { ClassProps } from "../../utility/common/props"
import AsyncSearchableInput, { GetLabelFunction, OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import { searchArticles } from "../../utility/knowledgeBase/articleSelect"
import { ValueType } from "react-select"
import { useTranslation } from "react-i18next"
import { Dispatch } from "../../utility/common/storeHelper"
import { AsyncProps } from "react-select/src/Async"

export const ARTICLE_OPTIONS_DEFAULT_PAGE_SIZE = 50

export interface ArticleSelectProps extends ClassProps {
    id: string
    name: string
    label?: string
    disabled?: boolean
    projectId?: string
    searchBySymbolCode?: boolean
    allowSearchWithoutPermissions?: boolean
    count?: number
    onSelect?: (option: ValueType<OptionType, false>) => void
    getLabel?: GetLabelFunction
    onChange?: (option: ValueType<OptionType, false>) => void
    dispatch: Dispatch
    useValueForDefaultOptions?: boolean
    defaultOptions?: AsyncProps<OptionType>["defaultOptions"]
    icon?: ReactNode
    filter?: string[]
}

const ArticleSelect: React.FC<ArticleSelectProps> = props => {
    const {
        className,
        id,
        name,
        label,
        disabled,
        projectId,
        searchBySymbolCode,
        allowSearchWithoutPermissions,
        count = ARTICLE_OPTIONS_DEFAULT_PAGE_SIZE,
        onSelect,
        getLabel,
        onChange,
        dispatch,
        useValueForDefaultOptions,
        defaultOptions,
        icon,
        children,
        filter
    } = props
    const { t } = useTranslation()

    const getIntentOptions = useCallback(
        searchArticles(dispatch, t, projectId, searchBySymbolCode, allowSearchWithoutPermissions, count, filter),
        [dispatch, projectId]
    )

    return (
        <AsyncSearchableInput
            id={id}
            className={className}
            label={
                <>
                    {label}
                    {icon}
                </>
            }
            name={name}
            getOptions={getIntentOptions}
            isDisabled={disabled}
            onSelect={onSelect}
            getCustomLabel={getLabel}
            useValueForDefaultOptions={useValueForDefaultOptions}
            defaultOptions={defaultOptions}
            onChange={onChange}
        >
            {children}
        </AsyncSearchableInput>
    )
}

export default ArticleSelect
