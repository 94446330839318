import React, { useEffect, useMemo } from "react"
import styles from "./ArticleSurveyForm.module.scss"
import { FieldArray, Formik, FormikProps } from "formik"
import { nameof } from "../../utility/common/nameof"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import AddButton from "../AddButton/AddButton"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { selectSlots } from "../../store/slots/selectors"
import { Article } from "../../models/article"
import { Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { getSlotTitlesMap } from "../../utility/knowledgeBase/slot"
import { selectEditedArticleSurvey } from "../../store/knowledgeBase/selectors"
import { SelectSlotFunction } from "../../pages/KnowledgeBase/KnowledgeBaseSidebar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import { setEditedArticleSurvey } from "../../store/knowledgeBase/actions"
import DraggableItem from "../DraggableItem/DraggableItem"

const tNamespace = "surveys:form."

interface ArticleSurveyValues {
    Slots: string[]
}

interface FormikArticleSurveyFormProps extends FormikProps<ArticleSurveyValues> {
    onAddSlot: (onSelect: SelectSlotFunction) => void
    isEdit?: boolean
}

const FormikArticleSurveyForm: React.FC<FormikArticleSurveyFormProps> = ({ values, onAddSlot, isEdit }) => {
    const { t } = useTranslation()

    const slots = useSelector(selectSlots)
    const slotsMap = useMemo(() => getSlotTitlesMap(slots), [slots])
    const dispatch = useDispatch()

    useEffect(() => {
        setEditedArticleSurvey(dispatch, values.Slots)
    }, [dispatch, values])

    return (
        <Form className={styles.articleSurveyForm} onKeyPress={preventSubmitOnEnter}>
            <div className={styles.articleSurveyForm__content}>
                <div className={styles.articleSurveyForm__section}>
                    <FieldArray
                        name={nameof<ArticleSurveyValues>("Slots")}
                        render={({ move, remove }) => (
                            <DragDropContext
                                onDragEnd={(result: DropResult) => {
                                    if (!result.destination || result.destination.index === result.source.index) {
                                        return
                                    }
                                    move(result.source.index, result.destination.index)
                                }}
                            >
                                <Droppable droppableId="droppable">
                                    {provided => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {values.Slots.map((slotId, index) => (
                                                <Draggable key={slotId} draggableId={slotId} index={index}>
                                                    {provided => (
                                                        <DraggableItem
                                                            title={slotsMap[slotId] ?? slotId}
                                                            onDelete={() => remove(index)}
                                                            provided={isEdit ? provided : undefined}
                                                            className={styles.articleSurveyForm__field}
                                                            isEdit={isEdit}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                            {isEdit ? (
                                                <AddButton
                                                    variant="light"
                                                    onClick={() => {
                                                        onAddSlot(slotId => {
                                                            if (values.Slots.includes(slotId)) return
                                                            setEditedArticleSurvey(dispatch, [...values.Slots, slotId])
                                                        })
                                                    }}
                                                    text={t(`${tNamespace}add-field`)}
                                                />
                                            ) : values.Slots.length ? null : (
                                                <span className={styles.articleSurveyForm__infoMessage}>
                                                    <FontAwesomeIcon
                                                        className={styles.articleSurveyForm__infoMessageIcon}
                                                        icon={faExclamationCircle}
                                                    />
                                                    {t(`${tNamespace}fields-not-added`)}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    />
                </div>
            </div>
        </Form>
    )
}

interface Props {
    projectId?: string
    article: Article
    onAddSlot: (onSelect: SelectSlotFunction) => void
    isEdit?: boolean
}

const ArticleSurveyForm: React.FC<Props> = props => {
    const { projectId, article, onAddSlot, isEdit } = props
    const editedSurvey = useSelector(selectEditedArticleSurvey)

    if (!projectId) {
        return null
    }

    return (
        <Formik
            initialValues={{
                Slots: isEdit ? editedSurvey : article.Survey ?? []
            }}
            onSubmit={() => void 0}
        >
            {formikProps => <FormikArticleSurveyForm {...formikProps} onAddSlot={onAddSlot} isEdit={isEdit} />}
        </Formik>
    )
}

export default ArticleSurveyForm
