import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { AgentValues } from "../../../../models/agent"
import ArticleSelect from "../../../ArticleSelect/ArticleSelect"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { FormikAgentFormProps } from "../../AgentForm"
import { SlotType } from "../../../../models/slot"
import SlotSelectControl from "../../common/SlotSelectControl"
import { formTranslation } from "../../../../locales/form"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"

const tNamespace = "agent:form.yandexmapgeocodeagent."
const tFormNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const FormikYandexMapGeocodeAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, disabled, ownedByThisScenario, agentOptions } = props

    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    return (
        <AgentFormWrapper
            agentType={agentType}
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tFormNamespace}title`)}
                />
                <SlotSelectControl
                    type={SlotType.Geo}
                    id="formCoordinatesSlotId"
                    name={nameof2<AgentValues, ParamValues>("Params", "coordinates_slot_id")}
                    label={t(`${tNamespace}slot-with-coordinates`)}
                    t={t}
                />
                <SlotSelectControl
                    type={SlotType.Text}
                    id="formAddressSlotId"
                    name={nameof2<AgentValues, ParamValues>("Params", "address_slot_id")}
                    label={t(`${tNamespace}slot-with-address`)}
                    t={t}
                />
                <SlotSelectControl
                    id="formLatitudeSlotId"
                    name={nameof2<AgentValues, ParamValues>("Params", "latitude_slot_id")}
                    label={t(`${tNamespace}slot-for-latitude`)}
                    t={t}
                />
                <SlotSelectControl
                    id="formLongitudeSlotId"
                    name={nameof2<AgentValues, ParamValues>("Params", "longitude_slot_id")}
                    label={t(`${tNamespace}slot-for-longitude`)}
                    t={t}
                />
                <ArticleSelect
                    id="formReplyText"
                    name={nameof2<AgentValues, ParamValues>("Params", "reply_text")}
                    label={t(`${tNamespace}success-response`)}
                    disabled={ownedByThisScenario}
                    projectId={projectId}
                    searchBySymbolCode
                    allowSearchWithoutPermissions
                    dispatch={dispatch}
                />
                <ValidatableInput
                    as="select"
                    id="formDefaultAgent"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "default_agent")}
                    label={t(`${tNamespace}failure-agent`)}
                    disabled={!agentOptions.length}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentOptions.map(({ Id }) => (
                        <option value={Id} key={Id}>
                            {Id}
                        </option>
                    ))}
                </ValidatableInput>
            </div>
        </AgentFormWrapper>
    )
}

export default FormikYandexMapGeocodeAgentFormGeneral
