import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { AgentValues, ClaimsMappingValue } from "../../../../models/agent"
import ArticleSelect from "../../../ArticleSelect/ArticleSelect"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { FormikAgentFormProps } from "../../AgentForm"
import DebouncedCheckBox from "../../../CheckBoxValidatableInput/DebouncedCheckBox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { FieldArray } from "formik"
import ClaimsMappingControl from "./ClaimsMappingControl"
import CopyInput from "../../../CopyInput/CopyInput"
import { getADFSAgentCallback } from "../../../../utility/common/urlHelper"
import CodeBlockValidatableInput from "../../../CodeBlockValidatableInput/CodeBlockValidatableInput"

const tNamespace = "agent:form.adfsagent."
const tFormNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const emptyClaimsMapping: ClaimsMappingValue = {
    claim: "",
    slot: "",
    field: "",
    fieldEnabled: false
}

const FormikADFSAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, disabled, ownedByThisScenario, values, defaultArticleOptions } =
        props

    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    return (
        <AgentFormWrapper
            agentType={agentType}
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tFormNamespace}title`)}
                />
                <DebouncedValidatableInput
                    id="formAdfsUrl"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "adfs-url")}
                    label={t(`${tNamespace}server-url`)}
                />
                <DebouncedValidatableInput
                    id="formAdfsUrl"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "redirect-url")}
                    label={t(`${tNamespace}redirect-url`)}
                />
                <DebouncedValidatableInput
                    id="formClientId"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "client-id")}
                    label={t(`${tNamespace}application-id`)}
                />
                <CodeBlockValidatableInput
                    id="formCallbackHtmlContent"
                    name={nameof2<AgentValues, ParamValues>("Params", "callback-html-content")}
                    label={t(`${tNamespace}callback-html-content`)}
                />
                <DebouncedValidatableInput
                    id="formRequiredAuthMessage"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "required-auth-message")}
                    label={t(`${tNamespace}authorization-requiring-text`)}
                />
                <ArticleSelect
                    id="formSuccessAuthMessage"
                    name={nameof2<AgentValues, ParamValues>("Params", "success-auth-message")}
                    label={t(`${tNamespace}success-auth-message`)}
                    disabled={ownedByThisScenario}
                    projectId={projectId}
                    searchBySymbolCode
                    allowSearchWithoutPermissions
                    dispatch={dispatch}
                    defaultOptions={defaultArticleOptions}
                />
                <ArticleSelect
                    id="formFailedAuthMessage"
                    name={nameof2<AgentValues, ParamValues>("Params", "failed-auth-message")}
                    label={t(`${tNamespace}failed-auth-message`)}
                    disabled={ownedByThisScenario}
                    projectId={projectId}
                    searchBySymbolCode
                    allowSearchWithoutPermissions
                    dispatch={dispatch}
                    defaultOptions={defaultArticleOptions}
                />
            </div>
            <div className="agent-form__section agent-form__section_border">
                <FieldArray
                    name={nameof2<AgentValues, ParamValues>("Params", "claims-mapping")}
                    render={({ push, remove }) => (
                        <>
                            <div className="agent-form__add-section">
                                <span className="agent-form__text">{t(`${tNamespace}adfs-claims-mapping`)}</span>
                                <span
                                    className="agent-form__button-icon"
                                    onClick={() => push({ ...emptyClaimsMapping })}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            </div>
                            {values.Params["claims-mapping"]?.map((i: ClaimsMappingValue, index: number) => (
                                <ClaimsMappingControl key={index} index={index} onDelete={() => remove(index)} t={t} />
                            ))}
                        </>
                    )}
                />
            </div>
            <div className="agent-form__section">
                <DebouncedCheckBox
                    id="formMergeUsers"
                    name={nameof2<AgentValues, ParamValues>("Params", "merge-users")}
                    label={t(`${tNamespace}merge-users`)}
                    className="agent-form__margin-top"
                />
                <div className="agent-form__adfsagent-callback">
                    <CopyInput
                        id="formCallback"
                        label={t(`${tNamespace}callback-url`)}
                        value={getADFSAgentCallback()}
                    />
                </div>
            </div>
        </AgentFormWrapper>
    )
}

export default FormikADFSAgentFormGeneral
