import { Reference } from "../../models/reference"
import { logError } from "../common/logError"

export const extractReferenceTypes = (data: Reference[]) => {
    try {
        return data.map(v => v.value.split("&&&")[0].trim())
    } catch (e) {
        logError(e, "Failed to parse reference types")
        return []
    }
}

const routing = {
    callcenter: ["DEFAULT", "ANY"],
    group: ["ANY"],
    lang: ["ANY"],
    skill: ["ANY"]
}

export const buildGetReferenceTypesRequest = (customerId: string) => [
    {
        type: "editorrecord",
        customer: [customerId],
        routing
    }
]

export const buildGetReferenceValuesRequest = (customerId: string, types: string[]) =>
    types.map(type => ({
        type,
        customer: [customerId],
        routing
    }))
