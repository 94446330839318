import { apiInstanceV1 } from "../instances"
import { IAISuggestRequest, IAISuggestItemResponse } from "../../models/Dialogs/aiSuggestDTOs"

const dialogsControllerV1 = {
    getAISuggest: ({ projectId, omniUserId, ...body }: IAISuggestRequest): Promise<IAISuggestItemResponse[]> =>
        apiInstanceV1
            .post(`dialogs/aisuggest?omniuser_id=${omniUserId}&customerId=${projectId}`, body)
            .then(response => response.data)
}

export default dialogsControllerV1
