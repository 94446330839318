import { useEffect } from "react"
import { Config } from "../../models/config"

const NOTIFICATION_TYPE = {
    DIALOG_ROUTED: "dialogRoutedNotification",
    MESSAGE: "messageNotification"
}

const resetFavicon = (faviconPath: string): void => {
    const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement

    favicon.href = faviconPath
}

const resetDocumentTitle = (): void => {
    document.title = document.title.split(" ‧ ")[1] ?? document.title
}

const resetNotification = (faviconPath: string): void => {
    resetFavicon(faviconPath)
    resetDocumentTitle()
}

const setDocumentTitle = (message: string): string =>
    document.title.includes("‧") ? `${message} ‧ ${document.title.split(" ‧ ")[1]}` : `${message} ‧ ${document.title}`

const createModifiedFavicon = (): void => {
    const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement

    if (favicon.href.startsWith("http")) {
        console.warn(
            "Favicon path does not support links to other sources (starting from 'http') in the context of displaying notifications on a tab. Specify a different path in the config and place the favicon in the project."
        )
        return
    }

    const faviconSize = 32
    const canvas = document.createElement("canvas")
    canvas.width = faviconSize
    canvas.height = faviconSize

    const context = canvas.getContext("2d")

    if (!context) {
        return
    }

    const img = new Image()
    img.src = favicon.href

    img.onload = () => {
        context.drawImage(img, 0, 0, faviconSize, faviconSize)
        context.beginPath()
        context.arc(canvas.width - faviconSize / 6, canvas.height - faviconSize / 6, faviconSize / 5, 0, 2 * Math.PI)
        // TODO: this color is not themed
        context.fillStyle = "#0067AD"
        context.fill()

        favicon.href = canvas.toDataURL("image/png")
    }
}

export const useNotificationListeners = (WebConfig?: Config, deps?: React.DependencyList): void => {
    let isNewDialog = false
    let isNewMessage = false

    useEffect(() => {
        if (!WebConfig) return

        const favPath = WebConfig.themeSettings.faviconPath

        const messageListener = (e: MessageEvent) => {
            if (e.data.notificationType) {
                if (e.data.notificationType === NOTIFICATION_TYPE.DIALOG_ROUTED && document.hidden) {
                    createModifiedFavicon()

                    isNewDialog = true
                }

                if (
                    e.data.notificationType === NOTIFICATION_TYPE.MESSAGE &&
                    e.data.messageCounter > 0 &&
                    document.hidden
                ) {
                    document.title = setDocumentTitle(e.data.messageCounter)

                    isNewMessage = true
                }
            }
        }

        const visibilityListener = () => {
            if (!document.hidden) {
                if (isNewDialog) {
                    resetFavicon(favPath)

                    isNewDialog = false
                }

                if (isNewMessage) {
                    document.title = document.title.split(" ‧ ")[1]

                    isNewMessage = false
                }

                resetNotification(favPath)
            }
        }
        window.addEventListener("message", messageListener)
        document.addEventListener("visibilitychange", visibilityListener)

        return () => {
            window.removeEventListener("message", messageListener, false)
            document.removeEventListener("visibilitychange", visibilityListener, false)
        }
    }, [WebConfig])
}

export enum NotificationsTypes {
    DialogRouted,
    NewMessage
}

type TabNotificationConfig = { type: NotificationsTypes; message?: string }

export const createTabNotification = ({ type, message }: TabNotificationConfig): void => {
    switch (type) {
        case NotificationsTypes.DialogRouted:
            if (document.hidden) {
                createModifiedFavicon()
            }
            break
        case NotificationsTypes.NewMessage:
            if (document.hidden) {
                document.title = setDocumentTitle(message ?? "")
            }
    }
}
