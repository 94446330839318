import React, { ReactNode } from "react"
import styles from "./MenuItemRow.module.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"

export interface MenuItemRowProps extends ClassProps {
    icon?: ReactNode
    title?: string
    selected?: boolean
    onClick?: () => void
    testId?: string
}

const MenuItemRow: React.FC<MenuItemRowProps> = props => {
    const { className, icon, title, onClick, selected, testId } = props
    return (
        <button
            className={cn(styles.menuItemRow, className, selected && styles.menuItemRow_selected)}
            onClick={() => onClick?.()}
            data-test-id={testId}
        >
            {icon && <div className={styles.menuItemRow__icon}>{icon}</div>}
            {title && <div className={styles.menuItemRow__title}>{title}</div>}
        </button>
    )
}

export default MenuItemRow
