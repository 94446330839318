import React, { useState } from "react"
import styles from "./PasswordControl.module.scss"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import cn from "classnames"

interface Props {
    name: string
    label: string
    testId?: string
    autocomplete?: "current-password" | "new-password"
    isPasswordSet: boolean
    isPasswordValid: boolean
}

const PasswordControl: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { name, label, autocomplete, testId, isPasswordSet, isPasswordValid } = props
    const [showPassword, setShowPassword] = useState(false)

    return (
        <div className={styles.passwordControl}>
            <ValidatableInput
                id="formPassword"
                type={showPassword ? "text" : "password"}
                name={name}
                label={label}
                className={styles.passwordControl__input}
                placeholder={t(formTranslation.enterPassword)}
                autocomplete={autocomplete}
                testId={testId}
            />
            {isPasswordSet && (
                <span
                    className={cn(
                        styles.passwordControl__iconContainer,
                        !isPasswordValid && styles.passwordControl__withError
                    )}
                >
                    <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        className={styles.passwordControl__icon}
                        onClick={() => setShowPassword(!showPassword)}
                    />
                </span>
            )}
        </div>
    )
}

export default PasswordControl
