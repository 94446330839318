import * as constants from "./constants"
import { Channel, ChannelProcessor, ChannelReadyEvent } from "../../models/channel"
import channelsController from "../../api/controllers/channels"
import { Dispatch } from "redux"
import { ChannelDeclarations } from "../../models/parameterDeclaration"
import { mapChannelToChannelDto } from "../../utility/channels/channelTranslator"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import { apiCallExceptionToSystemError } from "../../utility/common/apiCallExceptionToSystemError"
import { logError } from "../../utility/common/logError"
import { saveNotificationError } from "../notifications/thunks"
import { channelsHub } from "../../api/instances"
import { Controller } from "../../api/fake/controller"

export const subscribeOnChannelsReady = (projectId: string) => async (dispatch: Dispatch) => {
    try {
        await channelsHub.subscribe(projectId)
    } catch (e) {
        const error = apiCallExceptionToSystemError(constants.SUBSCRIBE_ON_CHANNELS_READY_FAILED, e)
        logError(error)
        saveNotificationError(dispatch, error)
    }
}

export const unsubscribeOnChannelsReady = (projectId: string) => async (dispatch: Dispatch) => {
    try {
        await channelsHub.unsubscribe(projectId)
    } catch (e) {
        const error = apiCallExceptionToSystemError(constants.UNSUBSCRIBE_ON_CHANNELS_READY_FAILED, e)
        logError(error)
        saveNotificationError(dispatch, error)
    }
}

export function getChannels(projectId: string, declarations: ChannelDeclarations) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getChannelsProcess())
            const processor: ChannelProcessor = await channelsController.getChannels(projectId)
            dispatch(actions.getChannelsSuccess({ processor, declarations }))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_CHANNELS_FAILED_MESSAGE,
                err => actions.getChannelsFailed(err),
                dispatch
            )
        }
    }
}

export function getChannelsDeclarations() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getChannelsDeclarationsProcess())
            const channelsDeclarations = await channelsController.getDeclarations()
            dispatch(actions.getChannelsDeclarationsSuccess(channelsDeclarations))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_CHANNELS_DECLARATIONS_FAILED_MESSAGE,
                err => actions.getChannelsDeclarationsFailed(err),
                dispatch
            )
        }
    }
}

export function createChannel(
    channel: Channel,
    declarations: ChannelDeclarations,
    callback: (channel: Channel) => void
) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.createChannelProcess())
            const createdChannel = await channelsController.create(mapChannelToChannelDto(channel))
            dispatch(
                actions.createChannelSuccess({
                    channel: createdChannel,
                    declarations
                })
            )
            callback(channel)
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_CHANNEL_FAILED_MESSAGE,
                err => actions.createChannelFailed(err),
                dispatch
            )
        }
    }
}

export function updateChannel(
    channel: Channel,
    declarations: ChannelDeclarations,
    callback: (channel: Channel) => void
) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateChannelProcess())
            const updatedChannel = await channelsController.update(mapChannelToChannelDto(channel))
            dispatch(
                actions.updateChannelSuccess({
                    channel: updatedChannel,
                    declarations
                })
            )
            callback(channel)
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_CHANNEL_FAILED_MESSAGE,
                err => actions.updateChannelFailed(err),
                dispatch
            )
        }
    }
}

export function deleteChannel(projectId: string, channelId: string, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.deleteChannelProcess())
            await channelsController.delete(projectId, channelId)
            dispatch(actions.deleteChannelSuccess(channelId))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.DELETE_CHANNEL_FAILED_MESSAGE,
                err => actions.deleteChannelFailed(err),
                dispatch
            )
        }
    }
}

export const setWebChatChannelReady = (event: ChannelReadyEvent) => async (dispatch: Dispatch) => {
    try {
        const { ProjectId, ChannelId } = event
        const data = await channelsController.getWebChatAdditionalData(ProjectId, ChannelId)
        dispatch(actions.setWebChatAdditionalData({ channelId: ChannelId, data }))
    } catch (e) {
        handleHttpException(
            e,
            constants.GET_WEBCHAT_CHANNEL_ADDITIONAL_DATA_MESSAGE,
            err => actions.updateChannelFailed(err),
            dispatch
        )
    }
}

export function getWebhooksPrefixes() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getWebhooksPrefixesProcess())
            const webhooksPrefixes = await channelsController.getWebhooksPrefixes()
            dispatch(actions.getWebhooksPrefixesSuccess(webhooksPrefixes))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_CHANNELS_DECLARATIONS_FAILED_MESSAGE,
                err => actions.getWebhooksPrefixesFailed(err),
                dispatch
            )
        }
    }
}

export function getChannelsNames(projectId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getChannelsNamesProcess())
            const channelNames = await channelsController.getChannelNames(projectId)
            dispatch(actions.getChannelsNamesSuccess(channelNames))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_CHANNELS_FAILED_MESSAGE,
                err => actions.getChannelsNamesFailed(err),
                dispatch
            )
        }
    }
}

export function getChannelScenario() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getChannelScenarioProcess())
            const scenario = await Controller.getChannelScenario
            dispatch(actions.getChannelScenarioSuccess(scenario))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_CHANNEL_SCENARIO_FAILED_MESSAGE,
                err => actions.getChannelScenarioFailed(err),
                dispatch
            )
        }
    }
}
