import React from "react"
import { FormControlProps } from "react-bootstrap/FormControl"
import { FormValue } from "../../types/FormValue"
import { useTranslation } from "react-i18next"
import { useField } from "formik"
import { Form } from "react-bootstrap"

export interface BaseValidatableInputProps extends FormControlProps {
    id?: string
    name: string
    label?: string
    placeholder?: string
    rows?: string
    cols?: string
    icon?: JSX.Element
    autocomplete?: "on" | "off" | string
    testId?: string
    children?: React.ReactNode
}

function ValidatableInputBase<Value extends FormValue>(props: BaseValidatableInputProps) {
    const { rows, cols, icon, testId, label, children, autocomplete = "on", name, ...inputProps } = props
    const { t } = useTranslation()
    const [field, meta] = useField<Value>(name)
    return (
        <>
            <Form.Control
                {...inputProps}
                {...field}
                className={inputProps.as === "select" ? "select-input" : undefined}
                rows={rows}
                cols={cols}
                isInvalid={meta.touched && !!meta.error}
                autoComplete={autocomplete}
                data-test-id={testId}
            >
                {children}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{meta.error && t(meta.error)}</Form.Control.Feedback>
        </>
    )
}

export default ValidatableInputBase
