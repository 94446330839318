import React, { useCallback } from "react"
import "./RestAgentForm.scss"
import { AgentFormProps } from "../../AgentForm"
import { FormikProps } from "formik"
import { RequestValues, RestAgentValues } from "../../../../models/agent"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateAgentState, selectUpdateAgentState } from "../../../../store/agents/selectors"
import { Form } from "react-bootstrap"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import RadioButtonValidatableInput from "../../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import Slider from "../../../Slider/Slider"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import InfoIcon from "../../../InfoIcon/InfoIcon"
import DebouncedCheckBox from "../../../CheckBoxValidatableInput/DebouncedCheckBox"
import { ParamValues } from "../../../../models/parameterDeclaration"
import RequestSettingsSection from "./common/RequestSettingsSection/RequestSettingsSection"
import DocLink from "../../../DocLink/DocLink"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import ExtractParameterSettingsSection, { RestAgentRequestType } from "./common/ExtractParameterSettingsSection"

const tNamespace = "agent:form."
const tTooltipNamespace = "agent:tooltip."
const tTimeNamespace = "common:time."
const tLinkNamespace = "agent:link."

const FormikRestAgentFormGeneral: React.FC<AgentFormProps & FormikProps<RestAgentValues> & WithT> = props => {
    const { agent, agentType, t, values, handleSubmit, setFieldValue, disabled, ownedByThisScenario } = props

    const asyncState = useSelector(agent ? selectUpdateAgentState : selectCreateAgentState, shallowEqual)

    const handleTimeoutChange = useCallback(
        (v: number) => {
            setFieldValue(nameof2<RestAgentValues, RequestValues>("Requests", "TimeoutSec"), v, false)
        },
        [setFieldValue]
    )

    return (
        <AgentFormWrapper
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
            agentType={agentType}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<RestAgentValues>("Id")}
                    label={t(`${tNamespace}title`)}
                />
                <DebouncedValidatableInput
                    id="formBaseUriRequests"
                    type="text"
                    name={nameof2<RestAgentValues, RequestValues>("Requests", "BaseUri")}
                    label="URL"
                />
                {t(`${tNamespace}method`)}
                <div className="agent-form__radio-buttons">
                    <RadioButtonValidatableInput
                        inline
                        value="GET"
                        id="formMethodType1Requests"
                        name={nameof2<RestAgentValues, RequestValues>("Requests", "Method")}
                        label="GET"
                    />
                    <RadioButtonValidatableInput
                        inline
                        value="POST"
                        id="formMethodType2Requests"
                        name={nameof2<RestAgentValues, RequestValues>("Requests", "Method")}
                        label="POST"
                    />
                    <RadioButtonValidatableInput
                        inline
                        value="PUT"
                        id="formMethodType3Requests"
                        name={nameof2<RestAgentValues, RequestValues>("Requests", "Method")}
                        label="PUT"
                    />
                    <RadioButtonValidatableInput
                        inline
                        value="DELETE"
                        id="formMethodType4Requests"
                        name={nameof2<RestAgentValues, RequestValues>("Requests", "Method")}
                        label="DELETE"
                    />
                </div>
            </div>
            <div className="agent-form__section">
                <div className="agent-form__text">{t(`${tNamespace}timeout`)}</div>
                <DebouncedCheckBox
                    id="formTimeoutSecEnabled"
                    name={nameof2<RestAgentValues, RequestValues>("Requests", "TimeoutSecEnabled")}
                    label={t(`${tNamespace}wait-end-of-request`)}
                />
                <div className="extra-settings">
                    <Form.Group controlId="formTimeoutSec">
                        <Slider
                            min={0}
                            max={10}
                            step={1}
                            marks={{
                                0: `0 ${t(`${tTimeNamespace}sec`)}`,
                                10: `10 ${t(`${tTimeNamespace}sec`)}`
                            }}
                            onChange={handleTimeoutChange}
                            value={values.Requests.TimeoutSec}
                            disabled={!values.Requests.TimeoutSecEnabled}
                            style={{ padding: "5px 0" }}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="agent-form__section agent-form__section_border">
                <DebouncedValidatableInput
                    id="formSuccessMessage"
                    type="text"
                    name={nameof2<RestAgentValues, ParamValues>("Params", "success-message")}
                    label={t(`${tNamespace}success-call-message`)}
                    disabled={ownedByThisScenario}
                />
                <DebouncedValidatableInput
                    id="formFailureMessage"
                    type="text"
                    name={nameof2<RestAgentValues, ParamValues>("Params", "failure-message")}
                    label={t(`${tNamespace}failure-call-message`)}
                    disabled={ownedByThisScenario}
                />
            </div>
            <div className="agent-form__section">
                <div className="agent-form__request-subtitle">{t(`${tNamespace}request`)}</div>
            </div>
            <RequestSettingsSection sectionType="Requests" type="Headers" />
            <RequestSettingsSection sectionType="Requests" type="Query" />
            <div className="agent-form__section">
                <DebouncedCheckBox
                    id="formBodyEnabled"
                    name={nameof2<RestAgentValues, RequestValues>("Requests", "BodyEnabled")}
                    label={t(`${tNamespace}json-body-template`)}
                    icon={
                        <InfoIcon
                            id="popoverBodyRequests"
                            content={t(`${tTooltipNamespace}json-body-template`, { skipInterpolation: true })}
                        />
                    }
                />
                <div className="extra-settings">
                    <DebouncedValidatableInput
                        id="formBodyRequests"
                        as="textarea"
                        name={nameof2<RestAgentValues, RequestValues>("Requests", "Body")}
                        className="agent-form__json-body-template"
                        disabled={!values.Requests.BodyEnabled}
                    />
                </div>
            </div>
            <div className="agent-form__section">
                <div className="agent-form__section-subtitle">{t(`${tNamespace}results`)}</div>
                <DebouncedCheckBox
                    id="formHttpCodeToSlot"
                    name={nameof2<RestAgentValues, RequestValues>("Requests", "HttpCodeToSlot")}
                    label={t(`${tNamespace}http-code-to-slot`)}
                    icon={<InfoIcon id="popoverBodyEnabled" content={t(`${tTooltipNamespace}http-code-to-slot`)} />}
                />
            </div>
            <div className="agent-form__section">
                <ExtractParameterSettingsSection sectionType={RestAgentRequestType.Requests} />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikRestAgentFormGeneral
