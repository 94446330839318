import React from "react"
import styles from "./SearchResultsHeader.module.scss"
import { useTranslation } from "react-i18next"

const tNamespace = "common:search."

export interface SearchResultsHeaderProps {
    itemCount: number
}

const SearchResultsHeader: React.FC<SearchResultsHeaderProps> = props => {
    const { t } = useTranslation()
    const { itemCount } = props

    return (
        <div className={styles.searchResultsHeader}>
            {itemCount !== 0 ? `${t(`${tNamespace}results-found`)}: ${itemCount}` : t(`${tNamespace}nothing-found`)}
        </div>
    )
}

export default SearchResultsHeader
