import React, { ReactNode } from "react"
import styles from "./InfoIcon.module.scss"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import { Placement } from "react-bootstrap/Overlay"

export interface InfoIconProps {
    id: string
    title?: string
    content: ReactNode | string
    placement?: Placement
}

const InfoIcon: React.FC<InfoIconProps> = props => (
    <PopoverTrigger {...props} placement={props.placement ?? "top"}>
        <FontAwesomeIcon icon={faQuestionCircle} className={styles.infoIcon} />
    </PopoverTrigger>
)

export default InfoIcon
