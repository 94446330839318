import React, { useCallback, useEffect } from "react"
import PublicPage from "../PublicPage/PublicPage"
import { Button } from "react-bootstrap"
import newProject from "../../assets/images/newProject.png"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { selectAllProjects, selectGetProjectsState } from "../../store/projects/selectors"
import { createProjectPath, projectPath } from "../../routerPaths"
import { getProjects } from "../../store/projects/thunks"
import { push } from "connected-react-router"
import Can from "../Can/Can"
import { Supervisor } from "../../permissions"
import styles from "./Welcome.module.scss"

const tNamespace = "project:"

const Welcome: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const allProjects = useSelector(selectAllProjects)
    const fetchProjectsState = useSelector(selectGetProjectsState)
    const goToProjectWizard = useCallback(() => dispatch(push(createProjectPath)), [dispatch])

    useEffect(() => {
        if (!allProjects) {
            dispatch(getProjects())
        }
    }, [allProjects, dispatch])

    useEffect(() => {
        const projects = fetchProjectsState.data
        if (projects && projects.length) {
            dispatch(push(projectPath))
        }
    }, [dispatch, fetchProjectsState])

    return (
        <PublicPage hideLanguageSelector={true} hidePrivacyPolicy={true} hideTermOfUse={true} contentInCenter>
            <Can permission={Supervisor}>
                {(can: boolean) => (
                    <div className={styles.welcome__content}>
                        <h1 className={styles.welcome__title}>{t(`${tNamespace}no-projects-title`)}</h1>
                        <p className={styles.welcome__message}>
                            {can
                                ? t(`${tNamespace}no-projects-message`)
                                : t(`${tNamespace}no-projects-no-supervisor-message`)}
                        </p>
                        <div className={styles.welcome__image}>
                            <img src={newProject} alt="" />
                        </div>
                        {can && (
                            <Button className={styles.welcome__button} onClick={goToProjectWizard}>
                                {t(`${tNamespace}no-projects-create`)}
                            </Button>
                        )}
                    </div>
                )}
            </Can>
        </PublicPage>
    )
}

export default Welcome
