export const nameof = <T>(name: keyof T) => name.toString()

export const nameof2 = <T1, T2>(name1: keyof T1, name2: keyof T2) => `${String(name1)}.${String(name2)}`

export const nameof3 = <T1, T2, T3>(name1: keyof T1, name2: keyof T2, name3: keyof T3) =>
    `${String(name1)}.${String(name2)}.${String(name3)}`

export const nameof4WithArrayOn3 = <T1, T2, T3, T4>(
    name1: keyof T1,
    name2: keyof T2,
    name3: keyof T3,
    name4: keyof T4,
    index: number
) => `${String(name1)}.${String(name2)}.${String(name3)}[${index}].${String(name4)}`
