import React, { useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { GlobalUser } from "../../models/globalUser"
import Async from "../Async/Async"
import { selectAllUsersState } from "../../store/users/selectors"
import { getAllUsers } from "../../store/users/thunks"
import GlobalUsers from "./GlobalUsers"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import { selectAllGlobalPermissionsState } from "../../store/roles/selectors"
import { getAllGlobalPermissions } from "../../store/roles/thunks"
import SettingsTableLoader from "../SettingsTable/SettingsTableLoader"
import AsyncState from "../../core/asyncState"
import { selectGlobalUserSurvey } from "../../store/surveys/selectors"
import { GlobalUserSurvey } from "../../models/globalUserSurvey"
import { getGlobalUserSurvey } from "../../store/surveys/thunks"

interface Props {
    onSettingsClick: (user: GlobalUser, allGlobalPermissions: string[], globalUserSurvey: GlobalUserSurvey) => void
}

const GlobalUsersContainer: React.FC<Props> = props => {
    const { onSettingsClick } = props
    const dispatch = useDispatch()

    const apiState = AsyncState.merge3(
        useSelector(selectAllUsersState),
        useSelector(selectAllGlobalPermissionsState),
        useSelector(selectGlobalUserSurvey)
    )

    const loadData = useCallback(async () => {
        dispatch(getAllUsers())
        dispatch(getAllGlobalPermissions())
        dispatch(getGlobalUserSurvey())
    }, [dispatch])

    useEffect(() => {
        loadData()
    }, [loadData])

    return (
        <Async
            dataState={apiState}
            processView={<SettingsTableLoader columns={3} rows={2} rowHeight={65} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: [users, allGlobalPermissions, globalUserSurvey] }) => (
                <GlobalUsers
                    users={users}
                    onSettingsClick={v => onSettingsClick(v, allGlobalPermissions, globalUserSurvey)}
                    globalUserSurvey={globalUserSurvey}
                />
            )}
        </Async>
    )
}

export default GlobalUsersContainer
