import { apiInstance } from "../instances"
import { NotificationPayload } from "../../models/notification"

const notificationController = {
    getNews: (): Promise<NotificationPayload[]> => apiInstance.get(`/news`).then(response => response.data),
    clearNews: (): Promise<NotificationPayload[]> => apiInstance.delete(`/news`).then(response => response.data),
    approve: (notificationId: string): Promise<void> => apiInstance.post(`/news/approve/${notificationId}`),
    approveNewsByUsersLogin: (usersLogin: string[], articleSymbolCode: string): Promise<void> =>
        apiInstance
            .post(`news/approve/article-delete/${articleSymbolCode}`, usersLogin)
            .then(response => response.data),
    deleteNews: (notificationId: string): Promise<void> => apiInstance.post(`/news/delete/${notificationId}`)
}

export default notificationController
