import { TFunction } from "i18next"

export type AppError = unknown
export const UNKNOWN_ERROR = "Unknown error"

export const isError = (appError: AppError): appError is Error => {
    if (typeof appError !== "object" || appError === null) {
        return false
    }

    return "name" in appError && "message" in appError
}

export interface LocalizedError {
    reason: string
    reasonKey: string
}

export const isLocalizedError = (appError: AppError): appError is LocalizedError => {
    if (typeof appError !== "object" || appError === null) {
        return false
    }

    return "reason" in appError && "reasonKey" in appError
}

export interface SystemError {
    contextKey: string
    error: AppError
}

export const createSystemError = (contextKey: string, error: AppError): SystemError => {
    return {
        contextKey,
        error
    }
}

export const getMessageWithTranslationKey = (error: AppError): [string, string?] => {
    if (isLocalizedError(error)) {
        return [error.reason, error.reasonKey]
    }

    if (isError(error)) {
        return [error.message, undefined]
    }

    if (typeof error === "string") {
        return [error, undefined]
    }

    return [UNKNOWN_ERROR, undefined]
}

export const getAppErrorMessage = (error: AppError, t: TFunction): string => {
    if (isLocalizedError(error)) {
        return t(error.reasonKey)
    }

    if (isError(error)) {
        return error.message
    }

    if (typeof error === "string") {
        return error
    }

    return UNKNOWN_ERROR
}

export const getSystemErrorMessage = (error: SystemError, t: TFunction): string => {
    return `${t(error.contextKey)}: ${getAppErrorMessage(error.error, t)}`
}
