import React from "react"
import { ArticleComment } from "../../models/articleComment"
import { useTranslation } from "react-i18next"
import UserCard from "../UserCard/UserCard"
import { formatDistanceToNowLocal } from "../../utility/common/formatDate"
import { Button } from "react-bootstrap"
import styles from "./ArticleCommentComponent.module.scss"
import Linkify from "linkify-react"

const tNamespace = "knowledgeBase:comment."

export interface ArticleCommentComponentProps {
    data: ArticleComment
    onThreadOpen?: (articleComment: ArticleComment) => void
}

const ArticleCommentComponent: React.FC<ArticleCommentComponentProps> = props => {
    const { data, onThreadOpen } = props
    const { t } = useTranslation()

    const handleThreadOpen = () => onThreadOpen && onThreadOpen(data)

    return (
        <div className={styles.articleComment}>
            <div className={styles.articleComment__top}>
                <div className={styles.articleComment__user}>
                    <UserCard
                        firstName={data.User.FirstName}
                        lastName={data.User.LastName}
                        picture={data.User.Picture}
                    />
                </div>
                <div className={styles.articleComment__date}>{formatDistanceToNowLocal(data.AddedTimestamp)}</div>
            </div>
            <div className={styles.articleComment__body}>
                <Linkify options={{ target: "_blank" }}>{data.Message.Text}</Linkify>
            </div>
            {onThreadOpen && (
                <div className="article-comment__bottom">
                    <Button variant="link" onClick={handleThreadOpen}>
                        {data.ReplyCount && data.ReplyCount > 0
                            ? data.ReplyCount === 1
                                ? "1 " + t(`${tNamespace}one-answer`)
                                : `${data.ReplyCount.toString()} ` + t(`${tNamespace}many-answers`)
                            : t(`${tNamespace}no-answers`)}
                    </Button>
                </div>
            )}
        </div>
    )
}

export default ArticleCommentComponent
