import { MenuItem } from "../../models/menu"
import { OldMenuPermissions } from "../menu/oldMenuPermissions"
import { isPermissionExists } from "../common/usePermissionsCheck"

export const getAllowedMenuItems = (items: MenuItem[], currentPermissions: Set<string>) => {
    return items.filter(i => {
        if (!i.role) {
            return true
        }
        let result = []
        const localMenuPermission = OldMenuPermissions[i.id]
        if (localMenuPermission) {
            result.push(localMenuPermission)
        }
        if (Array.isArray(i.role)) {
            result = result.concat(i.role)
        } else {
            result.push(i.role)
        }

        if (i.rolesValidator) {
            return i.rolesValidator(result, currentPermissions)
        }

        return result.every(p => isPermissionExists(p, currentPermissions))
    })
}
