import React, { useMemo } from "react"
import styles from "./ArticleAnswersForm.module.scss"
import { ArticleAnswer, ArticleAnswersFormValues } from "../../models/article"
import { SlotDto, SlotType } from "../../models/slot"
import { TFunction } from "react-i18next"
import { FieldArray, Formik, FormikProps, useFormikContext } from "formik"
import { nameof } from "../../utility/common/nameof"
import { Button, Form } from "react-bootstrap"
import { Select } from "../Select/Select"
import { ValueType } from "react-select"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import { formTranslation } from "../../locales/form"
import * as Yup from "yup"
import FormikArticleAnswersFormItem from "./FormikArticleAnswersFormItem"
import { v4 as uuidV4 } from "uuid"
import { getSlotById } from "../../utility/knowledgeBase/slot"

const tNamespace = "knowledgeBase:"

interface Props {
    t: TFunction
    slots: SlotDto[]
    onCancel: () => void
    onSelectAnswer?: (answerId: string) => void
    answer?: ArticleAnswer
    index?: number
}

const FormikArticleAnswersForm: React.FC<Props & FormikProps<ArticleAnswer>> = props => {
    const { submitForm, t, slots, values, onCancel } = props

    const slotOptions = useMemo(() => {
        const customCondition = {
            label: t(`${tNamespace}answers-modal.custom-condition`),
            value: ""
        }
        const formattedSlots = slots.map(s => ({ label: s.Title, value: s.ExternalId }))
        return [customCondition, ...formattedSlots]
    }, [slots, t])

    return (
        <div className={styles.articleAnswersForm}>
            <FieldArray
                name={nameof<ArticleAnswer>("Slots")}
                render={({ remove, push }) => (
                    <>
                        <Form.Group className={styles.articleAnswersForm__addSlot} controlId="addSlotToAnswer">
                            <Select
                                menuPlacement="auto"
                                value={{
                                    label: t(`${tNamespace}slot.select`),
                                    value: ""
                                }}
                                isDisabled={!slots.length}
                                options={slotOptions}
                                onChange={(option: ValueType<OptionType, false>) => {
                                    if (option) {
                                        const slot = getSlotById(option.value, slots)
                                        push({
                                            SlotId: option.value,
                                            Values: slot && slot.Type === SlotType.Dictionary ? [] : [""]
                                        })
                                    }
                                }}
                            />
                        </Form.Group>
                        <div className={styles.articleAnswersForm__slots}>
                            {values.Slots.map((s, i) => {
                                const slot = getSlotById(s.SlotId, slots)
                                return (
                                    <FormikArticleAnswersFormItem
                                        key={i}
                                        t={t}
                                        slot={slot}
                                        index={i}
                                        name={`${nameof<ArticleAnswer>("Slots")}[${i}]`}
                                        onRemove={() => remove(i)}
                                    />
                                )
                            })}
                        </div>
                    </>
                )}
            />
            <div className={styles.articleAnswersForm__bottom}>
                <Button variant="light" className={styles.articleAnswersForm__cancelButton} onClick={onCancel}>
                    {t(formTranslation.cancel)}
                </Button>
                <Button onClick={submitForm}>{t(formTranslation.save)}</Button>
            </div>
        </div>
    )
}

const ArticleAnswersForm: React.FC<Props> = props => {
    const { answer, index, onCancel, onSelectAnswer } = props
    const { values: formValues, setFieldValue, submitForm } = useFormikContext<ArticleAnswersFormValues>()

    const onSubmit = (values: ArticleAnswer) => {
        onCancel()
        if (answer && index) {
            setFieldValue(`${nameof<ArticleAnswersFormValues>("Answers")}[${index}]`, values)
        } else {
            setFieldValue(`${nameof<ArticleAnswersFormValues>("Answers")}`, [...formValues.Answers, values])
        }
        submitForm().then(() => onSelectAnswer?.(values.Id))
    }
    const getInitialValues = () =>
        answer
            ? answer
            : {
                  Id: uuidV4(),
                  Text: "/",
                  Slots: []
              }

    return (
        <Formik
            initialValues={getInitialValues()}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
                Id: Yup.string(),
                Title: Yup.string(),
                Slots: Yup.array().of(
                    Yup.object().shape({
                        SlotId: Yup.string(),
                        Values: Yup.array()
                            .of(Yup.string().requiredExcludeEmpty(`${tNamespace}answers-modal.non-empty-slot-values`))
                            .min(1, `${tNamespace}answers-modal.non-empty-slot-values`)
                    })
                )
            })}
        >
            {formikProps => <FormikArticleAnswersForm {...formikProps} {...props} />}
        </Formik>
    )
}

export default ArticleAnswersForm
