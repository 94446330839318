export const getAllSiblings = <T extends ChildNode>(elemOrig: HTMLElement, filter: (_: T) => boolean) => {
    const sibs: T[] = []

    let elem = elemOrig.parentNode?.firstChild as T

    if (!elem) {
        return sibs
    }

    do {
        if (elem.nodeType === 3) {
            continue
        }

        if (!filter || filter(elem)) {
            sibs.push(elem)
        }
    } while ((elem = elem.nextSibling as T))

    return sibs
}

export const injectScriptToDOM = (id: string, body: string) =>
    new Promise<{ id: string; body: string; e: Event }>((resolve, reject) => {
        const scriptTag = document.getElementById(id)

        if (!scriptTag) {
            const element = document.createElement("script")
            element.defer = true
            element.id = id
            element.innerHTML = body

            document.body.appendChild(element)
            element.onload = e => resolve({ id, body, e })
            element.onerror = e => reject(e)
        }
    })
