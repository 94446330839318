import { UpdateOperatorQueuesRequest } from "../../models/operator"
import { Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import operatorsController from "../../api/controllers/operators"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import { DeleteFromQueueRequest } from "../../models/operator"
import { OperatorDtoConverter } from "../../utility/operators/convert"
import { RootState } from "../rootReducer"

export function getOperators(tenantId: string) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(actions.getOperatorsProcess())
        try {
            const state = getState()
            const activeOperatorsDto = await operatorsController.getActiveByTenant(tenantId)
            const activeOperators = activeOperatorsDto.map(s =>
                OperatorDtoConverter.toOperator(s, state.userOperator.statuses)
            )
            dispatch(actions.getOperatorsSuccess(activeOperators))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_OPERATORS_FAILED_MESSAGE,
                err => actions.getOperatorsFailed(err),
                dispatch
            )
        }
    }
}

export function deleteFromQueue(operatorId: string, request: DeleteFromQueueRequest) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.deleteFromQueueProcess())
            await operatorsController.deleteFromQueue(operatorId, request)
            dispatch(
                actions.deleteFromQueueSuccess({
                    OperatorId: operatorId,
                    TenantId: request.TenantId,
                    QueueId: request.QueueId
                })
            )
        } catch (e) {
            handleHttpException(
                e,
                constants.DELETE_FROM_QUEUE_FAILED_MESSAGE,
                err => actions.deleteFromQueueFailed(err),
                dispatch
            )
        }
    }
}

export function updateQueues(operatorId: string, request: UpdateOperatorQueuesRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateQueuesProcess())
            const queues = await operatorsController.updateQueues(operatorId, request)
            dispatch(
                actions.updateQueuesSuccess({
                    OperatorId: operatorId,
                    TenantQueues: queues
                })
            )
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_OPERATOR_QUEUES_FAILED_MESSAGE,
                err => actions.updateQueuesFailed(err),
                dispatch
            )
        }
    }
}

export function makeOffline(operatorId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.makeOfflineProcess())
            await operatorsController.makeOffline(operatorId)
            dispatch(actions.makeOfflineSuccess())
        } catch (e) {
            handleHttpException(
                e,
                constants.MAKE_OFFLINE_FAILED_MESSAGE,
                err => actions.makeOfflineFailed(err),
                dispatch
            )
        }
    }
}
