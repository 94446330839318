export interface ClassifierConfigValue {
    Name: string
    AssistantClassificationResultThreshold: number
    BotClassificationResultThreshold: number
    ClassifierWeight: number
    ClassifierWebhook: string
}

export interface ClassifierConfigurationProcessed extends ClassifierConfigDto {
    ArticleTypes: string[]
    ExcludeArticleTypes: string[]
    Tags: string[]
    ExcludeTags: string[]
    LinkedClassifierConfigId?: string
}

export const isClassifierConfigurationProcessed = (
    classifier: ClassifierConfigurationProcessed | ClassifierConfigDto
): classifier is ClassifierConfigurationProcessed => "Tags" in classifier

export interface DatasetFilter {
    FilterKind: DatasetFilterKind
    ElasticType: TypeForTraining
    Fields?: DatasetFilterField[]
    QuestionCount?: QuestionCount
}

interface QuestionCount {
    Lt?: number
    Gt?: number
    Lte?: number
    Gte?: number
}

interface DatasetFilterField {
    FieldName: string
    Value: string
    Compare?: string
}

export enum DatasetFilterKind {
    ByFieldValue = "ByFieldValue",
    BySampleCount = "BySampleCount"
}

export enum TypeForTraining {
    IntentType = "IntentType",
    MessageType = "MessageType"
}

export enum ClassifierType {
    WMD = "WMD",
    GRU = "GRU",
    HAN = "HAN",
    W2V_NN = "W2V_NN",
    COMPOUND = "COMPOUND",
    LOGREG = "LOGREG",
    KNN = "KNN",
    KNN_ELASTIC = "KNN_ELASTIC",
    MATCH = "MATCH",
    BERT = "BERT",
    RULE_BASED = "RULE_BASED"
}

export interface ClassifierFormValues {
    ClassifierConfigId: string
    ClassifierId: string
    Type: ClassifierType
    Url: string
    Name: string
    Language: ClassifierLanguage
    UseSpellCorrection: boolean
    DoSynonymousAugmentation: boolean
    SynonymousAugmentationSlots: string[]
    Preprocessor: string
    SlotThreshold: number
    PlusStopWords: string[]
    MinusStopWords: string[]
    Tags: string[]
    ExcludeTags: string[]
    ArticleTypes: string[]
    ExcludeArticleTypes: string[]
}

export enum ClassifierStatus {
    Starting = "Starting",
    Loading = "Loading",
    Training = "Training",
    Active = "Active",
    Stopped = "Stopped"
}

export enum ClassifierLanguage {
    Russian = "Russian",
    English = "English",
    Uzbek = "Uzbek"
}

export enum ClassifierFilterValueType {
    Tags = "tags",
    RecordType = "record_type",
    Status = "status"
}

export enum ClassifierFilterCompareKind {
    Fnmatch = "Fnmatch",
    Lt = "Lt",
    Le = "Le",
    Eq = "Eq",
    Ne = "Ne",
    Ge = "Ge",
    Gt = "Gt"
}

export interface ClassifierConfigDto {
    ClassifierId: string
    ClassifierConfigId: string
    Name: string
    ProjectId: string
    Url: string
    UseSpellCorrection: boolean
    DoSynonymousAugmentation: boolean
    SynonymousAugmentationSlots: string[]
    SlotThreshold?: number
    PlusStopWords: string[]
    MinusStopWords: string[]
    PreprocessorBuilder: string
    Language: ClassifierLanguage
    TypesForTraining: TypeForTraining[]
    Type: ClassifierType
    Status: ClassifierStatus
    Active: boolean
    CompoundClassifiers?: CompoundClassifier[]
    DatasetFilters: DatasetFilter[]
    ChangedAt: string
    Version: string
    Folder?: Readonly<string>
}

interface CompoundClassifier {
    ClassifierName: string
}

export type ClassifierConfigDtoWithoutId = Omit<ClassifierConfigDto, "ClassifierId">
export interface CreateClassifierRequest {
    Classifier: ClassifierConfigDtoWithoutId
}

export interface UpdateClassifierRequest {
    Classifier: ClassifierConfigDto
}

export interface StartClassifierRequest {
    Active: boolean
    RetrainOptions: RetrainClassifierOptions
}

export interface RetrainClassifierOptions {
    RetrainModel: boolean
    RetrainEmbeddings: boolean
    RetrainSpellCorrector: boolean
}

export interface RollbackClassifierRequest {
    ClassifierId: string
}

export interface CurrentClassifier {
    ClassifierId: string
    ClassifierConfigId: string
    Status: ClassifierStatus
    Active: boolean
    Type?: ClassifierType
    LinkedClassifierConfigId?: string
    Version: string
}

export interface UpdateCurrentClassifierPayload {
    Classifier?: ClassifierConfigDto | ClassifierConfigurationProcessed
    Replace: boolean
}
