import React, { useContext } from "react"
import "./ConditionNodeLines.scss"
import { ConditionStatement, ConditionType, SlotSpecialValue } from "../../../../../models/scenarioCondition"
import { TFunction, useTranslation } from "react-i18next"
import { ArticleScenarioContext } from "../../../../ScenarioEditor/ScenarioContext"
import { SlotsMapType, SlotType } from "../../../../../models/slot"
import { DraggableStateSnapshot } from "react-beautiful-dnd"
import { useResizeFontWhenZoom } from "../../../../../utility/scenario/useResizeFontWhenZoom"

const tNamespace = "scenarioEditor:"

export const conditionsToString = (condition: ConditionStatement, slotsMap: SlotsMapType, t: TFunction) => {
    switch (condition.Slot) {
        case SlotSpecialValue.AllMandatoryFilledIn:
            return t(`${tNamespace}all-required-fields-filled`)
        case SlotSpecialValue.ArbitraryExpression:
            return condition.Value
        case SlotSpecialValue.WithoutConditions:
            return t(`${tNamespace}without-conditions`)
        default:
            const slot = slotsMap[condition.Slot]
            const title = slot?.Title ?? condition.Slot

            if (condition.Condition === ConditionType.Filled) {
                return `${title}: ${t(`${tNamespace}filled`).toLowerCase()}`
            }

            if (condition.Condition) {
                if (condition.Condition === ConditionType.Attempts) {
                    return `${t(`${tNamespace}attempts`)}: ${title} ${condition.Value}`
                }

                // Support old version for attempts
                return `${t(`${tNamespace}attempts`)}: ${title} ${condition.Condition} ${condition.Value}`
            }

            const value =
                slot?.Type === SlotType.Dictionary && condition.Value
                    ? slot.DomainMap[condition.Value]?.Title ?? condition.Value
                    : condition.Value

            return `${title}: "${value}"`
    }
}

interface Props {
    conditions: ConditionStatement[]
    snapshot?: DraggableStateSnapshot
}

const ConditionNodeLines: React.FC<Props> = ({ conditions, snapshot }) => {
    const { t } = useTranslation()
    const { slotsMap } = useContext(ArticleScenarioContext)
    const resizableRef = useResizeFontWhenZoom<HTMLDivElement>(snapshot as DraggableStateSnapshot)

    return (
        <>
            {conditions.map(condition => (
                <div className="condition-node-line" key={condition.Slot} ref={resizableRef}>
                    {conditionsToString(condition, slotsMap, t)}
                </div>
            ))}
        </>
    )
}

export default ConditionNodeLines
