import { Dispatch } from "../common/storeHelper"
import knowledgeBaseController from "../../api/controllers/knowledgeBase"
import { Column } from "react-table"
import { GetMessageMarkupRequest, QuestionType, SampleQuestionType, ArticleQuestion } from "../../models/article"
import { handleHttpExceptionWithoutAction } from "../../store/handleHttpException"
import * as knowledgeBaseConstants from "../../store/knowledgeBase/constants"
import { getId } from "../scenario/scenario"

export const RESULTS_PER_PAGE = 50

export const tableColumns: Array<Column<SampleQuestionType>> = [
    {
        Header: "Id",
        accessor: "id"
    },
    {
        Header: "Text",
        accessor: "text"
    },
    {
        Header: "Type",
        accessor: "type"
    }
]

export const toManualSampleQuestion = (question: ArticleQuestion | string) => ({
    id: getId("question"),
    text: typeof question === "string" ? question : question.Sentence,
    type: QuestionType.Manual
})

export const toArticleQuestions = (s: SampleQuestionType): ArticleQuestion => ({
    Sentence: s.text
})

export const getResultsForPage = async (
    markupId: string,
    start: number,
    dispatch: Dispatch,
    length = RESULTS_PER_PAGE
) => {
    const request: GetMessageMarkupRequest = {
        start,
        length,
        search: { value: "", regex: false }
    }

    try {
        return await knowledgeBaseController.getMessageMarkup(markupId, request)
    } catch (e) {
        handleHttpExceptionWithoutAction(e, knowledgeBaseConstants.GET_MESSAGE_MARKUP_FAILED, dispatch)
    }
}

export const handleRemoveMessageMarkup =
    (dispatch: Dispatch) =>
    async (markupId: string, messageIds: string[], callback?: () => void): Promise<void> => {
        try {
            await knowledgeBaseController.removeMessageMarkup(markupId, messageIds)
            callback && callback()
        } catch (e) {
            handleHttpExceptionWithoutAction(e, knowledgeBaseConstants.REMOVE_MESSAGE_MARKUP_FAILED, dispatch)
        }
    }

export const handleRemoveAllMessageMarkup =
    (dispatch: Dispatch) =>
    async (markupId: string, callback: () => void): Promise<void> => {
        try {
            await knowledgeBaseController.removeAllMessageMarkup(markupId)
            callback()
        } catch (e) {
            handleHttpExceptionWithoutAction(e, knowledgeBaseConstants.REMOVE_ALL_MESSAGE_MARKUP_FAILED, dispatch)
        }
    }
