import React, { useEffect } from "react"
import { Article } from "../../models/article"
import { handleUpdateArticleFormQuestions } from "../../store/knowledgeBase/actions"
import { useDispatch } from "react-redux"
import { KnowledgeBasePermittedAction } from "../../models/knowledgeBasePermission"
import { useModifyPermitted } from "../../utility/knowledgeBase/useModifyPermitted"
import FullscreenError from "../FullscreenError/FullscreenError"
import { errorTranslation } from "../../locales/error"
import { useTranslation } from "react-i18next"

interface Props {
    article?: Article
    permittedAction?: KnowledgeBasePermittedAction
    transformToScenario: boolean
    articleCode: string
    blocked: JSX.Element
    shareId?: string
    scenario: (article: Article, canModify: boolean) => JSX.Element
    classic: (article: Article, canModify: boolean) => JSX.Element
}

const ArticleViewProcessor: React.FC<Props> = props => {
    const { article, permittedAction, articleCode, blocked, transformToScenario, scenario, classic, shareId } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const canModifyArticle = useModifyPermitted(permittedAction)

    useEffect(() => {
        if (permittedAction && article) {
            handleUpdateArticleFormQuestions(article.Questions ?? [], dispatch)
        }
    }, [dispatch, article, permittedAction])

    if (!permittedAction) {
        return blocked
    }

    if (!article || (article.SymbolCode !== articleCode && article.ExtId && article.ExtId !== articleCode)) {
        return <FullscreenError message={t(errorTranslation.articleNotFound)} hideLogo />
    }

    if (article.Scenario && shareId) {
        // Scenarios are not support in a sharing mode
        return <FullscreenError message={t(errorTranslation.articleNotFound)} hideLogo />
    }

    if (article.Scenario || transformToScenario) {
        return scenario(article, canModifyArticle)
    } else {
        return classic(article, canModifyArticle)
    }
}

export default ArticleViewProcessor
