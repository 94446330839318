import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { VerifySignUpCodeResponse } from "../../models/signUp"
import { User } from "../../models/user"
import { resetReducerState } from "../action"

export type SignUpState = Readonly<{
    sendSignUpEmail: AsyncState<string>
    verifySignUpCode: AsyncState<VerifySignUpCodeResponse>
    signUpByEmail: AsyncState<void>
}>

const initialState: SignUpState = {
    sendSignUpEmail: AsyncState.create(),
    verifySignUpCode: AsyncState.create(),
    signUpByEmail: AsyncState.create()
}

const signUp = createSlice({
    name: "signUp",
    initialState,
    reducers: {
        sendSignUpEmailClear(state) {
            state.sendSignUpEmail = AsyncState.create()
        },
        sendSignUpEmailProcess(state) {
            state.sendSignUpEmail = state.sendSignUpEmail.toProcess()
        },
        sendSignUpEmailSuccess(state, action: PayloadAction<string>) {
            state.sendSignUpEmail = state.sendSignUpEmail.toSuccess(action.payload)
        },
        sendSignUpEmailFailed(state, action: PayloadAction<SystemError>) {
            state.sendSignUpEmail = state.sendSignUpEmail.toFailed(action.payload)
        },
        verifySignUpCodeProcess(state) {
            state.verifySignUpCode = state.verifySignUpCode.toProcess()
        },
        verifySignUpCodeSuccess(state, action: PayloadAction<VerifySignUpCodeResponse>) {
            state.verifySignUpCode = state.verifySignUpCode.toSuccess(action.payload)
        },
        verifySignUpCodeFailed(state, action: PayloadAction<SystemError>) {
            state.verifySignUpCode = state.verifySignUpCode.toFailed(action.payload)
        },
        signUpByEmailProcess(state) {
            state.signUpByEmail = state.signUpByEmail.toProcess()
        },
        signUpByEmailSuccess(state, action: PayloadAction<User>) {
            state.signUpByEmail = state.signUpByEmail.toSuccess()
        },
        signUpByEmailFailed(state, action: PayloadAction<SystemError>) {
            state.signUpByEmail = state.signUpByEmail.toFailed(action.payload)
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export default signUp.reducer

export const actions = signUp.actions
