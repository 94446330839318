import React from "react"
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingButton from "../LoadingButton/LoadingButton"
import styles from "./ArticleReadConfirmationCard.module.scss"

export interface ArticleReadConfirmationCardProps {
    title: string
    buttonTitle: string
    loading: boolean
    onConfirm: () => void
}

const ArticleReadConfirmationCard: React.FC<ArticleReadConfirmationCardProps> = props => {
    const { title, buttonTitle, loading, onConfirm } = props

    return (
        <div className={styles.articleReadConfirmationCard}>
            <div className={styles.articleReadConfirmationCard__header}>
                <div className={styles.articleReadConfirmationCard__title}>{title}</div>
            </div>
            <div className={styles.articleReadConfirmationCard__body}>
                <div className="article-read-confirmation-card__confirm">
                    <LoadingButton
                        className="article-read-confirmation-card__confirm-button"
                        loading={loading}
                        variant="primary"
                        onClick={onConfirm}
                    >
                        <FontAwesomeIcon
                            className={styles.articleReadConfirmationCard__confirmIcon}
                            icon={faCheck}
                            size="lg"
                        />
                        {buttonTitle}
                    </LoadingButton>
                </div>
            </div>
        </div>
    )
}

export default ArticleReadConfirmationCard
