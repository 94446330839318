import {
    Agent,
    AgentType,
    AgentValues,
    ClaimsMapping,
    EmailType,
    ExtractParameterValue,
    ExtractParameterDto,
    ExtractParametersType,
    RequestDto,
    RequestValues,
    RestAgentValues,
    RoutingAgentValues,
    ScheduleDto,
    ScheduleValue,
    IdentificationAgentConfigItem
} from "../../models/agent"
import { SlotDto } from "../../models/slot"
import { ParameterDeclaration } from "../../models/parameterDeclaration"
import { logError } from "../common/logError"
import { extractParams } from "./agentTranslator"
import { getDefaultParams } from "../common/parametersTranslator"
import cloneDeep from "lodash/cloneDeep"
import { getSlotById } from "../knowledgeBase/slot"
import { Time } from "../common/time"
import { channelTypes } from "../../components/ChannelTypeForm/ChannelTypeForm"
import {
    OverrideChannelTypeParamType,
    OverrideChannelTypeValue
} from "../../components/AgentForm/agents/PsbBssAgent/OverrideChannelTypeControl"
import { mergeUsersDefault, requireAuthMessageDefaultKey } from "./agentValuesDefaults"
import { tl } from "../common/language"
import {
    RestAgentRequestType,
    SYSTEM_REST_AGENT_TOKEN_SLOT_ID
} from "../../components/AgentForm/agents/RestAgent/common/ExtractParameterSettingsSection"

const isEmpty = (param: string) => param.match(/^\s*$/)

const getRedirectToIntentFormReplyCommand = (command?: string) => {
    if (!command) return ""

    const match = command.match(/^\/redirect \S+ intent_id="([^"]+)"$/)
    return match ? match[1] : ""
}

const getSwitchRedirectToIntentFormReplyCommand = (command?: string) => {
    if (!command) return ""

    const match = command.match(/^\/switchredirect \S+ intent_id="([^"]+)"$/)
    return match ? match[1] : ""
}

const getDefaultsToOverride = (agentType: AgentType, language: string) => {
    switch (agentType) {
        case AgentType.ADFSAgent:
            return {
                "merge-users": mergeUsersDefault,
                "required-auth-message": tl(requireAuthMessageDefaultKey, language)
            }
        default:
            return {}
    }
}

export const getValuesFromAgent = (
    agent: Agent,
    declarations: ParameterDeclaration[],
    language: string
): AgentValues => {
    const params = extractParams(agent.Type, {
        ...getDefaultParams(declarations),
        ...getDefaultsToOverride(agent.Type, language),
        ...agent.Params
    })
    let schedule: ScheduleValue[] = []

    switch (agent.Type) {
        case AgentType.InitialMsgAgent:
            params.text = getSwitchRedirectToIntentFormReplyCommand(params.text)
            break
        case AgentType.ABAgent:
            params.probability = params.probability * 100
            break
        case AgentType.NightAgent:
            const parsedSchedule = params.schedule
            schedule = parsedSchedule.map((v: ScheduleDto) => ({
                NightStartHr: v.NightStartTime.slice(0, 2),
                NightStartMin: v.NightStartTime.slice(3),
                NightEndHr: v.NightEndTime.slice(0, 2),
                NightEndMin: v.NightEndTime.slice(3),
                Dates: v.Dates,
                WeekDays: Array(7)
                    .fill(0)
                    .map((_, i) => (v.WeekDays.includes(i + 1) ? 1 : 0)),
                AllDay: v.NightStartTime === "00:00" && v.NightEndTime === "23:59"
            }))
            delete params.schedule
            break
        case AgentType.EmailAgent:
            params["email-type"] = params["intent-id"] ? EmailType.Template : EmailType.Text
            break
        case AgentType.JavaScriptAgent:
            params["agent-timeout"] = Time.msToSeconds(params["agent-timeout"])
            break
        case AgentType.PsbBssAgent:
            const slotsToSplit = params["continue-session-slot-ids"]
            params["continue-session-slot-ids"] = isEmpty(slotsToSplit) ? [] : slotsToSplit.trim().split(/\s+/)

            const extensionsToSplit = params["allowed-attachment-extensions"]
            params["allowed-attachment-extensions"] = isEmpty(extensionsToSplit)
                ? []
                : extensionsToSplit
                      .trim()
                      .split(/\s+/)
                      .map((ext: string) => ext.replace(/^./, ""))

            params["override-channel-type"] = Object.entries(
                params["override-channel-type"] as OverrideChannelTypeParamType
            )
                .filter(entry => typeof entry[1] === "string" && channelTypes.includes(entry[0]))
                .map<OverrideChannelTypeValue>(([from, to]) => ({ from, to }))

            break
        case AgentType.ADFSAgent:
            params["claims-mapping"] = params["claims-mapping"].map((mapping: ClaimsMapping) => ({
                ...mapping,
                fieldEnabled: !!mapping.field
            }))
            break
        case AgentType.FixedReplyAgent:
            params.text = getRedirectToIntentFormReplyCommand(params.text)
            break
        case AgentType.IdentificationAgent:
            params["definition"] = params["definition"].map((item: IdentificationAgentConfigItem) => ({
                ...item,
                Action: item.Action.startsWith("/switchredirect") ? item.Action.split(" ")[1] : item.Action
            }))
            break
    }

    return {
        Id: agent.Id,
        NextAgent: agent.NextAgent ?? "",
        Params: params,
        ...(agent.Type === AgentType.NightAgent && { Schedule: schedule })
    }
}

export const getDefaultValues = (
    agentType: AgentType,
    declarations: ParameterDeclaration[],
    language: string
): AgentValues => {
    const agent: Agent = {
        Id: "",
        ProjectId: "",
        Type: agentType,
        NextAgent: "",
        Params: {}
    }

    return getValuesFromAgent(agent, declarations, language)
}

export const getValuesFromRoutingAgent = (agent: Agent, declarations: ParameterDeclaration[]): RoutingAgentValues => {
    const params = extractParams(AgentType.RoutingAgent, {
        ...getDefaultParams(declarations),
        ...agent.Params
    })

    return {
        Id: agent.Id,
        FailOverAgent: agent.FailOverAgent ?? "",
        allow_only_known_agents: params["allow_only_known_agents"],
        DefaultSettings: {
            call_center: params["routing:cc"],
            lang: params["routing:lang"],
            skill: params["routing:skill"],
            group: params["routing:group"],
            max_dialogs: params["routing:max_dialogs"],
            operator_id: params["routing:operator_id"],
            queue_id: params["queue_id"],
            reply_after_routing: getRedirectToIntentFormReplyCommand(params["routing:reply_after_routing_command"])
        }
    }
}

export const getDefaultRoutingAgentValues = (declarations: ParameterDeclaration[]): RoutingAgentValues => {
    const defaultAgent: Agent = {
        Id: "",
        ProjectId: "",
        Type: AgentType.RoutingAgent,
        Params: {}
    }

    return getValuesFromRoutingAgent(defaultAgent, declarations)
}

const defaultRequest = {
    Id: "",
    BaseUri: "",
    Method: "POST",
    Query: [],
    Headers: [],
    Body: "",
    TimeoutSec: 0,
    ResponseParameters: {
        HttpCodeToSlot: true,
        Ignore: false,
        ExtractParameters: []
    }
}

export const defaultRequests = {
    AuthorizationRequest: defaultRequest,
    Requests: [cloneDeep(defaultRequest)]
}

export const getValuesFromRestAgent = (
    agent: Agent,
    declarations: ParameterDeclaration[],
    slots: SlotDto[]
): RestAgentValues => {
    const params = extractParams(AgentType.RestAgent, {
        ...getDefaultParams(declarations),
        ...agent.Params
    })

    const requests = params.requests.Requests[0] ?? defaultRequests.Requests[0]
    const authorizationRequest = params.requests.AuthorizationRequest ?? defaultRequests.AuthorizationRequest

    return {
        Id: agent.Id,
        NextAgent: agent.NextAgent ?? "",
        Requests: getRequestParams(requests, RestAgentRequestType.Requests, slots),
        AuthorizationRequest: getRequestParams(authorizationRequest, RestAgentRequestType.AuthorizationRequest, slots),
        CustomApiKeyEnabled: !!params["custom-api-key"],
        RequestTokenEnabled: agent.Id ? !!params.requests.AuthorizationRequest : false,
        Params: {
            "custom-api-key": params["custom-api-key"],
            "success-message": params["success-message"],
            "failure-message": params["failure-message"]
        }
    }
}

export const getDefaultRestAgentValues = (declarations: ParameterDeclaration[], slots: SlotDto[]): RestAgentValues => {
    const agent = {
        Id: "",
        ProjectId: "",
        Type: AgentType.RestAgent,
        Params: {}
    }
    return getValuesFromRestAgent(agent, declarations, slots)
}

export const getRequestParams = (requests: RequestDto, type: RestAgentRequestType, slots: SlotDto[]): RequestValues => {
    const extractParameters = requests.ResponseParameters.ExtractParameters
    return {
        BaseUri: requests.BaseUri,
        BodyEnabled: !!requests.Body,
        Body: requests.Body,
        Headers: requests.Headers,
        Id: requests.Id,
        Method: requests.Method,
        Query: requests.Query,
        TimeoutSec: requests.TimeoutSec ?? 0,
        TimeoutSecEnabled: !!requests.TimeoutSec,
        HttpCodeToSlot: requests.ResponseParameters.HttpCodeToSlot,
        Ignore: requests.ResponseParameters.HttpCodeToSlot,
        ExtractParameters:
            type === RestAgentRequestType.AuthorizationRequest
                ? getExtractTokenParameters(extractParameters)
                : getExtractParameters(extractParameters, slots)
    }
}

const extractParameterDtoToValue = (p: ExtractParameterDto, title?: string): ExtractParameterValue => {
    const extractParameter: ExtractParameterValue = {
        Type: p.Header ? ExtractParametersType.Header : ExtractParametersType.JsonPaths,
        JsonPaths: p.JsonPaths ?? "",
        Header: p.Header ?? "",
        SlotId: p.SlotId,
        WholeResponse: p.JsonPaths === undefined
    }
    if (title) {
        extractParameter.SlotTitle = title
    }
    return extractParameter
}

const getExtractParameters = (params: ExtractParameterDto[], slots: SlotDto[]): ExtractParameterValue[] => {
    try {
        return params.reduce((params: ExtractParameterValue[], p) => {
            const slot = getSlotById(p.SlotId, slots)
            if (slot) {
                params.push(extractParameterDtoToValue(p, slot.Title))
            }
            return params
        }, [])
    } catch (e) {
        logError(e)
        return []
    }
}

const defaultExtractTokenParameter: ExtractParameterDto = {
    SlotId: SYSTEM_REST_AGENT_TOKEN_SLOT_ID
}

const getExtractTokenParameters = (params: ExtractParameterDto[]): ExtractParameterValue[] => {
    const p = params.find(p => p.SlotId === SYSTEM_REST_AGENT_TOKEN_SLOT_ID) ?? defaultExtractTokenParameter
    return [extractParameterDtoToValue(p)]
}
