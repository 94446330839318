import React from "react"
import ToggleBase, { ToggleProps } from "react-toggle"
import "react-toggle/style.css"
import "./Toggle.scss"
import cn from "classnames"

interface Props extends ToggleProps {
    small?: boolean
}

const Toggle: React.FC<Props> = props => {
    const { small, className, ...toggleProps } = props

    return <ToggleBase {...toggleProps} className={cn(className, small ? "react-toggle_small" : undefined)} />
}

export default Toggle
