import { OperatorQueuesUpdatedMsg } from "../../models/operator"
import {
    Awt,
    ExtendedSettings,
    ExtendQueueSettings,
    FinishedDialogs,
    FinishedDialogsDaily,
    MonitoringOverview,
    Queue,
    QueueAddedMsg,
    QueueAwtUpdatedMsg,
    QueueFinishedDialogsDailyUpdatedMsg,
    QueueFinishedDialogsUpdatedMsg,
    QueueOperatorTasksCountUpdatedMsg,
    QueueSlUpdatedMsg,
    QueuesWithMonitoringOverviewResponse,
    QueueUpdatedMsg
} from "../../models/queue"
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { MessagePreview, Task, TaskPreviewUpdatedMsg, TaskQueuedMsg, TasksMovedMsg } from "../../models/task"
import { logError } from "../../utility/common/logError"
import {
    DeleteQueuePayload,
    GetQueueCategoriesResponse,
    MoveQueueResponse,
    QueueCategory,
    ToggleQueueCategoryPayload
} from "../../models/queueCategory"
import {
    addOrUpdateQueue,
    addQueueCategory,
    convertQueueCategoriesToTree,
    deleteQueue,
    removeQueueCategory,
    updateCategoriesAfterMoving,
    updateQueueCategory,
    updateQueueData
} from "../../utility/queues/queueCategoriesTree"
import { mutateTree, TreeData } from "@atlaskit/tree"
import { getQueuesMap, QueuesMap } from "../../utility/queues/queuesMap"
import { QueueSearchResponse, QueueSearchResponsePayload, QueueSearchInputData } from "../../models/queueSearch"
import { resetReducerState } from "../action"

type MonitoringOverviewCategoryState = {
    CategoryId?: string
    Displayed: boolean
}

export type QueuesState = Readonly<{
    queues: AsyncState<Queue[]>
    queuesMap: QueuesMap
    queueCategories?: TreeData
    getQueueCategories: AsyncState<boolean>
    createQueueCategory: AsyncState<void>
    updateQueueCategory: AsyncState<void>
    deleteQueueCategory: AsyncState<void>
    createQueue: AsyncState<void>
    updateQueue: AsyncState<void>
    updateIndividualQueues: AsyncState<void>
    deleteQueue: AsyncState<void>
    moveQueue: AsyncState<void>
    monitoringOverview: AsyncState<MonitoringOverview>
    monitoringOverviewCategoryState: MonitoringOverviewCategoryState
    searchRequestData?: QueueSearchInputData
    searchResponse: AsyncState<QueueSearchResponse>
    getQueueExtendedSettings: AsyncState<boolean>
    queueExtendedSettings: AsyncState<ExtendedSettings>
}>

const initialState: QueuesState = {
    queues: AsyncState.create(),
    queuesMap: {},
    getQueueCategories: AsyncState.create(),
    createQueueCategory: AsyncState.create(),
    updateQueueCategory: AsyncState.create(),
    deleteQueueCategory: AsyncState.create(),
    createQueue: AsyncState.create(),
    updateQueue: AsyncState.create(),
    updateIndividualQueues: AsyncState.create(),
    deleteQueue: AsyncState.create(),
    moveQueue: AsyncState.create(),
    monitoringOverview: AsyncState.create(),
    monitoringOverviewCategoryState: { Displayed: false },
    searchResponse: AsyncState.create(),
    getQueueExtendedSettings: AsyncState.create(),
    queueExtendedSettings: AsyncState.create()
}

export const getMainQueuePart = (queueId: string) => {
    const parts = queueId.split(":")
    return parts.length === 2 ? parts[0] : queueId
}

const addTask =
    (task: Task, toIndividual: boolean) =>
    (queue: Queue): Queue => {
        return !toIndividual
            ? {
                  ...queue,
                  PendingTasks: [...queue.PendingTasks, task]
              }
            : {
                  ...queue,
                  PendingIndividualTasks: [...queue.PendingIndividualTasks, task]
              }
    }

const addTasks = (queue: Queue, tasks: Task[], toIndividual: boolean): Queue => {
    return !toIndividual
        ? {
              ...queue,
              PendingTasks: [...queue.PendingTasks, ...tasks]
          }
        : {
              ...queue,
              PendingIndividualTasks: [...queue.PendingIndividualTasks, ...tasks]
          }
}

const removeTask =
    (taskId: string, fromIndividual: boolean) =>
    (queue: Queue): Queue => {
        return !fromIndividual
            ? {
                  ...queue,
                  PendingTasks: queue.PendingTasks.filter(t => t.Id !== taskId)
              }
            : {
                  ...queue,
                  PendingIndividualTasks: queue.PendingIndividualTasks.filter(t => t.Id !== taskId)
              }
    }

const getMovedTasks = (msg: TasksMovedMsg, queuesMap: QueuesMap): [string, string, Task[], boolean, boolean] => {
    const fromQueueId = msg.FromIndividual ? getMainQueuePart(msg.FromQueueId) : msg.FromQueueId
    const toQueueId = msg.FromIndividual ? getMainQueuePart(msg.ToQueueId) : msg.ToQueueId

    const fromQueueMap = queuesMap[msg.FromCategoryId]?.find(q => q.Id === fromQueueId)
    if (fromQueueMap === undefined) {
        return [fromQueueId, toQueueId, [], msg.FromIndividual, msg.ToIndividual]
    }

    const tasks = new Set<Task>()
    const source = msg.FromIndividual ? fromQueueMap.PendingIndividualTasks : fromQueueMap.PendingTasks
    msg.TasksIds.forEach(id => {
        const index = source.findIndex(pt => pt.Id === id)
        if (index !== -1) {
            tasks.add(source[index])
        }
    })

    return [fromQueueId, toQueueId, Array.from(tasks), msg.FromIndividual, msg.ToIndividual]
}

const moveTaskInQueues = (
    fromQueueId: string,
    toQueueId: string,
    movedTasks: Task[],
    fromIndividual: boolean,
    toIndividual: boolean
) => {
    return (queues: Queue[]): Queue[] => {
        const fromQueueIndex = queues.findIndex(q => q.Id === fromQueueId)
        const toQueueIndex = queues.findIndex(q => q.Id === toQueueId)

        return queues.map((q, i) => {
            if (i === fromQueueIndex) {
                let updatedQueue = !fromIndividual
                    ? {
                          ...q,
                          PendingTasks: q.PendingTasks.filter(
                              (task, _) => movedTasks.findIndex(t => t.Id === task.Id) === -1
                          )
                      }
                    : {
                          ...q,
                          PendingIndividualTasks: q.PendingIndividualTasks.filter(
                              (task, _) => movedTasks.findIndex(t => t.Id === task.Id) === -1
                          )
                      }
                if (i === toQueueIndex) {
                    updatedQueue = addTasks(updatedQueue, movedTasks, toIndividual)
                }
                return updatedQueue
            } else if (i === toQueueIndex) {
                return addTasks(q, movedTasks, toIndividual)
            }

            return q
        })
    }
}

const updateQueue =
    (updates: QueueUpdatedMsg) =>
    (queue: Queue): Queue => {
        return {
            ...queue,
            Name: updates.Name,
            Type: updates.Type,
            TaskSize: updates.TaskSize,
            Priority: updates.Priority,
            TimeoutSettings: updates.TimeoutSettings,
            IndividualPriority: updates.IndividualPriority,
            IndividualTimeoutSettings: updates.IndividualTimeoutSettings,
            SlSettings: updates.SlSettings,
            AwtStatsSettings: updates.AwtStatsSettings,
            FinishedDialogsSettings: updates.FinishedDialogsSettings,
            FinishedDialogsDailySettings: updates.FinishedDialogsDailySettings,
            RoutingWebHook: updates.RoutingWebHook
        }
    }

const updateQueueSl =
    (sl: number) =>
    (queue: Queue): Queue => {
        return {
            ...queue,
            Sl: sl
        }
    }

const updateQueueAwt =
    (awt: Awt) =>
    (queue: Queue): Queue => {
        return {
            ...queue,
            Awt: awt
        }
    }

const updateQueueOperatorTasksCount =
    (payload: QueueOperatorTasksCountUpdatedMsg) =>
    (queue: Queue): Queue => {
        const operatorsTasksCount = { ...queue.OperatorsTasksCount }
        operatorsTasksCount[payload.OperatorId] = payload.Count

        return {
            ...queue,
            OperatorsTasksCount: operatorsTasksCount
        }
    }

const updateQueueFinishedDialogs =
    (finishedDialogs: FinishedDialogs) =>
    (queue: Queue): Queue => {
        return {
            ...queue,
            FinishedDialogs: finishedDialogs
        }
    }

const updateQueueFinishedDialogsDaily =
    (finishedDialogsDaily: FinishedDialogsDaily) =>
    (queue: Queue): Queue => {
        return {
            ...queue,
            FinishedDialogsDaily: finishedDialogsDaily
        }
    }

const addOperator = (queue: Queue, operatorId: string): Queue => {
    return {
        ...queue,
        OperatorsIds: [...queue.OperatorsIds, operatorId]
    }
}

const removeOperator = (queue: Queue, operatorId: string): Queue => {
    return {
        ...queue,
        OperatorsIds: queue.OperatorsIds.filter(v => v !== operatorId)
    }
}

const updateQueuesMap = (categoryId: string, map: QueuesMap, updateFn: (queues: Queue[]) => Queue[]): QueuesMap => {
    const categoryQueues = map[categoryId]
    return {
        ...map,
        [categoryId]: updateFn(categoryQueues)
    }
}

const updateAllQueuesMap = (map: QueuesMap, updateFn: (queues: Queue[]) => Queue[]): QueuesMap => {
    const categoryQueues = Object.entries(map)
    const result: QueuesMap = {}
    for (const categoryQueuesPair of categoryQueues) {
        result[categoryQueuesPair[0]] = updateFn(categoryQueuesPair[1])
    }
    return result
}

const updateQueueList =
    (queueId: string, mapFn: (q: Queue) => Queue) =>
    (queues: Queue[]): Queue[] => {
        return queues.map(q => {
            if (q.Id !== queueId) {
                return q
            }

            return mapFn(q)
        })
    }

const updateQueueListByOperatorId =
    (operatorId: string, mapFn: (q: Queue) => Queue) =>
    (queues: Queue[]): Queue[] => {
        return queues.map(q => {
            if (q.OperatorsIds.includes(operatorId)) {
                return q
            }

            return mapFn(q)
        })
    }

const updateQueueListByOperatorDiff =
    (diff: OperatorQueuesUpdatedMsg) =>
    (queues: Queue[]): Queue[] => {
        return queues.map(q => {
            if (diff.AddedTo.find(d => d.QueueId === q.Id)) {
                return addOperator(q, diff.OperatorId)
            }

            if (diff.RemovedFrom.find(d => d.QueueId === q.Id)) {
                return removeOperator(q, diff.OperatorId)
            }

            return q
        })
    }

const initQueuesMap = (categories: QueueCategory[]) => {
    const map = getQueuesMap(categories)
    const queues = Object.values(map).flat()
    return { map, queues }
}

interface RemoveTaskPayload {
    QueueId: string
    IsIndividual: boolean
    TaskId: string
}

const updateTaskPreview = (queue: Queue, taskId: string, message: string) => {
    const updatePreview: MessagePreview = {
        Text: message
    }
    let isTaskFind = false
    return {
        ...queue,
        PendingTasks: queue.PendingTasks.map(t => {
            if (t.Id === taskId) {
                isTaskFind = true
                return { ...t, Preview: updatePreview }
            }
            return t
        }),
        PendingIndividualTasks: isTaskFind
            ? queue.PendingIndividualTasks
            : queue.PendingIndividualTasks.map(t => (t.Id !== taskId ? { ...t, Preview: updatePreview } : t))
    }
}

const queues = createSlice({
    name: "queues",
    initialState,
    reducers: {
        getQueuesProcess(state) {
            state.queues = state.queues.toProcess()
        },
        getQueuesSuccess(state, action: PayloadAction<Queue[]>) {
            state.queues = state.queues.toSuccess(action.payload)
        },
        getQueuesFailed(state, action: PayloadAction<SystemError>) {
            state.queues = state.queues.toFailed(action.payload)
        },
        getQueuesAndMonitoringOverview(state, action: PayloadAction<QueuesWithMonitoringOverviewResponse>) {
            state.queues = state.queues.toSuccess(action.payload.Queues)
            state.monitoringOverview = state.monitoringOverview.toSuccess(action.payload.MonitoringOverview)
        },
        getMonitoringOverviewProcess(state) {
            state.monitoringOverview = state.monitoringOverview.toProcess()
        },
        getMonitoringOverviewSuccess(state, action: PayloadAction<MonitoringOverview>) {
            state.monitoringOverview = state.monitoringOverview.toSuccess(action.payload)
        },
        getMonitoringOverviewFailed(state, action: PayloadAction<SystemError>) {
            state.monitoringOverview = state.monitoringOverview.toFailed(action.payload)
        },
        addTask(state, action: PayloadAction<TaskQueuedMsg>) {
            const payload = action.payload
            const searchId = payload.ToIndividual ? getMainQueuePart(payload.QueueId) : payload.QueueId
            const mapFn = updateQueueList(searchId, addTask(payload.Task, payload.ToIndividual))
            state.queuesMap = updateQueuesMap(action.payload.CategoryId, state.queuesMap, mapFn)
            state.queues = state.queues.map(mapFn)
        },
        moveTasks(state, action: PayloadAction<TasksMovedMsg>) {
            try {
                const [fromQueueId, toQueueId, tasks, fromIndividual, toIndividual] = getMovedTasks(
                    action.payload,
                    current(state.queuesMap)
                )
                const mapFn = moveTaskInQueues(fromQueueId, toQueueId, tasks, fromIndividual, toIndividual)
                state.queuesMap = updateAllQueuesMap(state.queuesMap, mapFn)
                state.queues = state.queues.map(mapFn)
            } catch (err) {
                logError("Failed to move task.", err)
            }
        },
        removeTask(state, action: PayloadAction<RemoveTaskPayload>) {
            const payload = action.payload
            const searchId = payload.IsIndividual ? getMainQueuePart(payload.QueueId) : payload.QueueId
            const mapFn = updateQueueList(searchId, removeTask(action.payload.TaskId, payload.IsIndividual))
            state.queuesMap = updateAllQueuesMap(state.queuesMap, mapFn)
            state.queues = state.queues.map(mapFn)
        },
        updateQueuesTaskPreview(state, action: PayloadAction<TaskPreviewUpdatedMsg>) {
            const updateMessage = action.payload
            state.queues = state.queues.map(qs => {
                return qs.map(q => updateTaskPreview(q, updateMessage.TaskId, updateMessage.Text))
            })
        },
        addQueue(state, action: PayloadAction<QueueAddedMsg>) {
            if (state.queueCategories) {
                const queue = action.payload.Queue
                state.queueCategories = addOrUpdateQueue(queue.CategoryId, queue, state.queueCategories)
                state.queuesMap = {
                    ...state.queuesMap,
                    [queue.CategoryId]: [...state.queuesMap[queue.CategoryId], queue]
                }
                state.queues = state.queues.map(v => [...v, queue])
            } else {
                logError("Failed to add queue. Queue categories is empty")
            }
        },
        updateQueue(state, action: PayloadAction<QueueUpdatedMsg>) {
            if (state.queueCategories) {
                state.queueCategories = updateQueueData(action.payload, state.queueCategories)
                const mapFn = updateQueueList(action.payload.QueueId, updateQueue(action.payload))
                state.queuesMap = updateQueuesMap(action.payload.CategoryId, state.queuesMap, mapFn)
                state.queues = state.queues.map(mapFn)
            } else {
                logError("Failed to update queue. Queue categories is empty")
            }
        },
        updateQueueSl(state, action: PayloadAction<QueueSlUpdatedMsg>) {
            const mapFn = updateQueueList(action.payload.QueueId, updateQueueSl(action.payload.Sl))
            state.queuesMap = updateQueuesMap(action.payload.CategoryId, state.queuesMap, mapFn)
            state.queues = state.queues.map(mapFn)
        },
        updateQueueAwt(state, action: PayloadAction<QueueAwtUpdatedMsg>) {
            const mapFn = updateQueueList(action.payload.QueueId, updateQueueAwt(action.payload.Awt))
            state.queuesMap = updateQueuesMap(action.payload.CategoryId, state.queuesMap, mapFn)
            state.queues = state.queues.map(mapFn)
        },
        updateOperatorTasksCount(state, action: PayloadAction<QueueOperatorTasksCountUpdatedMsg>) {
            const mapFn = updateQueueListByOperatorId(
                action.payload.OperatorId,
                updateQueueOperatorTasksCount(action.payload)
            )
            state.queuesMap = updateAllQueuesMap(state.queuesMap, mapFn)
            state.queues = state.queues.map(mapFn)
        },
        updateQueueFinishedDialogs(state, action: PayloadAction<QueueFinishedDialogsUpdatedMsg>) {
            const mapFn = updateQueueList(
                action.payload.QueueId,
                updateQueueFinishedDialogs(action.payload.FinishedDialogs)
            )
            state.queuesMap = updateQueuesMap(action.payload.CategoryId, state.queuesMap, mapFn)
            state.queues = state.queues.map(mapFn)
        },
        updateQueueFinishedDialogsDaily(state, action: PayloadAction<QueueFinishedDialogsDailyUpdatedMsg>) {
            const mapFn = updateQueueList(
                action.payload.QueueId,
                updateQueueFinishedDialogsDaily(action.payload.FinishedDialogs)
            )
            state.queuesMap = updateQueuesMap(action.payload.CategoryId, state.queuesMap, mapFn)
            state.queues = state.queues.map(mapFn)
        },
        updateQueueOperators(state, action: PayloadAction<OperatorQueuesUpdatedMsg>) {
            const mapFn = updateQueueListByOperatorDiff(action.payload)
            state.queuesMap = updateAllQueuesMap(state.queuesMap, mapFn)
            state.queues = state.queues.map(mapFn)
        },
        createQueueProcess(state) {
            state.createQueue = state.createQueue.toProcess()
        },
        createQueueSuccess(state) {
            state.createQueue = state.createQueue.toSuccess()
        },
        createQueueFailed(state, action: PayloadAction<SystemError>) {
            state.createQueue = state.createQueue.toFailed(action.payload)
        },
        updateQueueProcess(state) {
            state.updateQueue = state.updateQueue.toProcess()
        },
        updateQueueSuccess(state) {
            state.updateQueue = state.updateQueue.toSuccess()
        },
        updateQueueFailed(state, action: PayloadAction<SystemError>) {
            state.updateQueue = state.updateQueue.toFailed(action.payload)
        },
        updateIndividualQueuesProcess(state) {
            state.updateIndividualQueues = state.updateIndividualQueues.toProcess()
        },
        updateIndividualQueuesSuccess(state) {
            state.updateIndividualQueues = state.updateIndividualQueues.toSuccess()
        },
        updateIndividualQueuesFailed(state, action: PayloadAction<SystemError>) {
            state.updateIndividualQueues = state.updateIndividualQueues.toFailed(action.payload)
        },
        deleteQueueProcess(state) {
            state.deleteQueue = state.deleteQueue.toProcess()
        },
        deleteQueueSuccess(state, action: PayloadAction<DeleteQueuePayload>) {
            const { queueId, categoryId } = action.payload
            state.deleteQueue = state.deleteQueue.toSuccess()
            state.queues = state.queues.map(v => v.filter(q => q.Id !== queueId))
            const categoryQueues = [...state.queuesMap[categoryId]]
            const newCategoryQueues = categoryQueues.filter(q => q.Id !== queueId)
            state.queuesMap = { ...state.queuesMap, [categoryId]: newCategoryQueues }
            if (state.queueCategories) {
                state.queueCategories = deleteQueue(categoryId, queueId, state.queueCategories)
            }
        },
        deleteQueueFailed(state, action: PayloadAction<SystemError>) {
            state.deleteQueue = state.deleteQueue.toFailed(action.payload)
        },
        getQueueCategoriesProcess(state) {
            state.getQueueCategories = state.getQueueCategories.toProcess()
            state.queues = state.queues.toProcess()
        },
        getQueueCategoriesSuccess(state, action: PayloadAction<GetQueueCategoriesResponse>) {
            const { AllCategories, TaskOnBotsCategoryId } = action.payload
            const treeData = convertQueueCategoriesToTree(AllCategories, TaskOnBotsCategoryId)
            state.getQueueCategories = state.getQueueCategories.toSuccess(true)
            state.queueCategories = treeData
            const { map, queues } = initQueuesMap(AllCategories)
            state.queuesMap = map
            state.queues = state.queues.toSuccess(queues)
            state.monitoringOverviewCategoryState = { CategoryId: TaskOnBotsCategoryId, Displayed: true }
        },
        getQueueCategoriesFailed(state, action: PayloadAction<SystemError>) {
            state.getQueueCategories = state.getQueueCategories.toFailed(action.payload)
        },
        getQueueExtendedSettingsProcess(state) {
            state.getQueueExtendedSettings = state.getQueueExtendedSettings.toProcess()
        },
        getQueueExtendedSettingsSuccess(state, action: PayloadAction<ExtendQueueSettings>) {
            state.getQueueExtendedSettings = state.getQueueExtendedSettings.toSuccess(true)
            state.queueExtendedSettings = state.queueExtendedSettings.toSuccess(action.payload)
        },
        getQueueExtendedSettingsFailed(state, action: PayloadAction<SystemError>) {
            state.getQueueExtendedSettings = state.getQueueExtendedSettings.toFailed(action.payload)
        },
        toggleQueueCategory(state, action: PayloadAction<ToggleQueueCategoryPayload>) {
            const { ToggleCategoryId, IsExpanded } = action.payload
            if (state.queueCategories) {
                state.queueCategories = mutateTree(state.queueCategories, ToggleCategoryId, {
                    isExpanded: IsExpanded
                })
            }
        },
        createQueueCategoryProcess(state) {
            state.createQueueCategory = state.createQueueCategory.toProcess()
        },
        createQueueCategorySuccess(state, action: PayloadAction<QueueCategory>) {
            state.createQueueCategory = state.createQueueCategory.toSuccess()
            state.queueCategories = addQueueCategory(action.payload, state.queueCategories)
            state.queuesMap = { ...state.queuesMap, [action.payload.Id]: action.payload.Queues }
        },
        createQueueCategoryFailed(state, action: PayloadAction<SystemError>) {
            state.createQueueCategory = state.createQueueCategory.toFailed(action.payload)
        },
        updateQueueCategoryProcess(state) {
            state.updateQueueCategory = state.updateQueueCategory.toProcess()
        },
        updateQueueCategorySuccess(state, action: PayloadAction<QueueCategory>) {
            state.updateQueueCategory = state.updateQueueCategory.toSuccess()
            if (state.queueCategories) {
                state.queueCategories = updateQueueCategory(action.payload, state.queueCategories)
                state.queuesMap = { ...state.queuesMap, [action.payload.Id]: action.payload.Queues }
            } else {
                logError("Failed to update queue category. Categories are not found")
            }
        },
        updateQueueCategoryFailed(state, action: PayloadAction<SystemError>) {
            state.updateQueueCategory = state.updateQueueCategory.toFailed(action.payload)
        },
        updateQueueExtendedSettings(state, action: PayloadAction<ExtendedSettings>) {
            state.queueExtendedSettings = state.queueExtendedSettings.toSuccess(action.payload)
        },
        deleteQueueCategoryProcess(state) {
            state.deleteQueueCategory = state.deleteQueueCategory.toProcess()
        },
        deleteQueueCategorySuccess(state, action: PayloadAction<string>) {
            state.deleteQueueCategory = state.deleteQueueCategory.toSuccess()
            if (state.queueCategories) {
                state.queueCategories = removeQueueCategory(action.payload, state.queueCategories)
                delete state.queuesMap[action.payload]
            } else {
                logError("Failed to remove queue category. Categories are not found")
            }
        },
        deleteQueueCategoryFailed(state, action: PayloadAction<SystemError>) {
            state.deleteQueueCategory = state.deleteQueueCategory.toFailed(action.payload)
        },
        updateQueueCategoriesTree(state, action: PayloadAction<TreeData>) {
            state.queueCategories = action.payload
        },
        viewMonitoringOverviewCategory(state) {
            state.monitoringOverviewCategoryState = { ...state.monitoringOverviewCategoryState, Displayed: true }
            state.queues = state.queues.toSuccess([])
        },
        viewAll(state, action: PayloadAction<Queue[]>) {
            state.monitoringOverviewCategoryState = { ...state.monitoringOverviewCategoryState, Displayed: true }
            state.queues = state.queues.toSuccess(action.payload)
        },
        viewQueues(state, action: PayloadAction<Queue[]>) {
            state.monitoringOverviewCategoryState = { ...state.monitoringOverviewCategoryState, Displayed: false }
            state.queues = state.queues.toSuccess(action.payload)
        },
        moveQueueProcess(state) {
            state.moveQueue = state.moveQueue.toProcess()
        },
        moveQueueSuccess(state, action: PayloadAction<MoveQueueResponse>) {
            const { CategoryDestination, CategorySource } = action.payload
            let destinationMap = null
            if (CategoryDestination) {
                const destinationQueues = CategoryDestination.Queues
                destinationMap = { [CategoryDestination.Id]: destinationQueues }
            }
            const sourceQueues = CategorySource.Queues
            state.queuesMap = {
                ...state.queuesMap,
                ...(destinationMap ? destinationMap : {}),
                [CategorySource.Id]: sourceQueues
            }
            if (state.queueCategories) {
                state.queueCategories = updateCategoriesAfterMoving(
                    state.queueCategories,
                    CategorySource,
                    CategoryDestination
                )
            }
            state.moveQueue = state.moveQueue.toSuccess()
        },
        moveQueueFailed(state, action: PayloadAction<SystemError>) {
            state.moveQueue = state.moveQueue.toFailed(action.payload)
        },
        performSearchProcess(state) {
            state.searchResponse = state.searchResponse.toProcess()
        },
        performSearchSuccess(state, action: PayloadAction<QueueSearchResponsePayload>) {
            state.searchRequestData = action.payload.InputData
            state.searchResponse = state.searchResponse.toSuccess(action.payload.Response)
        },
        performSearchFailed(state, action: PayloadAction<SystemError>) {
            state.searchResponse = state.searchResponse.toFailed(action.payload)
        },
        clearSearch(state) {
            state.searchRequestData = undefined
            state.searchResponse = AsyncState.create()
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export default queues.reducer

export const { toggleQueueCategory, ...actions } = queues.actions
