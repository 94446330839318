import React, { memo } from "react"
import styles from "./FullscreenLoader.module.scss"
import PublicPage from "../PublicPage/PublicPage"
import { useTranslation } from "react-i18next"
import Spinner from "../Spinner/Spinner"
import cn from "classnames"
import { useSelector } from "react-redux"
import { selectThemeInitializingStatus } from "../../store/projects/selectors"

const tNamespace = "common:loading."

export interface FullscreenLoaderProps {
    message?: string
    hideLogo?: boolean
}

const FullscreenLoader: React.FC<FullscreenLoaderProps> = props => {
    const { t } = useTranslation()

    const themeInitStatus = useSelector(selectThemeInitializingStatus)

    const { message, hideLogo = false } = props
    return (
        <PublicPage
            hideLogo={hideLogo}
            hideLanguageSelector={true}
            hidePrivacyPolicy={true}
            hideTermOfUse={true}
            className={styles.fullscreenLoader}
            contentInCenter
        >
            <div className={styles.fullscreenLoader__content}>
                <Spinner
                    className={cn(
                        styles.fullscreenLoader__spinner,
                        themeInitStatus.data
                            ? styles.fullscreenLoader__spinner_colored
                            : styles.fullscreenLoader__spinner_neutral
                    )}
                />
                {themeInitStatus.data && (
                    <h1 className={styles.fullscreenLoader__title}>{message ? message : t(tNamespace + "default")}</h1>
                )}
            </div>
        </PublicPage>
    )
}

export default memo(FullscreenLoader)
