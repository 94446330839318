import { useEffect, useRef } from "react"
import { DraggableStateSnapshot } from "react-beautiful-dnd"
import { parseCssTransformRule } from "../common/parseCssTransformRule"

export const useResizeFontWhenZoom = <T extends HTMLElement>(snapshot: DraggableStateSnapshot) => {
    const resizableRef = useRef<T>(null)

    useEffect(() => {
        const zoomValues = document.querySelector<HTMLElement>(".react-flow__nodes")?.style.transform
        if (zoomValues && resizableRef.current) {
            const scaleValue = Number(parseCssTransformRule(zoomValues).scale)

            const fontSizeValue =
                scaleValue >= 1.2 ? scaleValue - 0.2 : scaleValue > 0.8 ? scaleValue - 0.1 : scaleValue - 0.09

            resizableRef.current.style.fontSize = snapshot?.isDragging ? `${fontSizeValue}rem` : ""
        }
    }, [])

    return resizableRef
}
