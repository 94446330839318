import React from "react"
import "./ValidatableInput.scss"
import { FormValue } from "../../types/FormValue"
import VerticalInputGroup, { VerticalInputGroupProps } from "../FormHelpers/VerticalInputGroup/VerticalInputGroup"
import ValidatableInputBase, { BaseValidatableInputProps } from "../ValidatableInputBase/ValidatableInputBase"

export interface ValidatableInputProps extends BaseValidatableInputProps, Omit<VerticalInputGroupProps, "children"> {}

function ValidatableInput<Value extends FormValue>(props: ValidatableInputProps) {
    const { id, icon, onDelete, label, ...inputProps } = props

    return (
        <VerticalInputGroup
            className={"validatable-input"}
            controlId={id}
            label={label}
            icon={icon}
            onDelete={onDelete}
            disabled={inputProps.disabled}
        >
            <ValidatableInputBase<Value> {...inputProps} />
        </VerticalInputGroup>
    )
}

export default ValidatableInput
