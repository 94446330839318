import { TFunction } from "react-i18next"
import { SlotDto, SlotType } from "../../models/slot"
import { ArticleAnswer, ArticleAnswerSlot } from "../../models/article"
import { useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { Select } from "../Select/Select"
import { ValueType } from "react-select"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import InfoIcon from "../InfoIcon/InfoIcon"
import IconButton from "../IconButton/IconButton"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { DebouncedFormControl } from "../ValidatableInput/DebouncedValidatableInput"
import styles from "./ArticleAnswersForm.module.scss"

const tNamespace = "knowledgeBase:"

interface ItemProps {
    t: TFunction
    name: string
    index: number
    slot?: SlotDto
    onRemove: () => void
}

const FormikArticleAnswersFormItem: React.FC<ItemProps> = props => {
    const { setFieldValue, errors, touched, values } = useFormikContext<ArticleAnswer>()
    const { t, slot, index, onRemove, name } = props
    const [error, setError] = useState<string | undefined>(undefined)

    const inputName = `${name}.Values`
    const answerSlot = values.Slots[index]

    useEffect(() => {
        const errorItem =
            errors.Slots && errors.Slots.length && errors.Slots.length > 0
                ? typeof errors.Slots[0] === "object"
                    ? typeof errors.Slots[0].Values === "string"
                        ? errors.Slots[0].Values
                        : Array.isArray(errors.Slots[0].Values)
                        ? errors.Slots[0].Values[0]
                        : undefined
                    : undefined
                : undefined
        setError(errorItem)
    }, [errors])

    const getInput = () => {
        const simpleInput = () => {
            return (
                <DebouncedFormControl
                    id="answerSlotValues"
                    name="answerSlotValues"
                    value={answerSlot.Values[0]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`${inputName}[0]`, e.target.value)
                    }
                    placeholder={t(`${tNamespace}slot.enter-value`)}
                    isInvalid={!!touched.Slots && !!error}
                />
            )
        }
        if (slot) {
            switch (slot.Type) {
                case SlotType.Text:
                    return simpleInput()
                case SlotType.Dictionary:
                    return (
                        <Select
                            menuPlacement="auto"
                            value={{
                                label: t(`${tNamespace}slot.select-value`),
                                value: ""
                            }}
                            isDisabled={!slot.Domain || !slot.Domain.length}
                            className={!!touched.Slots && !!error ? "is-invalid" : undefined}
                            options={slot.Domain ? slot.Domain.map(d => ({ label: d.Title, value: d.Id })) : []}
                            onChange={(option: ValueType<OptionType, false>) => {
                                if (option) {
                                    const isEmptyValue = answerSlot.Values.length > 0 && answerSlot.Values[0] === ""
                                    setFieldValue(
                                        inputName,
                                        isEmptyValue ? [option.value] : [...answerSlot.Values, option.value]
                                    )
                                }
                            }}
                        />
                    )
                default:
                    return simpleInput()
            }
        } else {
            return simpleInput()
        }
    }

    const handleValueRemove = (index: number) => () => {
        const newValues = [...answerSlot.Values]
        newValues.splice(index, 1)
        setFieldValue(inputName, newValues)
    }

    const getDomainAnswer = (domainId: string) => {
        const domain = slot?.Domain?.find(d => d.Id === domainId)
        return domain ? domain.Title : domainId
    }

    const getInfoTip = (answerSlot: ArticleAnswerSlot, slot?: SlotDto) => {
        if (slot && slot.Type === SlotType.Text) {
            return <InfoIcon id="answerSlotTip" content={t(`${tNamespace}answers-modal.slot-tip`)} />
        }
        if (answerSlot.SlotId === "") {
            return <InfoIcon id="answerSlotTip" content={t(`${tNamespace}answers-modal.custom-condition-tip`)} />
        }
    }

    return (
        <div className={styles.articleAnswersFormItem}>
            <div className={styles.articleAnswersFormItem__header}>
                <div className="article-answers-form-item__title">
                    {slot
                        ? slot.Title
                        : answerSlot.SlotId === ""
                        ? t(`${tNamespace}answers-modal.custom-condition`)
                        : answerSlot.SlotId}
                    {getInfoTip(answerSlot, slot)}
                </div>
                <div className={styles.articleAnswersFormItem__remove}>
                    <IconButton icon={faTrash} variant="link" onClick={onRemove} />
                </div>
            </div>
            <div className={styles.articleAnswersFormItem__input}>
                {getInput()}
                <Form.Control.Feedback type="invalid">{error && t(error)}</Form.Control.Feedback>
            </div>
            {slot && slot.Type === SlotType.Dictionary && (
                <div className={styles.articleAnswersFormItem__values}>
                    {answerSlot.Values.map((s, i) => (
                        <div className={styles.articleAnswersFormItem__value} key={i}>
                            <div className={styles.articleAnswersFormItem__valuePayload}>{getDomainAnswer(s)}</div>
                            <div className={styles.articleAnswersFormItem__valueRemove}>
                                <IconButton icon={faTimes} variant="link" onClick={handleValueRemove(i)} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default FormikArticleAnswersFormItem
