import React from "react"
import "./ArticleUserCardsLoader.scss"
import UserCardLoader from "../UserCard/UserCardLoader"

const ArticleUserCardsLoader: React.FC = () => {
    return (
        <div className="article-user-cards-loader">
            {new Array<number>(3).fill(0).map((_, i) => (
                <div className="article-user-cards-loader__item" key={i}>
                    <UserCardLoader />
                </div>
            ))}
        </div>
    )
}

export default ArticleUserCardsLoader
