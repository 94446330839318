import React from "react"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle"
import styles from "./MobileLockScreen.module.scss"

const tNamespace = "project:"

const MobileLockScreen: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.mobileLockScreen}>
            <FontAwesomeIcon icon={faInfoCircle} className={styles.mobileLockScreen__icon} />
            <h1 className={styles.mobileLockScreen__title}>{t(`${tNamespace}page-is-not-available`)}</h1>
            <p className={styles.mobileLockScreen__message}>{t(`${tNamespace}mobile-page-is-not-available`)}</p>
        </div>
    )
}

export default MobileLockScreen
