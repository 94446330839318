import { useSelector } from "react-redux"
import { selectReferences } from "../../store/projects/selectors"
import { useMemo } from "react"

const useRefValues = (refType: string) => {
    const references = useSelector(selectReferences)
    return useMemo(() => references?.filter(ref => ref.type === refType).map(ref => ref.value), [references, refType])
}

export default useRefValues
