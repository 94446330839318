import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import tags from "./tags"

export const queryApiReducerPath = "queryApi" as const
export const queryApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `/api/v2`,
        timeout: 25000
    }),
    reducerPath: queryApiReducerPath,
    tagTypes: tags,
    endpoints: () => ({})
})
