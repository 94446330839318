import { apiInstance } from "../instances"
import { AllRoleSettings, CreateRoleRequest, UpdateRoleRequest } from "./../../models/role"
import { Role } from "../../models/role"

const rolesController = {
    getRoles: (projectId: string): Promise<Role[]> =>
        apiInstance.get(`project/${projectId}/roles`).then(response => response.data),
    getAllRoleSettings: (projectId: string): Promise<AllRoleSettings> =>
        apiInstance.get(`project/${projectId}/all_role_settings`).then(response => response.data),
    getAllGlobalPermissions: (): Promise<string[]> =>
        apiInstance.get(`/all_global_permissions`).then(response => response.data),
    create: (request: CreateRoleRequest): Promise<Role> =>
        apiInstance.post("roles", request).then(response => response.data),
    update: (id: string, request: UpdateRoleRequest): Promise<Role> =>
        apiInstance.put("roles/" + id, request, { timeout: 60000 }).then(response => response.data),
    delete: (id: string): Promise<void> => apiInstance.delete("roles/" + id).then(response => response.data)
}

export default rolesController
