import React from "react"
import styles from "./RequestSettingsSection.module.scss"
import { FieldArray, useFormikContext } from "formik"
import { nameof2 } from "../../../../../../utility/common/nameof"
import { Pair, RequestValues, RestAgentValues } from "../../../../../../models/agent"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { useTranslation } from "react-i18next"
import { WithT } from "i18next"
import DebouncedValidatableInput from "../../../../../ValidatableInput/DebouncedValidatableInput"
import PairSettingsControl from "../../../../../PairSettingsControl/PairSettingsControl"
import IconControl from "../../../../../IconControl/IconControl"

const tNamespace = "agent:form."

interface InputProps {
    name: string
    index: number
    onDelete: () => void
    type: "http-header" | "parameter"
    disabled?: boolean
}

export const RequestSettingsInput: React.FC<InputProps & WithT> = props => {
    const { t, index, name, type, onDelete, disabled } = props

    return (
        <PairSettingsControl
            id={`formRequestName${type}${index}`}
            keyControl={
                <DebouncedValidatableInput
                    id={`formRequestKey${type}${index}`}
                    type="text"
                    name={`${name}n`}
                    placeholder={t(`${tNamespace}${type}-placeholder`)}
                    disabled={disabled}
                    label={t(`${tNamespace}${type}`)}
                />
            }
            valueControl={
                <DebouncedValidatableInput
                    id={`formRequestValue${type}${index}`}
                    type="text"
                    name={`${name}v`}
                    placeholder={`${t(`${tNamespace}enter-value-or-substitute`)} {{slot id}}`}
                    disabled={disabled}
                    label={t(`${tNamespace}value`)}
                />
            }
            onDelete={onDelete}
            disabled={disabled}
        />
    )
}

export interface RequestSettingsSectionProps {
    type: "Headers" | "Query"
    sectionType: "Requests" | "AuthorizationRequest"
    disabled?: boolean
}

const RequestSettingsSection: React.FC<RequestSettingsSectionProps> = props => {
    const { t } = useTranslation()
    const { type, sectionType, disabled } = props
    const { values } = useFormikContext<RestAgentValues>()

    const typeKey = type === "Headers" ? "http-header" : "parameter"

    return (
        <div className={styles.requestSettingsSection}>
            <FieldArray
                name={nameof2<RestAgentValues, RequestValues>(sectionType, type)}
                render={({ push, remove }) => (
                    <>
                        <div className={styles.requestSettingsSection__addSection}>
                            {t(`${tNamespace}${typeKey}s`)}
                            <IconControl
                                icon={faPlus}
                                className={styles.requestSettingsSection__buttonIcon}
                                onClick={() => {
                                    !disabled && push({ n: "", v: "" })
                                }}
                            />
                        </div>
                        <div>
                            {values[sectionType][type]?.map((header: Pair, index: number) => (
                                <RequestSettingsInput
                                    key={`${type}${index}`}
                                    index={index}
                                    name={`${nameof2<RestAgentValues, RequestValues>(sectionType, type)}[${index}].`}
                                    type={typeKey}
                                    onDelete={() => remove(index)}
                                    disabled={disabled}
                                    t={t}
                                />
                            ))}
                        </div>
                    </>
                )}
            />
        </div>
    )
}

export default RequestSettingsSection
