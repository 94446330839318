import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Row, Col, Button } from "react-bootstrap"
import { useField, useFormikContext } from "formik"
import HorizontalValidatableInput from "../HorizontalValidatableInput/HorizontalValidatableInput"
import { formTranslation } from "../../locales/form"

export interface PasswordValues {
    currentPassword: string
    newPassword: string
    repeatPassword: string
}

const defaultPasswordValues: PasswordValues = {
    currentPassword: "",
    newPassword: "",
    repeatPassword: ""
}

interface Props {
    name: string
}

const PasswordInput: React.FC<Props> = props => {
    const { name } = props
    const { t } = useTranslation()
    const currentPasswordName = getFullName("currentPassword")
    const [currentPasswordField, meta] = useField(currentPasswordName)
    const { setFieldValue } = useFormikContext()
    const [changing, setChanging] = useState(false)

    const handleToggle = () => {
        const newChanging = !changing
        if (!newChanging) {
            setFieldValue(name, undefined, true)
        } else {
            setFieldValue(name, defaultPasswordValues)
        }

        setChanging(newChanging)
    }

    function getFullName(fieldName: keyof PasswordValues) {
        return `${name}.${fieldName}`
    }

    return (
        <>
            <Form.Group as={Row} controlId="formPassword">
                <Form.Label column sm={5}>
                    {changing ? t(formTranslation.currentPassword) : t(formTranslation.password)}
                </Form.Label>
                {changing && (
                    <Col sm={5}>
                        <Form.Control
                            type="password"
                            name={currentPasswordName}
                            value={currentPasswordField.value}
                            onChange={currentPasswordField.onChange}
                            onBlur={currentPasswordField.onBlur}
                            isInvalid={meta.touched && !!meta.error}
                        />
                        <Form.Control.Feedback type="invalid">{meta.error && t(meta.error)}</Form.Control.Feedback>
                    </Col>
                )}
                <Col sm={{ span: 2, offset: changing ? 0 : 5 }}>
                    <Button variant="link" onClick={handleToggle}>
                        {changing ? t(formTranslation.cancel) : t(formTranslation.change)}
                    </Button>
                </Col>
            </Form.Group>
            {changing && (
                <>
                    <HorizontalValidatableInput
                        id="formNewPassword"
                        type="password"
                        name={getFullName("newPassword")}
                        label={t(formTranslation.newPassword)}
                        labelCol={{ sm: 5 }}
                        inputCol={{ sm: 5 }}
                    />
                    <HorizontalValidatableInput
                        id="formConfirmPassword"
                        type="password"
                        name={getFullName("repeatPassword")}
                        label={t(formTranslation.repeatPassword)}
                        labelCol={{ sm: 5 }}
                        inputCol={{ sm: 5 }}
                    />
                </>
            )}
        </>
    )
}

export default PasswordInput
