import React, { useEffect } from "react"
import "./ProjectWizard.scss"
import "../../components/ColorCircleInput/ColorCircleInput.module.scss"
import { useDispatch, useSelector } from "react-redux"
import {
    selectCreatProjectState,
    selectProjectWizardReady,
    selectProjectWizardStage
} from "../../store/projects/selectors"
import { actions } from "../../store/projects/slice"
import { Container } from "react-bootstrap"
import WizardView from "../../components/WizardView/WizardView"
import FullscreenLoader from "../../components/FullscreenLoader/FullscreenLoader"
import { TFunction, useTranslation } from "react-i18next"
import FullscreenError from "../../components/FullscreenError/FullscreenError"
import AsyncWithoutData from "../../components/Async/AsyncWithoutData"
import { Supervisor } from "../../permissions"
import Can from "../../components/Can/Can"
import { errorTranslation } from "../../locales/error"
import Stepper from "../../components/Stepper/Stepper"
import ProjectWizardError from "./ProjectWizardError"
import PublicPage from "../../components/PublicPage/PublicPage"

const tNamespace = "wizard:"
const tNamespaceSteps = "wizard:steps:"

const getSteps = (t: TFunction) => {
    return [
        t(`${tNamespaceSteps}name`),
        t(`${tNamespaceSteps}id`),
        t(`${tNamespaceSteps}language`),
        t(`${tNamespaceSteps}done`)
    ]
}

const ProjectWizard: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const ready = useSelector(selectProjectWizardReady)
    const createProjectState = useSelector(selectCreatProjectState)
    const stage = useSelector(selectProjectWizardStage)
    const steps = getSteps(t)

    useEffect(() => {
        !ready && dispatch(actions.initWizard())
    }, [dispatch, ready])

    return (
        <Can permission={Supervisor} no={() => <FullscreenError title={t(errorTranslation.accessDenied)} />}>
            <AsyncWithoutData
                state={createProjectState}
                processView={<FullscreenLoader message={t(`${tNamespace}loading`)} />}
                errorView={() => <ProjectWizardError onReset={() => dispatch(actions.initWizard())} />}
            >
                <PublicPage contentInCenter title={t(`${tNamespace}title`)} className="project-wizard">
                    <div className="project-wizard__sidebar">
                        <Stepper steps={steps} activeStep={stage} />
                    </div>
                    <Container fluid className="project-wizard__container">
                        <WizardView />
                    </Container>
                </PublicPage>
            </AsyncWithoutData>
        </Can>
    )
}

export default ProjectWizard
