import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormikProps, Formik } from "formik"
import { Form } from "react-bootstrap"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import "./ResetPasswordForm.scss"
import * as Yup from "yup"
import { WithT } from "i18next"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { nameof } from "../../utility/common/nameof"
import { passwordMinSize, passwordRegex } from "../../utility/common/password"
import { formTranslation } from "../../locales/form"
import NotificationCard from "../NotificationCard/NotificationCard"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle"
import LoadingButton from "../LoadingButton/LoadingButton"
import { selectResetPasswordState } from "../../store/password/selectors"
import { ResetPasswordRequest, VerifyResetPasswordCodeResponse } from "../../models/password"
import { resetPassword } from "../../store/password/thunks"
import { getSystemErrorMessage } from "../../core/error"

const tNamespace = "password:reset-password-form."

interface ResetPasswordValues {
    password: string
    confirmPassword: string
}

interface Props {
    verifyResponse: VerifyResetPasswordCodeResponse
}

const FormikResetPasswordForm: React.FC<Props & FormikProps<ResetPasswordValues> & WithT> = props => {
    const { t, handleSubmit } = props

    const resetPasswordState = useSelector(selectResetPasswordState)
    const processing = resetPasswordState.inProcess
    const error = resetPasswordState.error

    return (
        <div className={cn("reset-password-form")}>
            <h1>{t(`${tNamespace}title`)}</h1>
            <div className="reset-password-form__container">
                {!!error && <NotificationCard message={getSystemErrorMessage(error, t)} icon={faExclamationCircle} />}
                <Form noValidate onSubmit={handleSubmit}>
                    <ValidatableInput
                        id="signUpFormPassword"
                        type="password"
                        name={nameof<ResetPasswordValues>("password")}
                        placeholder={t(formTranslation.password)}
                    />
                    <ValidatableInput
                        id="signUpFormConfirmPassword"
                        type="password"
                        name={nameof<ResetPasswordValues>("confirmPassword")}
                        placeholder={t(formTranslation.confirmPassword)}
                    />
                    <LoadingButton
                        type="submit"
                        className="reset-password-form__submit"
                        loading={processing}
                        variant="primary"
                    >
                        {t(formTranslation.continue)}
                    </LoadingButton>
                </Form>
            </div>
        </div>
    )
}

const createRequest = (values: ResetPasswordValues, code: string): ResetPasswordRequest => {
    return {
        Code: code,
        Password: values.password
    }
}

const ResetPasswordForm: React.FC<Props> = props => {
    const { verifyResponse } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <Formik
            initialValues={{
                password: "",
                confirmPassword: ""
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string()
                    .required(formTranslation.passwordRequired)
                    .min(passwordMinSize, formTranslation.passwordInvalid)
                    .matches(passwordRegex, {
                        message: formTranslation.passwordInvalid,
                        excludeEmptyString: true
                    }),
                confirmPassword: Yup.string()
                    .required(formTranslation.confirmPasswordRequired)
                    .oneOf([Yup.ref("password"), ""], formTranslation.confirmPasswordIncorrect)
            })}
            onSubmit={(values: ResetPasswordValues) => {
                dispatch(resetPassword(createRequest(values, verifyResponse.Code)))
            }}
        >
            {formikProps => <FormikResetPasswordForm {...formikProps} t={t} {...props} />}
        </Formik>
    )
}

export default ResetPasswordForm
