import React from "react"
import Skeleton from "../Skeleton/Skeleton"
import "./ArticleUserViewsButton.scss"
import "./ArticleUserViewsButtonLoader.scss"

const ArticleUserViewsButtonLoader: React.FC = () => {
    return (
        <div className="article-user-views-button-loader">
            <div className="article-user-views-button-loader__inner">
                <Skeleton />
            </div>
        </div>
    )
}

export default ArticleUserViewsButtonLoader
