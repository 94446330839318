import { NotificationInternalPayload, NotificationPayload } from "../../models/notification"
import getInternalId from "./getInternalId"

const toNotificationPayload = (internalPayload: NotificationInternalPayload): NotificationPayload => ({
    ...internalPayload,
    Id: getInternalId(),
    UserId: "",
    NeedApprove: false,
    Priority: 0,
    Timestamp: Date.now()
})

export default toNotificationPayload
