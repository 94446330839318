import React from "react"
import useDelay from "../../utility/common/useDelay"
import Skeleton from "../Skeleton/Skeleton"

import "./ArticleView.scss"
import "./ArticleViewLoader.scss"

const ArticleViewLoader: React.FC = () => {
    const display = useDelay()
    if (!display) return null

    return (
        <div className="article-view article-view-loader">
            <div className="article-view__header">
                <div className="article-view-loader__header">
                    <Skeleton />
                </div>
            </div>
            <div className="article-view__body">
                <div className="article-view-loader__title">
                    <Skeleton />
                </div>
                <div className="article-view-loader__content">
                    <Skeleton />
                </div>
            </div>
        </div>
    )
}

export default ArticleViewLoader
