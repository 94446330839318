import React, { Fragment, ReactElement, ReactNode, useContext } from "react"
import "./Sidebar.scss"
import "./SidebarItem.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import SidebarContext from "../SidebarContext/SidebarContext"

interface SidebarItemProps extends ClassProps {
    title: string
    loading?: boolean
    topElement?: ReactElement
}

const SidebarItem: React.FC<SidebarItemProps> = props => {
    return (
        <div className={cn("sidebar-item", props.className)}>
            {props.loading ? (
                <Fragment>
                    <div className="sidebar-item__top-container top-container">
                        <div className="top-container__title-container title-container-loading" />
                    </div>
                    <div className="sidebar-item__middle-container middle-container-loading" />
                </Fragment>
            ) : (
                <Fragment>
                    <div className="sidebar-item__top-container top-container">
                        <div className="top-container__title-container title-container">
                            <span className="title-container__item">{props.title}</span>
                        </div>
                        <div className="top-container__top-element">{props.topElement}</div>
                    </div>
                    <div className="sidebar-item__middle-container middle-container">{props.children}</div>
                </Fragment>
            )}
        </div>
    )
}

type ISidebar<P> = React.FC<P> & { Item: typeof SidebarItem }

interface SidebarProps extends ClassProps {
    header?: ReactElement
    content?: ReactNode
    footer?: ReactElement
}

const Sidebar: ISidebar<SidebarProps> = props => {
    const isOpened = useContext(SidebarContext)
    const { header, content, footer, className } = props
    return (
        <div className={cn("sidebar", className, isOpened && "sidebar_opened")}>
            <div className="sidebar__header">{header}</div>
            <div className="sidebar__divider">
                <div className="sidebar__divider-element" />
            </div>
            <div className="sidebar__content">{content}</div>
            <div className="sidebar__footer">{footer}</div>
        </div>
    )
}

Sidebar.Item = SidebarItem

export default Sidebar
