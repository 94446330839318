import React from "react"
import { FormikAgentFormProps } from "../../AgentForm"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { AgentValues, IdentificationAgentConfigItem } from "../../../../models/agent"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { FieldArray } from "formik"
import IdentificationAgentConfigItemControl from "./IdentificationAgentConfigItemControl"
import { useSelector } from "react-redux"
import { selectClientTypes } from "../../../../store/users/selectors"

const tNamespace = "agent:form.identificationagent."
const tFormNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const FormikIdentificationAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, disabled, agentOptions, values } = props
    const clientTypes = useSelector(selectClientTypes)

    return (
        <AgentFormWrapper
            agentType={agentType}
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tFormNamespace}title`)}
                />
                <FieldArray
                    name={nameof2<AgentValues, ParamValues>("Params", "definition")}
                    render={({ push, remove }) => (
                        <>
                            <div className="agent-form__add-section">
                                <span className="agent-form__text">{t(`${tNamespace}routing-for-clients`)}</span>
                                <span
                                    className="agent-form__button-icon"
                                    onClick={() => {
                                        push({
                                            UserType: "",
                                            Action: ""
                                        })
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            </div>
                            {values.Params.definition?.map((i: IdentificationAgentConfigItem, index: number) => (
                                <IdentificationAgentConfigItemControl
                                    key={index}
                                    index={index}
                                    t={t}
                                    onDelete={() => remove(index)}
                                    disabled={disabled}
                                    agentOptions={agentOptions}
                                    clientTypes={clientTypes}
                                />
                            ))}
                        </>
                    )}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikIdentificationAgentFormGeneral
