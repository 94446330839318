import React, { useCallback, useEffect, useState } from "react"
import PageVisibility from "react-page-visibility"
import { getCookie } from "../../utility/auth/credentialHelper"
import { push } from "connected-react-router"
import { projectPath } from "../../routerPaths"
import { useDispatch } from "react-redux"
import { getProjects } from "../../store/projects/thunks"
import { logDebug } from "../../utility/common/logError"

const changeProjectTimeoutValue = 2000
let changeProjectTimeout: NodeJS.Timeout | null = null

export interface ProjectViewVisibilityProps {
    projectId?: string
}

const ProjectViewVisibility: React.FC<ProjectViewVisibilityProps> = props => {
    const { projectId, children } = props
    const dispatch = useDispatch()
    const [isVisible, setIsVisible] = useState(true)

    useEffect(() => {
        return () => {
            if (changeProjectTimeout) {
                clearTimeout(changeProjectTimeout)
            }
        }
    }, [])

    useEffect(() => {
        if (!isVisible && changeProjectTimeout) {
            clearTimeout(changeProjectTimeout)
        }
    }, [isVisible])

    const handleVisibilityChange = useCallback(
        (isCurrentlyVisible: boolean) => {
            if (!isVisible && isCurrentlyVisible) {
                if (changeProjectTimeout) {
                    clearTimeout(changeProjectTimeout)
                }

                changeProjectTimeout = setTimeout(() => {
                    const cookieProjectId = getCookie("CurrentCustomerId")

                    if (!cookieProjectId || projectId === cookieProjectId) {
                        return
                    }

                    logDebug("Changing project to", cookieProjectId)
                    dispatch(getProjects())
                    dispatch(push(`${projectPath}/${cookieProjectId}`))
                }, changeProjectTimeoutValue)
            }

            setIsVisible(isCurrentlyVisible)
        },
        [projectId, dispatch, isVisible]
    )

    return <PageVisibility onChange={handleVisibilityChange}>{children}</PageVisibility>
}

export default ProjectViewVisibility
