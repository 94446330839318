import React, { useEffect } from "react"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import { selectQueues } from "../../../../store/queues/selectors"
import { selectCreateAgentState, selectUpdateAgentState } from "../../../../store/agents/selectors"
import { AgentFormProps } from "../../AgentForm"
import { DefaultSettingsValues, RoutingAgentValues } from "../../../../models/agent"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { formTranslation } from "../../../../locales/form"
import ArticleSelect from "../../../ArticleSelect/ArticleSelect"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import { getQueueCategories } from "../../../../store/queues/thunks"

const tNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const FormikRoutingAgentFormGeneral: React.FC<AgentFormProps & FormikProps<RoutingAgentValues> & WithT> = props => {
    const { agent, agentType, t, handleSubmit, disabled } = props

    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    const asyncState = useSelector(agent ? selectUpdateAgentState : selectCreateAgentState, shallowEqual)

    useEffect(() => {
        if (!projectId) return

        dispatch(getQueueCategories(projectId))
    }, [projectId, dispatch])

    const queues = useSelector(selectQueues)

    return (
        <AgentFormWrapper
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
            agentType={agentType}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section agent-form__section_border">
                <ValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<RoutingAgentValues>("Id")}
                    label={t(`${tNamespace}title`)}
                />
            </div>
            <div className="agent-form__section">
                <ValidatableInput
                    id="formQueueId"
                    as="select"
                    type="text"
                    name={nameof2<RoutingAgentValues, DefaultSettingsValues>("DefaultSettings", "queue_id")}
                    label={t(`${tNamespace}default-queue`)}
                    disabled={!queues.length}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {queues.map(q => (
                        <option value={q.Id} key={q.Id}>
                            {q.Name}
                        </option>
                    ))}
                </ValidatableInput>
                <ArticleSelect
                    id="formReplyAfterRouting"
                    name={nameof2<RoutingAgentValues, DefaultSettingsValues>("DefaultSettings", "reply_after_routing")}
                    label={t(`${tNamespace}reply-after-routing`)}
                    projectId={projectId}
                    searchBySymbolCode
                    allowSearchWithoutPermissions
                    dispatch={dispatch}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikRoutingAgentFormGeneral
