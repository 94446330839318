import React, { MouseEventHandler } from "react"
import styles from "./ScrollToTopButton.module.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp } from "@fortawesome/pro-light-svg-icons"
import { testId } from "../../utility/tests/testId"

export interface ScrollToTopButtonProps extends ClassProps {
    hidden?: boolean
    disabled?: boolean
    onClick?: MouseEventHandler<HTMLDivElement>
}

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = props => {
    const { className, hidden, disabled, onClick } = props
    return (
        <div
            className={cn(
                styles.scrollToTopButton,
                disabled && styles.scrollToTopButton_disabled,
                hidden && styles.scrollToTopButton_hidden,
                className
            )}
            onClick={disabled ? undefined : onClick}
            data-testid={testId.scrollToTopButton}
        >
            <FontAwesomeIcon icon={faAngleUp} size={"2x"} />
        </div>
    )
}

export default ScrollToTopButton
