import React from "react"
import "./ArticleSubscriptionPopover.scss"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import ArticleSubscriptionForm, {
    ArticleSubscriptionFormProps
} from "../ArticleSubscriptionForm/ArticleSubscriptionForm"

interface ArticleSubscriptionPopoverProps extends ArticleSubscriptionFormProps {
    children: JSX.Element
    show: boolean
}

const ArticleSubscriptionPopover: React.FC<ArticleSubscriptionPopoverProps> = props => {
    const {
        title,
        show,
        loading,
        children,
        onSubmit,
        onCancel,
        subscriptionOptions,
        articleSubscriptions,
        currentProject,
        projects
    } = props
    return (
        <PopoverTrigger
            className="article-subscription-popover"
            id="article-subscription"
            content={
                <ArticleSubscriptionForm
                    title={title}
                    loading={loading}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    subscriptionOptions={subscriptionOptions}
                    articleSubscriptions={articleSubscriptions}
                    currentProject={currentProject}
                    projects={projects}
                />
            }
            trigger="click"
            placement="bottom"
            show={show}
        >
            {children}
        </PopoverTrigger>
    )
}

export default ArticleSubscriptionPopover
