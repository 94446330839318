import { Task } from "./task"
import { OperatorSearch } from "./operator"

export type QueueSearchInputData = {
    filterOption: string
    searchValue: string
}

export enum QueueSearchMode {
    Tasks,
    Operators
}

export interface QueueSearchRequest {
    Mode: QueueSearchMode
    Value: string
}

export interface QueueSearchResponse {
    Tasks: Task[]
    Operators: OperatorSearch[]
}

export interface QueueSearchResponsePayload {
    InputData: QueueSearchInputData
    Response: QueueSearchResponse
}
