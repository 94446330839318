export const accessTokenKey = ".AspNetCore.Cookies"

export const getSessionToken = () => getCookie(accessTokenKey)

export const getCookie = (key: string) => {
    const matches = document.cookie.match(new RegExp("(?:^|; )" + key.replace(/([$?*|{}\]\\^])/g, "\\$1") + "=([^;]*)"))
    return matches ? decodeURIComponent(matches[1]) : undefined
}

export const redirectToRoot = () => {
    window.location.replace("/")
}

export const redirectToLogout = () => {
    window.location.replace("/api/v2/logout")
}
