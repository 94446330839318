import React, { useContext, useEffect } from "react"
import styles from "./CatalogSharingForm.module.scss"
import CreateSharingLinkForm from "../CreateSharingLinkForm/CreateSharingLinkForm"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { selectShareCatalogState } from "../../store/knowledgeBase/selectors"
import { getCatalogSharing, removeCatalogSharing, shareCatalog } from "../../store/knowledgeBase/thunks"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"

const tNamespace = "knowledgeBase:"

interface CatalogSharingFormProps {
    catalogCode: string
}

const CatalogSharingForm: React.FC<CatalogSharingFormProps> = props => {
    const { t } = useTranslation()
    const { catalogCode } = props
    const { projectId } = useContext(KnowledgeBaseContext)
    const dispatch = useDispatch()
    const shareCatalogState = useSelector(selectShareCatalogState)
    const { data, inProcess } = shareCatalogState

    useEffect(() => {
        projectId && dispatch(getCatalogSharing(projectId, catalogCode))
    }, [catalogCode, dispatch, projectId])

    if (!projectId) {
        return null
    }

    const handleCreate = () => {
        dispatch(shareCatalog(projectId, catalogCode))
    }

    const handleDelete = () => {
        dispatch(removeCatalogSharing(projectId, catalogCode))
    }

    return (
        <div className={styles.catalogSharingForm}>
            <div className={styles.catalogSharingForm__content}>
                <CreateSharingLinkForm
                    id="catalog-sharing-link"
                    title={t(`${tNamespace}sharing.title`)}
                    warningTitle={t(`${tNamespace}remove-sharing-catalog-link.title`)}
                    warningMessage={t(`${tNamespace}remove-sharing-catalog-link.message`)}
                    value={data}
                    loading={inProcess}
                    onCreate={handleCreate}
                    onDelete={handleDelete}
                />
            </div>
        </div>
    )
}

export default CatalogSharingForm
