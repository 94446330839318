import React from "react"
import "./RadioButtonValidatableInput.scss"
import { Form } from "react-bootstrap"
import { useField } from "formik"
import cn from "classnames"
import { FormCheckProps } from "react-bootstrap/FormCheck"
import { FormValue } from "../../types/FormValue"

export interface RadioButtonValidatableInputProps extends FormCheckProps {
    id: string
    name: string
    icon?: JSX.Element
}

function RadioButtonValidatableInput<Value extends FormValue>(props: RadioButtonValidatableInputProps) {
    const [field, meta] = useField<Value>(props)
    const { id, className, label, disabled, value, inline, icon } = props

    const formLabel = (label || icon) && (
        <>
            {label}
            {icon}
        </>
    )

    return (
        <Form.Check
            custom
            inline={inline}
            value={value}
            disabled={disabled}
            type="radio"
            id={`${id}-input`}
            label={formLabel}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            checked={field.value === value}
            isInvalid={meta.touched && !!meta.error}
            className={cn("radio-input", className)}
        />
    )
}

export default RadioButtonValidatableInput
