import React, { useCallback } from "react"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { WithT } from "i18next"
import { ROOT_CATEGORY_CODE } from "../../utility/knowledgeBase/knowledgeBase"
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft"
import IconButton from "../IconButton/IconButton"
import { TreeItem } from "@atlaskit/tree/types"
import "./CurrentBranch.scss"

const tNamespace = "knowledgeBase:"

interface Props {
    parentCategory: TreeItem
    onParentClick: (parentCategory: TreeItem) => void
}

const CurrentBranch: React.FC<Props & WithT> = props => {
    const { parentCategory, onParentClick, t } = props

    const {
        data: { title: parentTitle, symbolCode }
    } = parentCategory

    const handleParentClick = useCallback(() => {
        onParentClick(parentCategory)
    }, [onParentClick, parentCategory])

    const title = parentTitle ? parentTitle : t(`${tNamespace}untitled`)

    return (
        <TooltipTrigger id="current-branch" placement="bottom" content={title}>
            <IconButton
                className="current-branch"
                iconClassName="current-branch__parent-icon"
                variant="link"
                icon={faChevronLeft}
                iconSize="xs"
                hideIcon={symbolCode === ROOT_CATEGORY_CODE}
                onClick={handleParentClick}
            >
                <div className="current-branch__parent-title">{title}</div>
            </IconButton>
        </TooltipTrigger>
    )
}

export default CurrentBranch
