import notificationTranslations from "../../locales/notification"
import { NotificationDto, NotificationDtoText } from "../../models/notificationDto"

const newsDescriptionTranslations: { [key: string]: string } = {
    NewArticle: notificationTranslations.descriptionNewArticle,
    ArticleEdited: notificationTranslations.descriptionArticleEdited,
    NewCommentAdded: notificationTranslations.descriptionNewCommentAdded,
    NewsAdded: notificationTranslations.descriptionNewsAdded
}

const mapNewsDescription = (payload: NotificationDto): NotificationDtoText | undefined => {
    if (payload.Description && payload.Description.Value in newsDescriptionTranslations) {
        const descriptionValue = newsDescriptionTranslations[payload.Description.Value]
        return {
            Value: descriptionValue,
            NeedLocalization: true
        }
    } else {
        return payload.Description
    }
}

export default mapNewsDescription
