import React from "react"
import useDelay from "../../utility/common/useDelay"
import Skeleton from "../Skeleton/Skeleton"
import ArticleCommentComponentLoader from "../ArticleComment/ArticleCommentComponentLoader"
import styles from "./ArticleCommentsView.module.scss"
import cn from "classnames"

const ArticleCommentsViewLoader: React.FC = () => {
    const display = useDelay()
    if (!display) return null

    return (
        <div className={cn(styles.articleCommentsView, styles.articleCommentsView_loader)}>
            <div className={styles.articleCommentsView__inputContainer}>
                <Skeleton />
            </div>
            {new Array<number>(2).fill(0).map((_, i) => (
                <ArticleCommentComponentLoader key={i} />
            ))}
        </div>
    )
}

export default ArticleCommentsViewLoader
