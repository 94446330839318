import { apiInstance } from "../instances"
import {
    CreateSlotRequest,
    CreateSlotResponse,
    DeleteSlotResponse,
    GetSlotPageRequest,
    GetSlotPageResponse,
    GetSlotResponse,
    UpdateSlotRequest,
    UpdateSlotResponse
} from "../../models/slot"

const slotController = {
    getSlotById: (projectId: string, slotId: string): Promise<GetSlotResponse> =>
        apiInstance.get(`${projectId}/slot/${slotId}`).then(response => response.data),
    getSlotPage: (projectId: string, request: GetSlotPageRequest): Promise<GetSlotPageResponse> =>
        apiInstance.post(`${projectId}/slot/page`, request).then(response => response.data),
    create: (projectId: string, request: CreateSlotRequest): Promise<CreateSlotResponse> =>
        apiInstance.post(`${projectId}/slot`, request).then(response => response.data),
    update: (projectId: string, slotId: string, request: UpdateSlotRequest): Promise<UpdateSlotResponse> =>
        apiInstance.put(`${projectId}/slot/${slotId}`, request).then(response => response.data),
    delete: (projectId: string, slotId: string): Promise<DeleteSlotResponse> =>
        apiInstance.delete(`${projectId}/slot/${slotId}`).then(response => response.data)
}

export default slotController
