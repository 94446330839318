import React, { useCallback, useContext, useEffect, useState } from "react"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import LoadingButton from "../LoadingButton/LoadingButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faBell,
    faEllipsisHAlt,
    faExclamationCircle,
    faExpandArrows,
    faFile,
    faFileEdit,
    faMinusCircle,
    faShareAlt,
    faSync,
    faTrashAlt
} from "@fortawesome/pro-light-svg-icons"
import { Button } from "react-bootstrap"
import { Article, ArticleAction, ArticleStatusType } from "../../models/article"
import { usePopoverShown } from "../../utility/knowledgeBase/usePopoverShown"
import ArticleSharePopover from "../ArticleSharePopover/ArticleSharePopover"
import styles from "./ArticleActions.module.scss"
import { ModifyKnowledgeBaseSubscriptions, PublishIntent } from "../../permissions"
import { getSubscriptionOptions } from "../../utility/knowledgeBase/knowledgeBase"
import { buildArticleActionUrl } from "../../utility/knowledgeBase/articleUrlHelper"
import Can from "../Can/Can"
import IconButton from "../IconButton/IconButton"
import ArticleSubscriptionPopover from "../ArticleSubscriptionPopover/ArticleSubscriptionPopover"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import AddButton from "../AddButton/AddButton"
import {
    ArticleSubscriptionFormValues,
    KnowledgeBaseArticleSubscriptions
} from "../../models/knowledgeBaseSubscription"
import Async from "../Async/Async"
import ArticleActionItemLoader from "./ArticleActionItemLoader"
import ArticleViewContext from "../ArticleViewProvider/ArticleViewContext"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
    selectArticleExpanded,
    selectCurrentAnswerId,
    selectCurrentArticle,
    selectCurrentBranch,
    selectRelatedCategoriesState,
    selectRemoveArticleSharingState,
    selectShareArticleState,
    selectUpdateArticleState
} from "../../store/knowledgeBase/selectors"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { push } from "connected-react-router"
import {
    getArticleSharing,
    publishArticle,
    removeArticle,
    removeArticleSharing,
    setUnpublishedStatusForArticle,
    shareArticle
} from "../../store/knowledgeBase/thunks"
import { selectAllProjects, selectCurrentProject } from "../../store/projects/selectors"
import { updateSubscriptions } from "../../store/knowledgeBaseSubscriptions/thunks"
import {
    selectCurrentSubscriptionsState,
    selectUpdateSubscriptionsState
} from "../../store/knowledgeBaseSubscriptions/selectors"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import { revertArticleChanges } from "../../store/knowledgeBase/actions"
import KnowledgeBasePermissionsProvider from "../KnowledgeBasePermissionsProvider/KnowledgeBasePermissionsProvider"
import { ScenarioContext } from "../ScenarioEditor/ScenarioContext"
import { selectCurrentUserLogin } from "../../store/users/selectors"
import useRefCheck from "../../utility/common/useRefCheck"
import { faArrowToBottom } from "@fortawesome/pro-light-svg-icons/faArrowToBottom"
import useDownloadKBCatalogFile from "../../utility/knowledgeBase/useDownloadKBCatalogFile"
import { DocType } from "../../models/knowledgeBaseConverter"
import { actions } from "../../store/knowledgeBase/slice"
import { SHARING_ENABLED } from "../../utility/common/flags"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"
import { formTranslation } from "../../locales/form"
import { approveNewsAfterArticleDelete } from "../../utility/common/approveNewsAfterArticleDelete"
import { useAppSelector } from "../../store/store"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import FavoriteArticleButton from "../FavoriteArticleButton/FavoriteArticleButton"

const tNamespace = "knowledgeBase:"
const tScenarioEditorNamespace = "scenarioEditor:"
const tFormNamespace = "form:"

export interface ArticleActionsProps {
    code: string
    article: Article
    isEdit: boolean
    disableAddBlockBtn?: boolean
    disableEditButtons?: boolean
    onSave: () => void
    onOpenApproveModal: () => void
    className?: string
    onRefresh?: () => void
    onOpenInfo?: (article: Article, isEdit?: boolean) => void
    onOpenSurvey?: (article: Article, isEdit?: boolean) => void
    onExpandArticle?: () => void
}

const ArticleActions: React.FC<ArticleActionsProps> = props => {
    const {
        code,
        article,
        className,
        isEdit,
        onSave,
        onOpenApproveModal,
        onRefresh,
        onOpenInfo,
        onOpenSurvey,
        onExpandArticle,
        disableAddBlockBtn,
        disableEditButtons
    } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { connected, onShare } = useContext(ArticleViewContext)
    const { url, permittedActionOptions, projectId } = useContext(KnowledgeBaseContext)
    const { handleAddBlock } = useContext(ScenarioContext)

    const currentProject = useSelector(selectCurrentProject)
    const projects = useSelector(selectAllProjects)
    const updateArticleState = useSelector(selectUpdateArticleState)
    const subscriptionsState = useSelector(selectCurrentSubscriptionsState)
    const updateSubscriptionsState = useSelector(selectUpdateSubscriptionsState)
    const currentAnswerId = useSelector(selectCurrentAnswerId)
    const sharingArticleState = useSelector(selectShareArticleState)
    const removeArticleSharingState = useSelector(selectRemoveArticleSharingState)
    const login = useSelector(selectCurrentUserLogin)
    const publishAllowed = usePermissionsCheck([PublishIntent])
    const isSharingEnabled = useRefCheck(SHARING_ENABLED)
    const { CurrentCatalogCode, CurrentCategoryId } = useSelector(selectCurrentBranch, shallowEqual)
    const isArticleExpanded = useSelector(selectArticleExpanded)
    const relatedArticlesState = useSelector(selectRelatedCategoriesState, shallowEqual)
    const currentArticle = useAppSelector(selectCurrentArticle)
    const isArticleLocked = currentArticle?.IsLocked

    const [isPendingShare, setPendingShare] = useState(false)

    const isScenario = !!article.Scenario

    const {
        modalOpen: removeArticleModalOpen,
        openModal: removeArticleOpenModal,
        closeModal: removeArticleCloseModal,
        onExited: removeArticleOnExited
    } = useModal(() => (
        <AlertDialog
            show={removeArticleModalOpen}
            title={t(`${tNamespace}article-actions.remove-article-delete-confirmation.title`)}
            message={t(`${tNamespace}article-actions.remove-article-delete-confirmation.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={removeArticleCloseModal}
            onSubmit={handleRemoveArticle}
            variant="danger"
            onExited={removeArticleOnExited}
        />
    ))

    useEffect(() => {
        projectId && isSharingEnabled && dispatch(getArticleSharing(projectId, code))
    }, [code, dispatch, isSharingEnabled, projectId])

    const handlePublishArticle = useCallback(() => {
        const id = article.Id
        const hasData = projectId && code && id

        if (hasData) {
            dispatch(publishArticle(projectId, url, code, id))
            dispatch(actions.updateArticleInFavoriteBlock({ id: article.SymbolCode, title: article.Title }))
        }
    }, [article, projectId, code, dispatch, url])

    const handleEdit = useCallback(() => {
        dispatch(push(buildArticleActionUrl(url, ArticleAction.Edit, code)))
    }, [dispatch, url, code])

    const handleCancelEdit = useCallback(() => {
        dispatch(push(buildArticleActionUrl(url, ArticleAction.View, code)))
        revertArticleChanges(article, dispatch, currentAnswerId)
    }, [dispatch, url, code, article, currentAnswerId])

    const handleSetUnpublishedStatus = useCallback(() => {
        const id = article.Id
        projectId && code && id && dispatch(setUnpublishedStatusForArticle(projectId, url, code, id))
    }, [article.Id, code, dispatch, projectId, url])

    const handleRemoveArticle = useCallback(() => {
        const id = article.Id
        const symbolCode = article.SymbolCode

        if (projectId && code && id) {
            dispatch(removeArticle(projectId, url, code, id))

            // необходимло для удаления уведомлений у пользователей после удаления статьи
            // без этого пользователи никак не могут убрать уведомления, если они с подтверждением
            // а при переходе по уведомлению показывается ошибка, что статьи нет
            approveNewsAfterArticleDelete(projectId, article.SymbolCode)
                .then(response =>
                    console.info(`Article (with symble code ${article.SymbolCode}) notifications was deleted success`)
                )
                .catch(error => console.error(`Error when deleting article notifications: ${error}`))

            if (CurrentCatalogCode && CurrentCategoryId) {
                dispatch(
                    actions.removeArticleCategories({ CatalogCode: CurrentCatalogCode, CategoryId: CurrentCategoryId })
                )
            }
            dispatch(actions.removeArticleInFavoriteBlock(symbolCode))
        }
    }, [article.Id, projectId, code, dispatch, url, CurrentCatalogCode, CurrentCategoryId])

    const handleSubmitSubscriptions = useCallback(
        (values: ArticleSubscriptionFormValues, hidePopover: () => void) => {
            projectId && dispatch(updateSubscriptions(projectId, code, values, hidePopover))
        },
        [projectId, dispatch, code]
    )

    const handleCreateArticleSharing = useCallback(() => {
        projectId && login && dispatch(shareArticle(projectId, code, article.Id, login))
        setPendingShare(true)
    }, [article.Id, code, dispatch, login, projectId])

    const handleDeleteArticleSharing = useCallback(() => {
        projectId && login && dispatch(removeArticleSharing(projectId, code, article.Id, login))
        setPendingShare(false)
    }, [article.Id, code, dispatch, login, projectId])

    const { handleDownloadCatalog, downloadLink } = useDownloadKBCatalogFile(
        DocType.Word,
        projectId,
        CurrentCatalogCode,
        code
    )

    const {
        shown: articleSharePopoverShown,
        showPopover: showArticleSharePopover,
        hidePopover: hideArticleSharePopover
    } = usePopoverShown()

    const {
        shown: articleSubscribePopoverShown,
        showPopover: showArticleSubscribePopover,
        hidePopover: hideArticleSubscribePopover
    } = usePopoverShown()

    const publishButtonShown =
        article.Status === ArticleStatusType.Draft ||
        ((article.Status === ArticleStatusType.Inactive || article.Status === ArticleStatusType.Approval) &&
            publishAllowed)

    const handleCancelArticlePopover = useCallback(() => {
        isPendingShare && handleDeleteArticleSharing()
        hideArticleSharePopover()
    }, [isPendingShare, handleDeleteArticleSharing, hideArticleSharePopover])

    const handlePublish = useCallback(
        () => (publishAllowed ? handlePublishArticle() : onOpenApproveModal()),
        [handlePublishArticle, onOpenApproveModal, publishAllowed]
    )

    const [editButtonTitle, editButtonCallback] = isEdit
        ? [t(`${tFormNamespace}cancel`), handleCancelEdit]
        : [t(`${tFormNamespace}change`), handleEdit]

    const subscriptionOptions = getSubscriptionOptions(t)

    const handleAddBlockClick = () => handleAddBlock()

    const getAdditionalSettings = useCallback(() => {
        const settings = []

        if (onExpandArticle) {
            settings.push(
                <SettingsContextMenuItem
                    key="maximum-article-width"
                    id="maximum-article-width"
                    icon={faExpandArrows}
                    text={t(`${tNamespace}article-actions.maximum-article-width`)}
                    onClick={onExpandArticle}
                    toggle={{ enabled: true, active: isArticleExpanded }}
                    unavailableMessage={
                        !isEdit ? t(`${tNamespace}article-actions.maximum-article-width-disabled`) : undefined
                    }
                ></SettingsContextMenuItem>
            )
        }

        if (onOpenInfo) {
            settings.push(
                <SettingsContextMenuItem
                    key="article-info"
                    id="article-info"
                    icon={faFile}
                    text={t(`${tNamespace}article-actions.article-info`)}
                    onClick={() => onOpenInfo(article, isEdit)}
                    useSeparator
                />
            )
        }

        if (onOpenSurvey) {
            settings.push(
                <SettingsContextMenuItem
                    key="survey"
                    id="survey"
                    icon={faFileEdit}
                    text={t(`${tNamespace}article-actions.survey`)}
                    onClick={() => onOpenSurvey(article, isEdit)}
                />
            )
        }

        settings.push(
            <SettingsContextMenuItem
                key="article-actions-download-article"
                id="article-actions-download-article"
                icon={faArrowToBottom}
                text={t(`${tNamespace}article-actions.download-article`)}
                onClick={handleDownloadCatalog}
                useSeparator
            />
        )

        if (onRefresh) {
            settings.push(
                <SettingsContextMenuItem
                    key="article-actions-update-check"
                    id="article-actions-update-check"
                    icon={faSync}
                    text={t(`${tNamespace}article-actions.update-check`)}
                    onClick={onRefresh}
                />
            )
        }

        if (!disableEditButtons) {
            if (article.Status === ArticleStatusType.Active) {
                settings.push(
                    <SettingsContextMenuItem
                        key="article-actions-set-unpublished"
                        id="article-actions-set-unpublished"
                        icon={faMinusCircle}
                        text={t(`${tNamespace}article-actions.set-unpublished-status`)}
                        onClick={handleSetUnpublishedStatus}
                        useSeparator
                        unavailableMessage={
                            isArticleLocked
                                ? t(`${tNamespace}article-changes-inheritance.functionality-locked`)
                                : undefined
                        }
                    />
                )
            }
            if (article.Status !== ArticleStatusType.Removed) {
                settings.push(
                    <SettingsContextMenuItem
                        key="article-actions-remove"
                        id="article-actions-remove"
                        icon={faTrashAlt}
                        text={t(`${tNamespace}article-actions.remove`)}
                        danger
                        onClick={
                            relatedArticlesState.data?.ChildCategories.length
                                ? removeArticleOpenModal
                                : handleRemoveArticle
                        }
                        unavailableMessage={
                            isArticleLocked
                                ? t(`${tNamespace}article-changes-inheritance.functionality-locked`)
                                : undefined
                        }
                    />
                )
            }
        }

        return settings
    }, [
        onExpandArticle,
        onOpenInfo,
        onOpenSurvey,
        t,
        handleDownloadCatalog,
        onRefresh,
        disableEditButtons,
        isArticleExpanded,
        isEdit,
        article,
        handleSetUnpublishedStatus,
        relatedArticlesState.data?.ChildCategories.length,
        removeArticleOpenModal,
        handleRemoveArticle
    ])

    return (
        <div className={cn(styles.articleActions, className)}>
            <KnowledgeBasePermissionsProvider
                permissions={article.Permissions}
                withNested={article.PermissionsWithNested}
            >
                <FavoriteArticleButton article={article} disabled={!connected} categoryId={CurrentCategoryId} />
                {!disableEditButtons && (
                    <>
                        <ArticleSharePopover
                            button={
                                <IconButton
                                    type="submit"
                                    variant="link"
                                    icon={faShareAlt}
                                    className={cn(
                                        styles.articleActions__action,
                                        styles.articleActions__action_additional
                                    )}
                                    disabled={!connected}
                                    iconClassName="article-actions__icon"
                                    onClick={
                                        articleSharePopoverShown ? hideArticleSharePopover : showArticleSharePopover
                                    }
                                >
                                    {t(`${tNamespace}article-actions.share`)}
                                </IconButton>
                            }
                            show={articleSharePopoverShown}
                            onShare={onShare}
                            onCancelSharing={handleCancelArticlePopover}
                            onSaveSharing={hideArticleSharePopover}
                            permittedActionOptions={permittedActionOptions}
                            userAccessTitle={t(`${tNamespace}permissions.title`)}
                            projects={projects}
                            currentProject={currentProject}
                            sharingLink={sharingArticleState.data}
                            updateArticleLoading={updateArticleState.inProcess}
                            sharingLoading={sharingArticleState.inProcess}
                            removeSharingLoading={removeArticleSharingState.inProcess}
                            onCreateSharing={handleCreateArticleSharing}
                            onDeleteSharing={handleDeleteArticleSharing}
                            isSharingEnabled={isSharingEnabled}
                            isScenario={isScenario}
                            isArticleLocked={isArticleLocked}
                        />

                        <Can permission={ModifyKnowledgeBaseSubscriptions}>
                            <Async<KnowledgeBaseArticleSubscriptions>
                                dataState={subscriptionsState}
                                processView={<ArticleActionItemLoader />}
                                errorView={() => (
                                    <IconButton
                                        variant="link"
                                        icon={faExclamationCircle}
                                        className={cn(
                                            styles.articleActions__action,
                                            styles.articleActions__action_error
                                        )}
                                        disabled={!connected}
                                    >
                                        {t(`${tNamespace}article-actions.subscription`)}
                                    </IconButton>
                                )}
                            >
                                {({ data: subscriptions }) => (
                                    <ArticleSubscriptionPopover
                                        loading={updateSubscriptionsState.inProcess}
                                        show={articleSubscribePopoverShown}
                                        onSubmit={handleSubmitSubscriptions}
                                        onCancel={hideArticleSubscribePopover}
                                        articleSubscriptions={subscriptions}
                                        subscriptionOptions={subscriptionOptions}
                                        title={t(`${tNamespace}subscription.title`)}
                                        currentProject={currentProject}
                                        projects={projects}
                                    >
                                        <IconButton
                                            type="submit"
                                            variant="link"
                                            icon={faBell}
                                            className={cn(
                                                styles.articleActions__action,
                                                styles.articleActions__action_additional
                                            )}
                                            iconClassName="article-actions__icon"
                                            disabled={!connected}
                                            onClick={
                                                articleSubscribePopoverShown
                                                    ? hideArticleSubscribePopover
                                                    : showArticleSubscribePopover
                                            }
                                        >
                                            {t(`${tNamespace}article-actions.subscription`)}
                                        </IconButton>
                                    </ArticleSubscriptionPopover>
                                )}
                            </Async>
                        </Can>

                        <SettingsContextMenu items={getAdditionalSettings()}>
                            <Button
                                type="submit"
                                className={cn(styles.articleActions__action, styles.articleActions__action_additional)}
                                variant="link"
                                disabled={!connected}
                            >
                                <FontAwesomeIcon
                                    icon={faEllipsisHAlt}
                                    size="lg"
                                    className={cn(styles.articleActions__icon, styles.articleActions__icon_single)}
                                />
                            </Button>
                            {downloadLink}
                        </SettingsContextMenu>

                        <TooltipTrigger
                            id="article-edit-button-blocked"
                            condition={!!isArticleLocked}
                            content={t(`${tNamespace}article-changes-inheritance.functionality-locked`)}
                            placement="bottom"
                        >
                            <Button
                                type="submit"
                                className={cn(
                                    styles.articleActions__action,
                                    styles.articleActions__action_edit,
                                    styles.articleActions__action_main
                                )}
                                variant="outline-primary"
                                disabled={!connected || isArticleLocked}
                                onClick={editButtonCallback}
                            >
                                {editButtonTitle}
                            </Button>
                        </TooltipTrigger>

                        {isEdit && (
                            <LoadingButton
                                type="submit"
                                loading={updateArticleState.inProcess}
                                className={cn(
                                    styles.articleActions__action,
                                    styles.articleActions__action_save,
                                    styles.articleActions__action_main
                                )}
                                variant="primary"
                                disabled={!connected}
                                onClick={onSave}
                            >
                                {t(`${tFormNamespace}save`)}
                            </LoadingButton>
                        )}

                        {!isEdit && publishButtonShown && (
                            <LoadingButton
                                type="submit"
                                loading={updateArticleState.inProcess}
                                className={cn(
                                    styles.articleActions__action,
                                    styles.articleActions__action_publish,
                                    styles.articleActions__action_main
                                )}
                                variant="primary"
                                disabled={!connected}
                                onClick={handlePublish}
                            >
                                {t(`${tNamespace}article-actions.publish`)}
                            </LoadingButton>
                        )}

                        {isScenario && isEdit && (
                            <AddButton
                                variant="outline-primary"
                                onClick={handleAddBlockClick}
                                className={cn(styles.articleActions__action, styles.articleActions__action_main)}
                                text={t(`${tScenarioEditorNamespace}add-block`)}
                                disabled={disableAddBlockBtn || !connected}
                            />
                        )}
                    </>
                )}
            </KnowledgeBasePermissionsProvider>
        </div>
    )
}

export default ArticleActions
