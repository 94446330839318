export function debounceAsync<T, U>(f: (v: T) => Promise<U>, interval: number): (v: T) => Promise<U> {
    let timer: NodeJS.Timeout | null = null

    return v => {
        if (timer) {
            clearTimeout(timer)
        }
        return new Promise(resolve => {
            timer = setTimeout(() => resolve(f(v)), interval)
        })
    }
}
