import { ClassifierEventDto, ClassifierModelDto } from "../../models/classifierEvent"
import { CurrentClassifier, UpdateCurrentClassifierPayload } from "../../models/classifier"

const dateTimeNone = "1970-01-01T00:00:00+00:00"

export const isDateTimeEmpty = (offset: string) => dateTimeNone === offset

export const eventToModel = (event: ClassifierEventDto): ClassifierModelDto => ({
    ClassifierId: event.ClassifierId,
    CustomerId: event.ProjectId,
    LearningStartedAt: event.ReceivedAt,
    LearningStartedByUser: event.TriggeredByUser,
    LearnedAt: dateTimeNone,
    Metrics: event.Metrics,
    UsedConfigs: []
})

export const isCurrentClassifier = (currentClassifier: CurrentClassifier, event: ClassifierEventDto | null) =>
    currentClassifier.ClassifierConfigId === event?.ClassifierConfigId

export const isReplaceRequired = (payload: UpdateCurrentClassifierPayload, currentClassifierConfigId: string) =>
    !payload.Classifier || payload.Replace || currentClassifierConfigId === payload.Classifier.ClassifierConfigId
