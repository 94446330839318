import { RefObject } from "react"

export type UseScrollProps<T> = {
    scrollableArea: RefObject<T> | null
    behavior?: ScrollBehavior
    top?: number
}
const useScroll = <T extends HTMLElement>(props: UseScrollProps<T>) => {
    const { scrollableArea, behavior = "smooth", top = 0 } = props
    const scrollToTargetRef = () => {
        if (scrollableArea && scrollableArea.current) {
            scrollableArea.current.scrollTo({
                behavior: behavior,
                top: top
            })
        }
    }

    return scrollToTargetRef
}

export default useScroll
