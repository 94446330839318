import { useEffect } from "react"
import { useState } from "react"

function useDelay(timeMs?: number, executeImmediate = false) {
    const [display, setDisplay] = useState(executeImmediate)

    useEffect(() => {
        const timerID = setTimeout(() => {
            setDisplay(true)
        }, timeMs ?? 200)
        return () => clearTimeout(timerID)
    })

    return display
}

export default useDelay
