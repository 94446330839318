import * as Yup from "yup"
import { projectIdRegex } from "../common/projectIdRegex"
import { AgentType, EmailType, ExtractParametersType } from "../../models/agent"
import { formTranslation } from "../../locales/form"

const tNamespace = "agent:error."

const agentTypes: string[] = Object.values(AgentType)
export const isAgentTypeExists = (type: string): type is AgentType => agentTypes.includes(type)

export const getValidationSchema = (agentType: AgentType, ownedByThisScenario?: boolean): Yup.ObjectSchema => {
    return Yup.object().shape({
        Id: Yup.string()
            .requiredExcludeEmpty(`${tNamespace}title-required`)
            .min(2, `${tNamespace}title-validation-min-2`)
            .matches(projectIdRegex, {
                message: `${tNamespace}title-invalid`,
                excludeEmptyString: true
            }),
        FailOverAgent: Yup.string(),
        Params: Yup.object().shape({
            ...getParamsValidationSchemaByType(agentType, ownedByThisScenario)
        }),
        ...(agentType === AgentType.IdentificationAgent && {
            NextAgent: Yup.string().required(`${tNamespace}next-agent-required`)
        }),
        ...(agentType === AgentType.NightAgent && {
            Schedule: Yup.array().of(
                Yup.object().shape({
                    NightStartHr: Yup.number().required(),
                    NightStartMin: Yup.number().required(),
                    NightEndHr: Yup.number().required(),
                    NightEndMin: Yup.number().required()
                })
            )
        }),
        ...(agentType === AgentType.RoutingAgent && {
            DefaultSettings: Yup.object().shape({
                call_center: Yup.string(),
                lang: Yup.string(),
                skill: Yup.string(),
                group: Yup.string(),
                max_dialogs: Yup.number().min(0, `${tNamespace}min-value-0`),
                operator_id: Yup.string(),
                queue_id: Yup.string()
            })
        }),
        ...(agentType === AgentType.AIAssist2 && {
            IntentSettings: Yup.object().shape({
                stop_sf_intent: Yup.string(),
                fail_intent: Yup.string()
            }),
            ClassifiersConf: Yup.array().of(
                Yup.object().shape({
                    Name: Yup.string(),
                    AssistantClassificationResultThreshold: Yup.number(),
                    BotClassificationResultThreshold: Yup.number().min(0).max(100),
                    ClassifierWeight: Yup.number().min(0)
                })
            ),
            Params: Yup.object().shape({
                max_intent_repetition_intent: Yup.string().when("max_intent_repetition_enabled", {
                    is: true,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}article-required`)
                })
            })
        }),
        ...(agentType === AgentType.RestAgent && {
            Requests: requestValidationSchema(),
            AuthorizationRequest: Yup.object().when("RequestTokenEnabled", {
                is: true,
                then: requestValidationSchema()
            })
        })
    })
}

const getParamsValidationSchemaByType = (agentType: AgentType, ownedByThisScenario?: boolean) => {
    switch (agentType) {
        case AgentType.InitialMsgAgent:
            return {
                required_authorization_text: Yup.string().when("required_authorization", {
                    is: true,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}text-required`)
                }),
                required_authorization_text_pc: Yup.string().when("required_authorization", {
                    is: true,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}text-required`)
                }),
                ...(!ownedByThisScenario && {
                    text: Yup.string().requiredExcludeEmpty(`${tNamespace}article-required`),
                    default_agent: Yup.string().requiredExcludeEmpty(`${tNamespace}agent-required`)
                })
            }
        case AgentType.ABAgent:
            if (ownedByThisScenario) return {}
            return {
                message_a: Yup.string().requiredExcludeEmpty(`${tNamespace}answer-required`),
                message_b: Yup.string().requiredExcludeEmpty(`${tNamespace}answer-required`)
            }
        case AgentType.EmailAgent:
            return {
                to: Yup.string().requiredExcludeEmpty(`${tNamespace}address-required`),
                from: Yup.string().requiredExcludeEmpty(`${tNamespace}address-required`),
                "smtp-host": Yup.string().requiredExcludeEmpty(`${tNamespace}server-required`),
                "smtp-port": Yup.string().requiredExcludeEmpty(`${tNamespace}port-required`),
                "smtp-login": Yup.string().when("enable-auth", {
                    is: true,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}login-required`)
                }),
                "smtp-password": Yup.string().when("enable-auth", {
                    is: true,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}password-required`)
                }),
                "intent-id": Yup.string().when("email-type", {
                    is: EmailType.Template,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}template-required`)
                }),
                ...(!ownedByThisScenario && {
                    "success-message": Yup.string().requiredExcludeEmpty(`${tNamespace}message-required`),
                    "failure-message": Yup.string().requiredExcludeEmpty(`${tNamespace}message-required`)
                })
            }
        case AgentType.NightAgent:
            if (ownedByThisScenario) return {}
            return {
                message: Yup.string().requiredExcludeEmpty(`${tNamespace}message-required`),
                default_agent: Yup.string().requiredExcludeEmpty(`${tNamespace}agent-required`),
                night_default_agent: Yup.string().requiredExcludeEmpty(`${tNamespace}agent-required`)
            }
        case AgentType.RestAgent:
            if (ownedByThisScenario) return {}
            return {
                "success-message": Yup.string().requiredExcludeEmpty(`${tNamespace}message-required`),
                "failure-message": Yup.string().requiredExcludeEmpty(`${tNamespace}message-required`)
            }
        case AgentType.FixedReplyAgent:
            return {
                text: Yup.string().requiredExcludeEmpty(`${tNamespace}article-required`)
            }
        case AgentType.FinishDialogAgent:
            return {
                finish_reason: Yup.string()
                    .requiredExcludeEmpty(`${tNamespace}reason-required`)
                    .matches(/^[a-zA-Z0-9_]*$/, {
                        message: `${tNamespace}finish-reason-invalid`,
                        excludeEmptyString: true
                    })
            }
        case AgentType.IdentificationAgent:
            return {
                definition: Yup.array().of(
                    Yup.object().shape({
                        UserType: Yup.string().requiredExcludeEmpty(`${tNamespace}type-required`),
                        Action: Yup.string().requiredExcludeEmpty(`${tNamespace}agent-required`)
                    })
                )
            }
        case AgentType.JqAgent:
            return {
                "json-payload-slot-id": Yup.string().requiredExcludeEmpty(`${tNamespace}slot-required`),
                "json-query": Yup.string().requiredExcludeEmpty(`${tNamespace}query-required`),
                "success-message": Yup.string().requiredExcludeEmpty(`${tNamespace}message-required`),
                "failure-message": Yup.string().requiredExcludeEmpty(`${tNamespace}message-required`)
            }
        case AgentType.JavaScriptAgent:
            return {
                "agent-code": Yup.string().requiredExcludeEmpty(`${tNamespace}code-required`)
            }
        case AgentType.MEDODSIntegration:
            return {
                specialist_slot_id: Yup.string().requiredExcludeEmpty(`${tNamespace}slot-required`),
                clinic_slot_id: Yup.string().requiredExcludeEmpty(`${tNamespace}slot-required`),
                result_slot_id: Yup.string().requiredExcludeEmpty(`${tNamespace}slot-required`),
                private_key: Yup.string().requiredExcludeEmpty(`${tNamespace}value-required`),
                identity: Yup.string().requiredExcludeEmpty(`${tNamespace}value-required`)
            }
        case AgentType.ADFSAgent:
            return {
                "adfs-url": Yup.string().requiredExcludeEmpty(`${tNamespace}url-required`),
                "redirect-url": Yup.string().requiredExcludeEmpty(`${tNamespace}url-required`),
                "client-id": Yup.string().requiredExcludeEmpty(`${tNamespace}id-required`),
                "callback-html-content": Yup.string().requiredExcludeEmpty(`${tNamespace}code-required`),
                "success-auth-message": Yup.string().requiredExcludeEmpty(`${tNamespace}article-required`),
                "failed-auth-message": Yup.string().requiredExcludeEmpty(`${tNamespace}article-required`),
                "claims-mapping": Yup.array().of(
                    Yup.object().shape({
                        claim: Yup.string()
                            .requiredExcludeEmpty(`${tNamespace}value-required`)
                            .matches(/^[a-zA-Z]*$/, {
                                message: `${tNamespace}claim-invalid`,
                                excludeEmptyString: true
                            }),
                        slot: Yup.string().requiredExcludeEmpty(`${tNamespace}slot-required`),
                        field: Yup.string().when("fieldEnabled", {
                            is: true,
                            then: Yup.string().requiredExcludeEmpty(`${tNamespace}value-required`)
                        })
                    })
                )
            }
        case AgentType.PsbBssAgent:
            return {
                "auth-token": Yup.string().requiredExcludeEmpty(`${tNamespace}value-required`),
                "start-session-url": Yup.string().requiredExcludeEmpty(`${tNamespace}url-required`),
                "continue-session-url": Yup.string().requiredExcludeEmpty(`${tNamespace}url-required`),
                "redirect-to-operator-action": Yup.string().requiredExcludeEmpty(`${tNamespace}value-required`),
                "max-attachment-size-mb": Yup.number().required(`${tNamespace}value-required`),
                "override-channel-type": Yup.array().of(
                    Yup.object().shape({
                        from: Yup.string().requiredExcludeEmpty(`${tNamespace}channel-required`),
                        to: Yup.string().requiredExcludeEmpty(`${tNamespace}value-required`)
                    })
                )
            }
        case AgentType.YandexMapAgent:
            return {
                geo_slot_id: Yup.string().requiredExcludeEmpty(`${tNamespace}slot-required`),
                search_query: Yup.string().requiredExcludeEmpty(formTranslation.titleRequired),
                link_text: Yup.string().requiredExcludeEmpty(`${tNamespace}text-required`),
                text_without_geo: Yup.string().requiredExcludeEmpty(`${tNamespace}article-required`)
            }
        case AgentType.YandexMapGeocodeAgent:
            return {
                coordinates_slot_id: Yup.string().requiredExcludeEmpty(`${tNamespace}slot-required`),
                address_slot_id: Yup.string().requiredExcludeEmpty(`${tNamespace}slot-required`),
                reply_text: Yup.string().requiredExcludeEmpty(`${tNamespace}article-required`),
                default_agent: Yup.string().requiredExcludeEmpty(`${tNamespace}agent-required`)
            }
        default:
            return {}
    }
}

const requestValidationSchema = () =>
    Yup.object().shape({
        BaseUri: Yup.string().requiredExcludeEmpty(`${tNamespace}url-required`),
        Body: Yup.string().when("BodyEnabled", {
            is: true,
            then: Yup.string().requiredExcludeEmpty(`${tNamespace}template-required`)
        }),
        Headers: Yup.array().of(
            Yup.object().shape({
                n: Yup.string().requiredExcludeEmpty(`${tNamespace}header-required`),
                v: Yup.string().requiredExcludeEmpty(`${tNamespace}value-required`)
            })
        ),
        Query: Yup.array().of(
            Yup.object().shape({
                n: Yup.string().requiredExcludeEmpty(`${tNamespace}header-required`),
                v: Yup.string().requiredExcludeEmpty(`${tNamespace}value-required`)
            })
        ),
        ExtractParameters: Yup.array().of(
            Yup.object().shape({
                JsonPaths: Yup.string().when(["Type", "WholeResponse"], {
                    is: (Type, WholeResponse) => Type === ExtractParametersType.JsonPaths && !WholeResponse,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}path-required`)
                }),
                Header: Yup.string().when("Type", {
                    is: ExtractParametersType.Header,
                    then: Yup.string().requiredExcludeEmpty(`${tNamespace}header-required`)
                })
            })
        )
    })
