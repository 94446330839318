import { Store } from "../../store/store"
import KnowledgeBaseHub from "./knowledgeBase"

class SharedKnowledgeBaseHub extends KnowledgeBaseHub {
    constructor(store: Store) {
        super(store, "/shared-knowledge-base-hub")
    }
}

export default SharedKnowledgeBaseHub
