import React from "react"
import styles from "./ExternalLink.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-light-svg-icons/faExternalLink"

export interface ExternalLinkProps {
    url: string
}

const ExternalLink: React.FC<ExternalLinkProps> = props => {
    const { url, children } = props

    return (
        <a className={styles.externalLink} href={url} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faExternalLink} className={styles.externalLink__icon} />
            <span>{children}</span>
        </a>
    )
}

export default ExternalLink
