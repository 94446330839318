import { useEffect, useMemo } from "react"
import { FormikProps } from "formik"

interface Props<T> {
    onCallback: () => void
    formikProps: FormikProps<T>
}

function OnSubmitValidationError<T>(props: Props<T>) {
    const { onCallback, formikProps } = props
    const submitCount = useMemo(() => formikProps.submitCount, [formikProps.submitCount])
    const isSubmitting = useMemo(() => formikProps.isSubmitting, [formikProps.isSubmitting])
    const isValid = useMemo(() => formikProps.isValid, [formikProps.isValid])

    useEffect(() => {
        if (submitCount > 0 && !isSubmitting && !isValid) {
            onCallback()
        }
    }, [submitCount, isSubmitting, isValid, onCallback])

    return null
}

export default OnSubmitValidationError
