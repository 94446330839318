import { DialogTopic, DialogTopicArray } from "../../models/Dialogs/dialog"
import { DialogTopicsTreeData, DialogTopicsTreeItem } from "../../store/dialogs/slice"

export const ROOT_ID = "root"

const addNodeToTreeData = (topic: DialogTopic, tree: DialogTopicsTreeData, parentId: string) => {
    tree.items[topic.Id] = {
        id: topic.Id,
        data: {
            title: topic.Title,
            isSelected: false,
            parentId
        },
        children:
            topic.Nodes?.map(node => {
                addNodeToTreeData(node, tree, topic.Id)
                return node.Id
            }) || [],
        hasChildren: topic.Nodes && topic.Nodes.length > 0,
        isExpanded: topic.Nodes && topic.Nodes.length > 0
    }
}

export const convertDialogTopicsToTree = (dialogTopics: DialogTopic[]): DialogTopicsTreeData => {
    const tree: DialogTopicsTreeData = {
        rootId: ROOT_ID,
        items: {
            root: {
                id: "root",
                children: [],
                hasChildren: false,
                data: {
                    parentId: "",
                    title: "",
                    isSelected: false
                }
            }
        }
    }

    if (!dialogTopics.length) {
        return tree
    }

    tree.items.root.hasChildren = true

    dialogTopics.forEach(topic => {
        addNodeToTreeData(topic, tree, ROOT_ID)
        tree.items.root.children.push(topic.Id)
    })

    return tree
}

export const mergeTopicsTrees = (tree: DialogTopicsTreeData, rootTree: DialogTopicsTreeData): DialogTopicsTreeData => {
    const newTree = { ...tree }

    Object.keys(newTree.items).forEach(topicId => {
        if (rootTree.items[topicId]) {
            newTree.items[topicId] = {
                ...newTree.items[topicId],
                isExpanded: true,
                data: {
                    ...newTree.items[topicId].data,
                    isSelected: rootTree.items[topicId].data.isSelected
                }
            }
        }
    })

    return newTree
}

export const getListOfSelectedIds = (tree: DialogTopicsTreeData, item: DialogTopicsTreeItem): string[] => {
    const result = [] as string[]
    let currId = item.id

    while (currId !== ROOT_ID) {
        result.push(currId as string)
        currId = tree.items[currId].data.parentId
    }

    return result
}
// DialogTopicArray used for update Dialog model
export const convertSelectedItemToDialogTopicArray = (
    tree: DialogTopicsTreeData,
    item: DialogTopicsTreeItem
): DialogTopicArray => {
    const result = [] as DialogTopicArray
    let currId = item.id

    while (currId !== ROOT_ID) {
        const currEl = tree.items[currId]

        result.push({
            Id: currEl.id,
            Title: currEl.data.title
        })
        currId = currEl.data.parentId
    }

    result.reverse()

    return result
}

export const getDialogTopicArrayPath = (topicArray: DialogTopicArray): string => {
    return topicArray.reduce((acc, item) => {
        return acc + item.Id + "."
    }, "")
}
