import React, { useContext } from "react"
import cn from "classnames"
import styles from "./Logo.module.scss"
import { ClassProps } from "../../utility/common/props"
import ConfigContext from "../ConfigContext/ConfigContext"
import { ThemingInjectorScenario } from "../../utility/theming"

export interface LogoProps extends ClassProps {
    onClick?: () => void
}

const Logo: React.FC<LogoProps> = props => {
    const { className, onClick } = props
    const { WebConfig } = useContext(ConfigContext)

    const renderLogo = () => {
        if (WebConfig.themeSettings?.loginIcon)
            return ThemingInjectorScenario.renderSvgIcon(WebConfig.themeSettings.loginIcon)
    }

    return (
        <div className={cn(styles.logo, className, onClick && styles.logo_clickable)} onClick={() => onClick?.()}>
            {renderLogo()}
        </div>
    )
}

export default Logo
