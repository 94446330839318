import { ArticleType, IntentRecordType } from "../../models/articleType"

export const toArticleType = (recordType: IntentRecordType): ArticleType => ({
    Id: recordType.id,
    Title: recordType.title
})

export const toIntentRecordType = (articleType: ArticleType, projectId: string): IntentRecordType => ({
    id: articleType.Id,
    title: articleType.Title,
    customer_id: projectId,
    required_slot_ids: []
})
