import { ChannelType, SimpleChannel, SimpleChannelDto } from "./channel"
import { TaskClient } from "./client"

export interface RouteTaskRequest {
    TaskId: string
    QueueId: string
    Type: string
    PreferredOperatorId?: string
    Channel: SimpleChannel
    Client: TaskClient
    CreatedAt: number
}

export interface AssignTaskRequest {
    TaskId: string
    OperatorId: string
    Type: string
    Channel: SimpleChannel
    Client: TaskClient
    CreatedAt: number
}

export interface MoveTaskRequest {
    TaskId: string
    QueueId: string
    OperatorId?: string
    CreatedAt?: number
}

export interface RerouteTaskRequest {
    TaskId: string
    OperatorId: string
    QueueId: string
    Message?: string
}

export interface SwitchTaskRequest {
    TaskId: string
    OperatorId: string
    QueueId: string
}

export enum TaskStatus {
    Pending,
    Assigned
}

export interface Task {
    Id: string
    TenantId: string
    QueueId?: string
    Type: string
    Status: TaskStatus
    ShortId?: string
    PreferredOperatorId?: string
    Channel: SimpleChannel
    Client: TaskClient
    CreatedAt: number
    Preview?: MessagePreview
    AssignedAt: number
}

export interface TaskDto {
    Id: string
    TenantId: string
    QueueId?: string
    Type: string
    Status: TaskStatus
    ShortId?: string
    PreferredOperatorId?: string
    Channel: SimpleChannelDto
    Client: TaskClient
    CreatedAt: number
    AssignedAt: number
    Preview?: MessagePreview
}

export type MessagePreview = {
    Text: string
}

export interface TaskQueuedMsg {
    Task: Task
    QueueId: string
    ToIndividual: boolean
    CategoryId: string
}

export interface TaskQueuedMsgDto {
    Task: TaskDto
    QueueId: string
    ToIndividual: boolean
    CategoryId: string
}

export interface TasksMovedMsg {
    FromIndividual: boolean
    FromQueueId: string
    FromCategoryId: string
    ToQueueId: string
    ToIndividual: boolean
    ToCategoryId: string
    TasksIds: string[]
}

export interface TaskDetachedMsg {
    TaskId: string
    OperatorId: string
}

export interface TaskAssignedMsg {
    Task: Task
    OperatorId: string
}

export interface TaskAssignedMsgDto {
    Task: TaskDto
    OperatorId: string
}

export interface TaskRoutedMsg {
    Task: Task
    OperatorId: string
    FromQueueId: string
    FromIndividual: boolean
    FromCategoryId: string
}

export interface TaskRoutedMsgDto {
    Task: TaskDto
    OperatorId: string
    FromQueueId: string
    FromIndividual: boolean
    FromCategoryId: string
}

export interface TaskCompletedMsg {
    TaskId: string
    TenantId: string
    QueueId?: string
    OperatorId?: string
}

export interface TaskFromQueueDeletedMsg {
    TaskId: string
    TenantId: string
    FromQueueId: string
}

export type TaskOfAutomaticProcessing = {
    Id: string
    TenantId: string
    ChannelId: string
    ChannelType: string
    Client: TaskClient
    AgentId: string
    StartedAt: number
    FirstReplyAt: number
    CurrentFirstReplyAt: number
    RoutedAt: number
    CurrentRoutedAt: number
    EnqueuedAt: number
    ConnectedOperatorIds: string[]
    Preview?: MessagePreview
}

export type TaskOfAutomaticProcessingDto = {
    Id: string
    TenantId: string
    ChannelId: string
    ChannelType: ChannelType
    Client: TaskClient
    AgentId: string
    StartedAt: number
    FirstReplyAt: number
    CurrentFirstReplyAt: number
    RoutedAt: number
    CurrentRoutedAt: number
    EnqueuedAt: number
    ConnectedOperatorIds: string[]
}

export type TaskCommon = Task | TaskOfAutomaticProcessing

export interface TaskPreviewUpdatedMsg {
    TaskId: string
    Text: string
    AttachmentCount: number
}
export function isTask(value: unknown): value is Task {
    return !!(
        typeof value === "object" &&
        value &&
        "Id" in value &&
        "TenantId" in value &&
        "Status" in value &&
        "Channel" in value &&
        "CreatedAt" in value
    )
}

export function isTaskOfAutomaticProcessing(value: unknown): value is TaskOfAutomaticProcessing {
    return !!(
        typeof value === "object" &&
        value &&
        "Id" in value &&
        "TenantId" in value &&
        "ChannelId" in value &&
        "ChannelType" in value &&
        "AgentId" in value &&
        "Client" in value
    )
}
