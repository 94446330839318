import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Row, Col, Button } from "react-bootstrap"
import { useField, useFormikContext } from "formik"
import { formTranslation } from "../../locales/form"

interface Props {
    name: string
}

const PhoneInput: React.FC<Props> = props => {
    const { name } = props
    const { t } = useTranslation()
    const [field, meta] = useField(name)
    const { setFieldValue } = useFormikContext()
    const [changing, setChanging] = useState(false)
    const [prevPhoneNumber, setPrevPhoneNumber] = useState(field.value)

    const handleToggle = () => {
        const newValue = !changing
        if (newValue) {
            setPrevPhoneNumber(field.value)
        } else {
            setFieldValue(name, prevPhoneNumber, true)
        }

        setChanging(newValue)
    }

    return (
        <Form.Group as={Row} controlId="formPhone">
            <Form.Label column sm={5}>
                {t(formTranslation.phoneNumber)}
            </Form.Label>
            <Col sm={5}>
                <Form.Control
                    plaintext={!changing}
                    readOnly={!changing}
                    placeholder="+7 (999) 999-99-99"
                    type="text"
                    name={name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={!changing ? undefined : field.onBlur}
                    isInvalid={meta.touched && !!meta.error}
                />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
            </Col>
            <Col sm={2}>
                <Button variant="link" onClick={handleToggle}>
                    {changing ? t(formTranslation.cancel) : t(formTranslation.change)}
                </Button>
            </Col>
        </Form.Group>
    )
}

export default PhoneInput
