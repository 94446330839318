import React from "react"
import "./TextItemSetting.scss"
import { CloseButton } from "react-bootstrap"

interface Props {
    title: string
    onDelete: () => void
    disabled?: boolean
}

const TextItemSetting: React.FC<Props> = props => {
    const { title, onDelete, disabled } = props

    return (
        <div className="text-item-setting">
            <span className="text-item-setting__title">{title}</span>
            <CloseButton onClick={onDelete} disabled={disabled} />
        </div>
    )
}

export default TextItemSetting
