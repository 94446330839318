import { useEffect, useRef } from "react"
import { DraggableStateSnapshot } from "react-beautiful-dnd"
import { parseCssTransformRule } from "../common/parseCssTransformRule"

export const useResizeWhenZoom = <T extends HTMLElement>(snapshot: DraggableStateSnapshot) => {
    const DRAGGABLE_BUTTON_WIDTH = "318px"
    const resizableRef = useRef<T>(null)

    useEffect(() => {
        const zoomValues = document.querySelector<HTMLElement>(".react-flow__nodes")?.style.transform
        if (zoomValues && resizableRef.current) {
            const scaleValue = parseCssTransformRule(zoomValues).scale

            resizableRef.current.style.width = snapshot.isDragging ? DRAGGABLE_BUTTON_WIDTH : ""
            resizableRef.current.style.transform = snapshot.isDragging ? `scale(${scaleValue})` : ""
        }
    }, [])

    return resizableRef
}
