import { useSelector } from "react-redux"
import { selectReferences } from "../../store/projects/selectors"
import { useMemo } from "react"

const useRefCheck = (refType: string) => {
    const references = useSelector(selectReferences)
    return useMemo(() => references?.some(ref => ref.type === refType && ref.value === "true"), [references, refType])
}

export default useRefCheck
