import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { actions } from "../store/app/slice"

function useOmniLoader(isLoading: boolean, condition?: boolean) {
    const dispatch = useDispatch()
    const processId = useRef(uuidv4())

    useEffect(() => {
        if (condition === undefined || condition) {
            dispatch(actions[isLoading ? "addProcess" : "removeProcess"](processId.current))
        }

        return () => {
            if (isLoading) {
                dispatch(actions.removeProcess(processId.current))
            }
        }
    }, [condition, isLoading])
}

export default useOmniLoader
