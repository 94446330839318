export interface Error {
    message: string
    key: string
}

export interface ErrorResponse {
    error: Error
}

export const isErrorResponse = (value: unknown): value is ErrorResponse => {
    if (typeof value !== "object" || value === null) {
        return false
    }

    const response = value as Partial<ErrorResponse>

    return (
        typeof response.error === "object" &&
        response.error !== null &&
        typeof response.error.message === "string" &&
        typeof response.error.key === "string"
    )
}
