import React from "react"
import "./ContentError.scss"

interface Props {
    message: string
}

const ContentError: React.FC<Props> = props => {
    const { message } = props
    return (
        <div className="content-error">
            <div className="content-error__msg">{message}</div>
        </div>
    )
}

export default ContentError
