import React, { useContext } from "react"
import { FormikProps } from "formik/dist/types"
import { Article as KnowledgeBaseArticle, ArticleEditFormValues, ArticleStatusType } from "../../models/article"
import { WithT } from "i18next"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import Article from "../Article/Article"
import { nameof } from "../../utility/common/nameof"
import { Form } from "react-bootstrap"
import { FormikArticleTypeSelect } from "../ArticleType/ArticleType"
import { FormikArticleTags } from "../ArticleTags/ArticleTags"
import { useField } from "formik"
import FormikTextareaAutosize from "../FormikTextareaAutosize/FormikTextareaAutosize"
import { ArticleContext } from "../../pages/KnowledgeBase/KnowledgeBase"
import ArticleToolbar from "../ArticleToolbar/ArticleToolbar"
import { selectArticleExpanded } from "../../store/knowledgeBase/selectors"
import { useSelector } from "react-redux"

const tNamespace = "knowledgeBase:article-edit-form."

interface Props {
    article: KnowledgeBaseArticle
    questionsCount?: number
}

const FormikScenarioArticleEditForm: React.FC<Props & FormikProps<ArticleEditFormValues> & WithT> = props => {
    const { article, handleSubmit, t, questionsCount } = props
    const { onOpenQuestions, isInWorkplace } = useContext(ArticleContext)
    const [titleField, titleMeta] = useField<string>(nameof<ArticleEditFormValues>("Title"))
    const isArticleExpanded = useSelector(selectArticleExpanded)

    return (
        <Form className="article-edit-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <Article
                isPublished={article.Status === ArticleStatusType.Active}
                symbolCode={article.SymbolCode}
                headerTop={
                    <>
                        <FormikArticleTypeSelect />
                        <FormikArticleTags />
                    </>
                }
                title={
                    <FormikTextareaAutosize
                        id="article-edit-form-title"
                        className="article-edit-form__input article-edit-form__title"
                        field={titleField}
                        meta={titleMeta}
                        placeholder={t(`${tNamespace}title`)}
                    />
                }
                widgets={
                    <ArticleToolbar
                        onOpenQuestions={() => onOpenQuestions(true)}
                        questionsCount={questionsCount}
                        isInWorkplace={isInWorkplace}
                    />
                }
                isExpanded={isArticleExpanded}
            />
        </Form>
    )
}

export default FormikScenarioArticleEditForm
