import React, { useState } from "react"
import { FormikSlotFormProps } from "../SlotForm/SlotForm"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import SearchInput from "../../SearchInput/SearchInput"
import { FieldArray, Form, useFormikContext } from "formik"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { reorder } from "../../../utility/scenario/scenario"
import { SlotDtoDomainValue, SlotValues } from "../../../models/slot"
import DictionaryValue from "./DictionaryValue"
import { nameof } from "../../../utility/common/nameof"
import { formTranslation } from "../../../locales/form"
import LoadingButton from "../../LoadingButton/LoadingButton"

export type SlotFormDictionaryValuesProps = FormikSlotFormProps

const SlotFormDictionaryValues: React.FC<SlotFormDictionaryValuesProps> = props => {
    const { asyncState, t, handleSubmit } = props
    const { values, setFieldValue } = useFormikContext<SlotValues>()

    const [query, setQuery] = useState("")

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) return
        setFieldValue(
            nameof<SlotValues>("Domain"),
            reorder(values.Domain, result.source.index, result.destination.index)
        )
    }

    const filterDomainValues = (v: SlotDtoDomainValue) => v.Id.toLowerCase().includes(query.toLowerCase())

    return (
        <Form className="slot-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="slot-form__content">
                <div className="slot-form__section">
                    <SearchInput onChange={v => setQuery(v)} />
                </div>
                <div className="slot-form__section">
                    <FieldArray
                        name={nameof<SlotValues>("Domain")}
                        render={({ push, remove }) => (
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="slotFormDroppableId" key="slotFormDroppableId">
                                    {provided => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className="slot-form__dict-values"
                                        >
                                            {values.Domain.filter(filterDomainValues).map((v, index) => (
                                                <Draggable
                                                    key={v.PermanentId}
                                                    draggableId={v.PermanentId}
                                                    index={index}
                                                >
                                                    {(providedInner, snapshotInner) => (
                                                        <DictionaryValue
                                                            t={t}
                                                            id={v.PermanentId}
                                                            index={index}
                                                            onDelete={() => remove(index)}
                                                            provided={providedInner}
                                                            isDragging={snapshotInner.isDragging}
                                                            value={v}
                                                            onPush={push}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    />
                </div>
            </div>
            <div className="slot-form__footer">
                <LoadingButton type="submit" variant="primary" block loading={asyncState.inProcess}>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default SlotFormDictionaryValues
