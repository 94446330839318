import React from "react"
import { useSelector } from "react-redux"
import { selectCurrentArticle } from "../../store/knowledgeBase/selectors"
import SampleQuestions from "./SampleQuestions"
import { isModifyPermitted } from "../../utility/knowledgeBase/useModifyPermitted"

interface Props {
    isEditable?: boolean
}

const SampleQuestionsContainer: React.FC<Props> = props => {
    const currentArticle = useSelector(selectCurrentArticle)
    if (!currentArticle) return null

    const { Article: article, PermittedAction } = currentArticle
    if (!article) return null
    const isEditable = props.isEditable && isModifyPermitted(PermittedAction)

    return <SampleQuestions article={article} isEditable={isEditable} />
}

export default SampleQuestionsContainer
