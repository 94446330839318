import React from "react"
import { FormikAgentFormProps } from "../../AgentForm"
import { AgentValues } from "../../../../models/agent"
import { Form } from "react-bootstrap"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { ParamValues } from "../../../../models/parameterDeclaration"
import Slider from "../../../Slider/Slider"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import DocLink from "../../../DocLink/DocLink"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"

const tNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const FormikABAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, setFieldValue, values, disabled, ownedByThisScenario } = props

    const handleProbabilityChange = (v: number) => {
        setFieldValue(nameof2<AgentValues, ParamValues>("Params", "probability"), v, false)
    }

    return (
        <AgentFormWrapper
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
            agentType={agentType}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tNamespace}title`)}
                />
                <Form.Group controlId="formProbability">
                    <Form.Label>{t(`${tNamespace}answer-a-choice-probability`)}</Form.Label>
                    <Slider
                        min={0}
                        max={100}
                        step={1}
                        marks={{ 0: "0", 100: "100%" }}
                        onChange={handleProbabilityChange}
                        value={values.Params.probability}
                    />
                </Form.Group>
            </div>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formMessageA"
                    as="textarea"
                    name={nameof2<AgentValues, ParamValues>("Params", "message_a")}
                    label={`${t(`${tNamespace}answer`)} A`}
                    className="agent-form__message-textarea"
                    disabled={ownedByThisScenario}
                />
                <DebouncedValidatableInput
                    id="formMessageB"
                    as="textarea"
                    name={nameof2<AgentValues, ParamValues>("Params", "message_b")}
                    label={`${t(`${tNamespace}answer`)} B`}
                    className="agent-form__message-textarea"
                    disabled={ownedByThisScenario}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikABAgentFormGeneral
