import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "../../../../utility/common/storeHelper"
import { TabPaneElement } from "../../../ValidatableFormTabs/ValidatableFormTabs"
import { Formik, FormikProps } from "formik"
import Tab from "react-bootstrap/Tab"
import { AgentType, AIAssist2AgentValues } from "../../../../models/agent"
import { AgentFormProps } from "../../AgentForm"
import {
    getDefaultAIAssist2AgentValues,
    getValuesFromAIAssist2Agent
} from "../../../../utility/agents/aiassist2AgentValues"
import { buildAIAssist2AgentRequest } from "../../../../utility/agents/agentRequest"
import FormikAIAssist2AgentFormGeneral from "./AIAssist2AgentGeneral"
import FormikAIAssist2AgentFormAdvanced from "./AIAssist2AgentAdvanced"
import { createAgent, updateAgent } from "../../../../store/agents/thunks"
import { selectCurrentProject, selectCurrentProjectId } from "../../../../store/projects/selectors"
import { getClassifiers } from "../../../../store/classifiers/thunks"
import OnSubmitValidationError from "../../../OnSubmitValidationError/OnSubmitValidationError"
import { getValidationSchema } from "../../../../utility/agents/agentValidation"
import { searchArticles } from "../../../../utility/knowledgeBase/articleSelect"
import { ARTICLE_OPTIONS_DEFAULT_PAGE_SIZE } from "../../../ArticleSelect/ArticleSelect"
import { OptionsType } from "react-select/src/types"
import { OptionType } from "../../../AsyncSearchableInput/AsyncSearchableInput"
import { WithT } from "i18next"
import FormikAIAssist2AgentFormClassifiers from "./AIAssist2AgentClassifiers"
import { getArticleTypes } from "../../../../store/knowledgeBase/thunks"

export interface AIAssist2AgentFormProps extends AgentFormProps, FormikProps<AIAssist2AgentValues>, WithT {
    defaultArticleOptions: OptionsType<OptionType>
}

const AIAssist2AgentForm: React.FC<AgentFormProps> = props => {
    const { t } = useTranslation()
    const projectId = useSelector(selectCurrentProjectId)
    const { agent, tabEntries, submitCallback, validateTabs, ownedByThisScenario } = props

    const project = useSelector(selectCurrentProject)
    const dispatch = useDispatch<Dispatch>()
    const [defaultArticles, setDefaultArticles] = useState<OptionsType<OptionType>>([])

    const mainRef = React.useRef<TabPaneElement>(null)
    const classifiersRef = React.useRef<TabPaneElement>(null)
    const advancedRef = React.useRef<TabPaneElement>(null)

    const validationSchema = useMemo(
        () => getValidationSchema(AgentType.AIAssist2, ownedByThisScenario),
        [ownedByThisScenario]
    )

    const validateAIAssist2Tabs = useCallback(() => {
        validateTabs?.([mainRef])
    }, [validateTabs])

    const initialValues = useMemo(
        () =>
            project
                ? agent
                    ? getValuesFromAIAssist2Agent(agent, project.language)
                    : getDefaultAIAssist2AgentValues(project.language)
                : null,
        [agent, project]
    )

    useEffect(() => {
        project && dispatch(getClassifiers(project.id))
    }, [dispatch, project])

    useEffect(() => {
        projectId && dispatch(getArticleTypes(projectId))
    }, [dispatch, projectId])

    useEffect(() => {
        const updateDefaultArticleOptions = async () => {
            const articles = await searchArticles(
                dispatch,
                t,
                projectId,
                true,
                true,
                ARTICLE_OPTIONS_DEFAULT_PAGE_SIZE
            )("")
            setDefaultArticles(articles)
        }
        updateDefaultArticleOptions()
    }, [dispatch, t, projectId])

    if (!project || !initialValues) {
        return null
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: AIAssist2AgentValues) => {
                const request = buildAIAssist2AgentRequest(project.id, values)
                if (agent) {
                    dispatch(updateAgent(request, submitCallback))
                } else {
                    dispatch(createAgent(request, submitCallback))
                }
            }}
        >
            {formikProps => (
                <>
                    <OnSubmitValidationError formikProps={formikProps} onCallback={validateAIAssist2Tabs} />
                    <Tab.Pane eventKey={tabEntries[0].key} ref={mainRef}>
                        <FormikAIAssist2AgentFormGeneral
                            {...props}
                            {...formikProps}
                            t={t}
                            defaultArticleOptions={defaultArticles}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey={tabEntries[1].key} ref={classifiersRef}>
                        <FormikAIAssist2AgentFormClassifiers
                            {...props}
                            {...formikProps}
                            t={t}
                            defaultArticleOptions={defaultArticles}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey={tabEntries[2].key} ref={advancedRef}>
                        <FormikAIAssist2AgentFormAdvanced
                            {...props}
                            {...formikProps}
                            t={t}
                            defaultArticleOptions={defaultArticles}
                        />
                    </Tab.Pane>
                </>
            )}
        </Formik>
    )
}

export default AIAssist2AgentForm
