import React, { useCallback, useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    selectArticleCommentInputStates,
    selectArticleCommentSendState,
    selectArticleCommentState
} from "../../store/knowledgeBaseComments/selectors"
import {
    cancelArticleCommentInput,
    cancelArticleCommentInputIfEmpty,
    enableArticleCommentInputMode,
    getArticleComments,
    sendArticleComment
} from "../../store/knowledgeBaseComments/thunks"
import { selectCurrentUser } from "../../store/users/selectors"
import { ArticleCommentInput } from "../../models/articleComment"
import { ArticleContext } from "../../pages/KnowledgeBase/KnowledgeBase"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import ArticleCommentsView from "./ArticleCommentsView"
import { selectArticleExpanded } from "../../store/knowledgeBase/selectors"
import { testId } from "../../utility/tests/testId"

interface Props {
    articleCode: string
    articleTitle: string
}

const ArticleCommentsViewContainer: React.FC<Props> = props => {
    const { articleCode, articleTitle } = props
    const dispatch = useDispatch()
    const articleCommentInputStates = useSelector(selectArticleCommentInputStates)
    const articleCommentSendState = useSelector(selectArticleCommentSendState)
    const articleCommentState = useSelector(selectArticleCommentState)
    const user = useSelector(selectCurrentUser)
    const { projectId } = useContext(KnowledgeBaseContext)
    const { onThreadOpen } = useContext(ArticleContext)
    const isArticleExpanded = useSelector(selectArticleExpanded)

    useEffect(() => {
        projectId && articleCode && dispatch(getArticleComments(projectId, articleCode))
        return () => {
            articleCode && cancelArticleCommentInputIfEmpty(dispatch, articleCode)
        }
    }, [dispatch, projectId, articleCode])

    const handleEnableCommentInputMode = useCallback(
        (articleCode: string) => {
            enableArticleCommentInputMode(dispatch, articleCode)
        },
        [dispatch]
    )

    const handleCancelCommentInput = useCallback(
        (articleCode: string) => {
            cancelArticleCommentInput(dispatch, articleCode)
        },
        [dispatch]
    )

    const handleSendComment = useCallback(
        (value: ArticleCommentInput) => {
            projectId && dispatch(sendArticleComment(projectId, articleCode, articleTitle, value))
        },
        [projectId, dispatch, articleCode, articleTitle]
    )

    return (
        <ArticleCommentsView
            user={user}
            articleCode={articleCode}
            articleTitle={articleTitle}
            articleCommentState={articleCommentState}
            articleCommentSendState={articleCommentSendState}
            inputStates={articleCommentInputStates}
            onEnableInputMode={handleEnableCommentInputMode}
            onCancelInput={handleCancelCommentInput}
            onThreadOpen={onThreadOpen}
            onCommentSend={handleSendComment}
            isArticleExpanded={isArticleExpanded}
            testId={testId.articleComments}
        />
    )
}

export default ArticleCommentsViewContainer
