import { Task, TaskDto, TaskOfAutomaticProcessing, TaskOfAutomaticProcessingDto } from "../../models/task"
import { Queue, QueueDto } from "../../models/queue"
import { taskConverter } from "./taskConverter"
import { QueueCategory, QueueCategoryDto } from "../../models/queueCategory"
import { OperatorDto, OperatorResponseDto } from "../../models/operatorDto"

export const mapConverters = {
    taskConverter: {
        mapCategories: (categories: QueueCategoryDto): QueueCategory => {
            return {
                ...categories,
                Queues: categories.Queues.map(mapConverters.taskConverter.mapQueue)
            }
        },
        mapQueue: (queue: QueueDto): Queue => {
            return {
                ...queue,
                PendingTasks: queue.PendingTasks.map(mapConverters.taskConverter.mapTask),
                PendingIndividualTasks: queue.PendingIndividualTasks.map(mapConverters.taskConverter.mapTask)
            }
        },
        mapOperator: (operator: OperatorResponseDto): OperatorDto => {
            return {
                ...operator,
                Tasks: operator.Tasks.map(mapConverters.taskConverter.mapTask)
            }
        },
        mapTask: (task: TaskDto): Task => {
            return taskConverter.toTaskModel(task)
        },
        mapTaskOfAutomaticProcessing: (task: TaskOfAutomaticProcessingDto): TaskOfAutomaticProcessing => {
            return taskConverter.toTaskOfAutomaticProcessingModel(task)
        }
    }
}
