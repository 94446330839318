import React, { useState } from "react"
import styles from "./ArticleAnswerItem.module.scss"
import { ArticleAnswer, ArticleAnswerSlot } from "../../models/article"
import { SlotDto } from "../../models/slot"
import { Draggable, DraggableProvided } from "react-beautiful-dnd"
import ArticleAnswersForm from "../ArticleAnswersForm/ArticleAnswersForm"
import { getSlotById, getSlotDomainInString, getSlotTitle } from "../../utility/knowledgeBase/slot"
import ScalableList from "../ScalableList/ScalableList"
import { WithT } from "i18next"
import DraggableItem from "../DraggableItem/DraggableItem"

export interface ArticleAnswerItemProps extends WithT {
    answer: ArticleAnswer
    index: number
    isSelected?: boolean
    isEdit: boolean
    disableInteraction?: boolean
    slots: SlotDto[]
    onRemove: () => void
    onSelectAnswer?: (answerId: string) => void
}

const tNamespace = "knowledgeBase:answers-modal."
const mainListMaxLength = 3

const ArticleAnswerItem: React.FC<ArticleAnswerItemProps> = props => {
    const {
        t,
        isSelected = false,
        isEdit,
        disableInteraction = false,
        index,
        answer,
        onRemove,
        slots,
        onSelectAnswer
    } = props
    const [isAnswerEdit, setAnswerEdit] = useState(false)
    const handleSelect = () => onSelectAnswer && onSelectAnswer(answer.Id)

    const getContentItem = (answer: ArticleAnswerSlot, index: number) => {
        const slot = getSlotById(answer.SlotId, slots)
        const slotTitle = getSlotTitle(answer.SlotId, slots)
        return (
            <div className={styles.articleAnswerItem__conditions} key={index}>
                <div className={styles.articleAnswerItem__conditionsTitle}>
                    {slotTitle === "" ? t(`${tNamespace}custom-condition`) : slotTitle}
                </div>
                <div className={styles.articleAnswerItem__conditionsValue}>
                    {getSlotDomainInString(answer.Values, slot, t(`${tNamespace}empty-value`))}
                </div>
            </div>
        )
    }

    const getContent = () => {
        if (answer.Slots.length) {
            return (
                <ScalableList
                    t={t}
                    items={answer.Slots}
                    limit={mainListMaxLength}
                    mainContainerClassName={styles.articleAnswerItem__mainList}
                    restContainerClassName={styles.articleAnswerItem__restList}
                    toggleClassName={styles.articleAnswerItem__toggle}
                >
                    {getContentItem}
                </ScalableList>
            )
        } else {
            return <div className={styles.articleAnswerItem__noSlots}>{t(`${tNamespace}without-conditions`)}</div>
        }
    }

    return (
        <ArticleAnswerItemDraggable {...props}>
            {provided => {
                return isAnswerEdit ? (
                    <ArticleAnswersForm
                        key={answer.Id}
                        answer={answer}
                        t={t}
                        index={index}
                        onCancel={() => setAnswerEdit(false)}
                        slots={slots}
                    />
                ) : (
                    <DraggableItem
                        key={answer.Id}
                        content={
                            <div className={styles.articleAnswerItem__content} onClick={handleSelect}>
                                {getContent()}
                            </div>
                        }
                        onEdit={() => setAnswerEdit(true)}
                        onDelete={onRemove}
                        onClick={handleSelect}
                        provided={isEdit && !disableInteraction ? provided : undefined}
                        isEdit={isEdit && !disableInteraction}
                        selected={isSelected}
                        className={styles.articleAnswerItem}
                    />
                )
            }}
        </ArticleAnswerItemDraggable>
    )
}

interface DraggableProps extends ArticleAnswerItemProps {
    children: (provided?: DraggableProvided) => JSX.Element
}

const ArticleAnswerItemDraggable: React.FC<DraggableProps> = props => {
    const { children, index, answer, isEdit } = props

    if (!isEdit) {
        return <>{children()}</>
    }

    return (
        <Draggable key={answer.Id} draggableId={answer.Id} index={index}>
            {provided => children(provided)}
        </Draggable>
    )
}

export default ArticleAnswerItem
