export interface DialogDraftsStore<T> {
    _uniqKey: string
    _draftsStoreJs: Record<string, T>

    _write(): void
    remove(key: string): void
    insert(key: string, payload: T): void
    get(key: string): unknown
}

/**
 * DialogDraftsStore implementation via LocalStorage
 */
export class DialogDraftsLocalStore<T extends unknown> implements DialogDraftsStore<T> {
    _uniqKey: string

    _draftsStoreJs: Record<string, T> = {}

    constructor(uniqKey: string) {
        this._uniqKey = uniqKey
        const draftsStore = localStorage.getItem(uniqKey)

        try {
            this._draftsStoreJs = draftsStore ? JSON.parse(draftsStore) : {}
        } catch (e: unknown) {}
    }

    public insert(key: string, payload: T) {
        this._draftsStoreJs[key] = payload
        this._write()
    }

    public remove(key: string) {
        delete this._draftsStoreJs[key]
        this._write()
    }

    /** Key is selectedDialogId or selectedOperatorClientId from store */
    public get(key: string): T | undefined {
        return this._draftsStoreJs[key]
    }

    _write() {
        localStorage.setItem(this._uniqKey, JSON.stringify(this._draftsStoreJs))
    }
}
