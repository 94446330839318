import { Children, useState } from "react"
import styles from "./DropdownPlicked.module.scss"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons"

export interface IDropdownPlickedProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string
    emptyListMsg?: string
    OverlayProps?: React.HTMLAttributes<HTMLDivElement>
    HeaderProps?: React.HTMLAttributes<HTMLDivElement>
    defaultVisible?: boolean
}

export const DropdownPlicked: React.FC<React.PropsWithChildren<IDropdownPlickedProps>> = props => {
    const {
        HeaderProps,
        OverlayProps,
        children,
        title,
        emptyListMsg = "Empty list",
        defaultVisible = false,
        ...otherProps
    } = props

    const [selected, setSelectedState] = useState<boolean>(defaultVisible)

    const isEmptyChilds = !Children.count(children)

    const renderHeader = (orig: "up" | "down" = "up") => (
        <>
            <span className={styles.dropdownPlicked__title}>{title}</span>
            <FontAwesomeIcon
                className={styles.dropdownPlicked__arrow}
                icon={orig === "up" ? faAngleUp : faAngleDown}
                size="lg"
            />
        </>
    )

    return (
        <div
            {...otherProps}
            className={cn(styles.dropdownPlicked, selected && styles.dropdownPlicked_selected, otherProps.className)}
            onClick={() => setSelectedState(prev => !prev)}
        >
            <div className={cn(styles.dropdownPlicked__header)}>{renderHeader("down")}</div>
            <div
                {...OverlayProps}
                className={cn(
                    styles.dropdownPlicked__overlay,
                    isEmptyChilds && styles.dropdownPlicked__overlay_nonclickable,
                    OverlayProps?.className
                )}
            >
                <div
                    {...HeaderProps}
                    className={cn(
                        styles.dropdownPlicked__header,
                        styles.dropdownPlicked__overlay_header,
                        HeaderProps?.className
                    )}
                >
                    {renderHeader()}
                </div>
                {!isEmptyChilds ? children : <span>{emptyListMsg}</span>}
            </div>
        </div>
    )
}
