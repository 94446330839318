import React, { useMemo } from "react"
import { CloseButton, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { FieldArray, useFormikContext } from "formik"
import { ExtractParametersType, RequestValues, RestAgentValues } from "../../../../../models/agent"
import { nameof2 } from "../../../../../utility/common/nameof"
import DebouncedValidatableInput from "../../../../ValidatableInput/DebouncedValidatableInput"
import InfoIcon from "../../../../InfoIcon/InfoIcon"
import { formTranslation } from "../../../../../locales/form"
import { useSelector } from "react-redux"
import { selectSlots } from "../../../../../store/slots/selectors"
import RadioButtonValidatableInput from "../../../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import DebouncedCheckBox from "../../../../CheckBoxValidatableInput/DebouncedCheckBox"
import { getSlotTitle } from "../../../../../utility/knowledgeBase/slot"

const tNamespace = "agent:form."
const tTooltipNamespace = "agent:tooltip."

export enum RestAgentRequestType {
    Requests = "Requests",
    AuthorizationRequest = "AuthorizationRequest"
}

interface InputProps {
    index: number
    onDelete?: () => void
    sectionType: RestAgentRequestType
    disabled?: boolean
}

export const SYSTEM_REST_AGENT_TOKEN_SLOT_ID = "sys_restagent_token"

export const ExtractParameterSettingsInput: React.FC<InputProps> = props => {
    const { t } = useTranslation()
    const { index, onDelete, sectionType, disabled } = props
    const fieldName = `${nameof2<RestAgentValues, RequestValues>(sectionType, "ExtractParameters")}[${index}].`

    const { values } = useFormikContext<RestAgentValues>()
    const type = useMemo(() => values[sectionType].ExtractParameters[index].Type, [values, index, sectionType])

    return (
        <div className="extract-parameter-settings">
            {onDelete && (
                <div className="extract-parameter-settings__header">
                    <span className="extract-parameter-settings__title">
                        {values[sectionType].ExtractParameters[index].SlotTitle}
                    </span>
                    <CloseButton onClick={onDelete} disabled={disabled} />
                </div>
            )}
            <div className="extract-parameter-settings__content">
                <RadioButtonValidatableInput
                    value={ExtractParametersType.JsonPaths}
                    id={`radioJsonPaths${sectionType}${index}`}
                    name={`${fieldName}Type`}
                    label={t(`${tNamespace}extract-from-response`)}
                    disabled={disabled}
                />
                <div className="extra-settings">
                    <DebouncedCheckBox
                        id={`formWholeResponse${sectionType}${index}`}
                        name={`${fieldName}WholeResponse`}
                        label={t(`${tNamespace}whole-response`)}
                        disabled={ExtractParametersType.JsonPaths !== type || disabled}
                    />
                    <DebouncedValidatableInput
                        id={`formJsonPaths${sectionType}${index}`}
                        type="text"
                        name={`${fieldName}JsonPaths`}
                        disabled={
                            ExtractParametersType.JsonPaths !== type ||
                            values[sectionType].ExtractParameters[index].WholeResponse ||
                            disabled
                        }
                        label={t(`${tNamespace}json-path`)}
                        icon={
                            <InfoIcon
                                id={`popoverJsonPaths${sectionType}${index}`}
                                content={t(`${tTooltipNamespace}json-path`)}
                            />
                        }
                    />
                </div>
                <RadioButtonValidatableInput
                    value={ExtractParametersType.Header}
                    id={`radioHeader${sectionType}${index}`}
                    name={`${fieldName}Type`}
                    label={t(`${tNamespace}extract-from-header`)}
                    disabled={disabled}
                />
                <div className="extra-settings">
                    <DebouncedValidatableInput
                        id={`formHeader${index}`}
                        type="text"
                        name={`${fieldName}Header`}
                        disabled={ExtractParametersType.Header !== type || disabled}
                    />
                </div>
            </div>
        </div>
    )
}

interface Props {
    sectionType: RestAgentRequestType
    disabled?: boolean
}

const ExtractParameterSettingsSection: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { sectionType, disabled } = props
    const { values } = useFormikContext<RestAgentValues>()

    const slots = useSelector(selectSlots)

    return (
        <FieldArray
            name={nameof2<RestAgentValues, RequestValues>(sectionType, "ExtractParameters")}
            render={({ push, remove }) => (
                <>
                    <Form.Group controlId={`formExtractParameters${sectionType}`}>
                        <Form.Label>{t(`${tNamespace}put-in-slot`)}</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                push({
                                    Type: ExtractParametersType.JsonPaths,
                                    SlotId: e.target.value,
                                    SlotTitle: getSlotTitle(e.target.value, slots),
                                    JsonPaths: "",
                                    WholeResponse: true,
                                    Header: ""
                                })
                            }
                            disabled={!slots.length || disabled}
                            value=""
                        >
                            <option value="" hidden>
                                {t(formTranslation.select)}
                            </option>
                            {slots.map(slot => (
                                <option key={slot.Id} value={slot.ExternalId}>
                                    {slot.Title}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    {values[sectionType].ExtractParameters.map((p, index: number) => (
                        <ExtractParameterSettingsInput
                            key={`${sectionType}${p.SlotId}`}
                            index={index}
                            onDelete={() => remove(index)}
                            sectionType={sectionType}
                            disabled={disabled}
                        />
                    ))}
                </>
            )}
        />
    )
}

export default ExtractParameterSettingsSection
