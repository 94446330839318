import React from "react"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { AgentValues } from "../../../../models/agent"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { FormikAgentFormProps } from "../../AgentForm"
import { Form } from "react-bootstrap"
import Slider from "../../../Slider/Slider"
import CodeBlockValidatableInput from "../../../CodeBlockValidatableInput/CodeBlockValidatableInput"

const tNamespace = "agent:form.jsagent."
const tFormNamespace = "agent:form."
const tLinkNamespace = "agent:link."
const tTimeNamespace = "common:time."

const FormikJavaScriptAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, disabled, values, setFieldValue } = props

    const handleTimeoutChange = (v: number) => {
        setFieldValue(nameof2<AgentValues, ParamValues>("Params", "agent-timeout"), v, false)
    }

    return (
        <AgentFormWrapper
            agentType={agentType}
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tFormNamespace}title`)}
                />
                <Form.Group controlId="formAgentTimeout">
                    <Form.Label>{t(`${tNamespace}timeout`)}</Form.Label>
                    <Slider
                        min={1}
                        max={20}
                        step={1}
                        marks={{ 1: "1", 20: `20 ${t(`${tTimeNamespace}sec`)}` }}
                        onChange={handleTimeoutChange}
                        value={values.Params["agent-timeout"]}
                    />
                </Form.Group>
            </div>
            <div className="agent-form__section">
                <CodeBlockValidatableInput
                    id="formAgentCode"
                    name={nameof2<AgentValues, ParamValues>("Params", "agent-code")}
                    label={t(`${tNamespace}js-code`)}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikJavaScriptAgentFormGeneral
