import React, { useEffect, useMemo } from "react"
import styles from "./DictionaryValue.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripLinesVertical } from "@fortawesome/pro-light-svg-icons/faGripLinesVertical"
import { DraggableProvided } from "react-beautiful-dnd"
import { nameof } from "../../../utility/common/nameof"
import { SlotDtoDomainValue, SlotValues } from "../../../models/slot"
import CloseButton from "../../CloseButton/CloseButton"
import TagInput from "../../TagInput/TagInput"
import { WithT } from "i18next"
import cn from "classnames"
import { useFormikContext } from "formik"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { emptyDomainValue } from "../../../utility/knowledgeBase/slotForm"
import { v4 as uuidV4 } from "uuid"
import { Col, Row } from "react-bootstrap"

const tPlaceholderNamespace = "slot:placeholder."

export interface DictionaryValueProps extends WithT {
    id: string
    index: number
    onDelete?: () => void
    provided?: DraggableProvided
    isDragging?: boolean
    value: SlotDtoDomainValue
    onPush: (v: SlotDtoDomainValue) => void
}

const DictionaryValue: React.FC<DictionaryValueProps> = props => {
    const { t, id, index, provided, onDelete, isDragging, value, onPush } = props
    const { setFieldValue } = useFormikContext<SlotValues>()

    const name = useMemo(() => `${nameof<SlotValues>("Domain")}[${index}]`, [index])
    const synonymsIdsName = `${name}.SynonymIds`

    const handleAddTags = (count: number) =>
        setFieldValue(
            synonymsIdsName,
            [
                ...value.Synonyms,
                ...Array(count)
                    .fill(0)
                    .map(() => uuidV4())
            ],
            false
        )

    const handleRemoveTag = (index: number) => setFieldValue(synonymsIdsName, value.Synonyms.splice(index, 1), false)

    useEffect(() => {
        if (!value.IsEmpty) return

        if (value.Id || value.Title || value.Synonyms.length) {
            setFieldValue(name, { ...value, IsEmpty: false }, false)
            onPush(emptyDomainValue())
        }
    }, [value, value.Id, value.Title, value.Synonyms, onPush, setFieldValue, name])

    return (
        <Row
            ref={provided?.innerRef}
            {...provided?.draggableProps}
            className={cn(styles.dictionaryValue, isDragging && styles.dictionaryValue_isDragging)}
        >
            <Col className={styles.dictionaryValue__dragIcon} {...provided?.dragHandleProps} tabIndex={-1}>
                <FontAwesomeIcon icon={faGripLinesVertical} />
            </Col>

            <Col className={styles.dictionaryValue__inputCol} md={4}>
                <DebouncedValidatableInput
                    id={`formDictionaryValueTitle${id}`}
                    type="text"
                    name={`${name}.Title`}
                    className={styles.dictionaryValue__valueInput}
                    placeholder={t(`${tPlaceholderNamespace}enter-title`)}
                    alert
                />
                <DebouncedValidatableInput
                    id={`formDictionaryValueId${id}`}
                    type="text"
                    name={`${name}.Id`}
                    className={styles.dictionaryValue__valueInput}
                    placeholder={t(`${tPlaceholderNamespace}enter-id`)}
                    alert
                />
            </Col>

            <Col className={styles.dictionaryValue__inputCol}>
                <TagInput
                    name={`${name}.Synonyms`}
                    className={styles.dictionaryValue__tagInput}
                    placeholder={t(`${tPlaceholderNamespace}enter-synonym`)}
                    onAddTags={handleAddTags}
                    onRemoveTag={handleRemoveTag}
                    splitOnSpaceOrComma={false}
                />
            </Col>

            <CloseButton
                onClick={onDelete}
                className={cn(
                    styles.dictionaryValue__deleteIcon,
                    value.IsEmpty && styles.dictionaryValue__deleteIcon_hidden
                )}
            />
        </Row>
    )
}

export default DictionaryValue
