import React, { useCallback } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { selectRelatedCategoriesState } from "../../store/knowledgeBase/selectors"
import CategoriesLoader from "../CategoryContainer/CategoriesLoader"
import Async from "../Async/Async"
import CurrentBranch from "./CurrentBranch"
import { useTranslation } from "react-i18next"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle"
import IconButton from "../IconButton/IconButton"
import "./CurrentBranch.scss"
import { TreeItem } from "@atlaskit/tree/types"

const tNamespace = "knowledgeBase:"

interface CurrentBranchContainerProps {
    onParentClick: (parentCategory: TreeItem) => void
}

const CurrentBranchContainer: React.FC<CurrentBranchContainerProps> = props => {
    const { onParentClick } = props
    const { t } = useTranslation()
    const relatedCategoriesState = useSelector(selectRelatedCategoriesState, shallowEqual)

    const handleParentClick = useCallback(
        (parentCategory: TreeItem) => {
            const {
                data: { parentId }
            } = parentCategory

            parentId && onParentClick(parentCategory)
        },
        [onParentClick]
    )

    return (
        <Async
            dataState={relatedCategoriesState}
            processView={<CategoriesLoader itemsCount={1} />}
            errorView={() => (
                <IconButton variant="link" icon={faExclamationCircle} className="current-branch-error">
                    {t(`${tNamespace}parent-article`)}
                </IconButton>
            )}
        >
            {({ data: { ParentCategory } }) => (
                <CurrentBranch parentCategory={ParentCategory} onParentClick={handleParentClick} t={t} />
            )}
        </Async>
    )
}

export default CurrentBranchContainer
