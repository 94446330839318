import { useState, useEffect, Dispatch, SetStateAction } from "react"

const onUnload = (event: BeforeUnloadEvent) => {
    const e = event || window.event
    e.preventDefault()
    if (e) {
        e.returnValue = ""
    }
    return ""
}

const initBeforeUnLoad = (showExitPrompt: boolean) => {
    if (showExitPrompt) {
        window.onbeforeunload = onUnload
    }
}

export default function useExitPrompt(bool: boolean): [boolean, Dispatch<SetStateAction<boolean>>] {
    const [showExitPrompt, setShowExitPrompt] = useState(bool)

    window.onload = function () {
        initBeforeUnLoad(showExitPrompt)
    }

    useEffect(() => {
        initBeforeUnLoad(showExitPrompt)
        return () => {
            window.onbeforeunload = null
        }
    }, [showExitPrompt])

    return [showExitPrompt, setShowExitPrompt]
}
