import React from "react"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { AgentValues } from "../../../../models/agent"
import ArticleSelect from "../../../ArticleSelect/ArticleSelect"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { FormikAgentFormProps } from "../../AgentForm"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"

const tNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const FormikFixedReplyAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, disabled, ownedByThisScenario } = props

    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    return (
        <AgentFormWrapper
            agentType={agentType}
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tNamespace}title`)}
                />
                <ArticleSelect
                    id="formText"
                    name={nameof2<AgentValues, ParamValues>("Params", "text")}
                    label={t(`${tNamespace}reply-article`)}
                    disabled={ownedByThisScenario}
                    projectId={projectId}
                    searchBySymbolCode
                    allowSearchWithoutPermissions
                    dispatch={dispatch}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikFixedReplyAgentFormGeneral
