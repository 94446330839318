import { Dialog, IGetOperatorClientResponse } from "../../models/Dialogs/dialog"
import { User } from "../../models/user"
import { generateFullName } from "./generateFullName"

export function formatIntegrationUrl(
    input: string,
    id: string,
    data: Dialog | IGetOperatorClientResponse | undefined,
    user: User | undefined,
    projectId: string,
    isMenu?: boolean
): string {
    const regex = /\{(\w+)}/g

    input += `&integration_id=${id}`

    isMenu = isMenu ?? false

    const operatorName = user ? generateFullName(user.LastName, user.FirstName) : ""
    const operatorLogin = user ? user.Login : ""

    if (typeof data === "undefined" && isMenu) {
        return input.replace(regex, (match, field: string) => {
            switch (field) {
                case "OperatorName":
                    return operatorName
                case "OperatorLogin":
                    return operatorLogin
                case "CustomerId":
                    return projectId
                default:
                    return ""
            }
        })
    } else if (data && "Channel" in data) {
        // then for dialog
        return input.replace(regex, (match, field: string) => {
            switch (field) {
                case "OperatorName":
                    return operatorName
                case "OperatorLogin":
                    return operatorLogin
                case "ClientName":
                    const { Lastname, Firstname, Middlename } = data.Client
                    return generateFullName(Firstname, Lastname, Middlename)
                case "OmniUserId":
                    return data.Client.OmniUserId
                case "DialogId":
                    return data.Id
                case "CustomerId":
                    return data.Project.Id
                default:
                    const filledSlot = data.SlotContext.FilledSlots.find(slot => slot.Id === field)
                    return filledSlot?.Value ?? ""
            }
        })
    } else {
        // then for client
        return input.replace(regex, (match, field: string) => {
            if (data) {
                switch (field) {
                    case "OperatorName":
                        return operatorName
                    case "OperatorLogin":
                        return operatorLogin
                    case "ClientName":
                        const { Lastname, Firstname, Middlename } = data
                        return generateFullName(Firstname, Lastname, Middlename)
                    case "OmniUserId":
                        return data.Id
                    case "DialogId":
                        return ""
                    case "CustomerId":
                        return data.Project.Id
                    default:
                        const filledSlot = data.SlotContext.FilledSlots.find(slot => slot.Id === field)
                        return filledSlot?.Value ?? ""
                }
            }
            return ""
        })
    }
}
