import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import { LoginRequest } from "../../models/login"
import * as constants from "./constants"
import accountController from "../../api/controllers/account"
import { actions } from "./slice"
import { loginFailed, loginProcess, loginSuccess } from "./actions"
import { handleHttpException } from "../handleHttpException"

export const login =
    (loginData: LoginRequest): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(loginProcess())
            const user = await accountController.login(loginData)
            dispatch(loginSuccess(user))
        } catch (e) {
            handleHttpException(e, constants.LOGIN_FAILED_MESSAGE, err => loginFailed(err), dispatch, false)
        }
    }

export const logout = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(actions.logoutProcess())
    try {
        await accountController.logout()
        dispatch(actions.logoutSuccess())
    } catch (e) {
        handleHttpException(e, constants.LOGOUT_FAILED_MESSAGE, err => actions.logoutFailed(err), dispatch, false)
    }
}
