import { Dispatch, SetStateAction, useEffect, useState } from "react"

export const useTooltipWithTimeout = (): [boolean, string, Dispatch<SetStateAction<string>>] => {
    const [tooltipLabel, setTooltipLabel] = useState("")
    const [show, setShow] = useState(false)

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null
        const onClick = () => {
            tooltipLabel && setShow(true)
            timeout = setTimeout(() => {
                setShow(false)
                setTooltipLabel("")
            }, 1000)
        }
        onClick()
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [tooltipLabel])

    return [show, tooltipLabel, setTooltipLabel]
}
