import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import PublicPage from "../../components/PublicPage/PublicPage"
import { useParams } from "react-router-dom"
import Async from "../../components/Async/Async"
import FullscreenLoader from "../../components/FullscreenLoader/FullscreenLoader"
import FullscreenError from "../../components/FullscreenError/FullscreenError"
import { selectResetPasswordSuccess, selectVerifyResetPasswordCodeState } from "../../store/password/selectors"
import { verifyResetPasswordCode } from "../../store/password/thunks"
import ResetPasswordForm from "../../components/ResetPasswordForm/ResetPasswordForm"
import { actions } from "../../store/password/slice"
import { useTranslation } from "react-i18next"
import { Button } from "react-bootstrap"
import { loginPath } from "../../routerPaths"
import { push } from "connected-react-router"
import "./ResetPassword.scss"

const tNamespace = "password:reset-password-form."

const ResetPassword: React.FC = () => {
    const dispatch = useDispatch()
    const { code } = useParams<{ code?: string }>()
    const { t } = useTranslation()

    const verifyResetPasswordCodeState = useSelector(selectVerifyResetPasswordCodeState)
    const resetPasswordSuccess = useSelector(selectResetPasswordSuccess)

    useEffect(() => {
        if (code !== undefined) {
            dispatch(verifyResetPasswordCode(code))
        }
    }, [code, dispatch])

    useEffect(() => {
        return () => {
            dispatch(actions.resetPasswordClear())
        }
    }, [dispatch])

    const handleToLoginPageClick = () => {
        dispatch(push(loginPath))
    }

    return (
        <Async
            dataState={verifyResetPasswordCodeState}
            processView={<FullscreenLoader />}
            errorView={({ message }) => <FullscreenError message={message} />}
        >
            {({ data }) => (
                <PublicPage className="reset-password" contentInCenter>
                    {resetPasswordSuccess ? (
                        <>
                            <PublicPage.Message
                                title={t(`${tNamespace}password-reset-message-title`)}
                                text={t(`${tNamespace}password-reset-message-text`)}
                            />
                            <div className="reset-password__login">
                                <Button onClick={handleToLoginPageClick}>{t(`${tNamespace}to-login-page`)}</Button>
                            </div>
                        </>
                    ) : (
                        <ResetPasswordForm verifyResponse={data} />
                    )}
                </PublicPage>
            )}
        </Async>
    )
}

export default ResetPassword
