import KnowledgeBaseHub from "./knowledgeBase"
import { Store } from "../../store/store"

class WorkplaceKnowledgeBaseHub extends KnowledgeBaseHub {
    constructor(store: Store) {
        super(store, "/knowledge-base-hub")
    }
}

export default WorkplaceKnowledgeBaseHub
