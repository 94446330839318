import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectAuthStatus } from "../../store/auth/selectors"
import { selectSearch } from "../../store/router/selectors"
import { getUrlParameter } from "./urlHelper"
import { push } from "connected-react-router"

const redirectParamKey = "continue"

export const useRedirect = (preventRedirect?: boolean) => {
    const authenticated = useSelector(selectAuthStatus)
    const search = useSelector(selectSearch)
    const dispatch = useDispatch()
    useEffect(() => {
        const redirectUrl = getUrlParameter(redirectParamKey, search)
        if (authenticated) {
            if (authenticated && !preventRedirect) {
                if (redirectUrl) {
                    window.location.href = redirectUrl
                } else {
                    dispatch(push("/"))
                }
            }
        }
    }, [authenticated, search, dispatch, preventRedirect])
}
