import { Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import surveysController from "../../api/controllers/surveys"
import { CreateSurveyRequest, GetSurveysRequest, UpdateSurveyRequest } from "../../models/survey"

export const defaultGetSurveysRequest: GetSurveysRequest = {
    PageFrom: 0,
    PageSize: 1000
}

export function getSurveys(projectId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getSurveysProcess())
            const { Data } = await surveysController.getSurveys(projectId, defaultGetSurveysRequest)
            dispatch(actions.getSurveysSuccess(Data))
        } catch (e) {
            handleHttpException(e, constants.GET_SURVEYS_FAILED_MESSAGE, err => actions.getSurveysFailed(err), dispatch)
        }
    }
}

export function createSurvey(projectId: string, request: CreateSurveyRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.createSurveyProcess())
            const { CreatedSurvey } = await surveysController.create(projectId, request)
            dispatch(actions.createSurveySuccess(CreatedSurvey))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_SURVEY_FAILED_MESSAGE,
                err => actions.createSurveyFailed(err),
                dispatch
            )
        }
    }
}

export function updateSurvey(projectId: string, surveyId: string, request: UpdateSurveyRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateSurveyProcess())
            const { UpdatedSurvey } = await surveysController.update(projectId, surveyId, request)
            dispatch(actions.updateSurveySuccess(UpdatedSurvey))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_SURVEY_FAILED_MESSAGE,
                err => actions.updateSurveyFailed(err),
                dispatch
            )
        }
    }
}

export function deleteSurvey(projectId: string, surveyId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.deleteSurveyProcess())
            await surveysController.delete(projectId, surveyId)
            dispatch(actions.deleteSurveySuccess(surveyId))
        } catch (e) {
            handleHttpException(
                e,
                constants.DELETE_SURVEY_FAILED_MESSAGE,
                err => actions.deleteSurveyFailed(err),
                dispatch
            )
        }
    }
}

export function getGlobalUserSurvey() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getGlobalUserSurveyProcess())
            const { Survey } = await surveysController.getGlobalUserSurvey()
            dispatch(actions.getGlobalUserSurveySuccess(Survey))
        } catch (e) {
            handleHttpException(
                e,
                constants.DELETE_SURVEY_FAILED_MESSAGE,
                err => actions.getGlobalUserSurveyFailed(err),
                dispatch
            )
        }
    }
}
