import React from "react"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faEye, IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import "./ArticleUserViewsButton.scss"

interface UsersCountProps extends ClassProps {
    count: number
    icon: IconDefinition
}

const UsersCount: React.FC<UsersCountProps> = props => {
    const { className, count, icon } = props

    return (
        <div className={cn("article-user-views-button__users-count", className)}>
            <div className="article-user-views-button__count">{count}</div>
            <FontAwesomeIcon icon={icon} size="lg" />
        </div>
    )
}

interface Props {
    viewsCount: number
    readConfirmationsCount: number
}

const ArticleUserViewsButton: React.FC<Props> = props => {
    const { viewsCount, readConfirmationsCount } = props

    return (
        <Button className="article-user-views-button" variant="link">
            <UsersCount className="article-user-views-button__views" count={viewsCount} icon={faEye} />
            <UsersCount className="article-user-views-button__confirms" count={readConfirmationsCount} icon={faCheck} />
        </Button>
    )
}

export default ArticleUserViewsButton
