import React, { createContext } from "react"

interface ModalContextType {
    showModal: (component: React.FC) => void
    hideModal: () => void
}

export const ModalContext = createContext<ModalContextType>({
    showModal: () => void 0,
    hideModal: () => void 0
})
