import React, { useCallback } from "react"
import "./SubArticles.scss"
import { faFileAlt } from "@fortawesome/pro-light-svg-icons/faFileAlt"
import IconButton from "../IconButton/IconButton"
import { TreeItem } from "@atlaskit/tree/types"
import { WithT } from "i18next"
import ScalableList from "../ScalableList/ScalableList"

const tKnowledgeBaseNamespace = "knowledgeBase:"

const listMaxLength = 3

interface SubArticlesProps {
    subArticles: TreeItem[]
    onSubArticleClick: (category: TreeItem) => void
}

const SubArticles: React.FC<SubArticlesProps & WithT> = props => {
    const { subArticles, onSubArticleClick, t } = props

    const handleSubArticleClick = useCallback(
        (subArticle: TreeItem) => {
            return () => {
                onSubArticleClick(subArticle)
            }
        },
        [onSubArticleClick]
    )

    const getContentItem = (subArticle: TreeItem) => {
        return (
            <IconButton
                key={subArticle.id}
                className="sub-articles__item"
                variant="link"
                icon={faFileAlt}
                iconSize="lg"
                onClick={handleSubArticleClick(subArticle)}
            >
                {subArticle.data.title}
            </IconButton>
        )
    }

    return (
        <div className="sub-articles">
            <h4 className="sub-articles__header">{t(`${tKnowledgeBaseNamespace}sub-articles`)}</h4>
            <ScalableList t={t} items={subArticles} limit={listMaxLength} mainContainerClassName="sub-articles__body">
                {getContentItem}
            </ScalableList>
        </div>
    )
}

export default SubArticles
