import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PublicPage from "../../components/PublicPage/PublicPage"
import { useTranslation } from "react-i18next"
import { actions } from "../../store/password/slice"
import { selectForgotPasswordEmailAddress } from "../../store/password/selectors"
import ForgotPasswordForm from "../../components/ForgotPassword/ForgotPasswordForm"

const tNamespace = "password:forgot-password-form."

const ForgotPassword: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const sentEmailAddress = useSelector(selectForgotPasswordEmailAddress)

    useEffect(() => {
        return () => {
            dispatch(actions.forgotPasswordClear())
        }
    }, [dispatch])

    return (
        <PublicPage contentInCenter>
            {sentEmailAddress ? (
                <PublicPage.Message
                    title={t(`${tNamespace}email-sent-message-title`)}
                    text={t(`${tNamespace}email-sent-message-text`, {
                        email: sentEmailAddress
                    })}
                />
            ) : (
                <ForgotPasswordForm />
            )}
        </PublicPage>
    )
}

export default ForgotPassword
