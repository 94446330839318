import { User } from "./user"
import { MessageAttachment } from "./Dialogs/message"

export enum ArticleCommentType {
    Comment = "comment",
    ThreadItem = "thread-item"
}

export type ArticleComment = {
    Id: string
    Type: ArticleCommentType
    ProjectId: string
    ArticleCode: string
    ParentId?: string
    Message: ArticleCommentMessage
    ReplyCount: number
    AddedTimestamp: number
    User: User
}

export type ArticleCommentMessage = {
    Text: string
    Attachments?: MessageAttachment[]
}

export type ArticleCommentThread = {
    RootComment: ArticleComment
    Items: ArticleComment[]
}

export interface ArticleCommentInput {
    MessageText: string
}

export interface ArticleCommentInputStates {
    [articleId: string]: ArticleCommentInput
}

export interface NewArticleReplyAddedPayload {
    ThreadId: string
    Value: ArticleComment
}

export interface GetArticleCommentsRequest {
    ArticleCode: string
}

export interface GetArticleReplyRequest {
    ArticleCode: string
    ThreadId: string
}

export interface AddArticleCommentRequest {
    ArticleTitle: string
    ArticleCode: string
    Message: ArticleCommentInput
}

export interface AddArticleReplyRequest {
    ArticleTitle: string
    ArticleCode: string
    ThreadId: string
    Message: ArticleCommentInput
}
