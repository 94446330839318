import React, { ReactNode, useCallback, useState, useRef } from "react"
import "./ContextMenu.scss"
import { CustomOverlayProps } from "../../utility/common/customOverlayTypes"
import { Overlay } from "react-bootstrap"
import cn from "classnames"
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay"

interface Props extends CustomOverlayProps {
    children: ReactNode
    items: ReactNode
    closeWhenClickInside?: boolean
    containerClassName?: string
    onHide?: () => void
    disabled?: boolean
    onCancelEditClick?: () => void
    keepOnlyOneOpened?: boolean
}

function ContextMenu(props: Props) {
    const {
        children,
        items,
        className,
        closeWhenClickInside = true,
        containerClassName,
        onHide,
        disabled = false,
        onCancelEditClick,
        keepOnlyOneOpened
    } = props

    const triggerRef = useRef<HTMLDivElement>(null)
    const [show, setShow] = useState(false)

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()

            if (!disabled) {
                setShow(!show)
            }
        },
        [show, disabled]
    )

    const handleCloseOtherMenus = () => {
        const contextMenus: NodeListOf<HTMLDivElement> = document.querySelectorAll(".context-menu")
        contextMenus.forEach(menu => {
            if (menu !== triggerRef.current) {
                menu.style.display = "none"
            }
        })
    }

    return (
        <>
            <Overlay
                {...props}
                rootClose={true}
                onHide={() => {
                    setShow(false)
                    onHide && onHide()
                }}
                show={show}
                target={triggerRef ? (triggerRef.current ? triggerRef.current : null) : null}
            >
                {({ ref, placement, style }: OverlayInjectedProps) => (
                    <div
                        ref={ref}
                        style={style}
                        className={cn("context-menu", className)}
                        data-placement={placement}
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            e.stopPropagation()
                            closeWhenClickInside && setShow(false)
                        }}
                    >
                        {items}
                    </div>
                )}
            </Overlay>
            <div
                className={cn("context-menu__container", containerClassName)}
                ref={triggerRef}
                onClick={e => {
                    handleClick(e)
                    keepOnlyOneOpened && handleCloseOtherMenus()
                }}
            >
                {children}
            </div>
        </>
    )
}

export default ContextMenu
