import React from "react"
import "./TabsBase.scss"
import cn from "classnames"
import { Nav, Tab } from "react-bootstrap"
import Can from "../Can/Can"
import { TabEntry } from "../../types/tabEntry"

interface TabsBaseProps {
    id: string
    alwaysReload: boolean
    entries: TabEntry[]
    handleSelect: (key: string | null, e: React.SyntheticEvent<unknown>) => void
    activeKey: string
    invalidTabKeys?: string[]
}

const TabsBase: React.FC<TabsBaseProps> = props => {
    const { id, alwaysReload, entries, invalidTabKeys, activeKey, handleSelect } = props

    const getTabClassname = (key: string) => cn("tab", invalidTabKeys?.includes(key) && "is-invalid")

    return (
        <Tab.Container
            id={id}
            activeKey={activeKey}
            mountOnEnter={alwaysReload}
            unmountOnExit={alwaysReload}
            onSelect={handleSelect}
        >
            <div>
                <Nav className="tabs">
                    {entries.map(e =>
                        e.permission ? (
                            <Can key={e.key} permission={e.permission}>
                                <Nav.Item key={e.key}>
                                    <Nav.Link className={cn("tab", getTabClassname(e.key))} eventKey={e.key}>
                                        {e.value}
                                    </Nav.Link>
                                </Nav.Item>
                            </Can>
                        ) : (
                            <Nav.Item key={e.key}>
                                <Nav.Link className={cn("tab", getTabClassname(e.key))} eventKey={e.key}>
                                    {e.value}
                                </Nav.Link>
                            </Nav.Item>
                        )
                    )}
                </Nav>
            </div>
            <Tab.Content className="tab__content">{props.children}</Tab.Content>
        </Tab.Container>
    )
}

export default TabsBase
