import React from "react"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { AgentValues } from "../../../../models/agent"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { FormikAgentFormProps } from "../../AgentForm"

const tNamespace = "agent:form.finishdialogagent."
const tFormNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const FormikFinishDialogAgentGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, disabled } = props

    return (
        <AgentFormWrapper
            agentType={agentType}
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tFormNamespace}title`)}
                />
                <DebouncedValidatableInput
                    id="formFinishReason"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "finish_reason")}
                    label={t(`${tNamespace}reason-for-finishing`)}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikFinishDialogAgentGeneral
