import React from "react"
import Skeleton from "../Skeleton/Skeleton"
import useDelay from "../../utility/common/useDelay"

import "./CategoriesLoader.scss"

interface CategoriesLoaderProps {
    itemsCount: number
}

const CategoriesLoader: React.FC<CategoriesLoaderProps> = props => {
    const { itemsCount } = props

    const display = useDelay()
    if (!display) return null

    return (
        <div className="categories-loader">
            <div className="categories-loader__items">
                {new Array<number>(itemsCount).fill(0).map((_, i) => (
                    <div key={i} className="categories-loader__item">
                        <Skeleton />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CategoriesLoader
