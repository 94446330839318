import {
    IGetOperatorClientBadgesResponse,
    Dialog,
    DialogBadge,
    DialogsSurveys,
    IGetOperatorClientResponse,
    IGetOperatorClientRequest,
    IGetOperatorsListResponse,
    IGetOperatorsListRequest,
    IGetOperatorChannelsResponse,
    ISearchClientsByCriterionResponse,
    IGetDialogTopicsRequest,
    IGetDialogTopicsResponse,
    IUpdateDialogTopicsRequest,
    ISearchOperatorsByCriterionResponse,
    ISearchOperatorsByCriterionRequest,
    IGetDialogTransferAvailableQueuesByOperatorRequest,
    IGetDialogTransferAvailableQueues,
    IOperatorDialogTransferToOperatorRequest,
    IOperatorDialogTransferToQueueRequest,
    IGetUserChannelsResponse,
    IGetUserChannelsRequest,
    ISetDialogChannelRequest
} from "../../models/Dialogs/dialog"
import { UpdateSlotContextChatUsersRequest, UpdateSlotContextRequest } from "../../models/Dialogs/surveyForm"
import {
    GetUpdatedMessagesRequest,
    GetUpdatedMessagesResponse,
    ISendFinishRequest,
    MergedMessageRequest,
    SendIntentRequest,
    SendMessageResponse
} from "../../models/Dialogs/message"
import { cacher } from "../../utility/common/rtkQueryCacheUtils"
import { queryApi } from "../api"
import {
    dialogBadgesTag,
    dialogMessagesTag,
    dialogsTag,
    dialogTopicsTag,
    activeDialogsCountTag,
    operatorChannelsTag,
    operatorClientBadgesTag,
    operatorClientsSectionTitleTag,
    operatorClientTag,
    operatorsListTag,
    userChannelsTag
} from "../tags"
// import { fakeClientSurvey, fakeDialogPerson } from "../fake/data"
/* import {
    mockedDialog,
    mockedDialogBadges,
    mockedGetUpdatedMessagesResponse,
    mockedOperatorClient,
    mockedOperatorClientBadges,
    mockedOperatorClientsSectionTitle,
    mockedOperatorsList
} from "../../utility/tests/mockedObjects" */
import { customSectionsTag, customMenusTag, dialogsSurveys } from "../tags"
import { CustomMenusValue, CustomSectionsValue } from "../../models/projectSettings"
import store from "../../store/store"
import { buildQueryString } from "../../helpers/url"
import { convertRequestToResponse } from "../../utility/message/convertRequestToResponse"
import { SYS_SLOTS_TO_UPDATE_MAPPING, isSlotSysFieldToUpdate } from "../../utility/dialogs/slots"
import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks"
import {
    getAIAssistHintByDialogId,
    getAIAssistHintByClientId,
    getAISuggestsByDialogId,
    getAISuggestsByClientId
} from "../../store/dialogs/thunks"

// import { mockedDialog, mockedDialogBadges, mockedGetUpdatedMessagesResponse, mockedActiveDialogsCount } from "../../utility/tests/mockedObjects"

import { apiInstance } from "../instances"
import { LegacyDialog } from "../../models/Dialogs/legacyDialog"
import { DialogDraftsLocalStore } from "../../utility/dialogs/dialogDraftsStore"
import { actions } from "../../store/dialogs/slice"

const dialogsController = {
    get: (id: string): Promise<LegacyDialog> => apiInstance.get(`dialogs/${id}`).then(response => response.data)
}

export default dialogsController

export const updateDialogBadges = (callback: (oldState: MaybeDrafted<DialogBadge[]>) => void) => {
    return dialogsApi.util.updateQueryData(DialogsApiEndpoints.getDialogBadges, undefined, oldState => {
        callback(oldState)
    })
}

export const dialogDraftsLocalStoreDriver = new DialogDraftsLocalStore<string>("dialogDraftsStore")

/*
  Во многих запросах здесь используется projectId
  Он бывает 2х видов:
  - тот, что у нас в редаксе выбран как активный на фронте на данный момент
  - тот, который был использован при создании диалога

  Пожалуйста, не забывайте эту специфику при разработке
  Чтобы понять, где какой projectId использовать, нужно почитать здесь -
  https://youtrack.craft-talk.ru/articles/CLOUD-A-11
*/

export const dialogsApi = queryApi.injectEndpoints({
    endpoints(build) {
        return {
            getOperatorClientsSectionTitle: build.query<string, string>({
                // queryFn: () => {
                //     return { data: mockedOperatorClientsSectionTitle }
                // },
                query: projectId => ({
                    url: `${projectId}/operator-clients/section-name`
                }),
                providesTags(result, error, arg) {
                    return cacher.cacheByIdArg(operatorClientsSectionTitleTag)(result, error, arg)
                }
            }),
            getOperatorClientsBadges: build.query<IGetOperatorClientBadgesResponse[], void>({
                // queryFn: () => {
                //     return { data: mockedOperatorClientBadges }
                // },
                query: () => ({
                    url: `/operator-clients`
                }),
                providesTags(result, error) {
                    const remapped = result?.map(x => ({
                        ...x,
                        Id: x.OmniUserId
                    }))

                    return cacher.providesList(operatorClientBadgesTag)(remapped, error)
                }
            }),
            searchClientsByCriterion: build.query<ISearchClientsByCriterionResponse[], string>({
                // queryFn: () => {
                //     return { data: mockedOperatorClientBadges }
                // }
                query: query => ({
                    url: `/user-profiles?query=${query}`
                })
            }),
            getOperatorsList: build.query<IGetOperatorsListResponse[], IGetOperatorsListRequest>({
                // queryFn: () => {
                //     return { data: mockedOperatorsList }
                // },
                query: ({ projectId, operators: OperatorIds }) => ({
                    url: `operators/${projectId}/info`,
                    method: "POST",
                    body: {
                        OperatorIds
                    }
                }),
                providesTags(result, error, arg) {
                    const argsStr = JSON.stringify(arg)
                    return cacher.cacheByIdArg(operatorsListTag)(result, error, argsStr)
                }
            }),
            getCustomSections: build.query<CustomSectionsValue, string>({
                query: (projectId: string) => ({
                    url: `/custom-sections?projectId=${projectId}`
                }),
                providesTags(result, error) {
                    const sections = result?.Values
                    return cacher.providesList(customSectionsTag)(sections, error)
                }
            }),
            getCustomMenus: build.query<CustomMenusValue, string>({
                query: (projectId: string) => ({
                    url: `/custom-menus?projectId=${projectId}`
                }),
                providesTags(result, error) {
                    const menus = result?.Values
                    return cacher.providesList(customMenusTag)(menus, error)
                }
            }),
            getDialogBadges: build.query<DialogBadge[], void>({
                // queryFn: () => {
                //     return { data: mockedDialogBadges }
                // },
                query: () => ({
                    url: "/dialogs"
                }),
                providesTags(result, error) {
                    return cacher.providesList(dialogBadgesTag)(result, error)
                }
            }),
            getActiveDialogsCount: build.query<number, void>({
                // queryFn: () => {
                //     return { data: mockedActiveDialogsCount }
                // },
                query: () => ({
                    url: "/dialog/active-dialogs-total"
                }),
                providesTags(result, error) {
                    return cacher.cacheByIdArg(activeDialogsCountTag)(result, error, "messagesCount")
                }
            }),
            getOperatorChannels: build.query<IGetOperatorChannelsResponse[], IGetOperatorClientRequest>({
                // queryFn: () => {
                //     return { data: mockedOperatorClient }
                // },
                query: ({ projectId, omniUserId }) => ({
                    url: buildQueryString({
                        baseUrl: "users/get_user_channels_info",
                        params: {
                            omni_user_id: omniUserId,
                            customer_id: projectId
                        }
                    })
                }),
                providesTags(result, error, arg) {
                    const argsStr = JSON.stringify(arg)
                    return cacher.cacheByIdArg(operatorChannelsTag)(result, error, argsStr)
                }
            }),
            getUserChannels: build.query<IGetUserChannelsResponse[], IGetUserChannelsRequest>({
                // queryFn: () => {
                //     return { data: mockedUserChannels }
                // },
                query: ({ projectId, omniUserId }) => ({
                    url: buildQueryString({
                        baseUrl: "users/get_user_channels",
                        params: {
                            omni_user_id: omniUserId,
                            customer_id: projectId
                        }
                    })
                }),
                providesTags(result, error, arg) {
                    const argsStr = JSON.stringify(arg)
                    return cacher.cacheByIdArg(userChannelsTag)(result, error, argsStr)
                }
            }),
            getOperatorClient: build.query<IGetOperatorClientResponse, IGetOperatorClientRequest>({
                // queryFn: () => {
                //     return { data: mockedOperatorClient }
                // },
                query: ({ projectId, omniUserId }) => ({
                    url: `${projectId}/chat-users/${omniUserId}`
                }),
                providesTags(result, error, arg) {
                    const argsStr = JSON.stringify(arg)
                    return cacher.cacheByIdArg(operatorClientTag)(result, error, argsStr)
                },
                async onQueryStarted({ omniUserId }, { dispatch, queryFulfilled }) {
                    const storedMessage = dialogDraftsLocalStoreDriver.get(omniUserId)

                    if (storedMessage) {
                        dispatch(actions.setMessageInput(storedMessage))
                        dispatch(actions.getAISuggestReset())
                    } else {
                        dispatch(actions.clearMessageInput())
                    }

                    await queryFulfilled
                    dispatch(getAIAssistHintByClientId(omniUserId))
                    dispatch(getAISuggestsByClientId(omniUserId))
                }
            }),
            getDialog: build.query<Dialog, string>({
                // queryFn: () => {
                //     return { data: mockedDialog }
                // },
                query: dialogId => ({
                    url: `dialog/${dialogId}`
                }),
                providesTags(result, error, arg) {
                    return cacher.cacheByIdArg(dialogsTag)(result, error, arg)
                },
                async onQueryStarted(dialogId, { dispatch, queryFulfilled }) {
                    const storedMessage = dialogDraftsLocalStoreDriver.get(dialogId)

                    if (storedMessage) {
                        dispatch(actions.setMessageInput(storedMessage))
                    } else {
                        dispatch(actions.clearMessageInput())
                    }

                    dispatch(actions.getAIAssistHintReset())

                    await queryFulfilled
                    dispatch(getAIAssistHintByDialogId(dialogId))
                    dispatch(getAISuggestsByDialogId(dialogId))
                }
            }),
            getDialogsSurveys: build.query<DialogsSurveys, string>({
                query: projectId => ({
                    url: `${projectId}/survey/dialog-surveys`
                }),
                providesTags(result, error, arg) {
                    return cacher.cacheByIdArg(dialogsSurveys)(result, error, arg)
                }
            }),
            getDialogMessages: build.query<GetUpdatedMessagesResponse, GetUpdatedMessagesRequest>({
                // queryFn: () => {
                //     return { data: mockedGetUpdatedMessagesResponse }
                // },
                query: ({ ProjectId, ...body }) => ({
                    url: `messages?projectId=${ProjectId}`,
                    method: "POST",
                    body
                }),
                providesTags(result, error) {
                    const messages = result?.Messages.map(m => m.Fields)
                    return cacher.providesList(dialogMessagesTag)(messages, error)
                }
            }),
            setDialogChannel: build.mutation<void, ISetDialogChannelRequest>({
                query: ({ dialogId, ...body }) => ({
                    url: `dialog/set_dialog_channel?dialogId=${dialogId}`,
                    method: "POST",
                    body
                })
            }),
            sendFinish: build.mutation<boolean, ISendFinishRequest>({
                query: ({ ProjectId, ...body }) => ({
                    url: `/message/send-finish?projectId=${ProjectId}`,
                    method: "POST",
                    body
                })
            }),
            sendIntent: build.mutation<boolean, SendIntentRequest>({
                query: ({ ProjectId, ...body }) => ({
                    url: `message/send-intent?projectId=${ProjectId}`,
                    method: "POST",
                    body
                })
            }),
            sendMessage: build.mutation<SendMessageResponse, MergedMessageRequest>({
                query: ({ message, projectId }) => ({
                    url: `/message/send-message?projectId=${projectId}`,
                    method: "POST",
                    body: message
                }),
                async onQueryStarted({ message, body }, { dispatch, queryFulfilled }) {
                    const currentOperator = store.getState().users.currentUser
                    const selectedDialogId = store.getState().dialogs.selectedDialogId

                    if (!selectedDialogId) {
                        return
                    }

                    const getDialogSelector = dialogsApi.endpoints.getDialog.select(selectedDialogId)
                    const getDialogQuery = getDialogSelector(store.getState())

                    if (!getDialogQuery.data) {
                        return
                    }

                    const getDialogBadgesPatchResult = dispatch(
                        updateDialogBadges(oldState => {
                            const idxIntoList = oldState.findIndex(x => x.Client.OmniUserId === message.OmniUserId)

                            if (idxIntoList > -1) {
                                oldState[idxIntoList].Preview = message.Text
                            }
                        })
                    )

                    await queryFulfilled.catch(getDialogBadgesPatchResult.undo)

                    const getDialogMessagesPatchResult = dispatch(
                        dialogsApi.util.updateQueryData(
                            "getDialogMessages",
                            { ...body, ProjectId: getDialogQuery.data.Project.Id },
                            draft => {
                                if (currentOperator.data) {
                                    const operatorId = currentOperator.data.Login //@TODO change to OminUserId
                                    draft.Messages.push(convertRequestToResponse(message, operatorId))
                                }
                            }
                        )
                    )

                    await queryFulfilled.catch(getDialogMessagesPatchResult.undo)
                }
            }),
            postUpdateSlotContext: build.mutation<boolean, UpdateSlotContextRequest>({
                query: ({ DialogId, UpdatedSlots }) => ({
                    url: `dialog/update_slot_context?dialogId=${DialogId}`,
                    method: "POST",
                    body: { UpdatedSlots }
                }),
                async onQueryStarted({ DialogId, UpdatedSlots }, { dispatch, queryFulfilled }) {
                    if (!DialogId) {
                        return
                    }

                    const getDialogPatchResult = dispatch(
                        dialogsApi.util.updateQueryData("getDialog", DialogId, oldState => {
                            UpdatedSlots.forEach(slot => {
                                if (isSlotSysFieldToUpdate(slot)) {
                                    oldState.Client[SYS_SLOTS_TO_UPDATE_MAPPING[slot.Id]] = slot.Value
                                }

                                const foundedSlotIndex = oldState.SlotContext.FilledSlots.findIndex(
                                    oldSlot => slot.Id === oldSlot.Id
                                )

                                if (foundedSlotIndex !== -1) {
                                    oldState.SlotContext.FilledSlots[foundedSlotIndex].Value = slot.Value
                                } else {
                                    oldState.SlotContext.FilledSlots.push(slot)
                                }
                            })
                        })
                    )

                    queryFulfilled.catch(getDialogPatchResult.undo)

                    const getDialogBadgesPatchResult = dispatch(
                        updateDialogBadges(oldState => {
                            const newState = oldState.map(badge => {
                                let newBadge = badge
                                if (badge.Id === DialogId) {
                                    UpdatedSlots.forEach(slot => {
                                        if (isSlotSysFieldToUpdate(slot)) {
                                            newBadge = {
                                                ...newBadge,
                                                Client: {
                                                    ...newBadge.Client,
                                                    [SYS_SLOTS_TO_UPDATE_MAPPING[slot.Id]]: slot.Value
                                                }
                                            }
                                        }
                                    })
                                }
                                return newBadge
                            })
                            Object.assign(oldState, newState)
                        })
                    )

                    queryFulfilled.catch(getDialogBadgesPatchResult.undo)
                },
                invalidatesTags: []
            }),
            postUpdateSlotContextChatUsers: build.mutation<boolean, UpdateSlotContextChatUsersRequest>({
                query: ({ ProjectId, OmniUserId, UpdatedSlots }) => ({
                    url: `${ProjectId}/chat-users/${OmniUserId}/slot_context`,
                    method: "POST",
                    body: { UpdatedSlots }
                }),
                async onQueryStarted({ OmniUserId, UpdatedSlots, ProjectId }, { dispatch, queryFulfilled }) {
                    if (!OmniUserId) {
                        return
                    }

                    const getOperatorClientPatchResult = dispatch(
                        dialogsApi.util.updateQueryData(
                            "getOperatorClient",
                            { projectId: ProjectId, omniUserId: OmniUserId },
                            oldState => {
                                UpdatedSlots.forEach(slot => {
                                    if (isSlotSysFieldToUpdate(slot)) {
                                        oldState[SYS_SLOTS_TO_UPDATE_MAPPING[slot.Id]] = slot.Value
                                        const findedSlotIndex = oldState.SlotContext.FilledSlots.findIndex(
                                            oldSlot => slot.Id === oldSlot.Id
                                        )

                                        if (findedSlotIndex !== -1) {
                                            oldState.SlotContext.FilledSlots[findedSlotIndex].Value = slot.Value
                                        }
                                    }
                                })
                            }
                        )
                    )

                    queryFulfilled.catch(getOperatorClientPatchResult.undo)

                    const getOperatorClientBadgesPatchResult = dispatch(
                        dialogsApi.util.updateQueryData("getOperatorClientsBadges", undefined, oldState => {
                            const newState = oldState.map(badge => {
                                let newBadge = badge
                                if (badge.OmniUserId === OmniUserId) {
                                    UpdatedSlots.forEach(slot => {
                                        if (isSlotSysFieldToUpdate(slot)) {
                                            newBadge = {
                                                ...newBadge,
                                                [SYS_SLOTS_TO_UPDATE_MAPPING[slot.Id]]: slot.Value
                                            }
                                        }
                                    })
                                }
                                return newBadge
                            })
                            Object.assign(oldState, newState)
                        })
                    )

                    queryFulfilled.catch(getOperatorClientBadgesPatchResult.undo)
                },
                invalidatesTags: []
            }),
            getDialogTopics: build.query<IGetDialogTopicsResponse[], IGetDialogTopicsRequest>({
                query: ({ ProjectId }) => ({
                    url: `${ProjectId}/dialog-topics`,
                    method: "GET"
                }),
                providesTags(result, error) {
                    return cacher.providesList(dialogTopicsTag)(result, error)
                }
            }),
            putUpdateDialogTopics: build.mutation<void, IUpdateDialogTopicsRequest>({
                query: ({ DialogId, ...body }) => ({
                    url: `dialog/topics?dialogId=${DialogId}`,
                    method: "PUT",
                    body
                }),
                async onQueryStarted({ DialogId, ...body }, { dispatch, queryFulfilled }) {
                    const getDialogPatchResult = dispatch(
                        dialogsApi.util.updateQueryData("getDialog", DialogId, oldState => {
                            oldState.Topics = body.Topics
                        })
                    )

                    await queryFulfilled.catch(getDialogPatchResult.undo)
                },
                invalidatesTags: []
            }),
            searchOperatorByCriterion: build.query<
                ISearchOperatorsByCriterionResponse[],
                ISearchOperatorsByCriterionRequest
            >({
                /* queryFn: ({ query, queues, roles, statuses }) => {
                    console.log(">>>", query, queues, roles, statuses)

                    const randCount = random(10, 30)
                    const mockedRands = []

                    for (let i = 0; i < randCount; i++) {
                        const randIdx = Math.floor(Math.random() * 3)
                        mockedRands.push(mockedSearchOperatorsByCriterion[randIdx])
                    }

                    return { data: mockedRands }
                }, */
                query: ({ projectId, ...body }) => ({
                    url: `${projectId}/operators/search-for-reroute`,
                    method: "POST",
                    body: {
                        SearchQuery: body.query,
                        Roles: body.roles,
                        Queues: body.queues,
                        Size: 100,
                        IncludeCurrentUser: body.includeCurrentUser
                    }
                })
            }),
            operatorDialogTransferToQueue: build.mutation<void, IOperatorDialogTransferToQueueRequest>({
                query: ({ DialogId, ...body }) => ({
                    url: `dialog/reroute_to_queue?dialogId=${DialogId}`,
                    method: "POST",
                    body
                })
            }),
            operatorDialogTransferToOperator: build.mutation<void, IOperatorDialogTransferToOperatorRequest>({
                query: ({ DialogId, ...body }) => ({
                    url: `dialog/reroute_to_operator?dialogId=${DialogId}`,
                    method: "POST",
                    body
                })
            }),
            getDialogTransferAvailableQueues: build.query<IGetDialogTransferAvailableQueues, string>({
                query: ProjectId => ({
                    url: `${ProjectId}/queues/queues-for-reroute`
                })
            }),
            getDialogTransferAvailableQueuesByOperator: build.query<
                IGetDialogTransferAvailableQueues,
                IGetDialogTransferAvailableQueuesByOperatorRequest
            >({
                query: ({ projectId, operatorId }) => ({
                    url: `${projectId}/operators/${operatorId}/queues-for-reroute`
                })
            }),
            postEnableDialogHold: build.mutation<void, string>({
                query: dialogId => ({
                    url: `dialogs/hold/enable?dialogId=${dialogId}`,
                    method: "POST"
                }),
                async onQueryStarted(dialogId, { dispatch, queryFulfilled }) {
                    const getDialogBadgesPatchResult = dispatch(
                        updateDialogBadges(oldState => {
                            const dialogById = oldState.findIndex(d => d.Id === dialogId)

                            if (dialogById > -1) {
                                oldState[dialogById].IsHoldEnabled = true
                            }
                        })
                    )

                    await queryFulfilled.catch(getDialogBadgesPatchResult.undo)
                }
            }),
            putDisableDialogHold: build.mutation<void, string>({
                query: dialogId => ({
                    url: `dialogs/hold/disable?dialogId=${dialogId}`,
                    method: "PUT"
                }),
                async onQueryStarted(dialogId, { dispatch, queryFulfilled }) {
                    const getDialogBadgesPatchResult = dispatch(
                        updateDialogBadges(oldState => {
                            const dialogById = oldState.findIndex(d => d.Id === dialogId)

                            if (dialogById > -1) {
                                oldState[dialogById].IsHoldEnabled = false
                            }
                        })
                    )

                    await queryFulfilled.catch(getDialogBadgesPatchResult.undo)
                }
            })
        }
    }
})

export type DialogsApiEndpointNames = keyof typeof dialogsApi.endpoints
export const DialogsApiEndpoints = Object.keys(dialogsApi.endpoints).reduce((obj, key) => {
    return {
        ...obj,
        [key]: key
    }
}, {} as { [K in DialogsApiEndpointNames]: K })

export const {
    useGetCustomSectionsQuery,
    useGetCustomMenusQuery,
    useSendMessageMutation,
    useSendIntentMutation,
    useGetDialogBadgesQuery,
    useGetActiveDialogsCountQuery,
    useGetOperatorClientsBadgesQuery,
    useLazyGetOperatorClientsBadgesQuery,
    useLazyGetUserChannelsQuery,
    useLazyGetOperatorsListQuery,
    useLazyGetOperatorChannelsQuery,
    useGetOperatorClientsSectionTitleQuery,
    useLazyGetOperatorClientQuery,
    useGetDialogMessagesQuery,
    useGetDialogsSurveysQuery,
    useLazyGetDialogQuery,
    usePostUpdateSlotContextMutation,
    usePostUpdateSlotContextChatUsersMutation,
    useSendFinishMutation,
    useSetDialogChannelMutation,
    useSearchClientsByCriterionQuery,
    useLazySearchClientsByCriterionQuery,
    usePutUpdateDialogTopicsMutation,
    useGetDialogTopicsQuery,
    useSearchOperatorByCriterionQuery,
    useLazySearchOperatorByCriterionQuery,
    useOperatorDialogTransferToQueueMutation,
    useOperatorDialogTransferToOperatorMutation,
    useGetDialogTransferAvailableQueuesQuery,
    useLazyGetDialogTransferAvailableQueuesQuery,
    useGetDialogTransferAvailableQueuesByOperatorQuery,
    useLazyGetDialogTransferAvailableQueuesByOperatorQuery,
    useLazyGetDialogTopicsQuery,
    usePostEnableDialogHoldMutation,
    usePutDisableDialogHoldMutation
} = dialogsApi
