import React, { memo } from "react"
import "./AgentNode.scss"
import CommonNode from "../Common/CommonNode"
import { AgentPayload, ScenarioBlockType } from "../../../../models/scenario"
import { NodeComponentProps } from "react-flow-renderer/dist/nocss/types"
import AgentIcon from "../../../AgentIcon/AgentIcon"
import { Agent } from "../../../../models/agent"
import AgentNodeOutputs from "./AgentNodeOutputs"
import { useTranslation } from "react-i18next"

export interface AgentNodeData extends AgentPayload {
    Agent?: Agent
    AddBlock: (source: string, sourceHandle: string) => void
}

type Props = NodeComponentProps<AgentNodeData>

const AgentNode: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { data, id, isConnectable } = props

    return (
        <CommonNode
            id={id}
            className="agent-node"
            headerClassName="agent-node__header"
            type={ScenarioBlockType.Agent}
            isConnectable={isConnectable}
        >
            <div className="agent-node__content">
                <div className="agent-node__agent">
                    <div className="agent-node__agent-icon">
                        <AgentIcon type={data.AgentType} />
                    </div>
                    <div className="agent-node__agent-content">
                        <span className="agent-node__agent-title">{data.AgentId}</span>
                        <span className="agent-node__agent-type">{t(`agent:agent-type.${data.AgentType}`)}</span>
                    </div>
                </div>
                <AgentNodeOutputs
                    blockId={id}
                    agentType={data.AgentType}
                    disabled={!data.OwnedByThisScenario}
                    onAddBlock={data.AddBlock}
                    isConnectable={isConnectable}
                />
            </div>
        </CommonNode>
    )
}

export default memo(AgentNode)
