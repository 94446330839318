import React from "react"
import styles from "./CatalogForm.module.scss"
import { FormikProps } from "formik/dist/types"
import { CatalogFormValues } from "../../models/catalog"
import { WithT } from "i18next"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { nameof } from "../../utility/common/nameof"
import { formTranslation } from "../../locales/form"
import { Button, Form } from "react-bootstrap"
import UserAccessForm from "../UserAccessForm/UserAccessForm"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import LoadingButton from "../LoadingButton/LoadingButton"
import { UserAccessCommonProps } from "../UserAccess/UserAccess"
import { EArticleEditFormChangesInheritance } from "../../models/article"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"

const tNamespace = "knowledgeBase:"

export interface FormikCatalogFormProps extends UserAccessCommonProps {
    loading: boolean
    onCancel: () => void
    isEditForm?: boolean
    disabledPermInheritance?: boolean
}

const FormikCatalogForm: React.FC<FormikCatalogFormProps & FormikProps<CatalogFormValues> & WithT> = props => {
    const { isEditForm, t, handleSubmit, onCancel, loading, disabledPermInheritance } = props

    return (
        <Form className={styles.catalogForm} onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <ValidatableInput
                id="catalogFormTitle"
                type="text"
                className="catalog-form__title"
                name={nameof<CatalogFormValues>("Title")}
                label={t(formTranslation.title)}
                placeholder={t(formTranslation.enterText)}
            />
            <UserAccessForm
                type="permission"
                title={t(`${tNamespace}permissions.title`)}
                name={nameof<CatalogFormValues>("Permissions")}
                {...props}
            />
            {isEditForm && !disabledPermInheritance && (
                <div className={styles.catalogForm__changesInheritance}>
                    <RadioButtonValidatableInput
                        value={EArticleEditFormChangesInheritance.ROOT}
                        id="catalog-changes-inheritance-root"
                        name="ChangesInheritance"
                        label={t(`${tNamespace}catalog-changes-inheritance.root`)}
                    />
                    <RadioButtonValidatableInput
                        value={EArticleEditFormChangesInheritance.INHERIT}
                        id="catalog-changes-inheritance-inherit"
                        name="ChangesInheritance"
                        label={t(`${tNamespace}catalog-changes-inheritance.inherit`)}
                    />
                </div>
            )}
            <div className={styles.catalogForm__footer}>
                <Button className={styles.catalogForm__cancel} variant="light" onClick={onCancel}>
                    {t(formTranslation.cancel)}
                </Button>
                <LoadingButton className={styles.catalogForm__submit} variant="primary" loading={loading} type="submit">
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default FormikCatalogForm
