import React from "react"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import styles from "./Indicator.module.scss"

export interface IndicatorProps extends ClassProps {
    children?: number | string | React.ReactNode
}

const Indicator: React.FC<IndicatorProps> = props => {
    const { children, className } = props

    if (!children) {
        return null
    }

    return <div className={cn(styles.indicator, className)}>{children}</div>
}

export default Indicator
