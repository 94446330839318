export const buildQueryString = ({
    baseUrl,
    params,
    convType
}: {
    baseUrl: string
    params: Record<string, string | number | boolean | undefined | null>
    convType?: "camelToUnderscore"
}) => {
    const paramsPrepared = Object.entries(params)
        .filter(([, v]) => v !== undefined)
        .map(([k, v]) => {
            v = String(v)

            if (convType === "camelToUnderscore") {
                return [camelToUnderscore(k), v]
            }

            return [k, v]
        })

    const searchParams = new URLSearchParams(paramsPrepared)

    return baseUrl + "?" + searchParams.toString()
}

export const camelToUnderscore = (key: string) => {
    const result = key.replace(/([A-Z])/g, " $1")
    return result.split(" ").join("_").toLowerCase()
}
