import React, { useEffect } from "react"
import styles from "./AuthenticationTokenInput.module.scss"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getRemoteWorkplaceAuthToken, refreshRemoteWorkplaceAuthToken } from "../../store/users/thunks"
import { selectRemoteWorkplaceAuthTokenState } from "../../store/users/selectors"
import { Col, Form, Row } from "react-bootstrap"
import CopyInput from "../CopyInput/CopyInput"
import InfoIcon from "../InfoIcon/InfoIcon"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"

const tNamespace = "user:"

const AuthenticationTokenInput: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const tokenState = useSelector(selectRemoteWorkplaceAuthTokenState)

    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}generate-new-token-alert.title`)}
            message={t(`${tNamespace}generate-new-token-alert.message`)}
            onClose={closeModal}
            onSubmit={handleGenerate}
            onExited={onExited}
        />
    ))

    const handleGenerate = () => {
        dispatch(refreshRemoteWorkplaceAuthToken())
    }

    useEffect(() => {
        dispatch(getRemoteWorkplaceAuthToken())
    }, [dispatch])

    return (
        <Form.Group as={Row} controlId="formToken" className={styles["authentication-token-input"]}>
            <Form.Label column sm={5}>
                {t(`${tNamespace}authentication-token`)}
            </Form.Label>
            <Col sm={{ span: 7 }}>
                <div className={styles["authentication-token-input__copy-input"]}>
                    <CopyInput
                        id="token"
                        value={tokenState.data ?? ""}
                        blocked={!tokenState.data}
                        onUpdate={openModal}
                        loading={tokenState.inProcess}
                    />
                </div>
                <span className={styles["authentication-token-input__info-icon"]}>
                    <InfoIcon id="token" content={t(`${tNamespace}tooltip:authentication-token`)} />
                </span>
            </Col>
        </Form.Group>
    )
}

export default AuthenticationTokenInput
