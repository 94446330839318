import React from "react"
import { useSelector } from "react-redux"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { AgentValues } from "../../../../models/agent"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { FormikAgentFormProps } from "../../AgentForm"
import { selectSlots } from "../../../../store/slots/selectors"
import { Form } from "react-bootstrap"
import { CreatableSelect } from "../../../Select/Select"
import { formTranslation } from "../../../../locales/form"
import TextItemSetting from "../../../TextItemSetting/TextItemSetting"
import { FieldArray } from "formik"
import Tag from "../../../Tag/Tag"
import { fileExtensions } from "../../../../utility/agents/fileExtensions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import OverrideChannelTypeControl, { OverrideChannelTypeValue } from "./OverrideChannelTypeControl"
import SlotSelectControl from "../../common/SlotSelectControl"
import { getSlotTitle } from "../../../../utility/knowledgeBase/slot"

const tNamespace = "agent:form.psbbssagent."
const tFormNamespace = "agent:form."
const tLinkNamespace = "agent:link."

export const overrideChannelType = "override-channel-type"

const FormikPsbBssAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, disabled, values } = props
    const slots = useSelector(selectSlots)

    return (
        <AgentFormWrapper
            agentType={agentType}
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tFormNamespace}title`)}
                />
                <DebouncedValidatableInput
                    id="formAuthToken"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "auth-token")}
                    label={t(`${tNamespace}auth-token`)}
                />
                <DebouncedValidatableInput
                    id="formStartSessionUrl"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "start-session-url")}
                    label={t(`${tNamespace}start-session-url`)}
                />
                <DebouncedValidatableInput
                    id="formContinueSessionUrl"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "continue-session-url")}
                    label={t(`${tNamespace}continue-session-url`)}
                />
                <DebouncedValidatableInput
                    id="formRedirectToOperatorAction"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "redirect-to-operator-action")}
                    label={t(`${tNamespace}redirect-to-operator-action`)}
                />
                <FieldArray
                    name={nameof2<AgentValues, ParamValues>("Params", "override-channel-type")}
                    render={({ push, remove }) => (
                        <>
                            <div className="agent-form__add-section">
                                <span className="agent-form__text">{t(`${tNamespace}override-channel-type`)}</span>
                                <span
                                    className="agent-form__button-icon"
                                    onClick={() => {
                                        push({
                                            from: "",
                                            to: ""
                                        })
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            </div>
                            {values.Params["override-channel-type"]?.map(
                                (v: OverrideChannelTypeValue, index: number) => (
                                    <OverrideChannelTypeControl
                                        key={index}
                                        index={index}
                                        disabled={disabled}
                                        onDelete={() => remove(index)}
                                        t={t}
                                    />
                                )
                            )}
                        </>
                    )}
                />
                <DebouncedValidatableInput
                    id="formOverrideDialogRouting"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "override-dialog-routing")}
                    label={t(`${tNamespace}override-dialog-routing`)}
                />
                <SlotSelectControl
                    id="formOverrideDialogRoutingVariable"
                    name={nameof2<AgentValues, ParamValues>("Params", "override-dialog-routing-variable")}
                    label={t(`${tNamespace}override-dialog-routing-variable`)}
                    t={t}
                />
                <DebouncedValidatableInput
                    id="formMaxAttachmentSizeMb"
                    type="number"
                    name={nameof2<AgentValues, ParamValues>("Params", "max-attachment-size-mb")}
                    label={t(`${tNamespace}max-attachment-size-mb`)}
                />
                <DebouncedValidatableInput
                    id="formUnsupportedAttachmentSizeMessage"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "unsupported-attachment-size-message")}
                    label={t(`${tNamespace}unsupported-attachment-size-message`)}
                />
                <FieldArray
                    name={nameof2<AgentValues, ParamValues>("Params", "allowed-attachment-extensions")}
                    render={({ push, remove }) => (
                        <>
                            <Form.Group controlId="formAllowedAttachmentExtensions">
                                <Form.Label>{t(`${tNamespace}allowed-attachment-extensions`)}</Form.Label>
                                <CreatableSelect
                                    value={{
                                        label: t(`${tNamespace}add-extension`),
                                        value: ""
                                    }}
                                    options={fileExtensions.map(ext => ({ label: ext, value: ext }))}
                                    noOptionsMessage={() => null}
                                    formatCreateLabel={(inputValue: string) =>
                                        `${t(`${tNamespace}add-extension`)}: ${inputValue}`
                                    }
                                    onChange={option => {
                                        if (option) {
                                            push(option.value)
                                        }
                                    }}
                                />
                            </Form.Group>
                            <div className="agent-form__tags">
                                {values.Params["allowed-attachment-extensions"]?.map((ext: string, index: number) => (
                                    <Tag
                                        title={ext}
                                        index={index}
                                        className="agent-form__tag"
                                        onDelete={() => remove(index)}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                />
                <DebouncedValidatableInput
                    id="formUnsupportedAttachmentMessage"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "unsupported-attachment-extension-mock-message")}
                    label={t(`${tNamespace}unsupported-attachment-message`)}
                />
                <FieldArray
                    name={nameof2<AgentValues, ParamValues>("Params", "continue-session-slot-ids")}
                    render={({ push, remove }) => (
                        <>
                            <Form.Group controlId="formContinueSessionSlotIds">
                                <Form.Label>{t(`${tNamespace}transmitted-slots`)}</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => push(e.target.value)}
                                    disabled={!slots.length}
                                    value=""
                                >
                                    <option value="" hidden>
                                        {t(formTranslation.select)}
                                    </option>
                                    {slots.map(slot => (
                                        <option key={slot.Id} value={slot.ExternalId}>
                                            {slot.Title}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            {values.Params["continue-session-slot-ids"]?.map((v: string, index: number) => (
                                <TextItemSetting
                                    key={index}
                                    title={getSlotTitle(v, slots)}
                                    onDelete={() => remove(index)}
                                />
                            ))}
                        </>
                    )}
                />
                <DebouncedValidatableInput
                    id="formFileStorageHost"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "file-storage-host")}
                    label={t(`${tNamespace}file-storage-host`)}
                    className="agent-form__margin-top"
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikPsbBssAgentFormGeneral
