import React, { useContext, useEffect, useRef } from "react"
import { isEdge, useStoreActions } from "react-flow-renderer"
import { Elements } from "react-flow-renderer/dist/types"
import MessageNode from "../Graph/nodes/Message/MessageNode"
import { CommandNode as CommandNodeType, CommandType, Scenario, ScenarioBlockType } from "../../models/scenario"
import StartNode from "../Graph/nodes/Start/StartNode"
import { ArticleScenarioContext, ScenarioContext } from "./ScenarioContext"
import { articleScenarioToGraph, getBlockId } from "../../utility/scenario/scenario"
import ArticleNode from "../Graph/nodes/Article/ArticleNode"
import { logError } from "../../utility/common/logError"
import FormNode from "../../components/Graph/nodes/Form/FormNode"
import ConditionsNode from "../Graph/nodes/Conditions/ConditionsNode/ConditionsNode"
import AgentNode from "../Graph/nodes/Agent/AgentNode"
import { useDispatch, useSelector } from "react-redux"
import { selectAgents } from "../../store/agents/selectors"
import CommandNode from "../Graph/nodes/Command/CommandNode"
import ScenarioGraph from "../Graph/ScenarioGraph"
import { getAgents } from "../../store/agents/thunks"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ViewAgents } from "../../permissions"

const nodeTypes = {
    [ScenarioBlockType.Start]: StartNode,
    [ScenarioBlockType.Message]: MessageNode,
    [ScenarioBlockType.Article]: ArticleNode,
    [ScenarioBlockType.Form]: FormNode,
    [ScenarioBlockType.Condition]: ConditionsNode,
    [ScenarioBlockType.Agent]: AgentNode,
    [ScenarioBlockType.Command]: CommandNode
}

interface Props {
    scenario?: Scenario
    isEditing: boolean
}

const ScenarioEditor: React.FC<Props> = props => {
    const { scenario, isEditing } = props
    const dispatch = useDispatch()
    const viewAgentsAllowed = usePermissionsCheck([ViewAgents])
    const agents = useSelector(selectAgents)
    const scenarioRef = useRef<Scenario>()
    const agentsInitialized = useRef(false)
    const resetSelectedElements = useStoreActions(state => state.resetSelectedElements)

    const { projectId, setElements, handleAddBlock, closeSidebar, setScenarioTouched } = useContext(ScenarioContext)

    const {
        handleOpenConditionForm,
        handleOpenAgentForm,
        handleOpenSetSlotValueForm,
        handleOpenDeleteSlotValueForm,
        setSelectedCondition
    } = useContext(ArticleScenarioContext)

    useEffect(() => {
        return () => {
            setScenarioTouched && setScenarioTouched(false)
        }
    }, [setScenarioTouched])

    const handleSelectionChange = (selections: Elements, selectedId: string | null) => {
        let isAgentSelected = false

        setElements(els =>
            els.map(e => {
                if (isEdge(e)) return e

                if (e.id === selectedId) {
                    switch (e.type) {
                        case ScenarioBlockType.Agent:
                            if (e.data.Agent) {
                                handleOpenAgentForm(
                                    e.data.Agent.Type,
                                    e.data.Agent,
                                    e.data.OwnedByThisScenario === "true",
                                    undefined,
                                    resetSelectedElements
                                )
                                isAgentSelected = true
                            } else {
                                logError("Agent for block not exist:", e.data.AgentId)
                            }
                            break
                        case ScenarioBlockType.Command:
                            const commandNode: CommandNodeType = { id: e.id, data: e.data }
                            if (
                                [CommandType.SetSlotValue, CommandType.SetSlotValueWithExpression].includes(
                                    e.data.Command
                                )
                            ) {
                                handleOpenSetSlotValueForm(commandNode, undefined, resetSelectedElements)
                            } else {
                                handleOpenDeleteSlotValueForm(commandNode, undefined, resetSelectedElements)
                            }
                            break
                    }
                }

                return e
            })
        )

        if (!isAgentSelected) closeSidebar()

        if (selectedId) {
            setSelectedCondition(null)
        }
    }

    useEffect(() => {
        if (
            scenario &&
            projectId &&
            (!isEditing || !viewAgentsAllowed || agents) &&
            scenarioRef.current !== scenario &&
            !agentsInitialized.current
        ) {
            scenarioRef.current = scenario
            agentsInitialized.current = true
            setElements([])
            setElements(articleScenarioToGraph(scenario, handleAddBlock, handleOpenConditionForm, setElements, agents))
        }
    }, [
        handleAddBlock,
        setElements,
        scenario,
        projectId,
        handleOpenConditionForm,
        agents,
        viewAgentsAllowed,
        isEditing
    ])

    useEffect(() => {
        if (!scenario) {
            const id = getBlockId()
            setElements([
                {
                    id,
                    type: ScenarioBlockType.Start,
                    data: {
                        AddBlock: handleAddBlock
                    },
                    position: { x: 0, y: 0 }
                }
            ])
        }
    }, [handleAddBlock, setElements, scenario])

    useEffect(() => {
        if (isEditing && projectId && viewAgentsAllowed) {
            dispatch(getAgents(projectId))
        }
    }, [projectId, isEditing, viewAgentsAllowed, dispatch])

    return <ScenarioGraph isEditing={isEditing} onSelectionChange={handleSelectionChange} nodeTypes={nodeTypes} />
}

export default ScenarioEditor
