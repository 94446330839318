import { ProjectSettings, SystemProjectSettings } from "../../../models/projectSettings"
import { SystemSettingsValues } from "../../../models/projectSettingsValues"
import { v4 as uuidv4 } from "uuid"

export const defaultSystemProjectSettings = {
    Security: {
        InformationMaskingPatterns: {
            Values: []
        }
    },
    System: {
        UpdateClientDataFromChannel: true,
        ChannelOnDialogPanel: false,
        OperatorQuestionaryId: "",
        DialogSwitchNotificationWebhook: "",
        SwitchToBotWindowTitle: "",
        SwitchToBotWindowMessage: "",
        DisableMarkupModal: true,
        KnowledgeBaseConvertTemplate: {
            FileName: "",
            FileUrl: ""
        },
        EnableMessageSendBehaviour: false,
        AdditionalResendConfigDependsOnQueue: false,
        ColorSchema: {
            ColorSchemaUsing: false,
            ColorSchemaBaseColor: "#1066A8",
            ColorSchemaEditorColor: "#1066A8",
            ColorSchemaLinkColor: "#1066A8"
        },
        LogoHugeSizeChangeAllow: false,
        ColorSchemaChangeAllow: false,
        CustomMenus: {
            Values: []
        }
    }
}

const buildCustomMenusValues = (settings: ProjectSettings) => {
    return {
        Values: settings.System
            ? settings.System.CustomMenus?.Values.map(item => {
                  return {
                      Id: item.Id ? item.Id : uuidv4(),
                      Title: item.Title ? item.Title : "",
                      Url: item.Url ? item.Url : "",
                      Icon: item.Icon ? item.Icon : ""
                  }
              })
            : []
    }
}

const buildColorSchemaValues = (settings: ProjectSettings, rootSystemSettings?: SystemProjectSettings) =>
    settings.System?.ColorSchema && settings.System?.ColorSchema.ColorSchemaUsing
        ? {
              ...settings.System.ColorSchema
          }
        : rootSystemSettings && rootSystemSettings.ColorSchema
        ? {
              ColorSchemaEditorColor: rootSystemSettings?.ColorSchema.ColorSchemaEditorColor ?? "",
              ColorSchemaLinkColor: rootSystemSettings?.ColorSchema.ColorSchemaLinkColor ?? "",
              ColorSchemaBaseColor: rootSystemSettings?.ColorSchema.ColorSchemaBaseColor ?? "",
              ColorSchemaUsing: false
          }
        : {
              ...defaultSystemProjectSettings.System.ColorSchema
          }

export const getSystemSettingsInitialValues = (
    settings: ProjectSettings,
    rootSystemSettings?: SystemProjectSettings
): SystemSettingsValues => ({
    Security: { ...defaultSystemProjectSettings.Security, ...settings.Security },
    System: {
        ...defaultSystemProjectSettings.System,
        ...settings.System,
        CustomMenus: buildCustomMenusValues(settings),
        ColorSchema: buildColorSchemaValues(settings, rootSystemSettings)
    }
})

export const buildUpdateSystemSettingsRequest = (values: SystemSettingsValues): ProjectSettings => {
    return {
        Security: {
            InformationMaskingPatterns: {
                Values: values.Security.InformationMaskingPatterns.Values.filter(Boolean)
            }
        },
        System: values.System
    }
}
