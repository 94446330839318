import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { isApiMessage } from "../../models/Dialogs/message"
import { DialogBadge } from "../../models/Dialogs/dialog"
import { Store } from "../../store/store"
import i18next from "i18next"
import { actions } from "../../store/dialogs/slice"
import { isCurrentUserSender, applyCallbacksToEvents, notifyOperator, isStarterMessage } from "./helpers"
import { logDebug, logError } from "../../utility/common/logError"
import { NotificationsTypes } from "../../utility/common/TabNotifications"
import { dialogsApi } from "../controllers/dialogs"
import {
    refetchActiveDialogsCount,
    refetchDialogBadges,
    selectDialogBadges,
    updateCurrentDialogBadgeWithOutgoing,
    updateDialogBadge,
    updateOperatorClientsBadges,
    upsertDialogBadges
} from "../controllers/dialogs.helpers"
import { uncapitalize } from "../../helpers/text"
import { DialogsHubEventCallbackList, DialogsHubEventCallbackNames, DialogsHubEvents } from "../../models/Dialogs/hub"
import { selectDialogId, selectMessagesPaginationState } from "../../store/dialogs/selectors"
import { getAIAssistHintByDialogId, getAISuggestsByDialogId } from "../../store/dialogs/thunks"
import { getClientFullname } from "../../utility/dialogs/client"
import { MESSAGES_PER_PAGE } from "../../utility/dialogs/constant"

const tDialogsNotificationsNamespace = "dialogs:notifications."

class DialogsHubV2 {
    private _hub: IHub
    private _store: Store
    private _callbackList: DialogsHubEventCallbackList

    constructor(store: Store) {
        const reduxState = store.getState()
        const useAllTransportSignalR =
            reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR === true

        this._hub = new Hub(`/dialogs-hub-v2`, useAllTransportSignalR)
        this._store = store
        this._callbackList = Object.keys(DialogsHubEvents).reduce((DialogsHubCallbacks, DialogsHubEvent) => {
            const callbackName = `${uncapitalize(DialogsHubEvent)}Callback` as DialogsHubEventCallbackNames
            const eventName = DialogsHubEvents[DialogsHubEvent as keyof typeof DialogsHubEvents]

            DialogsHubCallbacks[eventName] = this[callbackName].bind(this)

            return DialogsHubCallbacks
        }, {} as DialogsHubEventCallbackList)
    }

    async subscribe() {
        await this._hub.subscribe("Subscribe")
    }

    async unsubscribe() {
        await this._hub.unsubscribe("Unsubscribe")
    }

    getInstance(): IHub {
        return this._hub
    }

    private checkOldWorkplaceLoaded() {
        const nodeEl = document.querySelector('[name="crafttalk-workplace-old-frame"]')

        if (nodeEl) {
            throw new Error("Old workspace frame node already loaded. Passing")
        }
    }

    private async detachDialogCallback(data: unknown) {
        const dispatch = this._store.dispatch

        try {
            const { Id: detachedDialogId } = data as { Id: string }
            const dialogBadges = selectDialogBadges()
            let nextCurrentDialogId = ""

            if (dialogBadges && dialogBadges.length > 0) {
                let nextCurrentClientId = ""
                const projectId = dialogBadges[0].Project.Id
                const client = dialogBadges.find(dialogBadge => dialogBadge.Id === detachedDialogId)?.Client
                const filteredDialogBadges = dialogBadges.filter(dialogBadge => dialogBadge.Id !== detachedDialogId)

                if (filteredDialogBadges.length > 0) {
                    nextCurrentDialogId = filteredDialogBadges[0].Id
                    nextCurrentClientId = filteredDialogBadges[0].Client.OmniUserId
                }

                if (client) {
                    const operatorClientsBadgesSelector = dialogsApi.endpoints.getOperatorClientsBadges.select()
                    const operatorClientsBadges = operatorClientsBadgesSelector(this._store.getState())

                    if (Array.isArray(operatorClientsBadges.data)) {
                        dispatch(updateOperatorClientsBadges(client, projectId))
                    }
                }

                dispatch(
                    actions.setMessagesPaginationState({
                        OmniUserId: nextCurrentClientId,
                        ProjectId: projectId,
                        FromTodayDialogs: true,
                        StartTime: 0,
                        Count: MESSAGES_PER_PAGE
                    })
                )
                dispatch(upsertDialogBadges(filteredDialogBadges))
            }

            dispatch(refetchActiveDialogsCount())
            dispatch(actions.setCurrentDialogId(nextCurrentDialogId))
        } catch (e) {
            logError(e)
        }
    }

    private async attachDialogCallback(data: unknown) {
        const dispatch = this._store.dispatch

        try {
            this.checkOldWorkplaceLoaded()

            const { Badge: badge } = data as { Badge: DialogBadge }
            const dialogId = badge.Id
            const projectId = badge.Project.Id
            const dialogBadges = selectDialogBadges()
            const title = i18next.t(`${tDialogsNotificationsNamespace}new-dialog`)
            const clientFullname =
                getClientFullname(badge.Client) || i18next.t(`${tDialogsNotificationsNamespace}unknown-client`)
            const body = i18next.t(`${tDialogsNotificationsNamespace}client`) + " " + clientFullname
            const selectedDialogId = selectDialogId(this._store.getState())

            if (dialogBadges && !selectedDialogId) {
                dispatch(actions.setCurrentDialogId(dialogId))
                dispatch(
                    dialogsApi.endpoints.getDialogTopics.initiate({
                        ProjectId: projectId
                    })
                )
                dispatch(
                    actions.setMessagesPaginationState({
                        OmniUserId: badge.Client.OmniUserId,
                        ProjectId: projectId,
                        StartTime: 0,
                        Count: MESSAGES_PER_PAGE
                    })
                )
            }

            if (!dialogBadges || dialogBadges.length === 0) {
                dispatch(upsertDialogBadges([badge]))
            } else {
                dispatch(upsertDialogBadges([badge, ...dialogBadges]))
            }

            dispatch(actions.unsetCurrentOperatorClientId())
            dispatch(refetchActiveDialogsCount())

            notifyOperator(
                {
                    title,
                    body,
                    projectId,
                    dialogId,
                    clientId: badge.Client.OmniUserId,
                    dispatch: this._store.dispatch
                },
                { type: NotificationsTypes.DialogRouted }
            )
        } catch (e) {
            logDebug(e)
        }
    }

    private async handleMessageCallback(message: unknown) {
        const dispatch = this._store.dispatch

        try {
            this.checkOldWorkplaceLoaded()

            if (!isApiMessage(message)) {
                return
            }

            if (isCurrentUserSender(message, this._store)) {
                dispatch(updateCurrentDialogBadgeWithOutgoing(message.Fields.DialogId))
                return
            }

            const currentMessagePagination = selectMessagesPaginationState(this._store.getState())
            const selectedDialogId = selectDialogId(this._store.getState())
            let dialogBadges = selectDialogBadges()
            const isCached = dialogBadges?.some(x => x.Client.OmniUserId === message.Fields.Sender.Id)

            if (selectedDialogId === message.Fields.DialogId && currentMessagePagination) {
                dispatch(
                    dialogsApi.util.updateQueryData("getDialogMessages", currentMessagePagination, oldState => {
                        oldState.Messages.push(message)
                    })
                )
                dispatch(getAIAssistHintByDialogId(selectedDialogId))
                dispatch(getAISuggestsByDialogId(selectedDialogId))
            }

            if (!isCached) {
                dialogBadges = (await dispatch(refetchDialogBadges())).data
            }

            const badgeIndex = dialogBadges?.findIndex(
                dialogBadge => dialogBadge.Client.OmniUserId === message.Fields.Sender.Id
            )

            if (badgeIndex !== undefined && badgeIndex > -1) {
                const badge = dialogBadges?.[badgeIndex]

                if (badge) {
                    const title =
                        getClientFullname(badge.Client) || i18next.t(`${tDialogsNotificationsNamespace}unknown-client`)
                    const unreadMessages = i18next.t(`${tDialogsNotificationsNamespace}unread-messages`, {
                        value: badge.UnreadMessages
                    })
                    const body = `${unreadMessages} ${message.Fields.Text}`
                    const dialogId = message.Fields.DialogId

                    dispatch(updateDialogBadge(badgeIndex, badge, message))
                    dispatch(dialogsApi.endpoints.putDisableDialogHold.initiate(dialogId))

                    if (!isStarterMessage(message)) {
                        notifyOperator(
                            {
                                title,
                                body,
                                dialogId,
                                dispatch,
                                projectId: badge.Project.Id,
                                clientId: message.Fields.Sender.Id
                            },
                            { type: NotificationsTypes.NewMessage, message: String(badge.UnreadMessages + 1) }
                        )
                    }
                }
            }

            dispatch(refetchActiveDialogsCount())
        } catch (e) {
            logDebug(e)
        }
    }

    registerServerEvents() {
        applyCallbacksToEvents(this._callbackList, this._hub.registerEvent.bind(this._hub))
    }

    unregisterServerEvents() {
        applyCallbacksToEvents(this._callbackList, this._hub.unregisterEvent.bind(this._hub))
    }
}

export default DialogsHubV2
