import { apiInstance } from "../instances"
import { GetOperatorStatusesResponse, OperatorStatusChangeWithReason } from "../../models/operatorStatus"

const operatorStatusController = {
    get: (): Promise<GetOperatorStatusesResponse> => apiInstance.get("operator-status").then(response => response.data),
    update: (request: OperatorStatusChangeWithReason): Promise<void> =>
        apiInstance.post("operator-status", request).then(response => response.data)
}

export default operatorStatusController
