import { Notification, NotificationNewsType } from "../../models/notification"
import { NotificationDto, NotificationDtoType } from "../../models/notificationDto"
import mapNewsDescription from "./mapDescription"

export const notificationDtoTypeConvert = {
    toNotificationNewsType(value?: NotificationDtoType): NotificationNewsType {
        switch (value) {
            case "KnowledgeBase":
                return "knowledgeBase"
            case "InviteOperator":
                return "inviteOperator"
            default:
                return "commonNews"
        }
    }
}

export const notificationDtoConvert = {
    toNotification(value: NotificationDto): Notification {
        const notificationType = notificationDtoTypeConvert.toNotificationNewsType(value.Type)
        return {
            ...value,
            Type: notificationType,
            Description: mapNewsDescription(value)
        }
    }
}
