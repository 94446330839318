import React, { ReactNode } from "react"
import styles from "./SettingsGroup.module.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { Button } from "react-bootstrap"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import CheckBoxValidatableInput from "../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { useFormikContext } from "formik"
import FormAccordion from "../FormAccordion/FormAccordion"
import { faTrash, faCheck } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { testId } from "../../utility/tests/testId"

export interface Checkable<TValue> {
    value: TValue
    isChecked: boolean
}

export interface Settings<TValue> {
    [key: string]: Checkable<TValue>
}

export function initSettings<T, U>(
    container: Settings<T>,
    allSettings: T[],
    selectedSettings: U[],
    getKey: (value: T) => string,
    getSelectedKey: (value: U) => string
) {
    fillAllSettings(container, allSettings, getKey)
    checkInSelectedSettings(container, selectedSettings, getSelectedKey)

    function fillAllSettings<T>(container: Settings<T>, allSettings: T[], getKey: (value: T) => string) {
        allSettings.forEach(s => {
            container[getKey(s)] = { value: s, isChecked: false }
        })
    }

    function checkInSelectedSettings<T, U>(
        container: Settings<T>,
        selectedSettings: U[],
        getSelectedKey: (value: U) => string
    ) {
        selectedSettings.forEach(s => {
            const key = getSelectedKey(s)
            const setting = container[key]
            if (setting !== undefined) {
                setting.isChecked = true
            }
        })
    }
}

export function getCheckedValues<T>(settings: Settings<T>): T[] {
    return Object.values(settings)
        .filter(v => v.isChecked)
        .map(v => v.value)
}

export interface SettingsGroupProps<T> extends ClassProps {
    disabled?: boolean
    name: string
    title: string
    values: Settings<T>
    getLabel: (value: T) => string
    dataTestId?: string
    children?: ReactNode
}

function SettingsGroup<T>(props: SettingsGroupProps<T>) {
    const { className, disabled, name, title, values, getLabel, dataTestId, children } = props
    const { setFieldValue } = useFormikContext()
    const { t } = useTranslation()
    const keys = Object.keys(values)

    const handleSelectAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        keys.forEach(v => setFieldValue(`${name}.${v}.isChecked`, true))
    }

    const handleClearAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        keys.forEach(v => setFieldValue(`${name}.${v}.isChecked`, false))
    }

    return (
        <FormAccordion
            disabled={disabled}
            title={title}
            className={cn(styles.settingsGroup, className)}
            data-test-id={dataTestId}
        >
            <div className={styles.settingsGroup__buttons}>
                <Button
                    variant="light"
                    onClick={handleSelectAll}
                    className={styles.settingsGroup__btn}
                    data-test-id={testId.selectAll}
                >
                    <FontAwesomeIcon icon={faCheck} className={styles.settingsGroup__btnIcon} />
                    {t(formTranslation.selectAll)}
                </Button>
                <Button
                    variant="light"
                    onClick={handleClearAll}
                    className={styles.settingsGroup__btn}
                    data-test-id={testId.clearAll}
                >
                    <FontAwesomeIcon icon={faTrash} className={styles.settingsGroup__btnIcon} />
                    {t(formTranslation.clearAll)}
                </Button>
            </div>
            <div className={styles.settingsGroup__inputsContainer}>
                {Object.entries(values).map(([k, v]) => (
                    <div className={styles.settingsGroup__checkBoxInput} key={k}>
                        <CheckBoxValidatableInput
                            id={"form" + k}
                            disabled={disabled}
                            name={`${name}.${k}.isChecked`}
                            label={getLabel(v.value)}
                        />
                    </div>
                ))}
            </div>

            {children}
        </FormAccordion>
    )
}

export default SettingsGroup
