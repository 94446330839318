import React from "react"
import { FormikAgentFormProps } from "../../AgentForm"
import { AgentValues } from "../../../../models/agent"
import { useDispatch, useSelector } from "react-redux"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { formTranslation } from "../../../../locales/form"
import RadioButtonValidatableInput from "../../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { ParamValues } from "../../../../models/parameterDeclaration"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../../CheckBoxValidatableInput/DebouncedCheckBox"
import DocLink from "../../../DocLink/DocLink"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import ArticleSelect from "../../../ArticleSelect/ArticleSelect"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"

const tNamespace = "agent:form."
const tLinkNamespace = "agent:link."

enum PolicyTypes {
    OnNewUser = "OnNewUser",
    AnyTime = "AnyTime",
    Once24H = "Once24H"
}

const FormikInitialMsgAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentOptions, agentType, values, t, handleSubmit, disabled, ownedByThisScenario } = props

    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    return (
        <AgentFormWrapper
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
            agentType={agentType}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tNamespace}title`)}
                />
                <ArticleSelect
                    id="formText"
                    name={nameof2<AgentValues, ParamValues>("Params", "text")}
                    label={t(`${tNamespace}welcome-article`)}
                    disabled={ownedByThisScenario}
                    projectId={projectId}
                    searchBySymbolCode
                    allowSearchWithoutPermissions
                    dispatch={dispatch}
                />
                <DebouncedValidatableInput
                    as="select"
                    id="formAgentAfterGreeting"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "default_agent")}
                    label={t(`${tNamespace}agent-after-greeting`)}
                    disabled={!agentOptions.length || ownedByThisScenario}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentOptions.map(option => (
                        <option value={option.Id} key={option.Id}>
                            {option.Id}
                        </option>
                    ))}
                </DebouncedValidatableInput>
            </div>
            <div className="agent-form__section">
                <span className="agent-form__section-subtitle">{t(`${tNamespace}greetings-policy`)}</span>
                <RadioButtonValidatableInput
                    value={PolicyTypes.AnyTime}
                    id="formSendPolicy1"
                    name={nameof2<AgentValues, ParamValues>("Params", "send_policy")}
                    label={t(`${tNamespace}always`)}
                />
                <RadioButtonValidatableInput
                    value={PolicyTypes.OnNewUser}
                    id="formSendPolicy2"
                    name={nameof2<AgentValues, ParamValues>("Params", "send_policy")}
                    label={t(`${tNamespace}new-users`)}
                />
                <RadioButtonValidatableInput
                    value={PolicyTypes.Once24H}
                    id="formSendPolicy3"
                    name={nameof2<AgentValues, ParamValues>("Params", "send_policy")}
                    label={t(`${tNamespace}24-hours`)}
                />
            </div>
            <div className="agent-form__section">
                <DebouncedCheckBox
                    id="formAuth"
                    name={nameof2<AgentValues, ParamValues>("Params", "required_authorization")}
                    label={t(`${tNamespace}required-authorization`)}
                    className="agent-form__section-subtitle"
                />
            </div>
            <div className="extra-settings">
                <DebouncedValidatableInput
                    id="formRequiredAuthText"
                    as="textarea"
                    name={nameof2<AgentValues, ParamValues>("Params", "required_authorization_text")}
                    label={t(`${tNamespace}message-in-messenger`)}
                    disabled={!values.Params.required_authorization}
                    className="agent-form__template-textarea"
                />
                <DebouncedValidatableInput
                    id="formRequiredAuthTextPC"
                    as="textarea"
                    name={nameof2<AgentValues, ParamValues>("Params", "required_authorization_text_pc")}
                    label={t(`${tNamespace}manual-input-message`)}
                    disabled={!values.Params.required_authorization}
                    className="agent-form__template-textarea"
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikInitialMsgAgentFormGeneral
