import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { AgentValues } from "../../../../models/agent"
import ArticleSelect from "../../../ArticleSelect/ArticleSelect"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { FormikAgentFormProps } from "../../AgentForm"
import { SlotType } from "../../../../models/slot"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"
import SlotSelectControl from "../../common/SlotSelectControl"

const tNamespace = "agent:form.yandexmaplinkagent."
const tFormNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const FormikYandexMapAgentFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, disabled, ownedByThisScenario } = props

    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    return (
        <AgentFormWrapper
            agentType={agentType}
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <ValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tFormNamespace}title`)}
                />
                <SlotSelectControl
                    type={SlotType.Geo}
                    id="formGeoSlotId"
                    name={nameof2<AgentValues, ParamValues>("Params", "geo_slot_id")}
                    label={t(`${tNamespace}slot-with-coordinates`)}
                    t={t}
                />
                <ValidatableInput
                    id="formSearchQuery"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "search_query")}
                    label={t(`${tNamespace}search-object-name`)}
                />
                <ValidatableInput
                    id="formTextBeforeLink"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "text_before_link")}
                    label={t(`${tNamespace}text-before-link`)}
                />
                <ValidatableInput
                    id="formLinkText"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "link_text")}
                    label={t(`${tNamespace}hyperlink-text`)}
                />
                <ArticleSelect
                    id="formTextWithoutGeo"
                    name={nameof2<AgentValues, ParamValues>("Params", "text_without_geo")}
                    label={t(`${tNamespace}no-geodata-response`)}
                    disabled={ownedByThisScenario}
                    projectId={projectId}
                    searchBySymbolCode
                    allowSearchWithoutPermissions
                    dispatch={dispatch}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikYandexMapAgentFormGeneral
