import React from "react"
import { ArticleViewContextType } from "./ArticleViewContext"
import ArticleViewContext from "./ArticleViewContext"

type ArticleViewProviderProps = ArticleViewContextType

const ArticleViewProvider: React.FC<ArticleViewProviderProps> = props => {
    const { children, ...rest } = props

    return <ArticleViewContext.Provider value={{ ...rest }}>{children}</ArticleViewContext.Provider>
}

export default ArticleViewProvider
