import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { locales } from "./locales"
import { translationEn } from "./locales/en/translations"
import { translationRu } from "./locales/ru/translations"
import { logError } from "./utility/common/logError"

const resources = {
    en: translationEn,
    ru: translationRu
}

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        load: "languageOnly",
        whitelist: locales.map(l => l.id),
        detection: {
            checkWhitelist: true,
            checkForSimilarInWhitelist: true
        },
        fallbackLng: locales.filter(l => l.id === "ru")[0].id,
        saveMissing: true,
        missingKeyHandler: (lng, ns, key) => logError(`[i18next] key "${key}" is missing. Language: ${lng}, NS: ${ns}`)
    })

export default i18n
