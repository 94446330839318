import React from "react"
import { logError } from "../../utility/common/logError"
import FullscreenError from "../FullscreenError/FullscreenError"
import { useTranslation } from "react-i18next"
import { errorTranslation } from "../../locales/error"
import ErrorMessage from "../ErrorMessage/ErrorMessage"

interface Props extends ErrorBoundaryViewProps {
    local?: boolean
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(): State {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        logError(error, "Catch error", errorInfo)
        // TODO log to server
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            if (this.props.local) {
                return <ErrorBoundaryLocal />
            }
            return <ErrorBoundaryView hideLogo={this.props.hideLogo} />
        }
        return this.props.children
    }
}

interface ErrorBoundaryViewProps {
    hideLogo?: boolean
}

export const ErrorBoundaryView: React.FC<ErrorBoundaryViewProps> = props => {
    const { hideLogo } = props
    const { t } = useTranslation()
    return <FullscreenError message={t(errorTranslation.default)} hideLogo={hideLogo} />
}

export const ErrorBoundaryLocal: React.FC = () => {
    const { t } = useTranslation()
    return <ErrorMessage text={t(errorTranslation.default)} />
}

export default ErrorBoundary
