import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { FrontendConfig } from "../../models/config"
import { resetReducerState } from "../action"

export type ConfigState = Readonly<{
    config: AsyncState<FrontendConfig>
}>
const initialState: ConfigState = {
    config: AsyncState.create()
}

const config = createSlice({
    name: "config",
    initialState,
    reducers: {
        getConfigProcess(state) {
            state.config = state.config.toProcess()
        },
        getConfigSuccess(state, action: PayloadAction<FrontendConfig>) {
            state.config = state.config.toSuccess(action.payload)
        },
        getConfigFailed(state, action: PayloadAction<SystemError>) {
            state.config = state.config.toFailed(action.payload)
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export default config.reducer

export const actions = config.actions
