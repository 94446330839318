import React from "react"
import { WithT } from "i18next"
import { AgentType } from "../../models/agent"
import TypeFormCard from "../TypeFormCard/TypeFormCard"
import AgentIcon from "../AgentIcon/AgentIcon"

const tNamespace = "agent:"

interface CardProps extends WithT {
    type: AgentType
    title: string
    onSelect: (type: AgentType) => void
}

const AgentTypeFormCard: React.FC<CardProps> = props => {
    const { t, type, title, onSelect } = props

    return (
        <TypeFormCard
            icon={<AgentIcon type={type} />}
            onSelect={() => onSelect(type)}
            title={title}
            description={t(`${tNamespace}agent-type-desc.${type}`)}
            testId={`${type}-agent-type`}
        />
    )
}

export default AgentTypeFormCard
