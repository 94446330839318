import {
    Condition,
    ConditionStatement,
    ConditionStatementType,
    ConditionStatementValue,
    ConditionType,
    isConditionType,
    SlotSpecialValue
} from "../../models/scenarioCondition"
import { comparisonSigns } from "../../components/ChooseConditionsForm/controls/AttemptsControl"

export const DEFAULT_ATTEMPTS = 1

const getConditionStatementType = (condition?: ConditionType): ConditionStatementType => {
    if (!condition) {
        return ConditionStatementType.Value
    }
    switch (condition) {
        case ConditionType.Filled:
            return ConditionStatementType.Filled
        case ConditionType.Attempts:
        case ConditionType.Greater:
        case ConditionType.GreaterEqual:
        case ConditionType.Equal:
        case ConditionType.Less:
        case ConditionType.LessEqual:
            return ConditionStatementType.Attempts
        default:
            return ConditionStatementType.Value
    }
}

const isComparisonSign = (condition: ConditionType) => comparisonSigns.includes(condition)

const getConditionValue = (value: string, type: ConditionStatementType, condition?: string): ConditionType => {
    if (type === ConditionStatementType.Attempts) {
        // Support old version
        if (isConditionType(condition) && isComparisonSign(condition)) {
            return condition
        }

        const [sign] = value.split(" ")
        if (isConditionType(sign) && isComparisonSign(sign)) {
            return sign
        }
    }
    return ConditionType.Equal
}

const parseAttempts = (value: string, type: ConditionStatementType) => {
    if (!value || type !== ConditionStatementType.Attempts) {
        return DEFAULT_ATTEMPTS
    }
    return parseInt(value.replace(/^\D*/g, "")) || DEFAULT_ATTEMPTS
}

export const getConditionValues = (condition?: Condition): ConditionStatementValue[] => {
    if (!condition) return []

    return condition.And.map(c => {
        const type = getConditionStatementType(c.Condition)
        const value = c.Value ?? ""

        return {
            Type: type,
            Slot: c.Slot,
            Condition: getConditionValue(value, type, c.Condition),
            Attempts: parseAttempts(value, type),
            Value: type === ConditionStatementType.Value ? value : ""
        }
    })
}

export const conditionsWithValues: string[] = [
    SlotSpecialValue.AllMandatoryFilledIn,
    SlotSpecialValue.WithoutConditions
]

export const processConditionsFormValues = (values: ConditionStatementValue[]): ConditionStatement[] => {
    return values.map(c => {
        switch (c.Type) {
            case ConditionStatementType.Filled:
                return {
                    Slot: c.Slot,
                    Condition: ConditionType.Filled
                }
            case ConditionStatementType.Attempts:
                return {
                    Slot: c.Slot,
                    Value: `${c.Condition} ${c.Attempts.toString()}`,
                    Condition: ConditionType.Attempts
                }
            case ConditionStatementType.Value:
            default:
                return {
                    Slot: c.Slot,
                    Value: c.Value
                }
        }
    })
}
