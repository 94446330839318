import React from "react"
import { ColProps, FormLabel } from "react-bootstrap"

export interface InputLabelSharedProps {
    label?: string
    icon?: JSX.Element
}

export interface InputLabelProps extends InputLabelSharedProps {
    col?: ColProps
    column?: boolean
}

const InputLabel: React.FC<InputLabelProps> = props => {
    const { col, column = false, label, icon, children } = props

    if (!label) {
        return null
    }

    const getColumnParams = () => {
        if (column && col) {
            return { ...col }
        }
        return undefined
    }

    return (
        <FormLabel {...getColumnParams()} column={column}>
            {label}
            {icon}
            {children}
        </FormLabel>
    )
}

export default InputLabel
