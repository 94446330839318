import React from "react"
import Skeleton from "../Skeleton/Skeleton"
import cn from "classnames"
import styles from "./ArticleCommentComponent.module.scss"

const ArticleCommentComponentLoader: React.FC = () => {
    return (
        <div className={cn(styles.articleComment, styles.articleComment_loader)}>
            <div className={styles.articleComment__top}>
                <div className={styles.articleComment__user}>
                    <Skeleton />
                    <Skeleton />
                </div>
                <div className={styles.articleComment__date}>
                    <Skeleton />
                </div>
            </div>
            <div className={styles.articleComment__body}>
                <Skeleton />
            </div>
            <div className="article-comment__bottom">
                <Skeleton />
            </div>
        </div>
    )
}

export default ArticleCommentComponentLoader
