import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import styles from "./ProjectView.module.scss"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectState, selectThemeInitializingStatus } from "../../store/projects/selectors"
import { Menu } from "../../models/menu"
import { Project } from "../../models/project"
import { push } from "connected-react-router"
import { accessDeniedPath, projectPath, welcomePath } from "../../routerPaths"
import SidebarContext from "../SidebarContext/SidebarContext"
import cn from "classnames"
import { getProjectSettings, getReferences, selectProject } from "../../store/projects/thunks"
import { useParams, useRouteMatch } from "react-router-dom"
import ProjectMenuSwitch from "../ProjectMenuSwitch/ProjectMenuSwitch"
import ProjectSidebar from "../ProjectSidebar/ProjectSidebar"
import { getCookie } from "../../utility/auth/credentialHelper"
import NotificationList from "../NotificationList/NotificationList"
import ProjectViewVisibility from "../ProjectViewVisibility/ProjectViewVisibility"
import { getDefaultArticle } from "../../store/knowledgeBase/thunks"
import { selectGetDefaultArticleState } from "../../store/knowledgeBase/selectors"
import FullscreenError from "../FullscreenError/FullscreenError"
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader"
import { ViewNewKnowledgeBase, ViewNewOperatorUI } from "../../permissions"
import { setEmptyDefaultArticle } from "../../store/knowledgeBase/actions"
import AsyncStrict from "../Async/AsyncStrict"
import { Route } from "react-router"
import ConfigContext from "../ConfigContext/ConfigContext"
import { getAllowedMenuItems } from "../../utility/project/getAllowedMenuItems"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"
import { selectCurrentPermissions } from "../../store/users/selectors"
import { mainMenuItems, removeSidebarMenuItems } from "../../utility/menu/menuItems"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { checkProjectExist } from "../../utility/project/checkProjectExist"
import { CustomMenusValue } from "../../models/projectSettings"
import { remapCustomMenus } from "../../utility/common/remapCustomMenus"
import Indicator from "../Indicator/Indicator"
import { useGetActiveDialogsCountQuery } from "../../api/controllers/dialogs"
import { useAppSelector } from "../../store/store"
import { selectOperatorStatusState } from "../../store/userOperator/selectors"
import { OperatorStatusValue } from "../../models/operatorStatus"
import { resetReducerState } from "../../store/action"
import ProjectHeader from "../ProjectHeader/ProjectHeader"

interface Props {
    projects: Project[]
    mainMenu: Menu
    customMenus: CustomMenusValue
}

const ProjectView: React.FC<Props> = props => {
    const dispatch = useDispatch()
    const { projectId } = useParams<{ projectId?: string }>()
    const { path, url } = useRouteMatch()
    const operatorStatusState = useAppSelector(selectOperatorStatusState)
    const projectState = useSelector(selectCurrentProjectState)
    const themeInitStatus = useSelector(selectThemeInitializingStatus)
    const defaultArticleState = useSelector(selectGetDefaultArticleState)
    const viewNewKnowledgeBaseAllowed = usePermissionsCheck([ViewNewKnowledgeBase])
    const viewNewOperatorUIAllowed = usePermissionsCheck([ViewNewOperatorUI])
    const { projects, mainMenu, customMenus } = props
    const [sidebarOpened, setSidebarOpened] = useState<boolean>(false)
    const {
        WebConfig: {
            appSettings: { authMode, signUpEnabled }
        }
    } = useContext(ConfigContext)
    const selectedProject = projectState.data
    const currentPermissions = useSelector(selectCurrentPermissions)
    const isOperatorOffline = operatorStatusState.data?.Value === OperatorStatusValue.Offline

    const isDialogsCounterAllowed = viewNewOperatorUIAllowed && !isOperatorOffline && !themeInitStatus.inProcess

    const selectMenu = useCallback(
        (menuItemId: string) => {
            if (projectId) {
                dispatch(push(`${projectPath}/${projectId}/${menuItemId}`))
            }
        },
        [dispatch, projectId]
    )

    const mainMenuItemsAllowed = useMemo(
        () => getAllowedMenuItems(mainMenuItems, currentPermissions).concat(remapCustomMenus(customMenus)),
        [currentPermissions, customMenus]
    )

    const dialogMenu = useMemo(() => {
        return getAllowedMenuItems([definedMenuItems.Dialogs, definedMenuItems.DialogsOld], currentPermissions).shift()
    }, [currentPermissions])

    const menuItemsRestAllowed = useMemo(() => {
        const hiddenMenuItems = [...mainMenu.items, definedMenuItems.UserManagement]
        return getAllowedMenuItems(removeSidebarMenuItems(hiddenMenuItems), currentPermissions)
    }, [currentPermissions, mainMenu.items])

    const menuItemsAll = useMemo(() => {
        const items = [...mainMenuItemsAllowed, ...menuItemsRestAllowed, definedMenuItems.Profile]

        if (dialogMenu) {
            items.unshift(dialogMenu)
        }

        return items
    }, [mainMenuItemsAllowed, menuItemsRestAllowed, dialogMenu])

    const handleCheckMenuAvailability = useCallback(
        (menuId: string) => {
            const selectedMenuItem = menuItemsAll.find(i => i.id === menuId)
            return !menuId || !!selectedMenuItem
        },
        [menuItemsAll]
    )

    useEffect(() => {
        if (projectState.inProcess || projectState.error) {
            return
        }
        const toDefaultProject = () => {
            const cookieProject = getCookie("CurrentCustomerId")
            // redirect to default project
            cookieProject === "" || cookieProject === undefined || !checkProjectExist(cookieProject, projects)
                ? dispatch(push(`${projectPath}/${projects[0].id}`))
                : dispatch(push(`${projectPath}/${cookieProject}`))
        }
        if (projects.length) {
            const selectedProject = projectState.data
            // if user has projects
            if (!projectId) {
                toDefaultProject()
            } else if (
                !selectedProject ||
                (selectedProject && selectedProject.id !== projectId) ||
                !checkProjectExist(selectedProject.id, projects)
            ) {
                if (!checkProjectExist(projectId, projects)) {
                    // if user tried to unknown project
                    toDefaultProject()
                } else {
                    // select project in store
                    dispatch(selectProject(projectId))
                }
            }
        } else {
            if (authMode === "oidc" && !signUpEnabled) {
                dispatch(push(accessDeniedPath))
            } else {
                dispatch(push(welcomePath))
            }
        }
    }, [authMode, dispatch, projectId, projects, projectState, signUpEnabled])

    useEffect(() => {
        if (selectedProject) {
            dispatch(getReferences(selectedProject.id))
            dispatch(getProjectSettings(selectedProject.id))
        }
    }, [dispatch, selectedProject])

    useEffect(() => {
        if (selectedProject) {
            if (viewNewKnowledgeBaseAllowed) {
                dispatch(getDefaultArticle(selectedProject.id))
            } else {
                setEmptyDefaultArticle(dispatch)
            }
        }
    }, [dispatch, selectedProject, viewNewKnowledgeBaseAllowed])

    const { data: count } = useGetActiveDialogsCountQuery(undefined, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const getSelectedMenu = (menuId: string, selectedMainMenu?: string) =>
        selectedMainMenu ? selectedMainMenu === menuId : false
    const selectProjectUrl = (projectId: string, selectedMainMenu?: string) => {
        dispatch(resetReducerState())
        localStorage.setItem("selectingProjectId", projectId)
        dispatch(push(`${projectPath}/${projectId}/${selectedMainMenu || ""}`))
    }

    return (
        <ProjectViewVisibility projectId={projectId}>
            <SidebarContext.Provider value={sidebarOpened}>
                <ProjectHeader
                    selectProjectUrl={selectProjectUrl}
                    getSelectedMenu={getSelectedMenu}
                    selectMenu={selectMenu}
                />
                <div className={cn(styles.projectView, sidebarOpened && styles.projectView_sidebarOpened)}>
                    <NotificationList />
                    {isDialogsCounterAllowed && (
                        <Indicator className={styles.projectView__dialogsCounter}>{count}</Indicator>
                    )}
                    {!themeInitStatus.inProcess && (
                        <ProjectSidebar
                            onToggleSidebar={() => setSidebarOpened(!sidebarOpened)}
                            selectMenu={selectMenu}
                            mainMenuItems={mainMenuItemsAllowed}
                            menuItemsRest={menuItemsRestAllowed}
                            dialogMenu={dialogMenu}
                            userProfile={definedMenuItems.Profile}
                        />
                    )}
                    <div className={styles.projectView__content}>
                        <Container fluid className={styles.projectView__container}>
                            <Row className={styles.projectView__row}>
                                <Col className="p-0">
                                    <Route
                                        path={`${path}/:menuId?`}
                                        render={renderProps => (
                                            <AsyncStrict
                                                dataState={defaultArticleState}
                                                errorView={({ message }) => (
                                                    <FullscreenError hideLogo message={message} />
                                                )}
                                                processView={<FullscreenLoader hideLogo />}
                                            >
                                                {({ data }) => (
                                                    <ProjectMenuSwitch
                                                        {...renderProps}
                                                        url={url}
                                                        defaultArticleId={data}
                                                        onCheckMenuAvailability={handleCheckMenuAvailability}
                                                        defaultMenu={menuItemsAll[0]}
                                                        customMenus={customMenus}
                                                        projectId={selectedProject?.id}
                                                    />
                                                )}
                                            </AsyncStrict>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </SidebarContext.Provider>
        </ProjectViewVisibility>
    )
}

export default ProjectView
