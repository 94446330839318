import React from "react"
import "./Login.scss"

import LoginForm from "../../components/LoginForm/LoginForm"
import PublicPage from "../../components/PublicPage/PublicPage"

const Login: React.FC = () => {
    return (
        <PublicPage contentInCenter>
            <LoginForm />
        </PublicPage>
    )
}

export default Login
