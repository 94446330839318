import React from "react"
import PairSettingsControl from "../../../PairSettingsControl/PairSettingsControl"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"
import { nameof2 } from "../../../../utility/common/nameof"
import { Agent, AgentValues } from "../../../../models/agent"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { formTranslation } from "../../../../locales/form"
import { UserType } from "../../../../models/user"
import { WithT } from "i18next"

const tNamespace = "agent:form.identificationagent."

interface Props extends WithT {
    index: number
    onDelete: () => void
    disabled?: boolean
    agentOptions: Agent[]
    clientTypes: UserType[]
}

const IdentificationAgentConfigItemControl: React.FC<Props> = props => {
    const { index, t, onDelete, disabled, agentOptions, clientTypes } = props

    return (
        <>
            <PairSettingsControl
                id={`formDefinition${index}`}
                keyControl={
                    <ValidatableInput
                        label={t(`${tNamespace}client-type`)}
                        id={`formUserType${index}`}
                        as="select"
                        name={`${nameof2<AgentValues, ParamValues>("Params", "definition")}[${index}].UserType`}
                        disabled={!clientTypes.length}
                    >
                        <option value="" hidden>
                            {t(formTranslation.select)}
                        </option>
                        {clientTypes.map(({ id, name }) => (
                            <option value={id} key={id}>
                                {t(name.replace(/^users/, "user"))}
                            </option>
                        ))}
                    </ValidatableInput>
                }
                valueControl={
                    <ValidatableInput
                        id={`formAction${index}`}
                        as="select"
                        name={`${nameof2<AgentValues, ParamValues>("Params", "definition")}[${index}].Action`}
                        label={t(`${tNamespace}agent`)}
                    >
                        <option value="" hidden>
                            {t(formTranslation.select)}
                        </option>
                        {agentOptions.map(({ Id }) => (
                            <option value={Id} key={Id}>
                                {Id}
                            </option>
                        ))}
                    </ValidatableInput>
                }
                onDelete={onDelete}
                disabled={disabled}
            />
        </>
    )
}

export default IdentificationAgentConfigItemControl
