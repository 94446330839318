import { ThunkAction, ThunkDispatch } from "redux-thunk"
import { Action } from "redux"
import { RootState } from "../../store/rootReducer"

type DefaultExtraArg = undefined

export type ActionResult<Result> = ThunkAction<Result, RootState, DefaultExtraArg, Action>
export type Dispatch = ThunkDispatch<RootState, DefaultExtraArg, Action>

export const getItemById = <I extends { id: string }>(items: I[], searchingItemId: string) => {
    const filteredItems = items.filter(item => item.id === searchingItemId)
    return filteredItems.length ? filteredItems[0] : undefined
}
