import React, { useCallback } from "react"
import { CloseButton, Col, Form } from "react-bootstrap"
import Slider from "../Slider/Slider"
import { useTranslation } from "react-i18next"
import { useField, useFormikContext } from "formik"
import { AIAssist2AgentValues } from "../../models/agent"
import styles from "./AgentClassifierSettingsInput.module.scss"

const tNamespace = "agent:form."

interface Props {
    index: number
    onDelete: () => void
}

const AgentClassifierSettingsInput: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { index, onDelete } = props
    const { values, setFieldValue } = useFormikContext<AIAssist2AgentValues>()
    const [weightField, weightMeta] = useField(`ClassifiersConf.${index}.ClassifierWeight`)

    const handleThresholdChange = useCallback(
        (v: number) => {
            setFieldValue(`ClassifiersConf.${index}.BotClassificationResultThreshold`, v, false)
        },
        [index, setFieldValue]
    )

    return (
        <div className={styles.agentClassifier}>
            <div className={styles.agentClassifier__header}>
                <span className={styles.agentClassifier__title}>{values.ClassifiersConf[index].Name}</span>
                <CloseButton onClick={onDelete} />
            </div>
            <div className={styles.agentClassifier__content}>
                <Form.Row>
                    <Col md={8}>
                        <Form.Row>
                            <Form.Group as={Col} controlId={`formThreshold${index}`}>
                                <Form.Label>{t(`${tNamespace}threshold`)}</Form.Label>
                                <Slider
                                    min={0}
                                    max={100}
                                    step={1}
                                    marks={{ 0: "0", 100: "100" }}
                                    onChange={handleThresholdChange}
                                    value={values.ClassifiersConf[index].BotClassificationResultThreshold}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    <Col md={3}>
                        <Form.Row>
                            <Form.Group as={Col} sm={{ span: 8, offset: 2 }} controlId={`formWeight${index}`}>
                                <Form.Label>{t(`${tNamespace}weight`)}</Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    className={styles.agentClassifier__spinButton}
                                    name={weightField.name}
                                    onChange={weightField.onChange}
                                    onBlur={weightField.onBlur}
                                    value={weightField.value}
                                    min={0}
                                    max={99}
                                    step={0.1}
                                    isInvalid={weightMeta.touched && !!weightMeta.error}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                </Form.Row>
            </div>
        </div>
    )
}

export default AgentClassifierSettingsInput
