import React from "react"
import SettingsTable, { SettingsTableProps } from "../SettingsTable"
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table"

export interface SettingsTableWithoutPaginationProps<T extends object> extends SettingsTableProps<T> {
    columns: Column<T>[]
    data: T[]
}

function SettingsTableWithoutPagination<T extends object>(props: SettingsTableWithoutPaginationProps<T>) {
    const { columns, data, btnAddProps, onRowClick } = props
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)

    return (
        <SettingsTable
            tableInstance={tableInstance}
            onRowClick={onRowClick}
            btnAddProps={btnAddProps}
            rows={tableInstance.rows}
        />
    )
}

export default SettingsTableWithoutPagination
