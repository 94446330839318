import React, { useContext, useEffect } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import PublicPage from "../../components/PublicPage/PublicPage"
import { useParams } from "react-router-dom"
import { verifySignUpCode } from "../../store/signUp/thunks"
import SignUpForm from "../../components/SignUpForm/SignUpForm"
import { selectVerifySignUpCodeState } from "../../store/signUp/selectors"
import Async from "../../components/Async/Async"
import FullscreenLoader from "../../components/FullscreenLoader/FullscreenLoader"
import FullscreenError from "../../components/FullscreenError/FullscreenError"
import ConfigContext from "../../components/ConfigContext/ConfigContext"
import { Redirect } from "react-router"

const SignUp: React.FC = () => {
    const {
        WebConfig: {
            appSettings: { signUpEnabled }
        }
    } = useContext(ConfigContext)
    const dispatch = useDispatch()
    const { code } = useParams<{ code?: string }>()
    const verifySignUpCodeState = useSelector(selectVerifySignUpCodeState, shallowEqual)

    useEffect(() => {
        if (code !== undefined) {
            dispatch(verifySignUpCode(code))
        }
    }, [code, dispatch])
    if (!signUpEnabled) {
        return <Redirect to="/" />
    }

    return (
        <Async
            dataState={verifySignUpCodeState}
            processView={<FullscreenLoader />}
            errorView={({ message }) => <FullscreenError message={message} />}
        >
            {({ data }) => (
                <PublicPage contentInCenter>
                    <SignUpForm verifyResponse={data} />
                </PublicPage>
            )}
        </Async>
    )
}

export default SignUp
