import React from "react"
import { Image } from "react-bootstrap"
import styles from "./UserAvatar.module.scss"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"

export interface UserAvatarProps extends ClassProps {
    src?: string
}

const UserAvatar: React.FC<UserAvatarProps> = props => {
    const { src, className } = props

    return <Image className={cn(styles.avatar, className)} src={src} />
}

export default UserAvatar
