import React, { createContext } from "react"
import { Elements, OnLoadParams } from "react-flow-renderer/dist/types"
import { SlotsMapType } from "../../models/slot"
import { CommandNode, Connection, OpenConditionFormHandler } from "../../models/scenario"
import { Agent, AgentType } from "../../models/agent"
import { Condition, ConditionStatement } from "../../models/scenarioCondition"
import { HandleAddSlotFunction } from "../../pages/KnowledgeBase/KnowledgeBaseSidebar"

export type SetElementsType = React.Dispatch<React.SetStateAction<Elements>>

export type AddConditionsCallback = (condition: Condition) => void
export type UpdateConditionsCallback = (conditions: Condition[]) => void

export type OpenConditionFormCallback = (
    conditions: ConditionStatement[],
    id?: string,
    callback?: AddConditionsCallback
) => void

export interface ScenarioContextType {
    elements: Elements
    setElements: SetElementsType
    instance: OnLoadParams | null
    setInstance: React.Dispatch<React.SetStateAction<OnLoadParams | null>>
    selectedNode: string | null
    scenarioTouched?: boolean
    setSelectedNode: React.Dispatch<React.SetStateAction<string | null>>
    setScenarioTouched?: React.Dispatch<React.SetStateAction<boolean>>
    handleAddBlock: (source?: string, setSourceHandle?: string) => void
    projectId?: string
    closeSidebar: () => void
}

export const scenarioContextDefaultValue: ScenarioContextType = {
    elements: [],
    setElements: () => void 0,
    instance: null,
    setInstance: () => void 0,
    selectedNode: null,
    setSelectedNode: () => void 0,
    handleAddBlock: () => void 0,
    closeSidebar: () => void 0
}

export const ScenarioContext = createContext<ScenarioContextType>(scenarioContextDefaultValue)

export interface ArticleScenarioContextType {
    handleAddSlot: HandleAddSlotFunction
    handleOpenConditionForm: OpenConditionFormHandler
    handleOpenAgentForm: (
        agentType: AgentType,
        agent?: Agent,
        ownedByThisScenario?: boolean,
        connection?: Connection,
        onCloseSidebar?: () => void
    ) => void
    handleOpenSetSlotValueForm: (
        commandNode?: CommandNode,
        connection?: Connection,
        onCloseSidebar?: () => void
    ) => void
    handleOpenDeleteSlotValueForm: (
        commandNode?: CommandNode,
        connection?: Connection,
        onCloseSidebar?: () => void
    ) => void
    slotsMap: SlotsMapType
    selectedCondition: string | null
    setSelectedCondition: React.Dispatch<React.SetStateAction<string | null>>
}

export const articleScenarioContextDefaultValue: ArticleScenarioContextType = {
    handleAddSlot: () => void 0,
    handleOpenConditionForm: () => void 0,
    handleOpenAgentForm: () => void 0,
    handleOpenSetSlotValueForm: () => void 0,
    handleOpenDeleteSlotValueForm: () => void 0,
    slotsMap: {},
    selectedCondition: null,
    setSelectedCondition: () => void 0
}

export const ArticleScenarioContext = createContext<ArticleScenarioContextType>(articleScenarioContextDefaultValue)
