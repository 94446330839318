import { Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import HorizontalValidatableInput from "../HorizontalValidatableInput/HorizontalValidatableInput"
import { Formik, FormikProps } from "formik"
import React from "react"
import styles from "./ArticleFillSlotsForm.module.scss"
import LoadingButton from "../LoadingButton/LoadingButton"
import * as Yup from "yup"
import { FillingSlot } from "../../models/slot"
import { WithT } from "i18next"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import cn from "classnames"

interface ArticleFillSlotsFormProps {
    slotsToFill: FillingSlot[]
    submit: (slots: Record<string, string>) => void
    alertingText: string
}

const FormikArticleFillSlotsForm: React.FC<ArticleFillSlotsFormProps & FormikProps<unknown> & WithT> = props => {
    const { slotsToFill, handleSubmit, t, alertingText } = props

    return (
        <Form className={styles.articleFillSlotsForm} onKeyPress={preventSubmitOnEnter} onSubmit={handleSubmit}>
            <div className={styles.articleFillSlotsForm__text}>{alertingText}</div>
            {slotsToFill.map(slot => (
                <HorizontalValidatableInput
                    id={slot.Id}
                    type="text"
                    name={slot.Id}
                    label={slot.Title}
                    labelCol={{ sm: 5 }}
                    inputCol={{ sm: 7 }}
                />
            ))}
            <LoadingButton
                type="submit"
                className={cn("btn-submit", styles.articleFillSlotsForm__button)}
                loading={false}
                variant="primary"
            >
                {t(formTranslation.save)}
            </LoadingButton>
        </Form>
    )
}

const ArticleFillSlotsForm: React.FC<ArticleFillSlotsFormProps> = props => {
    const { slotsToFill, submit } = props

    const { t } = useTranslation()

    const validationSchema = Object.assign(
        {},
        ...slotsToFill.map(slot => ({ [slot.Id]: Yup.string().required(formTranslation.requiredField) }))
    )

    return (
        <Formik
            initialValues={Object.assign({}, ...slotsToFill.map(slot => ({ [slot.Id]: slot.Value })))}
            onSubmit={values => submit(values)}
            validationSchema={Yup.object().shape(validationSchema)}
        >
            {formikProps => <FormikArticleFillSlotsForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default ArticleFillSlotsForm
